import { Button, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import ArrowsOutCardinal from 'assets/forms/ArrowsOutCardinal.svg';
import Trash from 'assets/forms/Trash.svg';
import purpulePlus from 'assets/purpulePlus.svg';

import '../media.css';
import '../style.css';
import './media.css';

const DragHandle = sortableHandle(() => <span><img src={ArrowsOutCardinal} alt="ArrowsOutCardinal" /></span>);

const SortableItem = sortableElement(({ children }) => (
    children
));

const SortableContainer = sortableContainer(({ children }) => {
    return <ol style={{ padding: 0 }}>{children}</ol>;
});


function GroupDivions({
    create_task,
    changeType,
    saveTaskForm,
    deleteTaskForm,
    stepUp,
    stepDown
}) {
    const [fields, setFields] = useState([])

    const [form] = Form.useForm();
    const tasks = Form.useWatch('tasks', form);

    const { t } = useTranslation();

    useEffect(() => {
        if (create_task.create_task !== null) {
            setFields([
                {
                    name: ['name'],
                    value: create_task.create_task.name,
                },
                {
                    name: ['tasks'],
                    value: create_task.create_task.tasks,
                },
                {
                    name: ['hashtags'],
                    value: (create_task.create_task.hashtags.length === 1 || create_task.create_task.hashtags.length === 0)
                        ? create_task.create_task.hashtags
                        : [{
                            first_hashtag: create_task.create_task.hashtags[0],
                            second_hashtag: create_task.create_task.hashtags[1],
                            third_hashtag: create_task.create_task.hashtags[2],
                            fourth_hashtag: create_task.create_task.hashtags[3]
                        }],
                },
            ])
        }
    }, [])

    const onFinish = (values) => {
        if (values?.hashtags?.length) {
            values.hashtags = [
                values.hashtags[0].first_hashtag,
                values.hashtags[0].second_hashtag,
                values.hashtags[0].third_hashtag,
                values.hashtags[0].fourth_hashtag,
            ]
        }
        if (values.tasks !== undefined && values.tasks.length !== 0) {
            for (let i = 0; i < values.tasks.length; i++) {
                if (values.tasks[i].answers === undefined) {
                    values.tasks[i].answers = []
                }
            }
            saveTaskForm(values)
            stepUp()
        } else {
            message.error("Вопросы отсутствуют!")
        }
    };

    const goBack = () => {
        deleteTaskForm()
        stepDown()
    }

    const onSortEnd = (oldIndex, newIndex, move) => {
        move(oldIndex, newIndex)
    };

    return (
        <>
            <Form
                name="basic"
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                fields={fields}
                autoComplete="off"
                className="modalForm createTaskForm"
                form={form}
            >
                <h2>Хеш-теги</h2>
                <Form.List name="hashtags">
                    {(fields, { add, remove, move }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <>
                                    <p>Обязательно нужно указать возраст, класс и предмет</p>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: 24, marginTop: 12 }} key={`hashtag-${name}`}>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'first_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'second_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'third_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'fourth_hashtag']}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t(`games.title_error`),
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <img src={Trash} alt="Trash" onClick={() => remove(name)} style={{ cursor: 'pointer' }} />
                                    </div>
                                </>
                            ))}
                            {fields.length == 0 &&
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={add}
                                    >
                                        <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />Добавить хеш-теги</p>
                                    </Button>
                                </Form.Item>
                            }
                        </>
                    )}
                </Form.List>
                <h2>Задание</h2>
                <Form.Item
                    label={t(`games.title`)}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(`games.title_error`),
                        },
                    ]}
                >
                    <Input className="formInput" />
                </Form.Item>

                <Form.List name="tasks">
                    {(fields, { add, remove }) => (
                        <>
                            <div className='groupDivionFormBlock'>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div className='groupDivionFormBlock_item' key={key}>
                                        <div className='groupDivionTitle'>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'title']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Введите название группы",
                                                    },
                                                ]}
                                            >
                                                <Input className='groupDivionTitleInput' placeholder='Название группы' bordered={false} />
                                            </Form.Item>
                                            <img src={Trash} alt="Trash" onClick={() => remove(name)} />
                                        </div>
                                        <Form.List
                                            {...restField}
                                            name={[name, 'answers']}
                                        >
                                            {(groupField, { add, remove, move }) => (
                                                <>
                                                    <SortableContainer onSortEnd={(e) => onSortEnd(e.oldIndex, e.newIndex, move)} useDragHandle>
                                                        {groupField.map(({ key, name, ...restField }) => (
                                                            <SortableItem key={`item-${name}`} index={name} >
                                                                <li index={key}>
                                                                    <div key={key}>
                                                                        <div>
                                                                            <div>
                                                                                <div className='quizesQuestionBlock'>
                                                                                    <div className='form_matchElementquestionBlock'>
                                                                                        <div className='form_matchElementquestionBlock__item'>
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                name={[name, 'answer']}
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: t(`games.title_error`),
                                                                                                    },
                                                                                                ]}
                                                                                            >
                                                                                                <Input className="formInput" />
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='form_questionOption'>
                                                                                        <DragHandle />
                                                                                        {/* <img src={Copy} alt="Copy" onClick={() => onCopy(key, name, add)} /> */}
                                                                                        <img src={Trash} alt="Trash" onClick={() => remove(name)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </SortableItem>
                                                        ))}
                                                        <Form.Item>
                                                            <Button type="link" onClick={() => add()} >
                                                                <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />{t(`games.addRow`)}</p>
                                                            </Button>
                                                        </Form.Item>
                                                    </SortableContainer>
                                                </>
                                            )}
                                        </Form.List>
                                    </div>
                                ))}
                            </div>
                            <Form.Item>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        if (fields.length === 6) {
                                            message.error("Достигнут лимит по созданию элементов!")
                                        } else {
                                            add()
                                        }
                                    }}
                                >
                                    <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />Добавить группу</p>
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item className='createTaskForm_buttons'>
                    <span className='editProfileButton' onClick={goBack}>{t(`games.backButton`)}</span>
                    <button htmltype="submit" className='editProfileButton'>{t(`games.saveButton`)}</button>
                </Form.Item>
            </Form>
        </>
    );
};

function mapStateToProps(state) {
    return {
        create_task: state.create_task
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        changeType: (text) => dispatch({ type: 'changeType', payload: text }),
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        deleteTaskForm: () => dispatch({ type: 'deleteTaskForm' }),
        stepUp: () => dispatch({ type: 'stepUp' }),
        stepDown: () => dispatch({ type: 'stepDown' }),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(GroupDivions);