import { ApiUrl, instance } from './api';

export const api_folders = {

    async ReadeFolders() {
        return await instance.get(`${ApiUrl}/api/v1/folders/get_by_owner?skip=0&limit=100`)
    },

    async ReadeOneFolder(id) {
        return await instance.get(`${ApiUrl}/api/v1/folders/${id}`)
    },

    async CreateFolder(data) {
        return await instance.post(`${ApiUrl}/api/v1/folders`, data)
    },

    async UpdateOneFolder(id, data) {
        return await instance.put(`${ApiUrl}/api/v1/folders/${id}`, data)
    },

    async DeleteOneFolder(id) {
        return await instance.delete(`${ApiUrl}/api/v1/folders/${id}`)
    },
}