export const pdfTaskInfo = [
    {
        game_type: 'TYPE_QUIZ',
        info: 'Выбери правильный вариант(ы) ответа'
    },
    {
        game_type: 'TYPE_INSERT_LETTER',
        info: 'Напиши пропущенные слова, используя предложенные варианты'
    },
    {
        game_type: 'TYPE_FIND_COUPLE',
        info: 'Подбери пару для каждого элемента, соедини их линиями'
    },
    {
        game_type: 'TYPE_WRITE_ANSWER',
        info: 'Напиши ответ, соблюдая правило: один символ в одну клетку'
    },
    {
        game_type: 'TYPE_ANAGRAM',
        info: 'Напиши слова, расставляя буквы в правильном порядке'
    },
    {
        game_type: 'TYPE_MATCH_ELEMENTS',
        info: 'Соотнеси варианты друг с другом'
    },
    {
        game_type: 'TYPE_GROUPS_DIVISION',
        info: 'Распредели варианты ответов по группам'
    },
    {
        game_type: 'TYPE_SPACE_MATHEMATICS',
        info: 'Закрась правильный ответ (ответы)'
    },
    {
        game_type: 'TYPE_INSERT_LETTER_SECOND',
        info: null //'Закрась букву, которую нужно вставить в слово.'
    },
    {
        game_type: 'TYPE_CROSSWORD',
        info: 'Дайте ответ на все вопросы заполнив кроссворд.'
    },
    {
        game_type: 'TYPE_YES_OR_NOT',
        info: 'Это ложь или истина? Выбери правильный ответ.'
    }
]

export const subscribePdfPrint = [
    {
        subscription: 'Start',
        games: []
    },
    {
        subscription: 'Standart',
        games: ['TYPE_MATCH_ELEMENTS', 'TYPE_QUIZ', 'TYPE_ANAGRAM', 'TYPE_WRITE_ANSWER', 'TYPE_FIND_COUPLE', 'TYPE_GROUPS_DIVISION', 'TYPE_INSERT_LETTER', 'TYPE_SPACE_MATHEMATICS', 'TYPE_INSERT_LETTER_SECOND', 'TYPE_CROSSWORD', 'TYPE_YES_OR_NOT']
    },
    {
        subscription: 'Standart+',
        games: ['TYPE_MATCH_ELEMENTS', 'TYPE_QUIZ', 'TYPE_ANAGRAM', 'TYPE_WRITE_ANSWER', 'TYPE_FIND_COUPLE', 'TYPE_GROUPS_DIVISION', 'TYPE_INSERT_LETTER', 'TYPE_SPACE_MATHEMATICS', 'TYPE_INSERT_LETTER_SECOND', 'TYPE_CROSSWORD', 'TYPE_YES_OR_NOT']
    }
]