import { getRandomInt } from 'functions/generalFunction';
import { useEffect, useRef, useState } from 'react';

import '../style.css';
import './style.css';

const bgArray = ['#ff7675', '#fdcb6e', '#00b894', '#cf6a87']

function FindCouple({ game }) {
    const [answers, setAnswers] = useState([])
    const [answersBg, setAnswersBg] = useState(Array(game.length))
    const temp = useRef()

    const startFunction = () => {
        const arr = []
        const arrayAnswersBg = []
        game.forEach(item => {
            arr.push({
                answer: item.answer,
                answer_photo: item.answer_photo
            })
            arrayAnswersBg.push(bgArray[getRandomInt(bgArray.length - 1)])
        })
        setAnswers(arr.sort(() => Math.random() - 0.5))
        setAnswersBg(arrayAnswersBg)
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    return (
        <div className='exercisesCardBg_content'>
            <div className='exercisesCardBg_content__card'>
                {game[0].photo !== null
                    ? <img src={game[0].photo} />
                    : <p style={{ fontSize: '15px' }}>{game[0].text}</p>
                }
            </div>
            <div className="insertLetterAnswers insertLetterBg">
                {answers.map((item, key) => {
                    return <div
                        key={key}
                        style={{ backgroundColor: `${answersBg[key]}`, padding: '12px 16px', color: 'white' }}
                        className='findCoupleBg_item'
                    >
                        {item.answer_photo && <img src={item.answer_photo} alt='answer_photo' className='findCoupleBg_img' />}   
                        {item.answer}
                    </div>
                })}
            </div>
        </div>
    );
}

export default FindCouple;
