import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchOneExercises } from "store/actions/oneExercises";

import { api_exercises } from 'API/exercises';
import { createGame } from "functions/createGames";
import { patternsGame } from "lists/list";
import { formthemes, spaseMathematics } from 'lists/themes';

import CreateTaskSteps from 'components/components/createTaskSteps/CreateTaskSteps';
import Pattern from 'components/components/pattern/Pattern';
import SelectFormType from 'components/forms/games/selectFormType';
import GameBlock from "components/games/GameBlock";

import { getQueryStringFromObject } from 'functions/filter';
import { fetchToggleHashtag } from 'store/actions/exercises';
import { fetchAndCreateHashtags } from 'store/actions/hashtags';
import './style.css';

Array.prototype.diff = function (arr2) { return this.filter(x => !arr2.includes(x)); }

function CreateTask(props) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [selectTheme, setSelectTheme] = useState(false)

    const publishExsercis = async () => {
        const data = { "is_published": true }
        props.firstStep()
        props.deleteTaskForm()
        await api_exercises.UpdateExercis(props.one_exercises.id, data)
        navigate(`../myTask`)
    }

    useEffect(() => {
        return () => {
            props.firstStep()
            props.deleteTaskForm()
        };
    }, []);

    const chageMode = () => {
        props.stepDown()
        props.editGame()
    }

    const onFinish = async () => {
        let values = {}
        if (props.create_task.background !== null) {
            if (!props.create_task.edit_game) {
                await api_exercises.CreateExercise(createGame(values, props.create_task))
                    .then(async res => {
                        props.fetchOneExercises(res.data.id)
                        if (props.create_task.create_task?.hashtags?.length) {
                            for (let i = 0; i < props.create_task.create_task.hashtags.length; i++) {
                                if (props.create_task.create_task.hashtags[i]) {
                                    await props.fetchAndCreateHashtags(
                                        getQueryStringFromObject(JSON.stringify({ name: props.create_task.create_task.hashtags[i] })),
                                        { name: props.create_task.create_task.hashtags[i] }
                                    ).then(tag_id => props.fetchToggleHashtag(res.data.id, tag_id))
                                }
                            }
                        }
                        props.stepUp()
                    })
            } else {
                const old_hashtags = props.one_exercises.hashtags.map(el => el.id)
                const new_hashtags = []
                for (let i = 0; i < props.create_task.create_task.hashtags.length; i++) {
                    if (props.create_task.create_task.hashtags[i]) {
                        await props.fetchAndCreateHashtags(
                            getQueryStringFromObject(JSON.stringify({ name: props.create_task.create_task.hashtags[i] })),
                            { name: props.create_task.create_task.hashtags[i] }
                        ).then(tag_id => new_hashtags.push(tag_id))
                    }
                }
                const difference_hashtags = old_hashtags
                    .filter(x => !new_hashtags.includes(x))
                    .concat(new_hashtags.filter(x => !old_hashtags.includes(x)))
                    
                await api_exercises.UpdateExercis(props.one_exercises.id, createGame(values, props.create_task))
                    .then(async res => {
                        await props.fetchOneExercises(res.data.id)
                        for (let i = 0; i < difference_hashtags.length; i++) {
                            await props.fetchToggleHashtag(res.data.id, difference_hashtags[i])
                        }
                        props.stepUp()
                    })
            }
        }
    };

    const onClickPattern = (type) => {
        if (props.exercises_count < props.userMeSubscription?.resources || props.userMeSubscription?.subscription?.resources === 0) {
            props.selectTaskPattern(type);
            props.stepUp()
        } else {
            message.warning(`По вашей подписке можно создать только ${props.userMeSubscription.subscription?.resources || props.userMeSubscription?.resources} заданий. Измените подписку или удалите уже созданные задания!`)
        }
    }

    return (
        <div className="resultsBlock">
            <div className='createTask_stepsBlock'>
                <CreateTaskSteps step={props.step} />
            </div>
            {props.step === 0 && props.userMeSubscription !== null &&
                <div className='createTask_patterns'>
                    {patternsGame.map((item, key) => (
                        <Pattern item={item} key={key} onClick={() => onClickPattern(item.type)} />
                    ))}
                </div>
            }
            {props.step === 1 && <SelectFormType />}
            {props.step === 2 &&
                <div>
                    <div className='createTask_themes'>
                        {props.create_task.task_type === "TYPE_SPACE_MATHEMATICS"
                            ? spaseMathematics.map((item, key) => {
                                return (
                                    <div className='createTask_themes__item' key={`spaseMathematics_${key}`} >
                                        <div className={props.create_task.background === item.type ? "leaderBoard_themes__item active" : "leaderBoard_themes__item"} onClick={() => { props.selectTheme(item.type) }}>
                                            <div className="leaderBoard_themes__item__img"><img src={item.img} alt={item.title} /></div>
                                            <p className="leaderBoard_themes__item__title">{t(`theme.theme_title.${item.title}`)}</p>
                                        </div>
                                    </div>
                                )
                            })
                            : formthemes.map((item, key) => {
                                return (
                                    <div className='createTask_themes__item' key={`formthemes_${key}`} >
                                        <div className={props.create_task.background === item.type ? "leaderBoard_themes__item active" : "leaderBoard_themes__item"} key={key} onClick={() => { props.selectTheme(item.type) }}>
                                            <div className="leaderBoard_themes__item__img"><img src={item.img} alt={item.title} /></div>
                                            <p className="leaderBoard_themes__item__title">{t(`theme.theme_title.${item.title}`)}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='createTaskForm_buttons' style={{ marginTop: '40px' }}>
                        <button className='editProfileButton' onClick={props.stepDown}>{t(`games.backButton`)}</button>
                        <button className='editProfileButton' onClick={onFinish}>{t(`games.saveButton`)}</button>
                    </div>
                </div>
            }
            {/* {props.step === 3 &&
                <>
                    <div className='createTask_patterns'>
                        <Option type="createTask" />
                    </div>
                </>
            } */}
            {props.step === 3 && props.one_exercises !== null &&
                <div className="patternInfoBlock">
                    <div>
                        <GameBlock group_id={'watch'} />
                        <p className="patternInfoBlock_header__title">{props.one_exercises.name}</p>
                        <p className="patternInfoBlock_actions__creater">{t(`tasks.by`)}<span> {props.one_exercises.owner.first_name} {props.one_exercises.owner.last_name}</span></p>
                    </div>
                    <div className='createTaskForm_buttons' style={{ marginTop: '40px' }}>
                        <button className='editProfileButton' onClick={chageMode}>{t(`games.backButton`)}</button>
                        <button className='editProfileButton' onClick={publishExsercis}>{t(`games.saveButton`)}</button>
                    </div>
                </div>

            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        create_task: state.create_task,
        one_exercises: state.one_exercises.one_exercises,
        userMeSubscription: state.userMe.userMeSubscription,
        exercises_count: state.userMe.exercises_count,
        step: state.step.step
    }
}


function mapDispatvhToProps(dispatch) {
    return {
        selectTaskPattern: (text) => dispatch({ type: 'selectTaskPattern', payload: text }),
        fetchOneExercises: (id) => dispatch(fetchOneExercises(id)),
        selectTheme: (text) => dispatch({ type: 'selectTheme', payload: text }),
        stepUp: () => dispatch({ type: 'stepUp' }),
        stepDown: () => dispatch({ type: 'stepDown' }),
        firstStep: () => dispatch({ type: 'firstStep' }),
        deleteTaskForm: () => dispatch({ type: 'deleteTaskForm' }),
        editGame: () => dispatch({ type: 'editGame' }),
        fetchAndCreateHashtags: (params, data) => dispatch(fetchAndCreateHashtags(params, data)),
        fetchToggleHashtag: (id, hashtag_id) => dispatch(fetchToggleHashtag(id, hashtag_id))
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(CreateTask);
