import { useState, useEffect } from 'react';
import './style.css';
import '../style.css';

export const GroupDivision = ({ game }) => {
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        const array = []
        game.forEach(el => {
            el.answers.forEach(item => {
                array.push(item.answer)
            })
        })

        setAnswers(array.sort(() => Math.random() - 0.5))
    }, [])

    return (
        <>
            <div className='groups'>
                {game.map((el, index) => (
                    <div key={`group${index}`} className='groups_item'>
                        <p>{el.title}</p>
                        <div className='groups_item__linesBlock'>
                            <div className='groups_item__linesBlock__line'/>
                            <div className='groups_item__linesBlock__line'/>
                            <div className='groups_item__linesBlock__line'/>
                            <div className='groups_item__linesBlock__line'/>
                            <div className='groups_item__linesBlock__line'/>
                            <div className='groups_item__linesBlock__line'/>
                            <div className='groups_item__linesBlock__line'/>
                            <div className='groups_item__linesBlock__line'/>
                        </div>
                    </div>
                ))}
            </div>
            <div className='Pdf_task groupAnswer'>
                {answers.map((el, index) => (
                    <p key={`groupAnswer${index}`}>{el}{answers.length - 1 > index && ' | '}</p>
                ))}
            </div>
        </>
    )
}