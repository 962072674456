import { ApiUrl, instance } from './api';

export const api_leaderBoard = {

    async CreateLeaderBoard(data) {
        return await instance.post(`${ApiUrl}/api/v1/leaderboards`, data)
    },

    async ReadeLeadrBoardByExercise(id, params, skip, limit) {
        return await instance.get(`${ApiUrl}/api/v1/leaderboards/by_exercise?exercise_id=${id}&skip=${skip}&limit=${limit}&params=${params}`)
    },

    async ReadeAchievements(id) {
        return await instance.get(`${ApiUrl}/api/v1/leaderboards/by_user?user_id=${id}&skip=0&limit=1000`)
    },

}