import starShip from 'assets/games/spaceMathematics/starShipPdf.png';

import '../style.css';
import './style.css';

const circleNumber = ['A', 'Б', 'В', 'Г', 'Д', 'Е']

export const SpaceMathematics = ({ game }) => {
    return (
        game.map((el, index) => (
            <div className='Pdf_task' key={`quizPdf_task${index}`}>
                <p>{index + 1}) {el.task}</p>
                <div className='spaceMathematicsPdf' style={{ marginTop: '3mm' }}>
                    <div className='spaceMathematicsPdf_taskHeader'>
                        <p>{el.text}</p>
                        <img src={starShip} alt="starShip" />
                    </div>
                    <div className='spaceMathematicsPdf_task'>
                        {el.task_answers.map((item, key) => (
                            <div className='spaceMathematicsPdf_task__answer'>
                                <p>{circleNumber[key]}</p>
                                <div className="pdfCircle" />
                                <p>{item.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ))
    )
}