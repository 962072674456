import autumn from 'assets/sound/AUTUMN.mp3';
import classic from 'assets/sound/CLASSIC.mp3';
import cloud from 'assets/sound/CLOUD.mp3';
import neutral from 'assets/sound/NEUTRAL.mp3';
import space from 'assets/sound/SPACE.mp3';
import sprint from 'assets/sound/SPRING.mp3';
import summer from 'assets/sound/SUMMER.mp3';
import winter from 'assets/sound/WINTER.mp3';

export const selecSound = (backgrounds) => {
    switch (backgrounds) {
        case 'CLASSIC':
            return classic
        case 'NEUTRAL':
            return neutral
        case 'WINTER':
            return winter
        case 'SUMMER':
            return summer
        case 'AUTUMN':
            return autumn
        case 'SPACE':
            return space
        case 'SPRING':
            return sprint
        case 'CLOUD':
            return cloud
        case 'BOOK':
            return neutral
        case 'PUZZLE':
            return classic
        case 'TREES':
            return cloud
        case 'STORY':
            return cloud
        case 'SCHOOL_BLACKBOARD':
            return classic
        case 'CHILDHOOD':
            return summer
        case 'CELL':
            return classic
        case 'MUSIC':
            return cloud
        case 'FANTASY':
            return space
        case 'NOTEBOOK':
            return neutral
    }
}