import { useEffect, useRef, useState } from 'react';

import './style.css';

function MatchElements({ game }) {
    const [firstHalf, setFirstHalf] = useState([])
    const [secondHalf, setSecondHalf] = useState([])
    const temp = useRef()
    
    const startFunction = () => {
        const newSecondHalf = []
        const newFirstHalf = []
        game.forEach(item => {
            newFirstHalf.push(item.first_half.toUpperCase())
            newSecondHalf.push({
                answer: item.second_half ? item.second_half.toUpperCase() : null,
                answer_photo: item.answer_photo
            })
        })
        setFirstHalf(newFirstHalf)
        setSecondHalf(newSecondHalf.sort(() => Math.random() - 0.5))
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    return (
        <div className='exercisesCardBg_content'>
            <div className="matchElementBlcok_firstHalf">
                {firstHalf.map((item, key) => {
                    return (
                        <div
                            className="matchElementBlcok_firstHalf__item"
                            key={key}
                        >
                            {item}
                        </div>
                    )
                })}
            </div>
            <div className="matchElementBlcok_secondHalf">
                {secondHalf.map((item, key) => (
                    <div
                        className="matchElementBlcok_secondHalf__item matchElementBgAnswers"
                        key={key}
                    >
                        {item.answer_photo && <img src={item.answer_photo} alt='answer_photo' />}   
                        {item.answer && <p>{item.answer}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MatchElements;
