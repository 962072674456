import { api_user } from 'API/users'
import {
    FETCH_USERME_START,
    FETCH_USERME_SUCCESS,
    FETCH_USERMESUBSCRIPTION_SUCCESS,
    FETCH_USERME_EXERCISES_COUNT_SUCCESS,
    FETCH_USERME_ERROR
} from '../actionTypes'

export function fetchUserMe() {
    return async dispatch => {
        dispatch(fetchUserMeStart())
        try {
            const userMe = await api_user.ReadeUserMe()
            dispatch(fetchUserMeSuccess(FETCH_USERME_SUCCESS, userMe.data))
        } catch (e) {
            dispatch(fetchUserMeError(e))
        }
    }
}

export function fetchUserMeSubscription() {
    return async dispatch => {
        dispatch(fetchUserMeStart())
        try {
            const userMe = await api_user.GetMeSubscription()
            dispatch(fetchUserMeSuccess(FETCH_USERMESUBSCRIPTION_SUCCESS, userMe.data))
            return userMe.data
        } catch (e) {
            dispatch(fetchUserMeError(e))
        }
    }
}

export function fetchUserMeExercisesCount() {
    return async dispatch => {
        dispatch(fetchUserMeStart())
        try {
            const userMe = await api_user.GetExercisesCount()
            dispatch(fetchUserMeSuccess(FETCH_USERME_EXERCISES_COUNT_SUCCESS, userMe.data))
            return userMe.data
        } catch (e) {
            dispatch(fetchUserMeError(e))
        }
    }
}

export function fetchUserMeStart() {
    return {
        type: FETCH_USERME_START
    }
}

export function fetchUserMeSuccess(type, userMe) {
    return {
        type,
        userMe,
    }
}

export function fetchUserMeError(e) {
    return {
        type: FETCH_USERME_ERROR,
        error: e,
    }
}
