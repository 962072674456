import axios from "axios"

const BING_KEY = "7e675045a89244e99e9e6bf223f37451"

let bing = axios.create({
    headers: { "Ocp-Apim-Subscription-Key": BING_KEY }
})

export const api_bing = {

    async BingSearchImages(search) {
        return await bing.get(`https://api.bing.microsoft.com/v7.0/images/search?q=${search}&count=150`)
    },

}