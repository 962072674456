import { api_hashtags } from 'API/hashtags';
import { getQueryStringFromObject } from 'functions/filter';
import { FETCH_HASHTAGS_ERROR, FETCH_HASHTAGS_START, FETCH_HASHTAGS_SUCCESS } from '../actionTypes';

export function fetchAndCreateHashtags(params, data) {
    return async dispatch => {
        try {
            const hashtags = await api_hashtags.ReadeHashtags(params)
            if(hashtags.data.length) {
                return hashtags.data[0].id
            } else {
                const create_hashtag = await api_hashtags.CreateHashtag(data)
                return create_hashtag.data.id
            }
        } catch (e) {
        }
    }
}

export function fetchSearchHashtags(type, text) {
    return async dispatch => {
        dispatch(fetchStart())
        try {
            let obj = {}
            obj[type] = text
            const params = getQueryStringFromObject(JSON.stringify(obj))
            const hashtags = await api_hashtags.ReadeHashtags(params)
            dispatch(fetchSuccess(hashtags.data))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchStart() {
    return {
        type: FETCH_HASHTAGS_START
    }
}

export function fetchSuccess(hashtags) {
    return {
        type: FETCH_HASHTAGS_SUCCESS,
        hashtags,
    }
}

export function fetchError(e) {
    return {
        type: FETCH_HASHTAGS_ERROR,
        error: e,
    }
}
