import { api_images } from "API/images"


export function fetchImages(data) {
    return async dispatch => {
        try {
            const img = await api_images.UploadPhoto(data)
            return img.data
        } catch (e) {
            return false
        }
    }
}