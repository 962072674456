import '../style.css';

function Cards({ game }) {
    return (
        <div className='exercisesCardBg_content'>
            <div className='exercisesCardBg_content__card'>
                {game[0].photo !== null
                    ? <img src={game[0].photo} />
                    : <p>{game[0].text}</p>
                }
            </div>
            <p className='exercisesCardBg_answer__card'>{game[0].answer}</p>
        </div>
    );
}

export default Cards;
