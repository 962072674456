import { useEffect, useState } from 'react';

import '../style.css';
import './style.css';

export const MatchElements = ({ game }) => {
    const [firstHalf, setFirstHalf] = useState([])
    const [secondHalf, setSecondHalf] = useState([])

    useEffect(() => {
        const newFirstHalf = []
        const newSecondHalf = []
        game.forEach(el => {
            newFirstHalf.push(el.first_half.toUpperCase())
            newSecondHalf.push({
                value: el?.second_half?.toUpperCase() || null,
                img: el?.answer_photo || null
            })
        })
        setFirstHalf(newFirstHalf.sort(() => Math.random() - 0.5))
        setSecondHalf(newSecondHalf.sort(() => Math.random() - 0.5))
    }, [])
    
    return (
        game.map((el, index) => (
            <div className='Pdf_task matchElementPdf_task' key={`pdf${index}`}>
                <div className='matchElementPdf_task__item' style={{ justifyContent: 'flex-end' }}>
                    <p>{firstHalf[index]}</p>
                    <div className="pdfCircle" />
                </div>
                <div className='matchElementPdf_task__item' style={{ justifyContent: 'flex-start' }}>
                    <div className="pdfCircle" />
                    {secondHalf[index]?.img && <img src={secondHalf[index]?.img} alt='matchElementPdf_task__item__img' />}
                    <p>{secondHalf[index]?.value?.toUpperCase()}</p>
                </div>
            </div>
        ))
    )
}