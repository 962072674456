import conePurpleGlossy from 'assets/backgrounds/Cone-Purple-Glossy.svg';
import roundCube from 'assets/backgrounds/RoundCube.svg';
import superToroid from 'assets/backgrounds/SuperToroid.svg';
import cloudBottom from 'assets/backgrounds/cloud-bottom.svg';
import cloudTop from 'assets/backgrounds/cloud-top.svg';
import ServiceCard from 'components/components/serviceCard/ServiceCard';
import { services } from 'lists/list';

import './style.css';

function Services() {
    return (
        <div className='servicesBlock'>
            <img src={conePurpleGlossy} alt='icon' className='servicesBlock_conePurpleGlossy' />
            <img src={roundCube} alt='icon' className='servicesBlock_roundCube' />
            <img src={superToroid} alt='icon' className='servicesBlock_superToroid' />
            
            <img src={cloudBottom} alt='cloudBottom' className='servicesBlock_clouds' />
            <p className='servicesBlock_title'>Сервисы, которые соединяют вас с вашими пользователями</p>
            <div className='servicesItems'>
                {services.map((el, key) => (
                    <div key={`services_${key}`} className='services_serviceCard'>
                        <ServiceCard item={el} />
                    </div>
                ))}
            </div>
            <img src={cloudTop} alt='cloudTop' className='servicesBlock_clouds' />
        </div>
    );
}

export default Services;
