import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';

import '../style.css';

function Join({
    setVisible
}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const onFinish = (values) => {
        navigate("../task/0")
    };

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    return (
        <>
            <p className="modalTitle">{t(`modal.join.title`)}</p>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="modalForm"
            >
                <Form.Item
                    label={t(`modal.join.name`)}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(`modal.join.name_error`),
                        },
                    ]}
                >
                    <Input className="formInput" />
                </Form.Item>
                <Form.Item>
                    <Button className="loginButton" htmlType="submit">{t(`modal.join.button`)}</Button>
                </Form.Item>
            </Form>
            <div className="modalDesc">
                <p className="modalDesc_warning" onClick={() => setVisible('login')}><span> {t(`modal.join.login`)}</span></p>
                <p className="modalDesc_warning" onClick={() => setVisible('registration')}><span> {t(`modal.join.registration`)}</span></p>
            </div>
        </>
    );
};

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
    }
}



export default connect(null, mapDispatvhToProps)(Join);