import { FETCH_LIKESUSERS_START, FETCH_LIKESUSERS_SUCCESS, FETCH_LIKESUSERS_ERROR } from '../actionTypes'

const initialState = {
    likes_users: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_LIKESUSERS_START:
            return {
                ...state, loading: true
            }
        case FETCH_LIKESUSERS_SUCCESS:
            return {
                ...state, loading: false,
                likes_users: action.likes,
            }
        case FETCH_LIKESUSERS_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}