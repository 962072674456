import { api_exercises } from 'API/exercises'
import { FETCH_ONEEXERCISES_START, FETCH_ONEEXERCISES_SUCCESS, FETCH_ONEEXERCISES_ERROR } from '../actionTypes'

export function fetchOneExercises(id) {
    return async dispatch => {
        dispatch(fetchExercisesStart())
        try {
            const one_exercises = await api_exercises.ReadeOneExercises(id)
            dispatch(fetchExercisesSuccess(one_exercises.data))
            return one_exercises.data
        } catch (e) {
            dispatch(fetchExercisesError(e))
        }
    }
}

export function fetchExercisesStart() {
    return {
        type: FETCH_ONEEXERCISES_START
    }
}

export function fetchExercisesSuccess(one_exercises) {
    return {
        type: FETCH_ONEEXERCISES_SUCCESS,
        one_exercises,
    }
}

export function fetchExercisesError(e) {
    return {
        type: FETCH_ONEEXERCISES_ERROR,
        error: e,
    }
}
