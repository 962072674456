import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';

import '../style.css';

function FastRegistration({
    setVisible,
    PageAfterLogin,
    FAST_REGISTATION_USER
}) {
    const { t } = useTranslation();
    const { id, group_id } = useParams()

    const onFinish = (values) => {
        FAST_REGISTATION_USER(values)
    };

    return (
        <>
            <p className="modalTitle">Укажите имя</p>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                className="modalForm"
            >
                <Form.Item
                    label="Имя"
                    name="full_name"
                    rules={[
                        {
                            required: true,
                            message: 'Введите имя',
                        },
                    ]}
                >
                    <Input className="formInput" />
                </Form.Item>
                <Form.Item>
                    <Button className="loginButton" htmlType="submit">Продолжить</Button>
                </Form.Item>
            </Form>
            <div className="modalDesc">
                <p className="modalDesc_registration" onClick={() => {setVisible('login'); PageAfterLogin(`../task/${id}/${group_id}`)}}>Авторизоваться</p>
                <p className="modalDesc_registration" onClick={() => {setVisible('registration'); PageAfterLogin(`../task/${id}/${group_id}`)}}>{t(`modal.login.registration`)}</p>
            </div>
        </>
    );
};

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        PageAfterLogin: (text) => dispatch({ type: 'PageAfterLogin', payload: text }),
        FAST_REGISTATION_USER: (text) => dispatch({ type: 'FAST_REGISTATION_USER', payload: text }),
    }
}



export default connect(null, mapDispatvhToProps)(FastRegistration);