import { FETCH_GAME_START, FETCH_GAME_SUCCESS, FETCH_GAME_ERROR } from '../actionTypes'

const initialState = {
    game: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_GAME_START:
            return {
                ...state, loading: true
            }
        case FETCH_GAME_SUCCESS:
            return {
                ...state, loading: false,
                game: action.game,
            }
        case FETCH_GAME_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}