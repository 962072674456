import privacy_policy from 'assets/docs/privacy_policy.pdf';
import tariff_plans from 'assets/docs/tariff_plans.pdf';
import user_agreement from 'assets/docs/user_agreement.pdf';
import school_tariff_plans from 'assets/docs/school_tariff_plans.pdf';
import requisites from 'assets/docs/IP.pdf';
import sber from 'assets/docs/sber.pdf';

export const privacyList = [
    {
        title: 'Политика конфиденциальности',
        subTitle: 'с политикой конфиденциальности',
        pdf: privacy_policy
    },
    {
        title: 'Тарифные планы',
        subTitle: 'с тарифными планами',
        pdf: tariff_plans
    },
    {
        title: 'Школьные тарифные планы',
        subTitle: 'со школьными тарифными планами',
        pdf: school_tariff_plans
    },
    {
        title: 'Реквизиты',
        subTitle: 'с реквизитами',
        pdf: requisites
    },
    {
        title: 'Пользовательское соглашение',
        subTitle: 'с пользовательским соглашением',
        pdf: user_agreement
    },
    {
        title: 'Оплата',
        subTitle: 'с правилами оплаты',
        pdf: sber
    },
]