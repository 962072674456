import cube from 'assets/games/cubes/cube.svg';

import './style.css';

function Cubes({ game }) {
    return (
        <div className='exercisesCardBg_content'>
            <div className='cubesBg'>
                {game && game.map((el, key) => (
                    <img src={cube} alt="cube" key={`cube_${key}`} />
                ))}
            </div>
        </div>
    );
}

export default Cubes;
