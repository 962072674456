import { FETCH_SEARCHEXERCISES_BY_HASHTAGS_SUCCESS, FETCH_SEARCHEXERCISES_ERROR, FETCH_SEARCHEXERCISES_START, FETCH_SEARCHEXERCISES_SUCCESS } from '../actionTypes'

const initialState = {
    search_exercises: [],
    search_exercises_by_hashtags: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SEARCHEXERCISES_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_SEARCHEXERCISES_SUCCESS:
            return {
                ...state,
                loading: false,
                search_exercises: action.exercises,
            }
        case FETCH_SEARCHEXERCISES_BY_HASHTAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                search_exercises_by_hashtags: action.exercises,
            }
        case FETCH_SEARCHEXERCISES_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}