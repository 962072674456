import { FETCH_PARTICIPANTEXERCISES_START, FETCH_PARTICIPANTEXERCISES_SUCCESS, FETCH_PARTICIPANTEXERCISES_ERROR } from '../actionTypes'

const initialState = {
    participant_exercises: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PARTICIPANTEXERCISES_START:
            return {
                ...state, loading: true
            }
        case FETCH_PARTICIPANTEXERCISES_SUCCESS:
            return {
                ...state, loading: false,
                participant_exercises: action.exercises,
            }
        case FETCH_PARTICIPANTEXERCISES_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}