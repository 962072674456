export const countScore = (result, mode) => {
    let count = 0
    result.forEach(el => {
        if (mode === 'max') {
            count += el.max_point
        } else if (mode === 'actual') {
            count += el.actual_point
        }
    })
    return count
}