import { api_user } from 'API/users';
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUserMe, fetchUserMeSubscription } from 'store/actions/userMe';

import { fetchLogin } from 'store/actions/login';

import { api_subscription } from 'API/subscriptions';
import '../media.css';
import '../style.css';

function Registration({
    link,
    changeType,
    fetchLogin,
    fetchUserMe,
    fetchUserMeSubscription
}) {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const onFinish = async (values) => {
        if (values.password === values.yet_password) {
            await api_user.CreateUser(values, t(`modal.registration.registartion_error`))
                .then(res => {
                    if (res.status === 200) {
                        let data = {
                            'username': values.email,
                            'password': values.password,
                        }
                        fetchLogin(data)
                            .then(async (res) => {
                                if (res !== "Incorrect username or password" && res !== 'Error: Network Error') {
                                    await api_subscription.FreeTrial()
                                    fetchUserMeSubscription()
                                    fetchUserMe()
                                    changeType('successful_registration')
                                    navigate(link)
                                }
                            })
                        // message.info('На данный момент у Вас тариф "Start". Именить тариф можно в разделе "Мой тариф" или "Подписка"', 8)
                    }
                })
        } else {
            message.error(t(`modal.registration.password_match`))
        }

    };

    return (
        <>
            <p className="modalTitle">{t(`modal.registration.title`)}</p>
            <Form
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                className="modalForm"
            >
                <div className="fioBlock">
                    <Form.Item
                        label={t(`modal.registration.name`)}
                        name="first_name"
                        rules={[
                            {
                                required: true,
                                message: t(`modal.registration.name_error`),
                            },
                        ]}
                    >
                        <Input className="formInput" />
                    </Form.Item>
                    <Form.Item
                        label={t(`modal.registration.surname`)}
                        name="last_name"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input className="formInput" />
                    </Form.Item>
                </div>
                <Form.Item
                    label={t(`modal.registration.email`)}
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: t(`modal.registration.email_error`),
                        },
                    ]}
                >
                    <Input className="formInput" />
                </Form.Item>
                <Form.Item
                    label={t(`modal.registration.password`)}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t(`modal.registration.password_error`),
                        },
                    ]}
                >
                    <Input.Password className="formInput" />
                </Form.Item>
                <Form.Item
                    label={t(`modal.registration.yet_password`)}
                    name="yet_password"
                    rules={[
                        {
                            required: true,
                            message: t(`modal.registration.yet_password_error`),
                        },
                    ]}
                >
                    <Input.Password className="formInput" />
                </Form.Item>
                <Form.Item>
                    <Button className="loginButton" htmlType="submit">{t(`modal.registration.button`)}</Button>
                </Form.Item>
            </Form>
            <div className="modalDesc">
                <p className="modalDesc_warning">{t(`modal.registration.warning`)}<span> {t(`modal.registration.warning_link`)}</span></p>
                <p className="modalDesc_registration" onClick={() => changeType('login')}>{t(`modal.registration.registration`)}</p>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        link: state.login.link,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUserMe: () => dispatch(fetchUserMe()),
        changeType: (text) => dispatch({ type: 'changeType', payload: text }),
        fetchLogin: (data) => dispatch(fetchLogin(data)),
        setVisible: () => dispatch({ type: 'closeModal' }),
        fetchUserMeSubscription: () => dispatch(fetchUserMeSubscription())
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(Registration);