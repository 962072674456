import { FETCH_GROUPS_START, FETCH_GROUPS_SUCCESS, FETCH_GROUPS_ERROR } from '../actionTypes'

const initialState = {
    groups: [],
    create_group_exerciseId: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_GROUPS_START:
            return {
                ...state, loading: true
            }
        case FETCH_GROUPS_SUCCESS:
            return {
                ...state, loading: false,
                groups: action.groups,
            }
        case FETCH_GROUPS_ERROR:
            return {
                ...state, loading: false
            }
        case 'SAVE_EXERCISESID_CREATEGROUP':
            return {
                ...state,
                create_group_exerciseId: action.id
            }
        default: return state
    }
}