export const declinationTask = (count) => {
    if(count > 1 && count < 5) return "задания"
    if(count > 4 && count < 21) return "заданий"        
    if(count % 10 === 0) return "заданий"    
    if(count === 1 || count % 10 === 1) return "задание" 
    if(count > 21) return "задания"  
}

export const declinationFolder = (count) => {
    if(count > 1 && count < 5) return "папки"
    if(count > 4 && count < 21) return "папок"        
    if(count % 10 === 0) return "папок"    
    if(count === 1) return "папка" 
    if(count > 21) return "папки"  
}