import { connect } from 'react-redux';
import { Modal } from 'antd';

import './style.css';

function CustomModal({visible, type, setVisible, children}) {
    return (
        <Modal
            title=""
            visible={visible}
            onCancel={setVisible}
            footer={[]}
            className={type === 'results' ? 'customModal customModal_result' : 'customModal'}
        >
            {children}
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        visible: state.modal.visible,
        type: state.modal.type,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: 'closeModal'}),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(CustomModal);
