import questionSign from 'assets/questionSign.png';
import { copy, getRandomInt } from 'functions/generalFunction';
import { useEffect, useRef, useState } from 'react';

import '../style.css';
import './style.css';

const bgArray = ['#ff7675', '#fdcb6e', '#a29bfe', '#cf6a87']

function InsertLetter({ game }) {
    const [tasks, setTasks] = useState(game.question.split(' '))
    const [answers, setAnswers] = useState(Array(game.answers.length))
    const [answersBg, setAnswersBg] = useState(Array(game.answers.length))
    const textArray = game.question.split(' ')
    const temp = useRef()

    const startFunction = () => {
        const copyTask = copy(tasks)
        const arrayAnswersBg = []
        const arrayAnswers = []
        game.answers.forEach((item, key) => {
            arrayAnswersBg.push(bgArray[getRandomInt(bgArray.length - 1)])
            arrayAnswers.push(item.answer)
        })
        copyTask.forEach((el, index) => {
            copyTask[index] = el.replace(/[,.;:?!"'|@#()_+=-]\s*$/, "")
        })
        setAnswers(arrayAnswers)
        setAnswersBg(arrayAnswersBg)
        setTasks(copyTask)
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])
    
    return (
        <div className='exercisesCardBg_content'>
            <div className="insertLetterQuestion insertLetterBg">
                {tasks.map((item, key) => {
                    let str = item
                    let dot = null

                    if (/[@_,.*^&?%$#!?()=+-]/.test(str)) {
                        dot = str.split('\n')[0][str.split('\n')[0].length - 1]
                        str = str.split('\n')[0].substring(0, str.split('\n')[0].length - 1)
                    }
                    if (answers.indexOf(str) === -1 && tasks[key].search(/\n/) === -1) {
                        return <p key={`insertLetter_${key}`}>{str}</p>
                    } else if (tasks[key].search(/\n/) !== -1) {
                        const nArray = []
                        if (tasks[key].substring(0, tasks[key].search(/[\n ]+/)).indexOf(str) === -1) {
                            if (answers.indexOf(tasks[key].substring(0, tasks[key].search(/[\n ]+/))) === -1) {
                                nArray.push(<p key={`insertLetter_${key}`}><span>{tasks[key].substring(0, tasks[key].search(/[\n ]+/))}</span></p>)
                            } else {
                                nArray.push(
                                    <>
                                        <div className="draggableBlock insertLetterBg" style={{ width: `${item.length * 2}px` }} key={key} />
                                        {dot}
                                    </>
                                )
                            }
                        } else {
                            if (answers.indexOf(str) === -1) {
                                nArray.push(<p key={`insertLetter_${key}`}>{str}{dot}</p>)
                            } else {
                                nArray.push(
                                    <>
                                        <div className="draggableBlock insertLetterBg" style={{ width: `${item.length * 2}px` }} key={key} />
                                        {dot}
                                    </>
                                )
                            }
                        }
                        if (tasks[key].length > tasks[key].search(/[\n ]+/) + 1) {
                            nArray.push(<br
                                style={{
                                    content: 'ww',
                                    width: '100%',
                                    display: 'block'
                                }}
                            />)
                            nArray.push(<p key={`insertLetter_n_${key}`}><span>{tasks[key].substring(tasks[key].search(/[\n ]+/) + 1, tasks[key].length)}</span></p>)
                        }
                        return nArray
                    } else {
                        return (
                            <>
                                {/* <div className="draggableBlock insertLetterBg" style={{ width: `${item.length * 2}px` }} key={key} /> */}
                                <div className='insertLetterBg__questions_placeholderBlock'><img src={questionSign} alt="questionSign" /></div>
                                {dot}
                            </>
                        )
                    }
                })}
            </div>
            <div className="insertLetterBg">
                {answers.map((item, key) => {
                    return <div
                        key={`answer_${key}_${item}`}
                        style={{ backgroundColor: `${bgArray[key % 4]}` }}
                    >
                        {item}
                    </div>
                })}
            </div>
        </div>
    );
}

export default InsertLetter;
