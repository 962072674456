import '../style.css';
import './style.css';

export const Quiz = ({ game }) => {
    return (
        game.map((el, index) => (
            <div className='Pdf_task' style={{ marginTop: '3mm' }} key={`quizPdf_task${index}`}>
                <div className='quizPdf_taskHeader'>
                    <p className='pdfTaskNumber'>{index + 1}</p>
                    <p>{el.text}</p>
                </div>
                <div className='quizPdf_task'>
                    {el.photo !== null &&
                        <div className='quizPdf_task__item__question'>
                            <img src={el.photo} alt="el.photo" />
                        </div>
                    }
                    <div className='quizrPdf_task__item__answer'>
                        {el.task_answers.map((item, key) => (
                            <div className='quizrPdf_task__item__answerItem'>
                                <div className="pdfCircle" />
                                <div className="quizPdf_task__item__answer__text" >
                                    {item.photo && <img src={item.photo} />}
                                    <p>{item.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ))
    )
}