import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Form, Input, Button } from 'antd';
import { fetchFolders } from 'store/actions/folders';
import { api_folders } from 'API/folders';

import '../style.css';
import { fetchOneFolder } from 'store/actions/oneFolder';

function CreateFolder({
  data,
  fetchFolders,
  fetchOneFolder,
  closeModal
}) {
  const { t } = useTranslation();

  const onFinish = async (values) => {
    if(data) {
      values.parent_id = data.parent_id
    }
    await api_folders.CreateFolder(values)
    fetchFolders()
    if(data){
      fetchOneFolder(data.parent_id)
    }
    closeModal()
  };

  return (
    <>
      <p className="modalTitle">{t(`modal.create_folder.title`)}</p>
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        className="modalForm"
      >
        <Form.Item
          label={t(`modal.create_folder.name`)}
          name="name"
          rules={[
            {
              required: true,
              message: t(`modal.create_folder.name_error`),
            },
          ]}
        >
          <Input className="formInput" />
        </Form.Item>
        <Form.Item>
          <Button className="loginButton" htmlType="submit">{t(`modal.create_folder.button`)}</Button>
        </Form.Item>
      </Form>
    </>
  );
};

function mapStateToProps(state) {
  return {
    data: state.modal.data
  }
}

function mapDispatvhToProps(dispatch) {
  return {
    fetchFolders: () => dispatch(fetchFolders()),
    closeModal: () => dispatch({ type: 'closeModal' }),
    fetchOneFolder: (id) => dispatch(fetchOneFolder(id)),
  }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateFolder);