import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';

import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';
import greenCheck from 'assets/games/inserLetterSecond/check.svg';
import error from 'assets/games/inserLetterSecond/error.svg';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import { Textfit } from 'react-textfit';
import './media.css';
import './style.css';

const borderColors = ['#E88F9A', '#A4D6EF', '#85E5A0', '#8E86E7', '#EEB157', '#A4D6EF']

function InsertLetterSecond(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(JSON.parse(JSON.stringify(props.one_exercises.game.tasks))) /*tasks */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState([]) /*Массив ответов в случайном полядке*/
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [nextAnswer, setNextAnswer] = useState(true) /*Можно ли нажимать на следующею вариант ответа */
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        question: {
            photo: фото вопроса
            text: текст вопроса
            question: текст основного вопроса 
        },
        answers: [], массив изначальных ответов 
        enter_answers: [], массив выбранных ответов
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true можно ли изменять этот объект
    */
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState() /*Таймер*/
    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        let answersArray = []
        const resultArray = []
        for (let j = 0; j < tasks.length; j++) {
            answersArray.push({
                choose_letters: tasks[j].choose_letters.split(',').sort(() => Math.random() - 0.5),
                task_index: j
            }
            )
            resultArray.push({
                question: {
                    photo: tasks[j].photo,
                    text: tasks[j].text,
                    question: tasks[j].answer
                },
                answers: tasks[j].delete_letter,
                enter_answers: null,
                max_point: 1,
                actual_point: 0,
                active: true
            })
        }
        setResult(resultArray);
        setAnswers(answersArray);
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        let answersArray = []
        const resultArray = []
        for (let j = 0; j < props.one_exercises.game.tasks.length; j++) {
            answersArray.push({
                choose_letters: props.one_exercises.game.tasks[j].choose_letters.split(',').sort(() => Math.random() - 0.5),
                task_index: j
            })
            resultArray.push({
                question: {
                    photo: props.one_exercises.game.tasks[j].photo,
                    text: props.one_exercises.game.tasks[j].text,
                    question: props.one_exercises.game.tasks[j].answer
                },
                answers: props.one_exercises.game.tasks[j].delete_letter,
                enter_answers: null,
                max_point: 1,
                actual_point: 0,
                active: true
            })
        }
        setResult(resultArray);
        setAnswers(answersArray);
        setTasks(JSON.parse(JSON.stringify(props.one_exercises.game.tasks)))
        setActiveTask(0)
        if (props.play) {
            audio.current.play()
            audio.current.currentTime = 0
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
        }
    }, [props.restart])

    useEffect(() => {
        if (tasks.length === 0) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            props.endGameResults(saveTime, result)
        }
    }, [activeTask, result])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const checkAnswer = (item, key) => {
        if (nextAnswer) {
            setNextAnswer(false)
            const answersCopy = JSON.parse(JSON.stringify(answers))
            const resultCopy = JSON.parse(JSON.stringify(result))
            if (item === tasks[activeTask].delete_letter) {
                answersCopy[activeTask].choose_letters[key] = 'success'
                if (result[answersCopy[activeTask].task_index].active) {
                    resultCopy[answersCopy[activeTask].task_index].actual_point = 1
                    resultCopy[answersCopy[activeTask].task_index].enter_answers = item
                }
                setTimeout(() => {
                    if (activeTask + 1 === tasks.length) {
                        setActiveTask(activeTask - 1)
                    }
                    tasks.splice(activeTask, 1)
                    answersCopy.splice(activeTask, 1)
                    setNextAnswer(true)
                }
                    , 1000
                )
            } else {
                answersCopy[activeTask].choose_letters[key] = 'error'
                if (result[answersCopy[activeTask].task_index].active) {
                    resultCopy[answersCopy[activeTask].task_index].active = false
                    resultCopy[answersCopy[activeTask].task_index].enter_answers = item
                }
                setNextAnswer(true)
            }
            setAnswers(answersCopy);
            setResult(resultCopy)
        }
    }

    const nextTask = () => {
        if (activeTask + 1 < tasks.length) {
            setActiveTask(activeTask + 1)
        }
    }

    const backTask = () => {
        if (activeTask !== 0) {
            setActiveTask(activeTask - 1)
        }
    }

    const onClickDontKnown = () => {
        const answersCopy = JSON.parse(JSON.stringify(answers))
        const resultCopy = JSON.parse(JSON.stringify(result))
        answersCopy[activeTask].choose_letters.forEach((el, key) => {
            if (el === tasks[activeTask].delete_letter) {
                answersCopy[activeTask].choose_letters[key] = 'success'
            }
        })
        setAnswers(answersCopy)
        setTimeout(() => {
            resultCopy[answersCopy[activeTask].task_index].actual_point = 0
            resultCopy[answersCopy[activeTask].task_index].enter_answers = 'Не знаю'
            setResult(resultCopy)
            if (activeTask + 1 === tasks.length && activeTask !== 0) {
                setActiveTask(activeTask - 1)
            }
            tasks.splice(activeTask, 1)
            answersCopy.splice(activeTask, 1)
            setAnswers(answersCopy)
        }
            , 1000
        )
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                {answers.length > 0 &&
                    <>
                        <div className="gameBlock_header">
                            {timer}
                            <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                            <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                                Не знаю
                            </div>
                        </div>
                        <div className="gameBlock_content">
                            <div className="insertLetterSecondGameBlock">
                                <div className="insertLetterSecondGameBlock_question">
                                    
                                        {tasks[activeTask].photo
                                            ?
                                            <>
                                                <img src={tasks[activeTask].photo} alt="img" />
                                                <p className="insertLetterSecondGameBlock_question__textUnderImg"><Textfit mode="multi" className='textfit_block' min={20}>{tasks[activeTask].text}</Textfit></p>
                                            </>
                                            : <Textfit mode="multi" className='textfit_block'>{tasks[activeTask].text}</Textfit>
                                        }
                                    
                                </div>
                                <div className="insertLetterSecondGameBlock_answer">
                                    <div className="insertLetterSecondGameBlock_answer__text">
                                        <Textfit mode="single" className='textfit_block'>
                                            {tasks[activeTask].answer.split('').map((el, key) => {
                                                // if (el === " ") {
                                                //     return <div style={{ width: '25px' }} />
                                                // } else if (el !== tasks[activeTask].delete_letter) {
                                                //     return <p key={`answerLetter_${key}`}>{el}</p>
                                                // } else {
                                                //     return <p key={`answerLetter_${key}`}>__</p>
                                                // }
                                                if (el === " ") {
                                                    return <div style={{ width: '25px' }}>{'\xa0'}</div>
                                                } else if (el !== '_') {
                                                    return <p key={`answerLetter_${key}`}>{el}</p>
                                                } else {
                                                    return <p key={`answerLetter_${key}`}>__</p>
                                                }
                                            })}
                                        </Textfit>
                                    </div>
                                    <div className="insertLetterSecondGameBlock_answer__items">
                                        {answers[activeTask].choose_letters.map((el, key) => (
                                            <div
                                                className="insertLetterSecondGameBlock_answer__items__item"
                                                style={{ borderColor: borderColors[key] }}
                                                key={`chooseLetter_${key}`}
                                                onClick={() => checkAnswer(el, key)}
                                            >
                                                {el === 'success' ? <img src={greenCheck} alt="greenCheck" /> : (el === 'error' ? <img src={error} alt="error" /> : <Textfit mode="single" className='textfit_block' throttle={100} max={40}>{el.toUpperCase()}</Textfit>)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__steps">
                                <img src={activeTask !== 0 ? circleLeftActive : circleLeft} alt="circleLeft" onClick={backTask} />
                                <p>{activeTask + 1} из {tasks.length}</p>
                                <img src={activeTask + 1 < tasks.length ? circleRightActive : circleRight} alt="circleRight" onClick={nextTask} />
                            </div>
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(InsertLetterSecond);
