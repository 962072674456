const initialState = {
    create_task: null,
    task_type: null,
    background: null,
    photo: null,
    edit_game: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case 'selectTaskPattern':
            return {
                ...state,
                task_type: action.payload
            }
        case 'selectTheme':
            return {
                ...state,
                background: action.payload
            }
        case 'selectPhoto':
            return {
                ...state,
                photo: action.payload
            }
        case 'saveTaskForm':
            return {
                ...state,
                create_task: action.payload
            }
        case 'editGame':
            return {
                ...state,
                edit_game: true
            }
        case 'deleteTaskForm':
            return {
                create_task: null,
                task_type: null,
                background: null,
                edit_game: false
            }
        default: return state
    }
}