import './style.css';


export const InsertLetterSecond = ({ game }) => {
    return (
        <div className='insertLetterSecondPdfBlock'>
            {game.map((el, index) => (
                <div className='Pdf_task insertLetterSecondPdf' style={{ marginTop: '3mm' }} key={`quizPdf_task${index}`}>
                    <div className='insertLetterSecondPdf_question'>
                        {el.photo
                            ?
                            <div>
                                <img src={el.photo} alt="img" />
                                <p className="insertLetterSecondPdf_question__textUnderImg">{el.text}</p>
                            </div>
                            : <p>{el.text}</p>
                        }
                    </div>
                    <div className='insertLetterSecondPdf_task'>
                        <div className='insertLetterSecondPdf_task__answer'>
                            {el.answer.split('').map((item, key) => {
                                if (item === " ") {
                                    return <div style={{ width: '15px' }} />
                                } else if (item !== '_') {
                                    return <p key={`answerLetter_${key}`}>{item}</p>
                                } else {
                                    return <p key={`answerLetter_${key}`}>__</p>
                                }
                            })}
                        </div>
                        <div className='insertLetterSecondPdf_task__chooseLetters'>
                            {el.choose_letters.split(',').map((item, key) => (
                                <div className='insertLetterSecondPdf_task__chooseLetters__item' key={`chooseLetter_${key}`}>
                                    {item.toUpperCase()}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}