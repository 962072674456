import { ApiUrl, instance } from './api';

export const api_exercises = {

    async ReadeExercises(params) {
        return await instance.get(`${ApiUrl}/api/v1/exercises/get_by_owner?skip=0&limit=1000&params=${params}`)
    },

    async SearchExercises(params) {
        return await instance.get(`${ApiUrl}/api/v1/exercises?skip=0&limit=1000&params=${params}`)
    },

    async ReadeOneExercises(id) {
        return await instance.get(`${ApiUrl}/api/v1/exercises/${id}`)
    },

    async ReadeJointExercises(id) {
        return await instance.get(`${ApiUrl}/api/v1/exercises/get_joint?user_id=${id}`)
    },

    async ReadeAllExercises(skip, limit, params) {
        return await instance.get(`${ApiUrl}/api/v1/exercises?skip=${skip}&limit=${limit}&params=${params}`)
    },

    async ReadeParticipantExercises() {
        return await instance.get(`${ApiUrl}/api/v1/exercises/get_participant?skip=0&limit=1000`)
    },

    async CreateExercise(data) {
        return await instance.post(`${ApiUrl}/api/v1/exercises`, data)
    },

    async AddExercisePhoto(id, data) {
        return await instance.patch(`${ApiUrl}/api/v1/exercises/${id}/change_photo`, data)
    },

    async DeleteExercisePhoto(id) {
        return await instance.patch(`${ApiUrl}/api/v1/exercises/${id}/delete_photo`)
    },

    async AddExerciseMusic(id, data) {
        return await instance.patch(`${ApiUrl}/api/v1/exercises/${id}/change_music`, data)
    },

    async UpdateExercis(id, data) {
        return await instance.put(`${ApiUrl}/api/v1/exercises/${id}`, data)
            .then(response => { return response })
            .catch(error => {
                if (error.response) {
                    return error.response.data.detail
                } else {
                    return 'Error: Network Error'
                }
            });
    },

    async DeleteExercis(id) {
        return await instance.delete(`${ApiUrl}/api/v1/exercises/${id}`)
    },

    async AddExercisesToFolder(id, folder_id) {
        return await instance.patch(`${ApiUrl}/api/v1/exercises/${id}/add_to_folder?folder_id=${folder_id}`)
    },

    async RemoveExercisesFromFolder(id, folder_id) {
        return await instance.patch(`${ApiUrl}/api/v1/exercises/${id}/remove_from_folder?folder_id=${folder_id}`)
    },

    async ToggleExerciseHashtags(id, hashtag_id) {
        return await instance.put(`${ApiUrl}/api/v1/exercises/${id}/toggle_hashtags/${hashtag_id}`)
    },

}