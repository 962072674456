import { useTranslation } from 'react-i18next';
import './style.css';

const InstructionCard = ({
    img,
    type
}) => {
    const { t } = useTranslation();

    return (
        <div className='instructionCard'>
            <img src={img} alt='img' className='instructionCard_img' />
            <div className='instructionCard_text'>
                <p className="instructionCard_title">{t(`instruction.${type}.title`)}</p>
                <p className="instructionCard_description">{t(`instruction.${type}.description`)}</p>
            </div>
        </div>
    )
}

export default InstructionCard