import TableResult from 'components/components/tableResult/TableResult';
import { connect } from 'react-redux';

const ViewResult = ({ leader_board_results, one_exercises }) => {
    
    return (
        one_exercises &&
        <>
            <p className="modalTitle" style={{ textAlign: 'center' }}>Результаты</p>
            <div className='tableOnmodal'>
                <div
                    style={{ minWidth: 960, marginTop: 15 }}
                >
                    <TableResult
                        tableData={leader_board_results}
                        page={'leader_board_results'}
                        game_type={one_exercises.game_type}
                    />
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        leader_board_results: state.leader_board_results.leader_board_results,
        one_exercises: state.one_exercises.one_exercises,
    }
}

export default connect(mapStateToProps, null)(ViewResult)