import { MenuOutlined } from '@ant-design/icons';
import { api_exercises } from 'API/exercises';
import { Dropdown, Menu, Space, Switch, message } from 'antd';
import { editGame } from 'functions/editGames';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { fetchExercises } from 'store/actions/exercises';
import { fetchFolders } from 'store/actions/folders';
import { fetchOneExercises } from "store/actions/oneExercises";

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import SwitchCardBg from "components/backgrounds/switchCardBg";

import './style.css';

function TaskCard(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const menu = (
        <Menu>
            <Menu.Item key={'is_results'} onClick={() => onClickAckionButton('edit')}>
                <p>
                    Редактировать
                </p>
            </Menu.Item>
            <Menu.Item key={'is_duplicate'} onClick={() => onClickAckionButton('duplicate')}>
                <p>
                    Дублировать
                </p>
            </Menu.Item>
            <Menu.SubMenu
                title={"Добавить в папку"}
                key="subMenuTask"
            >
                {typeof props.folders === 'object' && props.folders.map((item, key) => {
                    return <Menu.Item key={key} onClick={() => addToFolder(item.id)}>
                        <p>{item.name}</p>
                    </Menu.Item>
                })}
            </Menu.SubMenu>
            <Menu.Item key={'is_publish'}>
                <p className='taskMenu_isPubliched'>
                    <span>Открытый доступ</span>
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={props.item.is_published}
                        onChange={(e) => onChangeSwitch(e)}
                    />
                </p>
            </Menu.Item>
            <Menu.Item key={'removeExercises'} style={{ color: 'red' }} onClick={() => { props.setVisible('deleteExercis'); props.selectDeleteExercis(props.item.id) }}>Удалить задание</Menu.Item>
            {props.item.folder_id !== null &&
                <Menu.Item key={'removeFolder'} onClick={() => removeFromFolder(props.item.folder_id)}>{t(`myTask.removeFromFolder`)}</Menu.Item>
            }
        </Menu>
    );

    const onChangeSwitch = async (checked) => {
        const data = { "is_published": checked }
        await api_exercises.UpdateExercis(props.item.id, data)
        message.success("Доступ к заданию изменен")
    };

    const addToFolder = async (folder_id) => {
        await api_exercises.AddExercisesToFolder(props.item.id, folder_id)
            .then(() => {
                message.success(t(`myTask.addToFolder_success`))
                props.fetch()
                props.fetchExercises('search_name', props.search)
            })
            .catch(() => {
                message.error(t(`myTask.addToFolder_error`))
            })
    }

    const removeFromFolder = async (folder_id) => {
        await api_exercises.RemoveExercisesFromFolder(props.item.id, folder_id)
            .then(() => {
                message.success(t(`myTask.removeFromFolder_success`))
                props.fetch()
                props.fetchFolders()
                props.fetchExercises('search_name', props.search)
            })
    }

    const onClickAckionButton = (type) => {
        let sub
        if (props.userMeSubscription.subscription === undefined) {
            sub = props.userMeSubscription
        } else {
            sub = props.userMeSubscription.subscription
        }
        props.onSearch('')
        if (props.fetch !== undefined) {
            props.stepUp()
            props.selectTaskPattern(props.item.game_type)
            props.saveTaskForm(editGame(props.item))
            props.selectPhoto(editGame(props.item).photo)
            props.selectTheme(props.item.background)
            if (type === 'edit') props.editGame()
            props.fetchOneExercises(props.item.id)
            navigate(`../create_task`)
        } else {
            if (props.token === null) {
                props.setVisible('login')
                props.PageAfterLogin('../create_task')
            } else {
                if (props.item.game_type in sub.games_list) {
                    if (props.exercises_count < sub.resources || sub.resources === 0) {
                        props.stepUp()
                        props.selectTaskPattern(props.item.game_type)
                        props.saveTaskForm(editGame(props.item))
                        props.selectPhoto(editGame(props.item).photo)
                        props.selectTheme(props.item.background)
                        navigate(`../create_task`)
                    } else {
                        message.warning(`По вашей подписке можно создать только ${sub.resources} заданий. Измените подписку или удалите уже созданные задания!`)
                    }
                } else {
                    message.warning(`Данный шаблон не входит в вашу подписку`)
                }
            }
        }
    }

    return (
        <div className="taskBlock" style={{ width: '286px', position: 'relative' }}>
            <div className="taskCard" style={{ backgroundColor: `rgba(0, 0, 0, 0.3)` }}>
                <div className="taskCard_header">
                    <div className="taskCard_title">{t(`tasks.type.${props.item.game_type}`)}</div>
                    {props.fetch !== undefined &&
                        <Dropdown overlay={menu} trigger={['click']} placement="bottom" className='taskMenu'>
                            <Space>
                                <MenuOutlined />
                            </Space>
                        </Dropdown>
                    }
                </div>
                <div>
                    <p className="taskCard_Testtitle">{props.item.name}</p>
                    <div className='taskCard_buttons'>
                        <button className="taskCard_buttons__see" onClick={() => { props.onClick(); props.onSearch('') }}>Просмотреть</button>
                        {/* {props.actionButton !== null && <button className="taskCard_buttons__apply" onClick={() => navigate(`../task_results/${props.item.id}`)}>{props.actionButton}</button>} */}
                        {props.token && <button
                            className="taskCard_buttons__apply"
                            onClick={() => {
                                if (props.actionButton !== 'Результаты') {
                                    onClickAckionButton()
                                } else {
                                    navigate(`../task_results/${props.item.id}`)
                                }
                            }
                            }>
                            {props.actionButton}
                        </button>
                        }
                    </div>
                </div>

            </div>
            {props.mode === 'result' &&
                <div className="resultInfo">
                    {/* <p className="resultInfo_title">Рандомные карточки</p> */}
                    <p>{t(`tasks.best_score`)}: {props.item.leaderboard[0].score}</p>
                    <p>{t(`tasks.time`)}: {props.item.leaderboard[0].time}</p>
                    <p>{t(`tasks.attempt`)}: {props.item.leaderboard.length}</p>
                </div>
            }
            <SwitchCardBg game_type={props.item.game_type} game={props.item.game} background={props.item.background} />
        </div>

    );
}

function mapStateToProps(state) {
    return {
        // folders: state.folders.folders,
        token: state.login.token,
        search: state.exercises.search,
        exercises_count: state.userMe.exercises_count,
        userMeSubscription: state.userMe.userMeSubscription,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        selectDeleteExercis: (text) => dispatch({ type: 'selectDeleteExercis', payload: text }),
        fetchOneExercises: (id) => dispatch(fetchOneExercises(id)),
        fetchExercises: (type, text) => dispatch(fetchExercises(type, text)),
        fetchFolders: () => dispatch(fetchFolders()),
        stepUp: () => dispatch({ type: 'stepUp' }),
        selectTaskPattern: (text) => dispatch({ type: 'selectTaskPattern', payload: text }),
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        selectTheme: (text) => dispatch({ type: 'selectTheme', payload: text }),
        selectPhoto: (text) => dispatch({ type: 'selectPhoto', payload: text }),
        editGame: () => dispatch({ type: 'editGame' }),
        PageAfterLogin: (text) => dispatch({ type: 'PageAfterLogin', payload: text }),
        onSearch: (text) => dispatch({ type: 'onSearch', payload: text }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(TaskCard);
