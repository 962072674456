import { api_leaderBoard } from 'API/leaderBoard'
import { FETCH_ACHIEVEMENTS_START, FETCH_ACHIEVEMENTS_SUCCESS, FETCH_ACHIEVEMENTS_ERROR } from '../actionTypes'

export function fetchAchievements(id) {
    return async dispatch => {
        dispatch(fetchStart())
        try {
            const achievements = await api_leaderBoard.ReadeAchievements(id)
            dispatch(fetchSuccess(achievements.data))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchStart() {
    return {
        type: FETCH_ACHIEVEMENTS_START
    }
}

export function fetchSuccess(achievements) {
    return {
        type: FETCH_ACHIEVEMENTS_SUCCESS,
        achievements,
    }
}

export function fetchError(e) {
    return {
        type: FETCH_ACHIEVEMENTS_ERROR,
        error: e,
    }
}
