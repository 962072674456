import { UserOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { copy } from "functions/generalFunction";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { fetchAchievements } from "store/actions/achievements";
import { fetchExercises } from 'store/actions/exercises';
import { fetchParticipantExercises } from "store/actions/participantExercises";
import { fetchUserMe } from 'store/actions/userMe';

import CustomTable from "components/components/customTable/CustomTable";
import TaskCard from "components/components/taskCard/TaskCard";
import { progress_data } from "lists/table_data";

import background from 'assets/backgrounds/profile.png';

import './style.css';

const { Option } = Select;

function Profile({
    userMe,
    participant_exercises,
    achievements,
    fetchExercises,
    fetchAchievements,
    fetchParticipantExercises,
    fetchUserMe
}) {
    const [participantExercises, setParticipantExercises] = useState([])
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [activeButton, setActiveButton] = useState(1)
    const temp = useRef()

    const startFunction = () => {
        fetchParticipantExercises()
        if (userMe === null) {
            fetchUserMe()
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    useEffect(() => {
        if (userMe !== null) fetchAchievements(userMe.id)
    }, [userMe])

    useEffect(() => {
        setParticipantExercises(participant_exercises)
    }, [participant_exercises])

    const columns = [
        {
            title: t(`table.profile.place`),
            dataIndex: 'place',
            sorter: {
                compare: (a, b) => a.place - b.place,
                multiple: 1,
            },
        },
        {
            title: t(`table.profile.project`),
            dataIndex: 'project',
            sorter: {
                compare: (a, b) => a.project.localeCompare(b.project),
                multiple: 2,
            },
        },
        {
            title: t(`table.profile.name`),
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 3,
            },
        },
        {
            title: t(`table.profile.score`),
            dataIndex: 'score',
            sorter: {
                compare: (a, b) => a.score - b.score,
                multiple: 4,
            },
        },
        {
            title: t(`table.profile.time`),
            dataIndex: 'time',
            sorter: {
                compare: (a, b) => a.time - b.time,
                multiple: 5,
            },
        },
    ];

    const sortArray = (value) => {
        const sort = copy(participantExercises)
        if (value === 'rating') {
            sort.sort((a, b) => a.leaderboard.length - b.leaderboard.length)
        }
        if (value === 'name') {
            sort.sort((a, b) => a.name.localeCompare(b.name))
        }
        setParticipantExercises(sort)
    }

    return (
        <>
            <img src={background} className="mainMiniImg" />
            {userMe !== null &&
                <div className="patternsBlock">
                    <div className="profileBlock_header">
                        <div className="profileBlock_header__avatar">
                            {userMe.photo !== null ? <img src={userMe.photo} alt="avatar" /> : <UserOutlined style={{ fontSize: '50px' }} />}
                        </div>
                        <div className="profileBlock_header__description">
                            <p className="profileBlock_header__description__fio">{userMe.first_name} {userMe.last_name}</p>
                            <p className="profileBlock_header__description__info">{t(`profile.roly.${String(userMe.is_teacher)}`)}, {userMe.country} <Link to="/profile/edit" className="profileBlock_header__description__info__edit">{t(`profile.edit`)}</Link></p>
                        </div>
                    </div>
                    <div className="profileBlock_actions profile_actions">
                        <div className="profileBlock_actions__buttons">
                            <button
                                className={activeButton === 1 ? "active" : ''}
                                onClick={() => setActiveButton(1)}
                            >
                                {t(`profile.all_task`)}
                            </button>
                            {/* <button
                                className={activeButton === 2 ? "active" : ''}
                                onClick={() => setActiveButton(2)}
                            >
                                {t(`profile.joint_tasks`)}
                            </button> */}
                            <button
                                className={activeButton === 3 ? "active" : ''}
                                onClick={() => setActiveButton(3)}
                            >
                                {t(`profile.progress`)}
                            </button>
                        </div>
                        <div className="profileBlock_actions__sort">
                            <p>{t(`profile.sort.title`)}:</p>
                            <Select
                                style={{ width: 100 }}
                                bordered={false}
                                onChange={sortArray}
                            >
                                <Option value="rating">Популярности</Option>
                                <Option value="name">Имя</Option>
                            </Select>
                        </div>
                    </div>
                    {activeButton === 1 &&
                        <div className="patternsBlock_list profileTasks_list">
                            {participantExercises.map((item, key) => {
                                return <div className='profileTasks_list__item' key={`profile_task_${item.id}`} >
                                    <TaskCard mode={'result'} item={item} onClick={() => navigate(`../task/${item.id}/watch`)} actionButton={null} />
                                </div>
                            })}
                        </div>
                    }
                    {/* {activeButton === 2 &&
                        <div className="patternsBlock_list">
                            <TaskCard mode="result" />
                        </div>
                    } */}
                    {activeButton === 3 &&
                        <div className='resultsBlock_tableBlock'>
                            <h1>{t(`profile.table`)}</h1>
                            {/* <div className='noTasks'>
                <img src={noPlayers} alt="noPlayers" />
                <p>{t(`myTask.noPlayers`)}</p>
                <p>{t(`myTask.invitePlayers`)}</p>
                <p className='inviteLink' onClick={shareInvite}>http://www.groovestreet.com/21543777</p>
            </div> */}
                            <div className='tableBlock'>
                                <CustomTable columns={columns} data={progress_data(achievements)} />
                            </div>
                        </div>
                    }

                </div>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        userMe: state.userMe.userMe,
        participant_exercises: state.participant_exercises.participant_exercises,
        achievements: state.achievements.achievements
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchExercises: (type, text) => dispatch(fetchExercises(type, text)),
        fetchAchievements: (id) => dispatch(fetchAchievements(id)),
        fetchParticipantExercises: () => dispatch(fetchParticipantExercises()),
        fetchUserMe: () => dispatch(fetchUserMe()),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Profile);
