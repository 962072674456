import { api_user } from 'API/users'
import { FETCH_USER_START, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from '../actionTypes'

export function fetchUser(id) {
    return async dispatch => {
        dispatch(fetchUserStart())
        try {
            const user = await api_user.ReadeOneUser(id)
            dispatch(fetchUserSuccess(user.data))
        } catch (e) {
            dispatch(fetchUserError(e))
        }
    }
}

export function fetchUserStart() {
    return {
        type: FETCH_USER_START
    }
}

export function fetchUserSuccess(userMe) {
    return {
        type: FETCH_USER_SUCCESS,
        userMe,
    }
}

export function fetchUserError(e) {
    return {
        type: FETCH_USER_ERROR,
        error: e,
    }
}
