import Join from 'components/forms/join/Join';

import background from 'assets/backgrounds/main.png';

import './style.css';

function QuickAuthorization() {
    return (
        <>
            <img src={background} className="mainMiniImg" />
            <div className="quickAuthorizationBlock">
                <div className="joinBlock">
                    <Join />
                </div>
            </div>
        </>
    );
}

export default QuickAuthorization;
