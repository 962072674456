import { memo } from "react";
import { connect } from 'react-redux';

import './media.css';
import './style.css';

function Pattern({ mode, item, onClick, userMeSubscription }) {
    return (
        <div className={`patternBlock ${item.style}`} >
            <div className="patternBlock_content" onClick={onClick}>
                <div className="patternBlock_content_img">
                    <img src={item.icon} alt="icon" />
                </div>
                <div className="patternBlock_content_description">
                    <p className="patternBlock_content_description__title">{item.title}</p>
                    <p className="patternBlock_content_description__description">{item.description}</p>
                </div>
            </div>
            {/* {(mode !== 'instruction' && userMeSubscription !== null && !(item.type in (userMeSubscription.subscription ? userMeSubscription.subscription.games_list : userMeSubscription.games_list))) &&
                <div className="patternBlocked">
                    <p>Данный шаблон не входит в вашу подписку</p>
                </div>
            } */}
        </div>

    );
}

function mapStateToProps(state) {
    return {
        userMeSubscription: state.userMe.userMeSubscription
    }
}

export default connect(mapStateToProps, null)(memo(Pattern));
