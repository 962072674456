import { message } from 'antd';
import { editGame } from 'functions/editGames';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-skeleton-loader';
import { fetchLeaderBoard } from "store/actions/leaderBoard";
import { fetchOneExercises } from "store/actions/oneExercises";
import { fetchUser } from "store/actions/oneUser";

import LeaderBoard from "components/components/leaderBoard/LeaderBoard";
import FastRegistration from "components/forms/fastRegistration/FastRegistration";
import GameBlock from "components/games/GameBlock";
import Pdf from "components/pdf/Pdf";

import background from 'assets/backgrounds/main.png';
import edit from 'assets/edit.svg';
import share from 'assets/share.svg';

import { viewLeaderBoard } from 'lists/leaderBoard';
import { pdfTaskInfo } from "lists/pdfTaskInfo";
import './media.css';
import './style.css';

function TaskInfo({
    one_exercises,
    userMe,
    fast_reg,
    fetchOneExercises,
    fetchUser,
    stepUp,
    selectTaskPattern,
    saveTaskForm,
    selectTheme,
    selectPhoto,
    editGameProps,
    fetchLeaderBoard,
}) {
    const { t } = useTranslation();
    const { id, group_id } = useParams()
    const navigate = useNavigate()
    const temp = useRef()
    const [domen, setDomen] = useState(window.location.hostname)
    const [puth, setPuth] = useState('https')

    const startFunction = async () => {
        fetchOneExercises(id)
        fetchLeaderBoard(id, null, 0, 1000)
        if (domen === 'localhost') {
            setDomen('localhost:3000')
            setPuth('http')
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
        window.scrollTo(0, 0)
    }, [])

    const shareOnClick = () => {
        navigator.clipboard.writeText(window.location.href)
        message.success(t(`tasks.share_message`))
    }

    const shareForStudentsOnClick = () => {
        navigator.clipboard.writeText(`${puth}://${domen}/task/${id}/all`)
        message.success(t(`tasks.share_message`))
    }

    const onClickUser = () => {
        fetchUser(one_exercises.owner.id)
        navigate(`../profileUser/${one_exercises.owner.id}`)
    }

    const onClickEdit = () => {
        stepUp()
        selectTaskPattern(one_exercises.game_type)
        saveTaskForm(editGame(one_exercises))
        selectPhoto(editGame(one_exercises).photo)
        selectTheme(one_exercises.background)
        editGameProps()
        fetchOneExercises(one_exercises.id)
        navigate(`../create_task`)
    }

    const showTask = () => {
        return (
            <div className="patternInfoBlock taskInfoBlock">
                <p className="patternInfoBlock_header__title">{t(`tasks.type.${one_exercises.game_type}`)}: {one_exercises !== null ? one_exercises.name : <Skeleton width="400px" />}</p>
                <div style={{ marginTop: 16 }}>
                    <GameBlock group_id={group_id} />
                </div>
                <div className="patternInfoBlock_header taskInfo_actions" style={{ justifyContent: 'space-between' }}>
                    {/* <p className="patternInfoBlock_header__title">{one_exercises !== null ? one_exercises.name : <Skeleton width="400px" />}</p> */}
                    <div className='taskInfo_actions__hashtags'>
                        {one_exercises.hashtags.map(el => (
                            <p key={`hashtags_${el.id}`} className='taskInfo_actions__hashtags__item' onClick={() => navigate(`../tasks/all?hashtag_ids=${JSON.stringify(el)}`)}>{el.name}</p>
                        ))}
                    </div>
                    <div className="patternInfoBlock_actions">
                        {pdfTaskInfo.findIndex(el => el.game_type === one_exercises.game_type) !== -1 && <Pdf />}
                        <button className="patternInfoBlock_header__share" onClick={shareOnClick}><img src={share} alt="share" />{t(`tasks.share`)}</button>
                        {userMe !== null && userMe.id === one_exercises.owner.id && <button className="patternInfoBlock_header__share" onClick={shareForStudentsOnClick} style={{ marginLeft: 15 }}><img src={share} alt="share" />{t(`tasks.shareForStudents`)}</button>}
                    </div>
                </div>
                <div className="patternInfoBlock_actions">
                    {one_exercises !== null ? <p className="patternInfoBlock_actions__creater">{t(`tasks.by`)}<span style={{ cursor: 'pointer' }} onClick={onClickUser}> {one_exercises.owner.first_name} {one_exercises.owner.last_name}</span></p> : <Skeleton width="400px" />}
                    <div className="patternInfoBlock_actions__buttons">
                        {/* <p className="patternInfoBlock_actions__buttons__like"><img src={like} alt="like" />{t(`tasks.like`)}</p> */}
                        {userMe !== null &&
                            userMe.id === one_exercises.owner.id && <p className="patternInfoBlock_actions__buttons__edit" onClick={onClickEdit}><img src={edit} alt="edit" />{t(`tasks.edit`)}</p>
                        }
                    </div>
                </div>
                {viewLeaderBoard.indexOf(one_exercises.game_type) !== -1 &&
                    <LeaderBoard />
                }
            </div>
        )
    }

    const validUser = () => {
        if (group_id === 'watch') {
            return showTask()
        }
        if (userMe !== null || fast_reg !== null) {
            return showTask()
        } else {
            return (
                <div className="fastRegistartionBlock">
                    <FastRegistration />
                </div>
            )
        }
    }
    return (
        one_exercises !== null &&
        <>
            <img src={background} className="mainImg" />
            {validUser()}
        </>

    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
        userMe: state.userMe.userMe,
        fast_reg: state.userMe.fast_reg,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchOneExercises: (id) => dispatch(fetchOneExercises(id)),
        fetchUser: (id) => dispatch(fetchUser(id)),
        stepUp: () => dispatch({ type: 'stepUp' }),
        selectTaskPattern: (text) => dispatch({ type: 'selectTaskPattern', payload: text }),
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        selectTheme: (text) => dispatch({ type: 'selectTheme', payload: text }),
        selectPhoto: (text) => dispatch({ type: 'selectPhoto', payload: text }),
        editGameProps: () => dispatch({ type: 'editGame' }),
        fetchLeaderBoard: (id, group_name, skip, limit) => dispatch(fetchLeaderBoard(id, group_name, skip, limit)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(TaskInfo);
