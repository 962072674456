import { ApiUrl, instance } from './api';

export const api_hashtags = {
    
    async ReadeHashtags(params) {
        return await instance.get(`${ApiUrl}/api/v1/hashtags?skip=0&limit=100&params=${params}`)
    },

    async CreateHashtag(data) {
        return await instance.post(`${ApiUrl}/api/v1/hashtags`, data)
    },

}