import { Textfit } from 'react-textfit';
import './style.css';

const borderColors = ['#E88F9A', '#A4D6EF', '#85E5A0', '#8E86E7', '#EEB157', '#A4D6EF']

function InsertLetterSecond({ game }) {
    return (
        <div className='exercisesCardBg_content'>
            <div className='secondInsertLetterBg'>
                <div className='secondInsertLetterBg_question'>
                    {game[0].photo
                        ?
                        <>
                            <img src={game[0].photo} alt="img" />
                            <Textfit mode="multi" className='textfit_block' style={{ height: '100%' }}>{game[0].text}</Textfit>
                        </>
                        : <Textfit mode="multi" className='textfit_block'>{game[0].text}</Textfit>
                    }
                </div>
                <div className='secondInsertLetterBg_task'>
                    <div className='secondInsertLetterBg_task__answer'>
                        <Textfit mode="single" className='textfit_block'>
                            {game[0].answer.split('').map((el, key) => {
                                if (el === " ") {
                                    return <div style={{ width: '25px' }}>{'\xa0'}</div>
                                } else if (el !== "_") {
                                    return <p key={`answerLetter_${key}`}>{el}</p>
                                } else {
                                    return <p key={`answerLetter_${key}`}>__</p>
                                }
                            })}
                        </Textfit>
                    </div>
                    <div className='secondInsertLetterBg_task__chooseLetters'>
                        {game[0].choose_letters.split(',').map((el, key) => (
                            <div className='secondInsertLetterBg_task__chooseLetters__item' key={`chooseLetter_${key}`} style={{ borderColor: borderColors[key] }}>
                                <Textfit mode="single" className='textfit_block' max={10}>{el}</Textfit>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default InsertLetterSecond;
