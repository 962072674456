import { useTranslation } from "react-i18next";

import { steps } from "lists/list";

import './style.css';

function CreateTaskSteps({ step }) {
    const { t } = useTranslation();
    return (
        <div className="createTask_steps">
            <div className="stepsBlock_line" />
            <div className="createTask_steps__circles">
                {steps.map((item, key) => {
                    return (<div className="stepsBlock_step" key={key}>
                        <div className="stepsBlock_circle">
                            <div className="stepsBlock_circle__bacground">
                                <div className={`stepsBlock_circle2 ${step >= key && 'active'}`}> {/*active*/}
                                    {item.img}
                                </div>
                            </div>
                        </div>
                        <p className={`${step === key && 'stepsBlock_step_textActive'}`}>{t(`steps.${item.title}`)}</p>
                    </div>)
                })}
                {/* <div className="stepsBlock_step">
                    <div className="stepsBlock_circle">
                        <div className="stepsBlock_circle__bacground">
                            <div className="stepsBlock_circle2 active">
                                <img src={pattern} alt="pattern" />
                            </div>
                        </div>
                    </div>
                    <p>Выбрать шаблон</p>
                </div> */}
            </div>
        </div>
    );
}

export default CreateTaskSteps;
