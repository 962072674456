import { useState, useEffect, useRef } from 'react';

import '../style.css';

function Anagram({ game }) {
    const [answer, setAnswers] = useState([])
    const temp = useRef()

    const startFunction = () => {
        setAnswers(game[0].answer.toUpperCase().split('').sort(() => Math.random() - 0.5))
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    return (
        <div className='exercisesCardBg_content'>
            <div className='exercisesCardBg_content__card'>
                {game[0].photo !== null
                    ? <img src={game[0].photo} />
                    : <p>{game[0].text}</p>
                }
            </div>
            <div className="anagramBlock_question__dropDragBlock">
                {game[0].answer.split('').map((item, key) => {
                    if (item !== ' ') {
                        return <div className="anagramBlock_question__dropDragBlock__item" key={key} />
                    } else {
                        return <div style={{width: 20}} />
                    }
                })}
            </div>
            <div className='anagramBgAnswers'>
                {answer.map((item, key) => {
                    if (item !== ' ') {
                        return <div key={key}>{item}</div>
                    }
                })}
            </div>
        </div>
    );
}

export default Anagram;
