import './style.css';

const Snowflake = (props) => {
    return (
        <p className='Snowflake' id={`item${props.id}`} style={props.style}>
            *
        </p>
    )
}

function Snow() {
    const snow = () => {
        let animationDelay = '0s';
        let fontSize = '100px';
        let arr = Array.from('Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!!')
        return arr.map((el, i) => {
            animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
            fontSize = `${(Math.floor(Math.random() * 10) + 40)}px`;
            let style = {
                animationDelay,
                fontSize
            }
            return (<Snowflake key={i} id={i} style={style} />)
        })
    }

    return (
        <div className='snowAnimation winter_bg'>
            {snow()}
        </div>
    )
}

export default Snow;