import fullWindow from 'assets/games/openWindow/fullWindow.svg';

import '../style.css';

function OpenWindow({ game }) {
    return (
        <div className='exercisesCardBg_content'>
            <div className='openWindowBg_block'>
                {game.map((item, key) => {
                    if (key < 8) {
                        return <div className='openWindowBg_block__item' key={key}>
                            <p>{key + 1}</p>
                            <img src={fullWindow} alt="fullWindow" />
                        </div>
                    }
                })}
            </div>
        </div>
    );
}

export default OpenWindow;
