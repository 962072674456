import sound from 'assets/sound.svg'
import sound_white from 'assets/sound_white.svg'
import soundoff from 'assets/soundoff.svg'
import soundoff_white from 'assets/soundoff_white.svg'

export const selectIconColor = (backgrounds) => {
    // eslint-disable-next-line default-case
    switch (backgrounds) {
        case 'CLASSIC':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'NEUTRAL':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'WINTER':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'SUMMER':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'AUTUMN':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'SPACE':
            return {
                color: '#fff',
                sound: sound_white,
                soundoff: soundoff_white
            }
        case 'SPRING':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'CLOUD':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'BOOK':
            return {
                color: '#fff',
                sound: sound_white,
                soundoff: soundoff_white
            }
        case 'PUZZLE':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'TREES':
            return {
                color: '#fff',
                sound: sound_white,
                soundoff: soundoff_white
            }
        case 'STORY':
            return {
                color: '#fff',
                sound: sound_white,
                soundoff: soundoff_white
            }
        case 'SCHOOL_BLACKBOARD':
            return {
                color: '#fff',
                sound: sound_white,
                soundoff: soundoff_white
            }
        case 'CHILDHOOD':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'CELL':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'MUSIC':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'FANTASY':
            return {
                color: '#000',
                sound,
                soundoff
            }
        case 'NOTEBOOK':
            return {
                color: '#000',
                sound,
                soundoff
            }
    }
}