import { FETCH_LOGIN_START, FETCH_LOGIN_SUCCESS, FETCH_LOGIN_ERROR } from '../actionTypes'

const LocalToken = localStorage.getItem('superkid-token');
let token = LocalToken;

const initialState = {
    token: token,
    link: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_LOGIN_START:
            return {
                ...state, loading: true
            }
        case FETCH_LOGIN_SUCCESS:
            return {
                ...state, loading: false,
                token: action.token,
            }
        case FETCH_LOGIN_ERROR:
            return {
                ...state, loading: false
            }
        case 'EXIT':
            localStorage.removeItem('superkid-token');
            return {
                ...state,
                token: null
            }
        case 'PageAfterLogin':
            return {
                ...state,
                link: action.payload,
            }
        default: return state
    }
}