import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';
import shuffle from 'assets/shuffle.svg';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

function Cards(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks.slice(0)) /*Задания */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [loading, setLoading] = useState(false)
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/

    const [backCard, setBackCard] = useState()

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        const startAgain = {
            photo: null,
            text: <div onClick={refreshPage} >Начать сначала?</div>,
            answer: null
        }

        setLoading(true)
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTasks([...tasks, startAgain])
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    const mix = () => {
        const newArray = props.one_exercises.game.tasks.slice(0)
        newArray.sort(() => Math.random() - 0.5)
        newArray.push({
            photo: null,
            text: <div onClick={refreshPage}>Начать сначала?</div>,
            answer: null
        })
        setTasks(newArray)
    }

    const refreshPage = () => {
        setActiveTask(0)
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const deleteCless = (el, className) => {
        el.classList.remove(className)
    }

    const addRemoveStyle = (type) => {
        const el = document.getElementsByClassName('cardBlock_answer')[0]
        if (type === 'add') {
            el.style.display = 'inline-grid'
        } else {
            el.style.display = 'none'
        }
    }

    const nextTask = () => {
        const el1 = document.getElementsByClassName('cardBlock_question')[0]
        const el2 = document.getElementsByClassName('cardBlock_question')[1]
        if (activeTask + 1 < tasks.length) {
            if (tasks[activeTask + 1].photo !== null) {
                setBackCard(<div>
                    <img src={tasks[activeTask + 1].photo} alt="img" />
                    <p>{tasks[activeTask + 1].text}</p>
                </div>)
            } else {
                setBackCard(<div><p>{tasks[activeTask + 1].text}</p></div>)
            }
            el1.classList.add('item_1')
            el2.classList.add('item_2')
            el1.scrollTo(0, 0)
            el2.scrollTo(0, 0)
            setTimeout(deleteCless, 2200, el1, 'item_1')
            setTimeout(deleteCless, 2200, el2, 'item_2')
            setTimeout(addRemoveStyle, 2200, 'add')
            setTimeout(setActiveTask, 2100, activeTask + 1)
            setTimeout(setBackCard, 2200, null)
        }
    }

    const backTask = () => {
        const el1 = document.getElementsByClassName('cardBlock_question')[0]
        const el2 = document.getElementsByClassName('cardBlock_question')[1]
        if (activeTask !== 0) {
            if (tasks[activeTask - 1].photo !== null) {
                setBackCard(<div>
                    <img src={tasks[activeTask - 1].photo} alt="img" />
                    <p>{tasks[activeTask - 1].text}</p>
                </div>)
            } else {
                setBackCard(<div><p>{tasks[activeTask - 1].text}</p></div>)
            }
            el1.classList.add('item_1')
            el2.classList.add('item_2')
            el1.scrollTo(0, 0)
            el2.scrollTo(0, 0)
            setTimeout(deleteCless, 2200, el1, 'item_1')
            setTimeout(deleteCless, 2200, el2, 'item_2')
            setTimeout(addRemoveStyle, 2200, 'add')
            setTimeout(setActiveTask, 2100, activeTask - 1)
            setTimeout(setBackCard, 2200, null)
        }
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        <>
            {loading &&
                <Fullscreen
                    enabled={isFullscreenEnabled}
                    onClose={() => {
                        setIsFullscreenEnabled(false)
                        setIsFullscreenEnabledClass(false)
                    }}
                >
                    {props.animation}
                    <div className={`gameBlock ${props.background}`} >
                        <div className="gameBlock_header">
                            <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                        </div>
                        <div className="gameBlock_content cardGame_content">
                            <div className="cardBlock">
                                <div className="cardBlock_questionBlock">
                                    <div className="cardBlock_shadow item_1" />
                                    <div className="cardBlock_shadow item_2" />
                                    <div className="cardBlock_shadow item_3" />
                                    <div className="cardBlock_question">
                                        {backCard}
                                    </div>
                                    <div className={`cardBlock_question`}>
                                        {tasks[activeTask].photo !== null
                                            ? <div>
                                                <img src={tasks[activeTask].photo} alt="img" />
                                                <p>{tasks[activeTask].text}</p>
                                            </div>
                                            : <div><p>{tasks[activeTask].text}</p></div>
                                        }
                                    </div>
                                </div>
                                {/* <p className="cardBlock_answer">{tasks[activeTask].answer}</p> */}
                            </div>
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__steps">
                                <img src={activeTask !== 0 ? circleLeftActive : circleLeft} alt="circleLeft" onClick={backTask} />
                                <p>{activeTask + 1} из {tasks.length}</p>
                                <img src={activeTask + 1 < tasks.length ? circleRightActive : circleRight} alt="circleRight" onClick={nextTask} />
                            </div>
                            <div className="gameBlock_footer__options">
                                <img src={shuffle} alt="mix" onClick={mix} style={{ width: '32px', height: '32px' }} />
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </div>
                </Fullscreen>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Cards);
