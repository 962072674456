import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import HeadShake from 'react-reveal/HeadShake';
import ReactStopwatch from 'react-stopwatch';

import { copy } from "../../../functions/generalFunction";

import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';
import error from 'assets/error.svg';
import greenCheck from 'assets/greenCheck.svg';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import '../style.css';
import './media.css';

function Quiz(props) {
    const bgArray = ['#ff7675', '#fdcb6e', '#a29bfe', '#cf6a87']
console.log(props.one_exercises.game.tasks);
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks.slice(0)) /*tasks */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState([]) /*Массив ответов в случайном полядке*/
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [errorAnswer1, setErrorAnswer1] = useState(0) /*Проверка на ошибку первого ответа */
    const [errorAnswer2, setErrorAnswer2] = useState(0) /*Проверка на ошибку второго ответа */
    const [errorAnswer3, setErrorAnswer3] = useState(0) /*Проверка на ошибку третьего ответа */
    const [errorAnswer4, setErrorAnswer4] = useState(0) /*Проверка на ошибку четвертого ответа */
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        question: {
            photo:, фото вопроса
            text: текст вопроса
        },
        answers: [], массив изначальных ответов 
        enter_answers: [], массив выбранных ответов
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true можно ли изменять этот объект
    */
    const [nextAnswer, setNextAnswer] = useState(true)
    const errorAnswers = [errorAnswer1, errorAnswer2, errorAnswer3, errorAnswer4]
    const setErrorAnswers = [setErrorAnswer1, setErrorAnswer2, setErrorAnswer3, setErrorAnswer4]
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState() /*Таймер*/

    const temp = useRef()
    let audio = useRef()

    const findexTaskIndex = () => {
        return props.one_exercises.game.tasks.findIndex(t =>
            t.text === tasks[activeTask].text &&
            t.task_answers === tasks[activeTask].task_answers &&
            t.photo === tasks[activeTask].photo
        )
    }

    const startFunction = () => {
        let answersArray = []
        const resultArray = []
        for (let j = 0; j < tasks.length; j++) {
            answersArray.push(tasks[j].task_answers.sort(() => Math.random() - 0.5))
            resultArray.push({
                question: {
                    photo: tasks[j].photo,
                    text: tasks[j].text
                },
                answers: tasks[j].task_answers.filter(el => el.is_right).map(el => el.answer),
                enter_answers: [],
                max_point: tasks[j].task_answers.map(el => el.is_right).filter(right => right).length,
                actual_point: 0,
                active: true
            })
        }
        setResult(resultArray);
        setAnswers(answersArray);
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        let answersArray = []
        const resultArray = []
        const taskCopy = props.one_exercises.game.tasks.slice(0)
        for (let j = 0; j < taskCopy.length; j++) {
            answersArray.push(taskCopy[j].task_answers.sort(() => Math.random() - 0.5))
            resultArray.push({
                question: {
                    photo: taskCopy[j].photo,
                    text: taskCopy[j].text
                },
                answers: taskCopy[j].task_answers.filter(el => el.is_right).map(el => el.answer),
                enter_answers: [],
                max_point: taskCopy[j].task_answers.map(el => el.is_right).filter(right => right).length,
                actual_point: 0,
                active: true
            })
        }
        setResult(resultArray);
        setAnswers(answersArray);
        setTasks(taskCopy)
        if (props.play) {
            audio.current.play()
            audio.current.currentTime = 0
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
        }
    }, [props.restart])

    useEffect(() => {
        if (tasks.length === 0) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            props.endGameResults(saveTime, result)
        }
    }, [activeTask, result])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const checkAnswer = (item, key) => {
        const newArrayAnswer = copy(answers)
        const resultCopy = copy(result)
        if (nextAnswer) {
            setNextAnswer(false)
            if (!item.is_right) {
                // setErrorAnswers[key](errorAnswers[key] + 1)
                newArrayAnswer[activeTask][key].is_right = 'error'
                setTimeout(() => {
                    resultCopy[findexTaskIndex()].active = false
                    resultCopy[findexTaskIndex()].enter_answers.push({
                        answer: item.answer,
                        photo: item.photo
                    })
                    setNextAnswer(true)
                    setResult(resultCopy)
                }
                    , 1000
                )

            } else {
                if (result[findexTaskIndex()].active) {
                    resultCopy[findexTaskIndex()].actual_point += 1
                    resultCopy[findexTaskIndex()].enter_answers.push({
                        answer: item.answer,
                        photo: item.photo
                    })
                }
                newArrayAnswer[activeTask][key].is_right = 'success'
                setTimeout(() => {
                    if (answers[activeTask].map((el, i) => el.is_right === true ? i : '').filter(String).length === 1) {
                        if (activeTask + 1 === tasks.length && activeTask !== 0) {
                            setActiveTask(activeTask - 1)
                        }
                        tasks.splice(tasks.indexOf(tasks[activeTask], activeTask), 1)
                        newArrayAnswer.splice(activeTask, 1)
                    }
                    setNextAnswer(true)
                    setResult(resultCopy)
                }
                    , 1000)
            }
            setAnswers(newArrayAnswer)
        }
    }

    const nextTask = () => {
        if (activeTask + 1 < tasks.length) {
            setActiveTask(activeTask + 1)
        }
    }

    const backTask = () => {
        if (activeTask !== 0) {
            setActiveTask(activeTask - 1)
        }
    }

    const onClickDontKnown = () => {
        const newArrayAnswer = copy(answers)
        const resultCopy = copy(result)
        newArrayAnswer[activeTask].forEach(el => {
            if (el.is_right) {
                el.is_right = 'success'
            }
        })
        setAnswers(newArrayAnswer)
        setTimeout(() => {
            resultCopy[findexTaskIndex()].actual_point = 0
            resultCopy[findexTaskIndex()].enter_answers.length = 0
            resultCopy[findexTaskIndex()].enter_answers.push({
                answer: 'Не знаю',
            })
            setResult(resultCopy)
            if (activeTask + 1 === tasks.length && activeTask !== 0) {
                setActiveTask(activeTask - 1)
            }
            tasks.splice(tasks.indexOf(tasks[activeTask], activeTask), 1)
            newArrayAnswer.splice(activeTask, 1)
            setAnswers(newArrayAnswer)
        }
            , 1000
        )
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.getElementsByClassName('FullScreen')[0].classList.add("fullScreenActive")
                setIsFullscreenEnabledClass(true)
            } else {
                document.getElementsByClassName('FullScreen')[0].classList.remove("fullScreenActive")
                setIsFullscreenEnabledClass(false)
            }
        }
    }
    
    return (
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                {tasks.length > 0 &&
                    <>
                        <div className="gameBlock_header">
                            {timer}
                            <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                            <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                                Не знаю
                            </div>
                        </div>
                        <div className="gameBlock_content quiz_content">
                            <div className="quizesBlock_question_text">
                                {tasks[activeTask].photo !== null
                                    ?
                                    <>
                                        <img src={tasks[activeTask].photo} alt="img" className="quizesBlock_question_img" />
                                        {tasks[activeTask].text && <p style={{ whiteSpace: 'pre-line' }}>{tasks[activeTask].text}</p>}
                                    </>
                                    : <p style={{ whiteSpace: 'pre-line' }}>{tasks[activeTask].text}</p>
                                }
                            </div>
                            {answers.length > 0
                                &&
                                <div className="gameBlock_content__answers quizesBlock_answers" style={{ flexWrap: 'nowrap', alignItems: 'stretch' }}>
                                    {answers[activeTask].map((item, key) => {
                                        return (
                                            <HeadShake spy={errorAnswers[key]} key={`answer${key}`}>
                                                <div className="gameBlock_content__answers__step quiz_text"
                                                    style={{ border: `5px solid ${bgArray[key]}`, width: 'fit-content', flexDirection: 'column', alignItems: 'center', background: '#FFFFFF', height: '100%' }}
                                                    onClick={() => { if (item.is_right !== 'success') checkAnswer(item, key) }}
                                                >
                                                    <p className="gameBlock_content__answers__step__number">{key + 1}</p>
                                                    {item.photo && <img src={item.photo} alt='answerImage' className='quizAnswerPhoto' />}
                                                    <p className="gameBlock_content__answers__step__answer">{item.answer}</p>
                                                    {item.is_right === 'success' && <img className="gameBlock_content__answers__step__greenCheck" src={greenCheck} alt="greenCheck" />}
                                                    {item.is_right === 'error' && <img className="gameBlock_content__answers__step__greenCheck" src={error} alt="greenCheck" />}
                                                </div>
                                            </HeadShake>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__steps">
                                <img src={activeTask !== 0 ? circleLeftActive : circleLeft} alt="circleLeft" onClick={backTask} />
                                <p>{activeTask + 1} из {tasks.length}</p>
                                <img src={activeTask + 1 < tasks.length ? circleRightActive : circleRight} alt="circleRight" onClick={nextTask} />
                            </div>
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Quiz);
