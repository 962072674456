import { copy } from 'functions/generalFunction';
import { useEffect, useState } from 'react';
import '../style.css';
import './style.css';

export const InsertLetter = ({ game }) => {
    const [tasks, setTasks] = useState(game.question.split(' '))
    const [answers, setAnswers] = useState(Array(game.answers.length))
    const question = game.question.split(' ')

    useEffect(() => {
        const copyTask = copy(tasks)
        const arrayAnswers = []
        game.answers.forEach((item, key) => {
            arrayAnswers.push(item)
        })
        copyTask.forEach((el, index) => {
            copyTask[index] = el.replace(/[,.;:?!"'|@#()_+=-]\s*$/, "")
        })
        // setTasks(copyTask)
        setAnswers(arrayAnswers)
    }, [])
    
    return (
        <>
            <div className='Pdf_task insertLetterPdf'>
                <div className="insertLetterPdf_question">
                    {tasks.map((el, index) => {
                        let str = el
                        let dot = null
                        
                        if (/[@_,.*^&?%$#!?()=+-]/.test(str)) {
                            dot = str.split('\n')[0][str.split('\n')[0].length - 1]
                            str = str.split('\n')[0].substring(0, str.split('\n')[0].length - 1)
                        }
                        if (answers.indexOf(str) === -1 && tasks[index].search(/\n/) === -1) {
                            return <p key={`insertLettrQuestion_${index}`}>{str}{dot}</p>
                        } else if (tasks[index].search(/\n/) !== -1) {
                            const nArray = []
                            if (tasks[index].substring(0, tasks[index].search(/[\n ]+/)).indexOf(str) === -1) {
                                if (answers.indexOf(tasks[index].substring(0, tasks[index].search(/[\n ]+/))) === -1) {
                                    nArray.push(<p key={`insertLetter_${index}`}><span>{tasks[index].substring(0, tasks[index].search(/[\n ]+/))}</span></p>)
                                } else {
                                    nArray.push(
                                        <>
                                            <div className='pdfLine' />
                                            {dot}
                                        </>
                                    )
                                }
                            } else {
                                if (answers.indexOf(str) === -1) {
                                    nArray.push(<p key={`insertLetter_${index}`}>{str}{dot}</p>)
                                } else {
                                    nArray.push(
                                        <>
                                            <div className='pdfLine' />
                                            {dot}
                                        </>
                                    )
                                }
                            }
                            if (tasks[index].length > tasks[index].search(/[\n ]+/) + 1) {
                                nArray.push(<div
                                    style={{
                                        content: 'ww',
                                        width: '100%',
                                        // display: 'block'
                                    }}
                                />)
                                nArray.push(<p key={`insertLetter_n_${index}`}><span>{tasks[index].substring(tasks[index].search(/[\n ]+/) + 1, tasks[index].length)}</span></p>)
                            }
                            return nArray
                        } else {
                            return (
                                <>
                                    <div className='pdfLine' />
                                    {dot}
                                </>
                            )
                        }
                    })}
                </div>
                <div className="insertLetterPdf_answers">
                    <p style={{ fontWeight: 'bold' }}>Используй слова:</p>
                    {game.answers.sort(() => Math.random() - 0.5).map((el, index) => (
                        <p key={`insertLettrAnswer_${index}`}>{el}{game.answers.length - 1 > index && ','}</p>
                    ))}
                </div>
            </div>
        </>
    )
}