import background from 'assets/backgrounds/header.svg';
import purpuleArrow from 'assets/purpuleArrow.svg';
import TaskCard from "components/components/taskCard/TaskCard";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchAllExercises, fetchAllExercisesConcat } from 'store/actions/allExercises';

import { getQueryStringFromObject } from 'functions/filter';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FETCH_ALLEXERCISES_CLEAR } from 'store/actionTypes';
import '../media.css';
import './media.css';
import './style.css';

function Tasks({
    all_exercises,
    token,
    fetchAllExercises,
    fetchAllExercisesConcat,
    setVisible,
    PageAfterLogin,
    FETCH_ALLEXERCISES_CLEAR,
}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { game_type } = useParams()
    const temp = useRef()
    const [searchParams, setSearchParams] = useSearchParams();
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(100)

    const startFunction = () => {
        let obj = {}
        if (game_type === 'all') {
            obj.game_type = null
        } else {
            obj.game_type = game_type
        }
        if (Object.fromEntries([...searchParams]).hashtag_ids) {
            obj.hashtag_ids = [parseInt(JSON.parse(Object.fromEntries([...searchParams]).hashtag_ids).id)]
        }
        fetchAllExercises(skip, limit, getQueryStringFromObject(JSON.stringify(obj)))
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
        window.scrollTo(0, 0)
        return () => {
            FETCH_ALLEXERCISES_CLEAR()
        }
    }, [])

    useEffect(() => {
        let obj = {}
        if (game_type === 'all') {
            obj.game_type = null
        } else {
            obj.game_type = game_type
        }
        if (Object.fromEntries([...searchParams]).hashtag_ids) {
            obj.hashtag_ids = [parseInt(JSON.parse(Object.fromEntries([...searchParams]).hashtag_ids).id)]
        }
        fetchAllExercises(skip, limit, getQueryStringFromObject(JSON.stringify(obj)))
    }, [game_type, searchParams])

    const fetchMoreData = () => {
        let obj = {}
        if (game_type === 'all') {
            obj.game_type = null
        } else {
            obj.game_type = game_type
        }
        if (Object.fromEntries([...searchParams]).hashtag_ids) {
            obj.hashtag_ids = [parseInt(JSON.parse(Object.fromEntries([...searchParams]).hashtag_ids).id)]
        }
        if (all_exercises.length % 100 === 0 && all_exercises.length > 0) {
            fetchAllExercisesConcat(skip + 100, limit, getQueryStringFromObject(JSON.stringify(obj)))
            setSkip(skip + 100)
        }
    };

    return (
        <>
            <img src={background} className="mainMiniImg" />
            <div className="patternsBlock tasksBlock">
                <p className="patternsBlock_title">{t(`tasks.title`)}</p>
                {Object.fromEntries([...searchParams]).hashtag_ids && <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>По хештегу: <div key={`search_hashtags_${JSON.parse(Object.fromEntries([...searchParams]).hashtag_ids).id}`} className={`search_list__hashtagsItem search_list__hashtagsItem__active`}>#{JSON.parse(Object.fromEntries([...searchParams]).hashtag_ids).name}</div></div>}

                <InfiniteScroll
                    dataLength={all_exercises.length}
                    next={fetchMoreData}
                    hasMore={true}
                >
                    <div className="patternsBlock_list tasks_list">
                        {all_exercises.map((item, key) => {
                            return <div key={`task_${key}`} className="tasks_list__item"><TaskCard item={item} onClick={() => navigate(`../task/${item.id}/watch`)} actionButton="Сохранить к себе" /></div>
                        })}
                    </div>
                </InfiniteScroll>
            </div>
            {token === null && <p className="patternsBlock_button" onClick={() => { setVisible('login'); PageAfterLogin('../create_task') }}>{t(`tasks.button`)} <img src={purpuleArrow} alt="purpuleArrow" /></p>}
        </>
    );
}

function mapStateToProps(state) {
    return {
        all_exercises: state.all_exercises.all_exercises,
        token: state.login.token,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchAllExercises: (skip, limit, filter) => dispatch(fetchAllExercises(skip, limit, filter)),
        fetchAllExercisesConcat: (skip, limit, filter) => dispatch(fetchAllExercisesConcat(skip, limit, filter)),
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        PageAfterLogin: (text) => dispatch({ type: 'PageAfterLogin', payload: text }),
        FETCH_ALLEXERCISES_CLEAR: () => dispatch({ type: FETCH_ALLEXERCISES_CLEAR })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Tasks);
