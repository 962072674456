const initialState = {
    step: 0,
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case 'stepUp':
            return {
                step: state.step + 1
            }
        case 'stepDown':
            return {
                step: state.step - 1
            }
        case 'firstStep':
            return {
                step: 0
            }
        default: return state
    }
}