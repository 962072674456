import flower1 from 'assets/backgrounds/games/animation/flower1.png';
import flower2 from 'assets/backgrounds/games/animation/flower2.png';
import flower3 from 'assets/backgrounds/games/animation/flower3.png';

import './style.css';

const leaves = [flower1, flower2, flower3]
const Leavesflake = (props) => {
    return (
        <p className='Leavesflake' id={`item${props.id}`} style={props.style}>
            <img src={leaves[Math.round(Math.random() * (2))]} alt="leave1" style={props.imgStyle} />
        </p>

    )
}

function Spring() {
    const leave = () => {
        let animationDelay = '0s';
        let animation = 'upDown2 2.5s alternate infinite ease-in-out'
        let arr = Array.from('Snowflakes are awesome!!! ')
        let count = 0
        return arr.map((el, i) => {
            animationDelay = `${(Math.random() * 10).toFixed(2)}s`;
            if(count % 2 === 0) {
                animation= 'upDown 1.5s alternate infinite ease-in-out'
                count ++
            }            
            let style = {
                animationDelay
            }
            let imgStyle = {
                animation
            }
            return (<Leavesflake key={i} id={i} style={style} imgStyle={imgStyle} />)
        })
    }

    return (
        <div className='leavesAnimation spring_bg'>
            {leave()}
            <div className="butterfly">
                <div className="wing">
                    <div className="bit"></div>
                    <div className="bit"></div>
                </div>
                <div className="wing">
                    <div className="bit"></div>
                    <div className="bit"></div>
                </div>
            </div>
            <div className="butterfly2">
                <div className="wing">
                    <div className="bit"></div>
                    <div className="bit"></div>
                </div>
                <div className="wing">
                    <div className="bit"></div>
                    <div className="bit"></div>
                </div>
            </div>
        </div>
    )
}

export default Spring;