import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { fetchFAQ } from 'store/actions/faq';

import background from 'assets/backgrounds/main.png';

import './style.css';

const { Panel } = Collapse;

function Faq({ faq, fetchFAQ }) {
  const temp = useRef();

  const startFunction = () => {
    fetchFAQ();
  };

  temp.current = startFunction;

  useEffect(() => {
    temp.current();
  }, []);

  return (
    <>
      <img src={background} className='mainImg' />
      <div className='FAQBlock'>
        <p className='FAQBlock_title'>FAQ</p>
        <Collapse
          bordered={false}
          expandIconPosition='right'
          expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 45 : 0} />}
          className='FAQBlock_collapse'
        >
          {faq.map((item, key) => {
            return (
              <Panel header={item.title} key={key}>
                {item.content}
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    faq: state.faq.faq,
  };
}

function mapDispatvhToProps(dispatch) {
  return {
    fetchFAQ: () => dispatch(fetchFAQ()),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(Faq);
