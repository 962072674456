import { message } from 'antd';
import { api_likes } from 'API/likes';

export function likeAction(mode, object_type, object_id) {
    return async () => {
        try {
            const data = {
                object_type,
                object_id
            }
            if (mode === 'set') {
                await api_likes.SetLike(data)
                .then(() => {
                    if(object_type === "User"){
                        message.success('Пользователь добавлен в избранное')
                    } else {
                        message.success('Задание добавлено в избранное')
                    }      
                })
            } else {
                await api_likes.RemoteLike(data).then(() => {
                    if(object_type === "User"){
                        message.success('Пользователь удален из избранного')
                    } else {
                        message.success('Задание удалено из избранного')
                    }      
                })
            }
        } catch (e) {
            if(e.response.data.detail === 'User is already liked'){
                if(object_type === "User"){
                    message.error('Пользователь уже находится в избранных')
                } else {
                    message.error('Задание уже находится в избранных')
                } 
            }
        }
    }
}
