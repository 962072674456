import { FETCH_ONEEXERCISES_START, FETCH_ONEEXERCISES_SUCCESS, FETCH_ONEEXERCISES_ERROR, FETCH_ONEEXERCISES_CLEAR } from '../actionTypes'

const initialState = {
    one_exercises: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ONEEXERCISES_START:
            return {
                ...state,
                loading: true,
                one_exercises: null
            }
        case FETCH_ONEEXERCISES_SUCCESS:
            return {
                ...state, loading: false,
                one_exercises: action.one_exercises,
            }
        case FETCH_ONEEXERCISES_ERROR:
            return {
                ...state, loading: false
            }
        case FETCH_ONEEXERCISES_CLEAR:
            return {
                one_exercises: null,
                loading: false
            }
        default: return state
    }
}