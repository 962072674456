import { api_exercises } from 'API/exercises';
import { FETCH_ALLEXERCISES_ERROR, FETCH_ALLEXERCISES_START, FETCH_ALLEXERCISES_SUCCESS, FETCH_ALLEXERCISES_SUCCESS_CONCAT } from '../actionTypes';

export function fetchAllExercises(skip, limit, filter) {
    return async dispatch => {
        dispatch(fetchExercisesStart())
        try {
            const exercises = await api_exercises.ReadeAllExercises(skip, limit, filter)
            dispatch(fetchExercisesSuccess(exercises.data, FETCH_ALLEXERCISES_SUCCESS))
        } catch (e) {
            dispatch(fetchExercisesError(e))
        }
    }
}

export function fetchAllExercisesConcat(skip, limit, filter) {
    return async dispatch => {
        dispatch(fetchExercisesStart())
        try {
            const exercises = await api_exercises.ReadeAllExercises(skip, limit, filter)
            dispatch(fetchExercisesSuccess(exercises.data, FETCH_ALLEXERCISES_SUCCESS_CONCAT))
        } catch (e) {
            dispatch(fetchExercisesError(e))
        }
    }
}

export function fetchExercisesStart() {
    return {
        type: FETCH_ALLEXERCISES_START
    }
}

export function fetchExercisesSuccess(exercises, type) {
    return {
        type,
        exercises,
    }
}

export function fetchExercisesError(e) {
    return {
        type: FETCH_ALLEXERCISES_ERROR,
        error: e,
    }
}
