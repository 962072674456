import { useEffect } from 'react';

function Privacy({ doc }) {
    useEffect(() => {
        window.location.replace(doc)
    }, [])

    return (
        <>
        </>
    )
}

export default Privacy;
