import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';

import { fetchLogin } from 'store/actions/login';
import { fetchUserMe, fetchUserMeSubscription } from 'store/actions/userMe';

import '../style.css';

function LoginForm({
  link,
  changeType,
  fetchLogin,
  setVisible,
  fetchUserMe,
  fetchUserMeSubscription
}) {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const onFinish = (values) => {
    fetchLogin(values)
      .then(res => {
        if (res !== "Incorrect username or password" && res !== 'Error: Network Error') {
          setVisible()
          fetchUserMe()
          fetchUserMeSubscription()
          if(link !== '../create_task') navigate(link)
        }
      })
  };


  return (
    <>
      <p className="modalTitle">{t(`modal.login.title`)}</p>
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        className="modalForm"
      >
        <Form.Item
          label={t(`modal.login.email`)}
          name="username"
          rules={[
            {
              required: true,
              message: t(`modal.login.email_error`),
            },
          ]}
        >
          <Input className="formInput" />
        </Form.Item>
        <Form.Item
          label={t(`modal.login.password`)}
          name="password"
          rules={[
            {
              required: true,
              message: t(`modal.login.password_error`),
            },
          ]}
        >
          <Input.Password className="formInput" />
        </Form.Item>
        <Form.Item>
          <Button className="loginButton" htmlType="submit">{t(`modal.login.button`)}</Button>
        </Form.Item>
      </Form>
      <div className="modalDesc">
        <p className="modalDesc_forgot_password" onClick={() => changeType('forgotRassword')}>{t(`modal.login.forgot_password`)}</p>
        <p className="modalDesc_warning">{t(`modal.login.warning`)}<span> {t(`modal.login.warning_link`)}</span></p>
        <p className="modalDesc_registration" onClick={() => changeType('registration')}>{t(`modal.login.registration`)}</p>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
      link: state.login.link,
  }
}

function mapDispatvhToProps(dispatch) {
  return {
    changeType: (text) => dispatch({ type: 'changeType', payload: text }),
    fetchLogin: (data) => dispatch(fetchLogin(data)),
    setVisible: () => dispatch({ type: 'closeModal' }),
    fetchUserMe: () => dispatch(fetchUserMe()),
    fetchUserMeSubscription: () => dispatch(fetchUserMeSubscription())
  }
}



export default connect(mapStateToProps, mapDispatvhToProps)(LoginForm);