import { api_user } from 'API/users'
import { FETCH_LOGIN_START, FETCH_LOGIN_SUCCESS, FETCH_LOGIN_ERROR } from '../actionTypes'

export function fetchLogin(data) {
    return async dispatch => {
        dispatch(fetchLoginStart())
        try {
            const token = await api_user.Login(data['username'], data['password'])
            if(token !== 'Incorrect username or password' && token !== 'Error: Network Error'){
                dispatch(fetchLoginSuccess(token.access_token))
            }
        } catch (e) {
            dispatch(fetchLoginError(e))
        }
    }
}

export function fetchLoginStart() {
    return {
        type: FETCH_LOGIN_START
    }
}

export function fetchLoginSuccess(token) {
    return {
        type: FETCH_LOGIN_SUCCESS,
        token,
    }
}

export function fetchLoginError(e) {
    return {
        type: FETCH_LOGIN_ERROR,
        error: e,
    }
}
