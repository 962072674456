import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

const backgrounds = ['#E88F9A', '#EEB157', '#85E5A0']

function Cubes(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks) /*tasks */
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [activeTask, setActiveTask] = useState(0)
    const [cubeFace, setCubeFace] = useState(1)
    const [countClick, setCountClick] = useState(0)
    // console.log(props.one_exercises.game.tasks);
    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const FACE_NAMES = ",top,left,front,back,right,bottom".split(",");
    const cubeClass = ['one', 'two', 'three']
    const dice = name => {
        const el = document.querySelector(".dice-" + name);
        var value, state = "idle";
        const animate = name => (el.classList.remove(state, 'show-top', 'show-left', 'show-front', 'show-back', 'show-right', 'show-bottom'), el.classList.add(name), name);
        // setTimeout(() => setShowContent(true), 3000)
        return {
            get val() { return "" + value },
            roll() { value = Math.floor(6 * Math.random()) + 1; },
            show() { state = animate("show-" + FACE_NAMES[cubeFace]); },
            spin() { state = animate("spin") },

        };
    }
    const game = {
        dice: tasks.map((el, key) => (dice(cubeClass[key]))),
        roll() {
            game.callEach("roll");
            game.callEach("spin");
            setTimeout(game.callEach, 820, "show");
        },
        callEach(call) {
            game.dice.forEach(dice => dice[call]())
        },
    };

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                <div className="gameBlock_header">
                    <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                </div>
                {tasks.length > 0 &&
                    <>
                        <div className="cubesBlock_content">
                            <div className={`scene cubes_quantity_${tasks.length}`}>
                                {tasks.map((el, key) => (
                                    <div className="dice-display">
                                        <div className={`dice dice-${cubeClass[key]} idle`}>
                                            <div className="face face-front">
                                                <div className="front-pip-1" />
                                                <div className="front-pip-2" />
                                                <div className="front-pip-3" />
                                            </div>
                                            <div className="face face-back">
                                                <div className="back-pip-1" />
                                                <div className="back-pip-2" />
                                                <div className="back-pip-3" />
                                                <div className="back-pip-4" />
                                            </div>
                                            <div className="face face-right">
                                                <div className="right-pip-1" />
                                                <div className="right-pip-2" />
                                                <div className="right-pip-3" />
                                                <div className="right-pip-4" />
                                                <div className="right-pip-5" />
                                            </div>
                                            <div className="face face-left">
                                                <div className="left-pip-1" />
                                                <div className="left-pip-2" />
                                            </div>
                                            <div className="face face-top">
                                                <div className="top-pip-1" />
                                                <div className="top-blank-1"></div>
                                                <div className="top-blank-2"></div>
                                            </div>
                                            <div className="face face-bottom">
                                                <div className="bottom-pip-1" />
                                                <div className="bottom-pip-2" />
                                                <div className="bottom-pip-3" />
                                                <div className="bottom-pip-4" />
                                                <div className="bottom-pip-5" />
                                                <div className="bottom-pip-6" />
                                            </div>
                                        </div>
                                        {props.play &&
                                            <div className="cube-content" style={{ backgroundColor: backgrounds[key] }}>
                                                {el.answers[activeTask].title && <p className="cube_content_title">{el.answers[activeTask].title}</p>}
                                                <div className="cube_content_text">
                                                    {el.answers[activeTask].photo
                                                        ?
                                                        <div>
                                                            <img src={el.answers[activeTask].photo} alt="img" />
                                                            <p className="yesOrNot_questionBlock__textUnderImg">{el.answers[activeTask].text}</p>
                                                        </div>
                                                        : <p>{el.answers[activeTask].text}</p>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__steps">
                                <button
                                    className="cubesButton"
                                    onClick={() => {
                                        game.roll()
                                        if (activeTask < tasks[0].answers.length - 1) {
                                            if (countClick !== 0) {
                                                setActiveTask(activeTask + 1)
                                            }
                                        } else {
                                            setActiveTask(0)
                                        }
                                        if (cubeFace < 6 && cubeFace <= tasks[0].answers.length - 1) {
                                            setCubeFace(cubeFace + 1)
                                        } else {
                                            setCubeFace(1)
                                        }
                                        setCountClick(countClick + 1)
                                    }}
                                >
                                    Бросить кубики
                                </button>
                            </div>
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Cubes);
