import like from 'assets/like.svg'
import likeRed from 'assets/likeRed.svg'
import greyCursor from 'assets/greyCursor.svg'
import eye from 'assets/eye-green.svg';

export const players_data = (players, remote, link, likes_users, setVisible) => {
    let data = []
    players.forEach((item, index) => {
        const status = likes_users.find(user => user.id === item.owner_id)
        data.push(
            {
                key: index,
                place: index + 1,
                name: item.name,
                score: item.score,
                time: item.time,
                // group: <div className='tableData_group'>{item.group_name}</div>,
                action: <div>
                    {/* <img src={eye} alt="eye" style={{cursor: 'pointer', marginRight: 5}} onClick={() => setVisible(item.id, item.time)}/> */}
                    <button
                        style={{
                            color: '#7C3AED',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '8px 12px',
                            background: '#EDE9FE',
                            borderRadius: '8px',
                            marginRight: 5
                        }}
                        onClick={() => setVisible(item.id, item.time)}
                    >
                        Подробнее
                    </button>
                    {item.owner_id !== null && (status !== undefined ? <img src={likeRed} alt="like" onClick={() => remote(item.owner_id, 'remote')} /> : <img src={like} alt="like" onClick={() => remote(item.owner_id, 'set')} />)}
                </div>
            },
        )
    })
    return data
}

export const favourites_data = (user, remote, link) => {
    let data = []
    user.forEach((item, index) => {
        data.push(
            {
                key: index,
                name: item.first_name + ' ' + item.last_name,
                action: <div className='tableData_actions'><img src={likeRed} alt="like" onClick={() => remote(item.id)} /><img src={greyCursor} alt="greyCursor" onClick={() => link(item.id)} /></div>
            },
        )
    })
    return data
}

export const progress_data = (achievements) => {
    let data = []
    achievements.forEach((item, index) => {
        data.push(
            {
                key: index,
                place: item.place,
                name: item.name,
                score: item.score,
                time: item.time,
                project: item.game_name
            },
        )
    })
    return data
}

export const quize_result = (greateTask, allTasks, saveTime) => {
    let data = []
    data.push(
        {
            key: '1',
            score: `${greateTask} из ${allTasks}`,
            time: saveTime,
        },
    )
    return data
}