const initialState = {
    search: '',
    search_type: 'users'
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case 'onSearch':
            return {
                ...state,
                search: action.payload
            }
        case 'onSelectSearchType':
            return {
                ...state,
                search_type: action.payload
            }
        default: return state
    }
}