import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, message } from 'antd';
import { api_user } from 'API/users';

import '../style.css';

function ForgotPassword({
  changeType,
  setVisible
}) {
  const { t } = useTranslation();

  const onFinish = async (values) => {
    await api_user.ForgotPassword(values.email)
    .then(() => {
      message.success('Проверь свой email!')
      setVisible()
    })
    .catch(error => {
      if(error.response.data.detail === 'The user with this username does not exist in the system.') {
        message.error('Неправильный email!')
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  return (
    <>
      <p className="modalTitle">{t(`modal.forgot_password.title`)}</p>
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="modalForm"
      >
        <Form.Item
          label={t(`modal.forgot_password.email`)}
          name="email"
          rules={[
            {
              required: true,
              message: t(`modal.forgot_password.email_error`),
            },
          ]}
        >
          <Input className="formInput" />
        </Form.Item>
        <Form.Item>
          <Button className="loginButton" htmlType="submit">{t(`modal.forgot_password.button`)}</Button>
        </Form.Item>
      </Form>
      <div className="modalDesc">
        <p className="modalDesc_registration" onClick={() => changeType('login')}>{t(`modal.forgot_password.registration`)}</p>
      </div>
    </>
  );
};

function mapDispatvhToProps(dispatch) {
  return {
    changeType: (text) => dispatch({ type: 'changeType', payload: text }),
    setVisible: () => dispatch({ type: 'closeModal' }),
  }
}



export default connect(null, mapDispatvhToProps)(ForgotPassword);