import { message } from 'antd';
import axios from 'axios';
import { api, ApiUrl, instance } from './api';

export const api_user = {

    async Login(username, password) {
        let form = new FormData()
        form.append('username', username);
        form.append('password', password);
        return axios.post(`${ApiUrl}/api/token`, form, {
            headers: { "Content-Type": "mutlipart/form-data" }
        }).then(res => {
            localStorage.setItem('superkid-token', res.data.access_token)
            api.initToken()
            return res.data
        }).catch(function (error) {
            if (error.response) {
                message.error('Неправильный логин или пароль');
                return error.response.data.detail
            } else {
                return 'Error: Network Error'
            }
        });
    },

    async ForgotPassword(email) {
        return await instance.post(`${ApiUrl}/api/password-recovery/${email}`)
    },

    async ResetPassword(data) {
        return await axios.post(`${ApiUrl}/api/reset-password`, data)
    },

    async CreateUser(data, errorMessage) {
        return await axios.post(`${ApiUrl}/api/v1/users`, data, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(response => { return response })
            .catch(error => {
                if (error.response) {
                    message.error(errorMessage);
                    return error.response.data.detail
                } else {
                    return 'Error: Network Error'
                }
            });
    },

    async ReadeUserMe() {
        return await instance.get(`${ApiUrl}/api/v1/users/me`)
    },

    async GetMeSubscription() {
        return await instance.get(`${ApiUrl}/api/v1/users/me/subscription`)
    },

    async GetExercisesCount() {
        return await instance.get(`${ApiUrl}/api/v1/users/me/exercises_count`)
    },

    async UpdateUserImg(data) {
        return await instance.patch(`${ApiUrl}/api/v1/users/me/change_photo`, data)
    },

    async UpdateUser(data) {
        return await instance.put(`${ApiUrl}/api/v1/users/me`, data)
            .then(response => { return response })
            .catch(error => {
                if (error.response) {
                    return error.response.data.detail
                } else {
                    return 'Error: Network Error'
                }
            });
    },

    async ReadeUsers(params) {
        return await instance.get(`${ApiUrl}/api/v1/users/open?params=${params}`)
    },

    async ReadeOneUser(id) {
        return await instance.get(`${ApiUrl}/api/v1/users/open/${id}`)
    },

}