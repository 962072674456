import plusCircle from 'assets/plus-circle.svg'

import './style.css';

function YesOrNot({ game }) {
    return (
        <div className='exercisesCardBg_content'>
            <div className='cardBgYesOrNot'>
                <div className="cardBgYesOrNot_answerBlock">
                    <p className="cardBgYesOrNot_answerBlock__title">Да</p>
                    <div className="cardBgYesOrNot_answerBlock__plusBlock">
                        <img src={plusCircle} alt="plusCircle" />
                    </div>
                </div>
                <div
                    className="cardBgYesOrNot_questionBlock"
                >
                    {game[0].photo
                        ?
                        <div>
                            <img src={game[0].photo} alt="img" />
                            <p className="yesOrNot_questionBlock__textUnderImg">{game[0].text}</p>
                        </div>
                        : <p>{game[0].text}</p>
                    }
                </div>
                <div className="cardBgYesOrNot_answerBlock">
                    <p className="cardBgYesOrNot_answerBlock__title">Нет</p>
                    <div className="cardBgYesOrNot_answerBlock__plusBlock">
                        <img src={plusCircle} alt="plusCircle" />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default YesOrNot;
