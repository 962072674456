import { api_groups } from 'API/groups'
import { FETCH_GROUPS_START, FETCH_GROUPS_SUCCESS, FETCH_GROUPS_ERROR } from '../actionTypes'

export function fetchGroups(id) {
    return async dispatch => {
        dispatch(fetchStart())
        try {
            const groups = await api_groups.ReadeGroupsByExercise(id)
            dispatch(fetchSuccess(groups.data))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchStart() {
    return {
        type: FETCH_GROUPS_START
    }
}

export function fetchSuccess(groups) {
    return {
        type: FETCH_GROUPS_SUCCESS,
        groups,
    }
}

export function fetchError(e) {
    return {
        type: FETCH_GROUPS_ERROR,
        error: e,
    }
}
