/* eslint-disable react-hooks/exhaustive-deps */
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';

import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';


import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import { Textfit } from 'react-textfit';
import './media.css';
import './style.css';

const colorArray = ['#EEB157', '#8E86E7', '#3B8F44', '#E88F9A', '#85E5A0', '#81CDF2', '#436BF8', '#EE57E8']

function CheckYourself(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState([[]]) /*список окон для каждой страницы */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState(0) /*Счетчик выбранных окон */

    const [activePage, setActivePage] = useState(0) /* отображаемая страница */
    const [modal, setModal] = useState(false) /*Показывать модальное окно с вопросом */
    const [activeCard, setActiveCard] = useState(0) /*Номер активной карточки */
    const [activeCardSide, setActiveCardSide] = useState('front') /*Какую сторону показывать */

    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [renderText, setRenderText] = useState(false)

    const maxWindows = 8 //количество окон на странице

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        let subarray = [];
        for (let i = 0; i < Math.ceil(props.one_exercises.game.tasks.length / maxWindows); i++) {
            subarray[i] = props.one_exercises.game.tasks.slice((i * maxWindows), (i * maxWindows) + maxWindows);
        }
        setTasks(subarray);
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    useEffect(() => {
        if (tasks.length === 0) {
            audio.current.pause()
            onClickFullScreen(false)
        }
    }, [activeTask])

    const openWindowClick = (key) => {
        // const windowsStatusCopy = copy(windowsStatus)
        // if (!windowsStatusCopy[activePage][key]) {
        //     setAnswers(answers + 1)
        // }
        // windowsStatusCopy[activePage][key] = true
        // setActiveTask(key)
        // setWindowsStatus(windowsStatusCopy)
        setAnswers(answers + 1)
        setModal(true)
        setActiveCard(key)
        setTimeout(() => { setRenderText(true) }, 100)
    }

    const nextTask = () => {
        if (activePage + 1 < tasks.length) {
            setActivePage(activePage + 1)
        }
    }

    const backTask = () => {
        if (activePage !== 0) {
            setActivePage(activePage - 1)
        }
    }

    const nextCard = () => {
        if (activeCard + 1 < tasks[activePage].length) {
            setActiveCard(activeCard + 1)
            setActiveCardSide('front')
            setRenderText(false)
            setTimeout(() => { setRenderText(true) }, 20)
        } else if (activeCard + 1 < props.one_exercises.game.tasks.length && activePage + 1 < tasks.length) {
            setActiveCard(0)
            setActiveCardSide('front')
            setActivePage(activePage + 1)
            setRenderText(false)
            setTimeout(() => { setRenderText(true) }, 20)
        }
    }

    const backCard = () => {
        if (activeCard !== 0) {
            setActiveCard(activeCard - 1)
            setActiveCardSide('front')
            setRenderText(false)
            setTimeout(() => { setRenderText(true) }, 10)
        } else if (activePage !== 0) {
            setActivePage(activePage - 1)
            setActiveCard(maxWindows - 1)
            setActiveCardSide('front')
            setRenderText(false)
            setTimeout(() => { setRenderText(true) }, 10)
        }
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    const onCloseCard = () => {
        setModal(false)
        setActiveCardSide('front')
        setTimeout(() => { setRenderText(false) }, 700)
    }

    return (
        tasks[activePage].length > 0 &&
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                <div className="gameBlock_header" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
                    <p className="gameBlock_header__title" style={{ margin: 0 }}>{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                    <p className="gameBlock_header__dascription">Коснитесь одного окна, чтобы открыть</p>
                </div>
                <div className="gameBlock_content">
                    <div className="checkYourself">
                        {tasks[activePage].map((_, key) => (
                            <div
                                className="checkYourself_card"
                                key={`checkYourself_${key + 1 + (activePage) * maxWindows}`}
                                onClick={() => openWindowClick(key)}
                            >
                                <p style={{ color: colorArray[key] }}>{key + 1 + (activePage) * maxWindows}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="gameBlock_footer">
                    <div className="gameBlock_footer__steps">
                        <img
                            src={activePage !== 0 ? circleLeftActive : circleLeft}
                            alt="circleLeft"
                            onClick={backTask}
                        />
                        <p>{activePage + 1} из {tasks.length}</p>
                        <img
                            src={activePage + 1 < tasks.length ? circleRightActive : circleRight}
                            alt="circleRight"
                            onClick={nextTask}
                        />
                    </div>
                    <div className="gameBlock_footer__options">
                        {soundPlay
                            ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                            : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                        }
                        {(isFullscreenEnabled || isFullscreenEnabledClass)
                            ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                            : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                        }
                    </div>
                </div>
                <div className={`checkYourselfAnswer ${answers > 0 ? (modal ? 'active' : 'notactive') : ''}`}>
                    <div className='checkYourselfAnswer_bg' onClick={onCloseCard} />
                    <div className='checkYourselfAnswer_body'>
                        <div className="gameBlock_footer__steps checkYourselfAnswer_steps">
                            <img
                                src={activeCard + (activePage) * maxWindows !== 0 ? circleLeftActive : circleLeft}
                                alt="circleLeft"
                                onClick={backCard}
                            />
                            <p>{activeCard + 1 + (activePage) * maxWindows} из {props.one_exercises.game.tasks.length}</p>
                            <img
                                src={activeCard + 1 + (activePage) * maxWindows < props.one_exercises.game.tasks.length ? circleRightActive : circleRight}
                                alt="circleRight"
                                onClick={nextCard}
                            />
                        </div>
                        <div className='checkYourselfAnswer__card'>
                            <div className={`checkYourselfAnswer__card_front ${activeCardSide === 'front' ? 'active' : 'noActive'}`}>
                                {tasks[activePage][activeCard]?.photo &&
                                    <div className='checkYourselfAnswer__card_img'>
                                        <img src={tasks[activePage][activeCard].photo} alt="img" />
                                    </div>
                                }
                                {tasks[activePage][activeCard]?.text && renderText &&
                                    <Textfit mode="multi" className='checkYourselfAnswer__card_text' min={1}>
                                        {tasks[activePage][activeCard].text}
                                    </Textfit>
                                }
                            </div>
                            <div className={`checkYourselfAnswer__card_back ${activeCardSide === 'back' ? 'active' : 'noActive'}`}>
                                {tasks[activePage][activeCard]?.answer_photo &&
                                    <div className='checkYourselfAnswer__card_img'>
                                        <img src={tasks[activePage][activeCard].answer_photo} alt="img" />
                                    </div>
                                }
                                {tasks[activePage][activeCard]?.answer && renderText &&
                                    <Textfit mode="multi" className='checkYourselfAnswer__card_text' throttle={200}>
                                        {tasks[activePage][activeCard].answer}
                                    </Textfit>
                                }
                            </div>
                        </div>
                        <div className='checkYourselfAnswer_buttons'>
                            <button onClick={onCloseCard}>Закрыть</button>
                            <button onClick={() => setActiveCardSide(activeCardSide === 'front' ? 'back' : 'front')}>Перевернуть</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
    }
}

export default connect(mapStateToProps, null)(CheckYourself);
