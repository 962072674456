import { FETCH_HASHTAGS_ERROR, FETCH_HASHTAGS_START, FETCH_HASHTAGS_SUCCESS, SAVE_ACTIVE_HASHTAGS_SUCCESS } from '../actionTypes'

const initialState = {
    hashtags: [],
    active_hashtags: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_HASHTAGS_START:
            return {
                ...state, loading: true
            }
        case FETCH_HASHTAGS_SUCCESS:
            return {
                ...state, loading: false,
                hashtags: action.hashtags,
            }
        case SAVE_ACTIVE_HASHTAGS_SUCCESS:
            return {
                ...state, loading: false,
                active_hashtags: action.hashtags,
            }
        case FETCH_HASHTAGS_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}