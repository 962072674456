import { PrinterOutlined } from '@ant-design/icons';
import { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { PDF_START, PDF_SUCCESS } from 'store/actionTypes';

import { message } from 'antd';
import { subscribePdfPrint } from 'lists/pdfTaskInfo';
import ComponentToPrint from './ComponentToPrint';

const pageStyle = `
    @page {
        // size: A4; /* DIN A4 standard, Europe */
        margin: 10mm 0 10mm 0;
    }
    @media print {
        .Pdf_task {
            break-inside: avoid;
          }
      }
`

const Pdf = ({ one_exercises, token, userMeSubscription, PDF_START, PDF_SUCCESS }) => {
    const componentRef = useRef();
    const onBeforeGetContentResolve = useRef(null);
    const [loading, setLoading] = useState(false);
    const handleOnBeforeGetContent = useCallback(() => {
        if (token === null) {
            message.warning('Вам необходимо авторизоваться!')
        } else {
            if (subscribePdfPrint.find(el => el.subscription === (userMeSubscription.subscription ? userMeSubscription.subscription.name : userMeSubscription.name)).games.indexOf(one_exercises.game_type) === -1) {
                message.warning('Ваша подписка не позволяет сохранить/распечатать задание!')
            } else {
                setLoading(true);
                PDF_START()
                return new Promise((resolve) => {
                    onBeforeGetContentResolve.current = resolve;
                    setTimeout(() => {
                        setLoading(false);
                        resolve();
                        PDF_SUCCESS()
                    }, 2000);
                });
            }
        }
    }, [setLoading]);

    return (
        <div>
            <ReactToPrint
                onBeforeGetContent={handleOnBeforeGetContent}
                documentTitle={one_exercises.name}
                trigger={() => <PrinterOutlined style={{ fontSize: '25px', marginRight: '15px' }} />}
                content={() => componentRef.current}
                pageStyle={pageStyle}
            />
            {loading &&
                <div style={{ display: 'none' }}>
                    <ComponentToPrint
                        ref={componentRef}
                        game={one_exercises.game.tasks}
                        background={one_exercises.background}
                        game_type={one_exercises.game_type}
                        one_exercises={one_exercises}
                    />
                </div>
            }
        </div>
    );
};

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
        token: state.login.token,
        userMeSubscription: state.userMe.userMeSubscription,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        PDF_START: () => dispatch({ type: PDF_START }),
        PDF_SUCCESS: () => dispatch({ type: PDF_SUCCESS }),
    }
}


export default connect(mapStateToProps, mapDispatvhToProps)(Pdf)