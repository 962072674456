import { FETCH_LEADERBOARD_START, FETCH_LEADERBOARD_SUCCESS, FETCH_LEADERBOARD_ERROR } from '../actionTypes'

const initialState = {
    leader_board: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_LEADERBOARD_START:
            return {
                ...state, loading: true
            }
        case FETCH_LEADERBOARD_SUCCESS:
            return {
                ...state, loading: false,
                leader_board: action.leader_board,
            }
        case FETCH_LEADERBOARD_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}