import { api_exercises } from 'API/exercises'
import { FETCH_PARTICIPANTEXERCISES_START, FETCH_PARTICIPANTEXERCISES_SUCCESS, FETCH_PARTICIPANTEXERCISES_ERROR } from '../actionTypes'

export function fetchParticipantExercises() {
    return async dispatch => {
        dispatch(fetchExercisesStart())
        try {
            const exercises = await api_exercises.ReadeParticipantExercises()
            dispatch(fetchExercisesSuccess(exercises.data))
        } catch (e) {
            dispatch(fetchExercisesError(e))
        }
    }
}

export function fetchExercisesStart() {
    return {
        type: FETCH_PARTICIPANTEXERCISES_START
    }
}

export function fetchExercisesSuccess(exercises) {
    return {
        type: FETCH_PARTICIPANTEXERCISES_SUCCESS,
        exercises,
    }
}

export function fetchExercisesError(e) {
    return {
        type: FETCH_PARTICIPANTEXERCISES_ERROR,
        error: e,
    }
}
