import { FETCH_SEARCHIMAGES_START, FETCH_SEARCHIMAGES_SUCCESS, FETCH_SEARCHIMAGES_ERROR, SELECT_SEARCHIMAGE } from '../actionTypes'

const initialState = {
    search_images: [],
    image_url: '',
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SEARCHIMAGES_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_SEARCHIMAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                search_images: action.images,
            }
        case SELECT_SEARCHIMAGE:
            return {
                ...state,
                image_url: action.image_url,
            }
        case FETCH_SEARCHIMAGES_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}