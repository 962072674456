import { api_faq } from 'API/faqs'
import { FETCH_FAQ_START, FETCH_FAQ_SUCCESS, FETCH_FAQ_ERROR } from '../actionTypes'

export function fetchFAQ() {
    return async dispatch => {
        dispatch(fetchFAQStart())
        try {
            const faq = await api_faq.GetFAQ()
            dispatch(fetchFAQSuccess(faq.data))
        } catch (e) {
            dispatch(fetchFAQError(e))
        }
    }
}

export function fetchFAQStart() {
    return {
        type: FETCH_FAQ_START
    }
}

export function fetchFAQSuccess(faq) {
    return {
        type: FETCH_FAQ_SUCCESS,
        faq,
    }
}

export function fetchFAQError(e) {
    return {
        type: FETCH_FAQ_ERROR,
        error: e,
    }
}
