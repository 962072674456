import leave1 from 'assets/backgrounds/games/animation/leave1.png';
import leave2 from 'assets/backgrounds/games/animation/leave2.png';

import './style.css';

const leaves = [leave1, leave2]
const Leavesflake = (props) => {
    return (
        <p className='Leavesflake' id={`item${props.id}`} style={props.style}>
            <img src={leaves[Math.round(Math.random() * (1))]} alt="leave1" />
        </p>

    )
}

function Leave() {
    const leave = () => {
        let animationDelay = '0s';
        let arr = Array.from('Snowflakes are awesome!!! are awesome!!!')
        return arr.map((el, i) => {
            animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
            let style = {
                animationDelay,
            }
            return (<Leavesflake key={i} id={i} style={style} />)
        })
    }

    return (
        <div className='leavesAnimation autumn_bg'>
            {leave()}
        </div>
    )
}

export default Leave;