import { api_folders } from 'API/folders'
import { FETCH_ONEFOLDER_START, FETCH_ONEFOLDER_SUCCESS, FETCH_ONEFOLDER_ERROR } from '../actionTypes'

export function fetchOneFolder(id) {
    return async dispatch => {
        dispatch(fetchOneFolderStart())
        try {
            const one_folder = await api_folders.ReadeOneFolder(id)
            dispatch(fetchOneFolderSuccess(one_folder.data))
        } catch (e) {
            dispatch(fetchOneFolderError(e))
        }
    }
}

export function fetchOneFolderStart() {
    return {
        type: FETCH_ONEFOLDER_START
    }
}

export function fetchOneFolderSuccess(one_folder) {
    return {
        type: FETCH_ONEFOLDER_SUCCESS,
        one_folder,
    }
}

export function fetchOneFolderError(e) {
    return {
        type: FETCH_ONEFOLDER_ERROR,
        error: e,
    }
}
