import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';

import { fetchLogin } from 'store/actions/login';
import { fetchUserMe, fetchUserMeSubscription } from 'store/actions/userMe';

import './style.css';

function LoginPage({
                       link,
                       fetchLogin,
                       fetchUserMe,
                       fetchUserMeSubscription
                   }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onFinish = (values) => {
        fetchLogin(values)
            .then(res => {
                if (res !== "Incorrect username or password" && res !== 'Error: Network Error') {
                    fetchUserMe();
                    fetchUserMeSubscription();
                    navigate('/myTask');
                }
            });
    };

    return (
        <div className="loginPage">
            <div className="loginPage_container">
                <p className="loginPage_title">{t(`modal.login.title`)}</p>
                <Form
                    layout="vertical"
                    requiredMark={false}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    className="loginForm"
                >
                    <Form.Item
                        label={t(`modal.login.email`)}
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: t(`modal.login.email_error`),
                            },
                        ]}
                        className="loginForm_item"
                    >
                        <Input className="formInput" />
                    </Form.Item>
                    <Form.Item
                        label={t(`modal.login.password`)}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: t(`modal.login.password_error`),
                            },
                        ]}
                        className="loginForm_item"
                    >
                        <Input.Password className="formInput" />
                    </Form.Item>
                    <Form.Item className="loginForm_item">
                        <Button className="loginForm_button" htmlType="submit">{t(`modal.login.button`)}</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        link: state.login.link,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchLogin: (data) => dispatch(fetchLogin(data)),
        fetchUserMe: () => dispatch(fetchUserMe()),
        fetchUserMeSubscription: () => dispatch(fetchUserMeSubscription()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
