import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import CWG from 'cwg';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';

import closeGrey from 'assets/close_grey.svg';
import error from 'assets/games/spaceMathematics/errorCheck.svg';
import greenCheck from 'assets/games/spaceMathematics/greenCheckr.svg';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

function CrosswordPuzzle(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(null)
    const [crosswordTable, setCrosswordTable] = useState(null) /*Сетка кроссворда */
    const [crosswordInputValues, setCrosswordInputValues] = useState(null)
    const [loading, setLoading] = useState(false)
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [timer, setTimer] = useState() /*Таймер*/
    const [visibleQuestion, setVisibleQuestion] = useState(false)/*Показывать вопрос или нет */
    const [questionNumber, setQuestionNumber] = useState(0) /*Номер вопроса */
    const [visibleCheckButton, setVisibleCheckButton] = useState(null) /*Показывать кнопку проверить или нет */
    const [saveCurrentAnswers, setSaveQurrentAnswers] = useState(null) /*Правильно заполненная сетка кроссворда */
    const [endGame, setEndGame] = useState(false) /*Конечц игры */
    const [position, setPosition] = useState({}) /*Позиция блока с вопросом */
    const [horizonStatus, setHorizonStatus] = useState(null)
    const [dontKnownStatus, setDontKnownStatus] = useState(false)
    const [clickRestart, setClickRestart] = useState(false)
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        question: {
            photo:, фото вопроса
            text: текст вопроса
        },
        answers: [], массив изначальных ответов 
        enter_answers: [], массив выбранных ответов
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true можно ли изменять этот объект
    */
    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        const words = []
        const resultArray = []
        props.one_exercises.game.tasks.forEach(el => {
            words.push(el.answer)
            resultArray.push({
                question: {
                    photo: el.photo,
                    text: el.text
                },
                answers: el.answer,
                enter_answers: null,
                max_point: 1,
                actual_point: 0,
                active: true
            })
        })
        const cwg = CWG(words)
        const createCrosswordInputValues = []
        const createCurrentCrossword = []
        if (cwg) {
            cwg.ownerMap.forEach((row, key) => {
                const array = []
                const currentArray = []
                for (let i = 0; i < row.length; i++) {
                    if (row[i] !== undefined) {
                        array.push(null)
                        currentArray.push(row[i].letter)
                    } else {
                        array.push(-1)
                        currentArray.push(undefined)
                    }
                }
                createCrosswordInputValues.push(array)
                createCurrentCrossword.push(currentArray)
            })
        }
        setTasks(JSON.parse(JSON.stringify(props.one_exercises.game.tasks)))
        setCrosswordTable(cwg)
        setLoading(true)
        setVisibleQuestion(false)
        setQuestionNumber(0)
        setCrosswordInputValues(createCrosswordInputValues)
        setSaveQurrentAnswers(createCurrentCrossword)
        setEndGame(false)
        setResult(resultArray)
        setDontKnownStatus(false)
        setHorizonStatus(null)
        setClickRestart(false)
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        if (clickRestart) {
            temp.current()
        }
    }, [props.restart])

    useEffect(() => {
        if (crosswordInputValues) {
            let count = 0
            crosswordInputValues.forEach(row => {
                row.forEach(el => {
                    if (el === null || el === '' || el === 'error') {
                        count += 1
                    }
                })
            })
            if (count === 0) {
                setVisibleCheckButton(
                    <div className="gameBlock_footer__check" onClick={() => sheckAnswers()}>
                        Проверить
                    </div>
                )
            } else {
                setVisibleCheckButton(null)
            }
        }
    }, [crosswordInputValues])

    useEffect(() => {
        if (result.length > 0 && endGame) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            props.endGameResults(saveTime, result)
        }
    }, [result, endGame])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const generateTableCol = (row, rowIndex) => {
        const cols = []
        for (let i = 0; i < row.length; i++) {
            if (row[i] !== undefined) {
                cols.push(
                    <td>
                        <div className="crosswordPuzzleTable_input" >
                            {crosswordTable.positionObjArr.map((el, key) => {
                                if (el.yNum === rowIndex) {
                                    if (el.xNum === i) {
                                        return <p className="crosswordAnswerNumber" style={el.isHorizon ? { right: 2 } : { left: 4 }} onClick={() => { onClickAnswerNumber(key, el.isHorizon, el.xNum, el.yNum, crosswordTable.height, crosswordTable.width) }}>{key + 1}.</p>
                                    }
                                }
                            })}
                            {crosswordInputValues[rowIndex][i] === 'greate'
                                ? <img src={greenCheck} alt="greenCheck" />
                                : (crosswordInputValues[rowIndex][i] === 'error'
                                    ? <img src={error} alt="error" />
                                    : <input
                                        maxLength={1}
                                        value={crosswordInputValues[rowIndex][i] || ''}
                                        onChange={(e => onChangeCrosswordValue(e.target.value, rowIndex, i))}
                                        className={`crosswordPuzzleInput_${rowIndex}_${i}`}
                                        onClick={() => checkPosition(rowIndex, i)}
                                    />
                                )
                            }
                        </div>
                    </td>
                )
            } else {
                cols.push(<td></td>)
            }
        }
        return cols
    }

    const checkPosition = (y, x) => {
        let findWord = null
        for (let i = 0; i < crosswordTable.positionObjArr.length; i++) {
            const el = crosswordTable.positionObjArr[i]

            if (el.xNum === x && el.yNum === y) {
                findWord = el
                setHorizonStatus(el.isHorizon)
                break;
            }
        }
        if (!findWord) {
            for (let i = 0; i < crosswordTable.positionObjArr.length; i++) {
                const el = crosswordTable.positionObjArr[i]

                if (el.xNum === x && el.yNum + el.wordStr.length >= y && el.yNum <= y && !el.isHorizon) {
                    findWord = el
                    setHorizonStatus(el.isHorizon)
                    break;
                }
                if (el.yNum === y && el.xNum + el.wordStr.length >= x && el.xNum <= x && el.isHorizon) {
                    findWord = el
                    setHorizonStatus(el.isHorizon)
                    break;
                }
            }
        }
    }

    const onClickAnswerNumber = (key, isHorizon, xNum, yNum, height, width) => {
        if (isHorizon) {
            if (yNum < height / 2) {
                if (xNum <= width / 2) {
                    setPosition({
                        bottom: 0,
                        right: 0
                    })
                } else {
                    setPosition({
                        bottom: 0,
                        left: 0
                    })
                }
            } else {
                if (xNum <= width / 2) {
                    setPosition({
                        top: 0,
                        right: 0
                    })
                } else {
                    setPosition({
                        top: 0,
                        left: 0
                    })
                }
            }
        } else {
            if (xNum <= width / 2) {
                setPosition({
                    top: 0,
                    right: 0
                })
            } else {
                setPosition({
                    top: 0,
                    left: 0
                })
            }
        }
        setVisibleQuestion(true);
        setQuestionNumber(key)
    }

    const onChangeCrosswordValue = (value, rowIndex, colIndex) => {
        const crosswordInputValuesCopy = JSON.parse(JSON.stringify(crosswordInputValues))
        if (value !== '') {
            crosswordTable.positionObjArr.forEach(el => {
                if (el.isHorizon === horizonStatus && horizonStatus) {
                    if (colIndex >= el.xNum && colIndex <= el.xNum + el.wordStr.length - 1) {
                        if (crosswordInputValuesCopy[rowIndex][colIndex + 1] !== -1 && crosswordInputValuesCopy[rowIndex][colIndex + 1] !== undefined) {
                            document.getElementsByClassName(`crosswordPuzzleInput_${rowIndex}_${colIndex + 1}`)[0].focus()
                            document.getElementsByClassName(`crosswordPuzzleInput_${rowIndex}_${colIndex + 1}`)[0].select()
                        }
                    }
                }
                if (el.isHorizon === horizonStatus && !horizonStatus) {
                    if (colIndex === el.xNum) {
                        if (rowIndex >= el.yNum && rowIndex <= el.yNum + el.wordStr.length - 1) {
                            if (crosswordInputValuesCopy[rowIndex + 1] !== undefined && crosswordInputValuesCopy[rowIndex + 1][colIndex] !== -1) {
                                document.getElementsByClassName(`crosswordPuzzleInput_${rowIndex + 1}_${colIndex}`)[0].focus()
                                document.getElementsByClassName(`crosswordPuzzleInput_${rowIndex + 1}_${colIndex}`)[0].select()
                            }
                        }
                    }
                }
            })
        }
        crosswordInputValuesCopy[rowIndex][colIndex] = value
        setCrosswordInputValues(crosswordInputValuesCopy)
    }

    const onClickDontKnown = () => {
        const resultCopy = JSON.parse(JSON.stringify(result))
        resultCopy.forEach(el => {
            if (!el.enter_answers) {
                el.enter_answers = 'Не знаю'
            }
        })
        setDontKnownStatus(true)
        setResult(resultCopy)
        setCrosswordInputValues(saveCurrentAnswers)
        setTimeout(() => {
            setEndGame(true)
            setClickRestart(true)
        }, 1000)
    }

    const sheckAnswers = () => {
        const crosswordInputs = JSON.parse(JSON.stringify(crosswordInputValues))
        const crosswordInputsWithoutErrors = JSON.parse(JSON.stringify(crosswordInputValues))
        const resultCopy = JSON.parse(JSON.stringify(result))
        let checkEndGame = 0
        crosswordTable.positionObjArr.forEach((el, index) => {
            let count = 0
            let errorCount = 0
            let enter_answer = ''
            if (el.isHorizon) {
                crosswordInputValues[el.yNum].forEach((letter, key) => {
                    if (key >= el.xNum && key < (el.xNum + el.wordStr.length)) {
                        if (letter !== -1 && letter !== null) {
                            enter_answer += letter
                            if (el.wordStr[count] === letter) {
                                crosswordInputs[el.yNum][key] = 'greate'
                            } else {
                                crosswordInputs[el.yNum][key] = 'error'
                                crosswordInputsWithoutErrors[el.yNum][key] = null
                                errorCount += 1
                            }
                            count += 1
                        }
                    }
                })
                if (resultCopy[index].active) {
                    if (errorCount === 0) {
                        resultCopy[index].actual_point = 1
                    }
                    resultCopy[index].active = false
                    resultCopy[index].enter_answers = enter_answer
                }
                checkEndGame += errorCount
            } else {
                crosswordInputValues.forEach((row, key) => {
                    if (key >= el.yNum && key < (el.yNum + el.wordStr.length)) {
                        if (row[el.xNum] !== -1 && row[el.xNum] !== null && el.wordStr[count]) {
                            enter_answer += row[el.xNum]
                            if (el.wordStr[count] === row[el.xNum]) {
                                crosswordInputs[key][el.xNum] = 'greate'
                            } else {
                                crosswordInputs[key][el.xNum] = 'error'
                                crosswordInputsWithoutErrors[key][el.xNum] = null
                                errorCount += 1
                            }
                            count += 1
                        }
                    }
                })
                if (resultCopy[index].active) {
                    if (errorCount === 0) {
                        resultCopy[index].actual_point = 1
                    }
                    resultCopy[index].active = false
                    resultCopy[index].enter_answers = enter_answer
                }
                checkEndGame += errorCount
            }
        })
        if (checkEndGame === 0) {
            setEndGame(true)
        }
        setResult(resultCopy)
        setCrosswordInputValues(crosswordInputs)
        setTimeout(() => {
            setCrosswordInputValues(crosswordInputsWithoutErrors)
            setClickRestart(true)
        }, 2000)
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        <>
            {loading &&
                <Fullscreen
                    enabled={isFullscreenEnabled}
                    onClose={() => {
                        setIsFullscreenEnabled(false)
                        setIsFullscreenEnabledClass(false)
                    }}
                >
                    {props.animation}
                    <div className={`gameBlock ${props.background}`} >
                        <div className="gameBlock_header" style={{ flexDirection: 'column' }}>
                            {props.play &&
                                <>
                                    <div style={{
                                        width: '100%',
                                        position: 'relative',
                                        height: 30,
                                        alignItems: 'center'
                                    }}>
                                        {timer}
                                        <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                                        <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                                            Не знаю
                                        </div>
                                    </div>
                                    <p className="gameBlock_header__dascription">Для открытия вопроса нажмите на номер вопроса в кроссворде</p>
                                </>
                            }
                        </div>
                        {crosswordTable &&
                            <div className='crosswordPuzzleContent_questionBlock' style={{ position: 'relative' }}>
                                {visibleQuestion && <div key={`horizonQuestion_${0}`} className="crosswordPuzzleContent_questionColumn__item" style={position}>
                                    <div className="crosswordPuzzleContent_questionColumn__item__number">{questionNumber + 1}.</div>
                                    <div className="crosswordPuzzleContent_questionColumn__item__question">
                                        {tasks[questionNumber].photo
                                            ?
                                            <div>
                                                <img src={tasks[questionNumber].photo} alt="img" />
                                                <p className="yesOrNot_questionBlock__textUnderImg">{tasks[questionNumber].text}</p>
                                            </div>
                                            : <p>{tasks[questionNumber].text}</p>
                                        }
                                    </div>
                                    <img src={closeGrey} alt="closeGrey" className="crosswordPuzzleContent_questionColumn__item__close" onClick={() => setVisibleQuestion(false)} />
                                </div>}
                                <div className="crosswordPuzzleContent">
                                    <div className="crosswordPuzzleContent_questionColumn">
                                        {/* <div className="crosswordPuzzleContent_questionColumn__title">По горизонтали</div>
                                        {crosswordTable.positionObjArr.map((el, key) => {
                                            if (el.isHorizon) {
                                                return (
                                                    <div key={`horizonQuestion_${key}`} className="crosswordPuzzleContent_questionColumn__item">
                                                        <div className="crosswordPuzzleContent_questionColumn__item__number">{key + 1}.</div>
                                                        <div className="crosswordPuzzleContent_questionColumn__item__question">
                                                            {tasks[key].photo
                                                                ?
                                                                <div>
                                                                    <img src={tasks[key].photo} alt="img" />
                                                                    <p className="yesOrNot_questionBlock__textUnderImg">{tasks[key].text}</p>
                                                                </div>
                                                                : <p>{tasks[key].text}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })} */}
                                    </div>
                                    <table
                                        className="crosswordPuzzleTable"
                                    >
                                        {crosswordTable.ownerMap.map((row, key) => (
                                            <tr key={`crosswordRow_${key}`}>
                                                {generateTableCol(row, key)}
                                            </tr>
                                        ))}
                                    </table>
                                    <div className="crosswordPuzzleContent_questionColumn">
                                        {/* <div className="crosswordPuzzleContent_questionColumn__title">По вертикали</div>
                                        {crosswordTable.positionObjArr.map((el, key) => {
                                            if (!el.isHorizon) {
                                                return (
                                                    <div key={`horizonQuestion_${key}`} className="crosswordPuzzleContent_questionColumn__item">
                                                        <div className="crosswordPuzzleContent_questionColumn__item__number">{key + 1}.</div>
                                                        <div className="crosswordPuzzleContent_questionColumn__item__question">
                                                            {tasks[key].photo
                                                                ?
                                                                <div>
                                                                    <img src={tasks[key].photo} alt="img" />
                                                                    <p className="yesOrNot_questionBlock__textUnderImg">{tasks[key].text}</p>
                                                                </div>
                                                                : <p>{tasks[key].text}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })} */}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="gameBlock_footer">
                            {!dontKnownStatus && visibleCheckButton}
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </div>
                </Fullscreen>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CrosswordPuzzle);
