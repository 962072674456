import {
    FETCH_LEADERBOARD_RESULTS_START,
    FETCH_LEADERBOARD_RESULTS_SUCCESS,
    FETCH_LEADERBOARD_RESULTS_ERROR
} from '../actionTypes'

const initialState = {
    leader_board_results: {
        time: null,
        result: []
    },
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_LEADERBOARD_RESULTS_START:
            return {
                ...state, loading: true
            }
        case FETCH_LEADERBOARD_RESULTS_SUCCESS:
            return {
                ...state, loading: false,
                leader_board_results: {
                    time: action.leader_board_results.time,
                    result: action.leader_board_results.result
                },
            }
        case FETCH_LEADERBOARD_RESULTS_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}