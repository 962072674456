import { FETCH_ALLEXERCISES_CLEAR, FETCH_ALLEXERCISES_ERROR, FETCH_ALLEXERCISES_START, FETCH_ALLEXERCISES_SUCCESS, FETCH_ALLEXERCISES_SUCCESS_CONCAT } from '../actionTypes'

const initialState = {
    all_exercises: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALLEXERCISES_START:
            return {
                ...state, loading: true
            }
        case FETCH_ALLEXERCISES_SUCCESS:
            return {
                ...state, loading: false,
                all_exercises: action.exercises,
            }
        case FETCH_ALLEXERCISES_SUCCESS_CONCAT:
            return {
                ...state, loading: false,
                all_exercises: state.all_exercises.concat(action.exercises),
            }
        case FETCH_ALLEXERCISES_CLEAR:
            return {
                ...state,
                all_exercises: [],
            }
        case FETCH_ALLEXERCISES_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}