import { useState, useEffect, useRef } from 'react';

import '../style.css';

function GroupDivision({ game }) {
    const [answers, setAnswers] = useState([])
    const temp = useRef()

    const startFunction = () => {
        const arr = []
        game.forEach(item => {
            item.answers.forEach(item => {
                arr.push(item.answer)
            })
        })
        setAnswers(arr.sort(() => Math.random() - 0.5))
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <div className='groupDivisionBg_groups cardBg'>
                {game.map((item, key) => (
                    <div className='groupDivisionBg_groups__item' key={key}>{item.title}</div>
                ))}
            </div>
            <div className='groupDivisionBg_answers'>
                {answers.map((item, key) => (
                    <div className='groupDivisionBg_answers__item' key={key}>{item}</div>
                ))}
            </div>
        </div>
    );
}

export default GroupDivision;
