import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Upload, Select, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { fetchUserMe } from 'store/actions/userMe';
import { api_user } from 'API/users';

import { profile_country, profile_roly } from 'lists/list';

import '../style.css';
import '../media.css'

const { Option } = Select;

const propsUpload = {
    action: '',
    accept: ".png, .jpg",
    listType: "picture",
    maxCount: 1,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function EditProfileForm({
    userMe,
    fetchUserMe
}) {
    const [fields, setFields] = useState([
        {
            name: 'first_name',
            value: userMe.first_name,
        },
        {
            name: 'last_name',
            value: userMe.last_name,
        },
        {
            name: 'is_teacher',
            value: String(userMe.is_teacher),
        },
        {
            name: 'country',
            value: userMe.country,
        },
        {
            name: 'email',
            value: userMe.email,
        },
        {
            name: 'photo',
            value: [],
        },
    ]);

    const [fileList, setFileList] = useState(null)

    const { t } = useTranslation();

    const onFinish = async (values) => {
        if (values.photo.length !== 0) {
            let data = new FormData();
            let filedata = values.photo[0].originFileObj
            delete filedata.uid
            data.append('photo', filedata);

            await api_user.UpdateUserImg(data)

            setFileList(null)
        }

        let noPhoto = values
        delete noPhoto.photo

        await api_user.UpdateUser(noPhoto)

        fetchUserMe()

        message.success(t(`editProfile.success`))
    };

    const onChange = (newFields) => { setFields(newFields); }

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e?.fileList;
    };
    const validatePwd = (rule, value) => {
        return new Promise(async (resolve, reject) => {
            if (!/^[a-zA-Zа-яА-Я]+$/.test(value)) {
                await reject('Некорректный формат')
            } else {
                await resolve()
            }
        })
    }
    return (
        <>
            {userMe !== null &&
                <Form
                    layout="vertical"
                    requiredMark={false}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    className="modalForm editProfileForm"
                    fields={fields}
                    onFieldsChange={(_, allFields) => {
                        onChange(allFields);
                    }}
                >
                    <div className='editProfileForm_avatarBlock'>
                        {userMe.photo !== null ? <img src={userMe.photo} alt="avatar" className='editProfileAvatar' /> : <UserOutlined style={{ fontSize: '50px' }} />}
                        <Form.Item
                            name="photo"
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                {...propsUpload}
                                fileList={fileList}
                                onChange={handleChange}
                                customRequest={dummyRequest}
                            // onRemove={remotePhoto}
                            >
                                <Button className='uploadButton'>{t(`editProfile.upload`)}</Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    <div className="fioBlock">
                        <Form.Item
                            label={t(`editProfile.name`)}
                            name="first_name"
                            rules={[{ validator: validatePwd }]}
                        >
                            <Input className="formInput" />
                        </Form.Item>
                        <Form.Item
                            label={t(`editProfile.surname`)}
                            name="last_name"
                            rules={[{ validator: validatePwd }]}
                        >
                            <Input className="formInput" />
                        </Form.Item>
                    </div>
                    <Form.Item
                        name="is_teacher"
                        label={t(`editProfile.roly`)}
                    >
                        <Select>
                            {profile_roly.map((item, key) => {
                                return <Option key={key} value={item}>{t(`editProfile.roly_select.${item}`)}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="country"
                        label={t(`editProfile.country`)}
                    >
                        <Select >
                            {profile_country.map((item, key) => {
                                return <Option key={key} value={item}>{t(`editProfile.country_select.${item}`)}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t(`modal.registration.email`)}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t(`modal.registration.email_error`),
                            },
                        ]}
                    >
                        <Input className="formInput" disabled />
                    </Form.Item>
                    <Form.Item>
                        {/* <Button className="loginButton" htmlType="submit">{t(`modal.create_group.button`)}</Button> */}
                        <button htmlType="submit" className='editProfileButton'>{t(`editProfile.saveButton`)}</button>
                    </Form.Item>
                </Form>
            }
        </>
    );
};

function mapStateToProps(state) {
    return {
        userMe: state.userMe.userMe,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUserMe: () => dispatch(fetchUserMe()),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(EditProfileForm);