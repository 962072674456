import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import questionSign from 'assets/questionSign.png';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';
import { copy } from '../../../functions/generalFunction';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FETCH_ONEEXERCISES_CLEAR } from "store/actionTypes";
import '../style.css';
import './media.css';
import './style.css';

function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
        return style;
    }

    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.001s`,
    };
}

function InsertLetter(props) {
    const bgArray = ['#ff7675', '#fdcb6e', '#a29bfe', '#cf6a87']

    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks.question.split(' ')) /*Задание */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState(Array(props.one_exercises.game.tasks.answers.length)) /*Массив ответов в случайном полядке*/
    const [taskAnswers, setTaskAnswers] = useState() /*Массив ответов в случайном полядке*/
    const [loading, setLoading] = useState(false)
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState(null) /*Таймер*/
    const [trueAnswerArray, setTrueAnswerArray] = useState([]) //очередность правильных ответов
    const [maxWidth, setMaxWidth] = useState(0) //Длина самого длинного искомого слова
    const [checkAnswers, setCheckAnswers] = useState(false) /*Готовность проверить задание */
    const [dontKnownStatus, setDontKnownStatus] = useState(false) //нажата ли кнопка "не знаю"

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.currentTime = 0
            audio.current.volume = 0.05
        } else {
            const copyTask = JSON.parse(JSON.stringify(tasks)) //созданий копии массива задания
            const copyTrueAnswers = []
            const arrayAnswers = []
            props.one_exercises.game.tasks.answers.forEach((item, key) => {
                arrayAnswers.push(item.answer)
            })
            copyTask.forEach((el, index) => {
                copyTask[index] = el
                if (props.one_exercises.game.tasks.answers.findIndex(str => str.answer === copyTask[index].split('\n')[0].replace(/[,.;:?!"'|@#()_+=-]\s*$/, "")) === -1) {
                    copyTrueAnswers.push(null)

                } else {
                    copyTrueAnswers.push({
                        answer: el.split('\n')[0].replace(/[,.;:?!"'|@#()_+=-]\s*$/, ""),
                        enter_answer: null,
                        max_point: 1,
                        actual_point: 0,
                        active: true
                    })
                }
            })
            props.one_exercises.game.tasks.answers = arrayAnswers

            for (let i = 0; i < answers.length; i++) {
                answers[i] = null
            }
            audio.current = new Audio(props.sound)
            audio.current.loop = true
            setTrueAnswerArray(copyTrueAnswers)
            // setTasks(copyTask)
            setLoading(true)
            setAnswers([])
            setTaskAnswers(props.one_exercises.game.tasks.answers.map(el => { if (el !== undefined) return el.replace(/[,.;:?!"'|@#()_+=-]\s*$/, "") }).sort(() => Math.random() - 0.5))
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    // useEffect(() => {
    //     return () => {
    //         props.FETCH_ONEEXERCISES_CLEAR()
    //     };
    // }, [])

    useEffect(() => {
        if (props.play) {
            const copyTask = copy(tasks) //созданий копии массива задания
            const copyTrueAnswers = []
            copyTask.forEach((el, index) => {
                if (props.one_exercises.game.tasks.answers.indexOf(copyTask[index].split('\n')[0].replace(/[,.;:?!"'|@#()_+=-]\s*$/, "")) === -1) {
                    copyTrueAnswers.push(null)
                } else {
                    copyTrueAnswers.push({
                        answer: el.split('\n')[0].replace(/[,.;:?!"'|@#()_+=-]\s*$/, ""),
                        enter_answer: null,
                        max_point: 1,
                        actual_point: 0,
                        active: true
                    })
                }
            })

            for (let i = 0; i < answers.length; i++) {
                answers[i] = null
            }
            setTrueAnswerArray(copyTrueAnswers)
            setTasks(copyTask)
            setLoading(true)
            setDontKnownStatus(false)
            setAnswers(Array(props.one_exercises.game.tasks.answers.length))
            setTaskAnswers(props.one_exercises.game.tasks.answers.map(el => el.replace(/[,.;:?!"'|@#()_+=-]\s*$/, "")).sort(() => Math.random() - 0.5))
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.currentTime = 0
            audio.current.volume = 0.05
        }
    }, [props.restart])

    useEffect(() => {
        audio.current.pause()
        setIsFullscreenEnabled(false)
        setIsFullscreenEnabledClass(false)
    }, [activeTask])

    useEffect(() => {
        const newArray = answers.slice()
        const filterNewArray = newArray.filter((a) => { return a !== null })
        if (filterNewArray.length === trueAnswerArray.filter((a) => { return a !== null }).length && trueAnswerArray.filter((a) => { return a !== null }).length > 0) {
            setCheckAnswers(true)
        } else {
            setCheckAnswers(false)
        }
    }, [answers])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const onClickDontKnown = () => {
        setTimer(null)
        const trueAnswersCopy = copy(trueAnswerArray)
        trueAnswersCopy.forEach(el => {
            if (el && el.active) {
                el.active = false
            }
            if (el && !el.enter_answer) {
                el.enter_answer = 'Не знаю'
            }
        })
        setAnswers(trueAnswersCopy)
        setDontKnownStatus(true)
        audio.current.pause()
        setTimeout(() => props.endGameResults(saveTime, trueAnswersCopy.filter((a) => { return a !== null })), 2000)
    }

    const onCheckAnswers = () => {
        const answersCopy = copy(answers)
        const trueAnswersCopy = copy(trueAnswerArray)
        answersCopy.forEach((el, index) => {
            if (el) {
                console.log('trueAnswersCopy[index]', trueAnswersCopy[index]);
                if (el.answer === trueAnswersCopy[index].answer) {
                    if (trueAnswersCopy[index].active) {
                        el.actual_point = 1
                        trueAnswersCopy[index].actual_point = 1
                        trueAnswersCopy[index].enter_answer = trueAnswersCopy[index].answer
                    }
                } else {
                    answersCopy[index] = null
                }
                trueAnswersCopy[index].active = false
                el.enter_answer = trueAnswersCopy[index].answer
            }
        })
        if (answersCopy.filter((a) => { return a !== null }).length === trueAnswerArray.filter((a) => { return a !== null }).length) {
            audio.current.pause()
            setTimer(null)
            setTimeout(() => props.endGameResults(saveTime, answersCopy.filter((a) => { return a !== null })), 2000)
        }
        setAnswers(answersCopy);
        setTrueAnswerArray(trueAnswersCopy)
    }

    const onDragEnd = (result) => {
        if (result.destination) {
            const newArray = answers.slice()
            if (result.source.droppableId === "answers") {
                newArray[result.destination.index] = {
                    answer: taskAnswers[result.source.index],
                    enter_answer: null,
                    max_point: 1,
                    actual_point: 0,
                    active: true
                }
            } else {
                const i = newArray[result.destination.index]
                newArray[result.destination.index] = newArray[result.source.index]
                newArray[result.source.index] = i
            }
            setAnswers(newArray)
        }
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        <>
            {loading &&
                <Fullscreen
                    enabled={isFullscreenEnabled}
                    onClose={() => {
                        setIsFullscreenEnabled(false)
                        setIsFullscreenEnabledClass(false)
                    }}
                >
                    {props.animation}
                    <div className={`gameBlock ${props.background}`} >
                        <div className="gameBlock_header">
                            {timer}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
                                <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                                {checkAnswers && !dontKnownStatus &&
                                    <div className="gameBlock_header__checkAnswers" onClick={onCheckAnswers} >
                                        Проверить
                                    </div>
                                }
                            </div>
                            {props.play &&
                                <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                                    Не знаю
                                </div>
                            }
                        </div>
                        <div className="gameBlock_content" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <div className="insertLetterQuestion">
                                    {tasks.map((item, key) => {
                                        let str = item
                                        let dot = null
                                        if (/[@_,.*^&?%$#!?()=+-]/.test(str)) {
                                            dot = str.split('\n')[0][str.split('\n')[0].length - 1]
                                            str = str.split('\n')[0].substring(0, str.split('\n')[0].length - 1)
                                        }

                                        if (props.one_exercises.game.tasks.answers.indexOf(str) === -1 && tasks[key].search(/\n/) === -1) {
                                            return <p key={`insertLetter_${key}`} className='insertLetter_flexItem'>{str}{dot}</p>
                                        } else if (tasks[key].search(/\n/) !== -1) {
                                            const nArray = []
                                            if (tasks[key].substring(0, tasks[key].search(/[\n ]+/)).indexOf(str) === -1) {
                                                if (props.one_exercises.game.tasks.answers.indexOf(tasks[key].substring(0, tasks[key].search(/[\n ]+/))) === -1) {
                                                    nArray.push(<p key={`insertLetter_${key}`} className='insertLetter_flexItem'><span>{tasks[key].substring(0, tasks[key].search(/[\n ]+/))}</span></p>)
                                                } else {
                                                    nArray.push(
                                                        <>
                                                            <div className="draggableBlock" style={{ width: `${maxWidth * 10}px`, height: 50 }}>
                                                                <Droppable droppableId={`questions${key}`}>
                                                                    {(droppableProvided, droppableSnapshot) => (
                                                                        <div
                                                                            ref={droppableProvided.innerRef}
                                                                            {...droppableProvided.droppableProps}
                                                                        >
                                                                            <Draggable
                                                                                key={`question${key}`}
                                                                                draggableId={`question${key}`}
                                                                                index={key}
                                                                                isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                                            >
                                                                                {(draggableProvided, draggableSnapshot) => (
                                                                                    <p
                                                                                        className="darggItem darggItem_question"
                                                                                        ref={draggableProvided.innerRef}
                                                                                        {...draggableProvided.draggableProps}
                                                                                        {...draggableProvided.dragHandleProps}
                                                                                    // style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                                                    >
                                                                                        {answers[key] && answers[key].answer}
                                                                                    </p>
                                                                                )}
                                                                            </Draggable>
                                                                        </div>)}
                                                                </Droppable>
                                                                {!answers[key] && <div className='questions__placeholderBlock'><img src={questionSign} alt="questionSign" /></div>}
                                                            </div>
                                                            {dot}
                                                        </>
                                                    )
                                                }
                                            } else {
                                                if (props.one_exercises.game.tasks.answers.indexOf(str) === -1) {
                                                    nArray.push(<p key={`insertLetter_${key}`}>{str}{dot}</p>)
                                                } else {
                                                    nArray.push(
                                                        <>
                                                            <div className="draggableBlock" style={{ width: `${maxWidth * 10}px`, height: 50 }}>
                                                                <Droppable droppableId={`questions${key}`}>
                                                                    {(droppableProvided, droppableSnapshot) => (
                                                                        <div
                                                                            ref={droppableProvided.innerRef}
                                                                            {...droppableProvided.droppableProps}
                                                                        >
                                                                            <Draggable
                                                                                key={`question${key}`}
                                                                                draggableId={`question${key}`}
                                                                                index={key}
                                                                                isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                                            >
                                                                                {(draggableProvided, draggableSnapshot) => (
                                                                                    <p
                                                                                        className="darggItem darggItem_question"
                                                                                        ref={draggableProvided.innerRef}
                                                                                        {...draggableProvided.draggableProps}
                                                                                        {...draggableProvided.dragHandleProps}
                                                                                    // style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                                                    >
                                                                                        {answers[key] && answers[key].answer}
                                                                                    </p>
                                                                                )}
                                                                            </Draggable>
                                                                        </div>)}
                                                                </Droppable>
                                                                {!answers[key] && <div className='questions__placeholderBlock'><img src={questionSign} alt="questionSign" /></div>}
                                                            </div>
                                                            {dot}
                                                        </>
                                                    )
                                                }
                                            }
                                            if (tasks[key].length > tasks[key].search(/[\n ]+/) + 1) {
                                                nArray.push(<br
                                                    style={{
                                                        content: 'ww',
                                                        width: '100%',
                                                        display: 'block'
                                                    }}
                                                />)
                                                nArray.push(<p key={`insertLetter_n_${key}`}><span>{tasks[key].substring(tasks[key].search(/[\n ]+/) + 1, tasks[key].length)}</span></p>)
                                            }
                                            return nArray
                                        } else {
                                            if (str.length > maxWidth / 2) setMaxWidth(str.length * 2)
                                            return (
                                                <>
                                                    <Droppable droppableId={`questions_${key}`}>
                                                        {(droppableProvided, droppableSnapshot) => (
                                                            <div
                                                                ref={droppableProvided.innerRef}
                                                                {...droppableProvided.droppableProps}
                                                            >
                                                                <div className="draggableBlock" style={{ width: `${maxWidth * 10}px`, height: 50 }}>
                                                                    <Draggable
                                                                        key={`question${key}`}
                                                                        draggableId={`question${key}`}
                                                                        index={key}
                                                                        isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                                    >
                                                                        {(draggableProvided, draggableSnapshot) => (
                                                                            <p
                                                                                className="darggItem darggItem_question"
                                                                                ref={draggableProvided.innerRef}
                                                                                {...draggableProvided.draggableProps}
                                                                                {...draggableProvided.dragHandleProps}
                                                                            // style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                                            >
                                                                                {answers[key] && answers[key].answer}
                                                                            </p>
                                                                        )}
                                                                    </Draggable>
                                                                    {!answers[key] && <div className='questions__placeholderBlock'><img src={questionSign} alt="questionSign" /></div>}
                                                                </div>
                                                            </div>)}
                                                    </Droppable>
                                                    {dot}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="insertLetterAnswers">
                                    <Droppable droppableId={`answers`} className="insertLetterAnswers">
                                        {(droppableProvided, droppableSnapshot) => (
                                            <div
                                                // className="groupDivionBlock_answers"
                                                ref={droppableProvided.innerRef}
                                                {...droppableProvided.droppableProps}
                                            >
                                                {taskAnswers.map((item, key) => {
                                                    return <Draggable
                                                        key={`answer${key}`}
                                                        draggableId={`answer${key + 1}`}
                                                        index={key}
                                                        isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                    >
                                                        {(draggableProvided, draggableSnapshot) => (
                                                            <p
                                                                className="darggItem"
                                                                ref={draggableProvided.innerRef}
                                                                {...draggableProvided.draggableProps}
                                                                {...draggableProvided.dragHandleProps}
                                                                style={getStyle({ ...draggableProvided.draggableProps.style, backgroundColor: `${bgArray[key % 4]}` }, draggableSnapshot)}
                                                            >
                                                                {item}
                                                            </p>
                                                        )}
                                                    </Draggable>
                                                })}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </DragDropContext>
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </div>
                </Fullscreen>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        FETCH_ONEEXERCISES_CLEAR: () => dispatch({ type: FETCH_ONEEXERCISES_CLEAR })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(InsertLetter);