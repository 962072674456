import axios from 'axios';

const LocalToken = localStorage.getItem('superkid-token');
let token = LocalToken;

export let instance = axios.create({
    headers: { Authorization: `Bearer ${token}` }
})

export const ApiUrl = 'https://lk.superkid.online'

export const api = {
    initToken() {
        const LocalToken = localStorage.getItem('superkid-token');
        token = LocalToken;

        instance = axios.create({
            headers: { Authorization: `Bearer ${token}` }
        })
    },
}