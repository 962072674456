import { UserOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { copy } from "functions/generalFunction";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { fetchAchievements } from "store/actions/achievements";
import { fetchJointExercises } from "store/actions/jointExercises";
import { likeAction } from "store/actions/likeAction";
import { fetchLikesUsers } from 'store/actions/likesUsers';
import { fetchUser } from "store/actions/oneUser";

import CustomTable from "components/components/customTable/CustomTable";
import TaskCard from "components/components/taskCard/TaskCard";
import { progress_data } from "lists/table_data";

import background from 'assets/backgrounds/profile.png';

import './style.css';

const { Option } = Select;

function ProfileUser({
    token,
    user,
    userMe,
    likes_users,
    achievements,
    joint_exercises,
    fetchUser,
    likeAction,
    fetchLikesUsers,
    fetchAchievements,
    fetchJointExercises
}) {
    const [exercises, setExercises] = useState([])
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { id } = useParams()
    const [activeButton, setActiveButton] = useState(1)
    const [status, setStatus] = useState(undefined)
    const temp = useRef()

    const startFunction = () => {
        if (userMe !== null) {
            if (userMe.id === parseInt(id)) {
                navigate(`../profile`)
            } else {
                fetchUser(id)
                fetchAchievements(id)
                fetchJointExercises(id)
            }
        } else {
            fetchUser(id)
            fetchAchievements(id)
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    useEffect(() => {
        setStatus(likes_users.find(user => String(user.id) === id));
    }, [likes_users])

    useEffect(() => {
        if (user !== null) setExercises(user.exercises)
    }, [user])

    const columns = [
        {
            title: t(`table.profile.place`),
            dataIndex: 'place',
            sorter: {
                compare: (a, b) => a.place - b.place,
                multiple: 1,
            },
        },
        {
            title: t(`table.profile.project`),
            dataIndex: 'project',
        },
        {
            title: t(`table.profile.name`),
            dataIndex: 'name'
        },
        {
            title: t(`table.profile.score`),
            dataIndex: 'score',
            sorter: {
                compare: (a, b) => a.score - b.score,
                multiple: 3,
            },
        },
        {
            title: t(`table.profile.time`),
            dataIndex: 'time',
            sorter: {
                compare: (a, b) => a.time - b.time,
                multiple: 4,
            },
        },
    ];

    const likeActionFunc = async (mode) => {
        await likeAction(mode, 'User', id)
        fetchLikesUsers()
    }

    const sortArray = (value) => {
        const sort = copy(exercises)
        if (value === 'rating') {
            sort.sort((a, b) => a.leaderboard.length - b.leaderboard.length)
        }
        if (value === 'name') {
            sort.sort((a, b) => a.name.localeCompare(b.name))
        }
        setExercises(sort)
    }

    return (
        <>
            <img src={background} alt="background" className="mainMiniImg" />
            {user !== null &&
                <div className="patternsBlock">
                    <div className="profileBlock_header">
                        <div className="profileBlock_header__avatar">
                            {user.photo !== null ? <img src={user.photo} alt="avatar" /> : <UserOutlined style={{ fontSize: '50px' }} />}
                        </div>
                        <div className="profileBlock_header__description">
                            <p className="profileBlock_header__description__fio">{user.first_name} {user.last_name}</p>
                            <p className="profileBlock_header__description__info">{t(`profile.roly.${String(user.is_teacher)}`)} {user.country}
                                {token !== null
                                    ? status === undefined
                                        ? <span className="profileBlock_header__description__info__edit" onClick={() => likeActionFunc('set')}>, Добавить в избранное</span>
                                        : <span className="profileBlock_header__description__info__edit" onClick={() => likeActionFunc('remote')}>, Удалить из избранного</span>
                                    : null}
                            </p>
                        </div>
                    </div>
                    <div className="profileBlock_actions profile_actions">
                        <div className="profileBlock_actions__buttons">
                            <button
                                className={activeButton === 1 ? "active" : ''}
                                onClick={() => setActiveButton(1)}
                            >
                                {t(`profile.all_task`)}
                            </button>
                            {userMe !== null &&
                                <button
                                    className={activeButton === 2 ? "active" : ''}
                                    onClick={() => setActiveButton(2)}
                                >
                                    {t(`profile.joint_tasks`)}
                                </button>
                            }
                            <button
                                className={activeButton === 3 ? "active" : ''}
                                onClick={() => setActiveButton(3)}
                            >
                                {t(`profile.progress`)}
                            </button>
                        </div>
                        <div className="profileBlock_actions__sort">
                            <p>{t(`profile.sort.title`)}:</p>
                            <Select
                                style={{ width: 100 }}
                                bordered={false}
                                onChange={sortArray}
                            >
                                <Option value="name">Имя</Option>
                            </Select>
                        </div>
                    </div>
                    {activeButton === 1 &&
                        <div className="patternsBlock_list profileTasks_list">
                            {exercises.map((item, key) => {
                                return <div className='profileTasks_list__item' key={`profile_task_${item.id}`} >
                                    <TaskCard item={item} onClick={() => navigate(`../task/${item.id}/watch`)} actionButton="Сохранить к себе" />
                                </div>
                            })}
                        </div>
                    }
                    {activeButton === 2 && userMe !== null &&
                        <div className="patternsBlock_list profileTasks_list">
                            {joint_exercises.map((item, key) => (
                                <div className='profileTasks_list__item' key={`profile_task_${item.id}`} ><TaskCard item={item} onClick={() => navigate(`../task/${item.id}/watch`)} actionButton="Сохранить к себе" /></div>
                            ))}
                            {/* <TaskCard mode="result" /> */}
                        </div>
                    }
                    {activeButton === 3 &&
                        <div className='resultsBlock_tableBlock'>
                            {/* <h1>{t(`profile.table`)}</h1>
                            <div className='noTasks'>
                <img src={noPlayers} alt="noPlayers" />
                <p>{t(`myTask.noPlayers`)}</p>
                <p>{t(`myTask.invitePlayers`)}</p>
                <p className='inviteLink' onClick={shareInvite}>http://www.groovestreet.com/21543777</p>
            </div> */}
                            <div className='tableBlock'>
                                <CustomTable columns={columns} data={progress_data(achievements)} />
                            </div>
                        </div>
                    }

                </div>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user.user,
        userMe: state.userMe.userMe,
        likes_users: state.likes_users.likes_users,
        achievements: state.achievements.achievements,
        joint_exercises: state.joint_exercises.joint_exercises
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUser: (id) => dispatch(fetchUser(id)),
        likeAction: (mode, object_type, object_id) => dispatch(likeAction(mode, object_type, object_id)),
        fetchLikesUsers: () => dispatch(fetchLikesUsers()),
        fetchAchievements: (id) => dispatch(fetchAchievements(id)),
        fetchJointExercises: (id) => dispatch(fetchJointExercises(id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ProfileUser);
