import { connect } from 'react-redux';

import SubscriptionPrice from 'components/components/subscriptionPrice/SubscriptionPrice';
import EditProfileForm from 'components/forms/editProfile/EditProfile';

import { dateConvertOnlyNumber } from 'functions/dateConverterFunction';
import './media.css';
import './style.css';

function EditProfile({
    userMe,
    userMeSubscription,
}) {

    return (
        <div className='resultsBlock'>
            {userMe !== null && userMeSubscription !== null &&
                <div className='profileEdit'>
                    <EditProfileForm />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {userMeSubscription.meta && !userMeSubscription.meta.is_extended && <p style={{ color: 'red' }}>Подписка действует до {dateConvertOnlyNumber(userMeSubscription.meta.end_date)}</p>}
                        <SubscriptionPrice
                            item={userMeSubscription.subscription
                                ? userMeSubscription.subscription
                                : userMeSubscription
                            }
                            page={'profile'}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        userMe: state.userMe.userMe,
        userMeSubscription: state.userMe.userMeSubscription,
    }
}

export default connect(mapStateToProps, null)(EditProfile);
