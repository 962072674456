import { FETCH_USERS_START, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR } from '../actionTypes'
import { getQueryStringFromObject } from 'functions/filter'
import { api_user } from 'API/users'

export function fetchUsers(type, text) {
    return async dispatch => {
        dispatch(fetchStart())
        try {
            let obj = {}
            obj[type] = text
            const params = getQueryStringFromObject(JSON.stringify(obj))
            const users = await api_user.ReadeUsers(params)
            dispatch(fetchSuccess(users.data))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchStart() {
    return {
        type: FETCH_USERS_START
    }
}

export function fetchSuccess(users) {
    return {
        type: FETCH_USERS_SUCCESS,
        users,
    }
}

export function fetchError(e) {
    return {
        type: FETCH_USERS_ERROR,
        error: e,
    }
}
