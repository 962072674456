import { api_leaderBoard_results } from 'API/leaderboardResult'
import {
    FETCH_LEADERBOARD_RESULTS_ERROR,
    FETCH_LEADERBOARD_RESULTS_START,
    FETCH_LEADERBOARD_RESULTS_SUCCESS
} from '../actionTypes'

export function fetchCreateLeaderBoardResult(data) {
    return async dispatch => {
        try {
            await api_leaderBoard_results.CreateLeaderBoardResult(data)
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchLeaderBoardResultsById(leaderboard_id, skip, limit, time) {
    return async dispatch => {
        dispatch(fetchStart())
        try {
            let leader_board_results = await api_leaderBoard_results.ReadeLeadrBoardResultByLeaderboard(leaderboard_id, skip, limit)
            dispatch(fetchSuccess({
                result: leader_board_results.data,
                time
            }))
            return leader_board_results.data
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchStart() {
    return {
        type: FETCH_LEADERBOARD_RESULTS_START
    }
}

export function fetchSuccess(leader_board_results) {
    return {
        type: FETCH_LEADERBOARD_RESULTS_SUCCESS,
        leader_board_results,
    }
}

export function fetchError(e) {
    return {
        type: FETCH_LEADERBOARD_RESULTS_ERROR,
        error: e,
    }
}
