import {
    FETCH_USERME_START,
    FETCH_USERME_SUCCESS,
    FETCH_USERME_ERROR,
    FETCH_USERMESUBSCRIPTION_SUCCESS,
    FETCH_USERME_EXERCISES_COUNT_SUCCESS,
    FETCH_USERME_EXIT,
    FAST_REGISTATION_USER
} from '../actionTypes'

const initialState = {
    userMe: null,
    userMeSubscription: null,
    exercises_count: 0,
    loading: false,
    fast_reg: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USERME_START:
            return {
                ...state, loading: true
            }
        case FETCH_USERME_SUCCESS:
            return {
                ...state, loading: false,
                userMe: action.userMe,
            }
        case FETCH_USERMESUBSCRIPTION_SUCCESS:
            return {
                ...state, loading: false,
                userMeSubscription: action.userMe,
            }
        case FETCH_USERME_EXERCISES_COUNT_SUCCESS:
            return {
                ...state, loading: false,
                exercises_count: action.userMe,
            }
        case FETCH_USERME_ERROR:
            return {
                ...state, loading: false
            }
        case FETCH_USERME_EXIT:
            return {
                ...state,
                userMe: null,
                userMeSubscription: null
            }
        case FAST_REGISTATION_USER:
            return {
                ...state, fast_reg: action.payload
            }
        default: return state
    }
}