const initialState = {
    visible: false,
    type: '',
    data: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case 'setVisible':
            return {
                ...state,
                visible: !state.visible,
                type: action.payload,
                data: action.data
            }
        case 'closeModal':
            return {
                ...state,
                visible: false,
                type: '',
                data: null
            }
        case 'changeType':
            return {
                ...state,
                type: action.payload,
            }
        default: return state
    }
}