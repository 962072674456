import { api_likes } from 'API/likes'
import { FETCH_LIKESUSERS_START, FETCH_LIKESUSERS_SUCCESS, FETCH_LIKESUSERS_ERROR } from '../actionTypes'

export function fetchLikesUsers() {
    return async dispatch => {
        dispatch(fetchFAQStart())
        try {
            const likes = await api_likes.ReadeLikesUsers()
            dispatch(fetchFAQSuccess(likes.data))
        } catch (e) {
            dispatch(fetchFAQError(e))
        }
    }
}

export function fetchFAQStart() {
    return {
        type: FETCH_LIKESUSERS_START
    }
}

export function fetchFAQSuccess(likes) {
    return {
        type: FETCH_LIKESUSERS_SUCCESS,
        likes,
    }
}

export function fetchFAQError(e) {
    return {
        type: FETCH_LIKESUSERS_ERROR,
        error: e,
    }
}
