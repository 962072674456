import { api_exercises } from 'API/exercises'
import { FETCH_JOINTEXERCISES_START, FETCH_JOINTEXERCISES_SUCCESS, FETCH_JOINTEXERCISES_ERROR } from '../actionTypes'

export function fetchJointExercises(id) {
    return async dispatch => {
        dispatch(fetchExercisesStart())
        try {
            const joint_exercises = await api_exercises.ReadeJointExercises(id)
            dispatch(fetchExercisesSuccess(joint_exercises.data))
        } catch (e) {
            dispatch(fetchExercisesError(e))
        }
    }
}

export function fetchExercisesStart() {
    return {
        type: FETCH_JOINTEXERCISES_START
    }
}

export function fetchExercisesSuccess(joint_exercises) {
    return {
        type: FETCH_JOINTEXERCISES_SUCCESS,
        joint_exercises,
    }
}

export function fetchExercisesError(e) {
    return {
        type: FETCH_JOINTEXERCISES_ERROR,
        error: e,
    }
}
