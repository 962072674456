import { copy } from "functions/generalFunction";

export const createGame = (values, create_task) => {
    // eslint-disable-next-line default-case
    switch (create_task.task_type) {
        case 'TYPE_MATCH_ELEMENTS':
            return createSimpleGame(values, create_task)
        case 'TYPE_QUIZ':
            return createQuizeGame(values, create_task)
        case 'TYPE_CARDS':
            return createhardGame(values, create_task)
        case 'TYPE_ANAGRAM':
            return createhardGame(values, create_task)
        case 'TYPE_INSERT_LETTER':
            return createInserLetterGame(values, create_task)
        case 'TYPE_FIND_COUPLE':
            return createhardGame(values, create_task)
        case 'TYPE_OPEN_WINDOW':
            return createhardGame(values, create_task)
        case 'TYPE_WRITE_ANSWER':
            return createhardGame(values, create_task)
        case 'TYPE_GROUPS_DIVISION':
            return createSimpleGame(values, create_task)
        case 'TYPE_YES_OR_NOT':
            return createYesOrNotGame(values, create_task)
        case 'TYPE_CUBES':
            return createCardsGame(values, create_task)
        case 'TYPE_SPACE_MATHEMATICS':
            return createCpaseMathemathicsGame(values, create_task)
        case 'TYPE_INSERT_LETTER_SECOND':
            return createInsertLetterSecondGame(values, create_task)
        case 'TYPE_CROSSWORD':
            return createCrosswordPuzzleGame(values, create_task)
        case 'TYPE_CHECK_YOURSELF':
            return createhardGame(values, create_task)
        
    }
}

const createSimpleGame = (values, create_task) => {
    let object = {}
    object['name'] = create_task.create_task.name
    object['game'] = {}
    object.game['tasks'] = create_task.create_task.tasks
    if (create_task.create_task.task) object.game['task'] = create_task.create_task.task
    object['game_type'] = create_task.task_type
    object['background'] = create_task.background
    object['is_published'] = false
    return object
}

const createInserLetterGame = (values, create_task) => {
    let object = {}
    object['name'] = create_task.create_task.name
    object['game'] = {}
    object.game['tasks'] = {}
    object.game.tasks['answers'] = create_task.create_task.tasks
    object.game.tasks['question'] = create_task.create_task.question
    object['game_type'] = create_task.task_type
    object['background'] = create_task.background
    object['is_published'] = false
    return object
}

const createQuizeGame = (values, create_task) => {
    let copyArray = copy(create_task)
    let data = new FormData();
    copyArray.create_task.tasks.forEach(async (item, key) => {
        let task_answers = []
        if (item.text === undefined || item.text === "") { copyArray.create_task.tasks[key].text = null }
        if (item.photo !== null) {
            if (item.photo === undefined) {
                copyArray.create_task.tasks[key].photo = null
            }
        }
        for (let i = 1; i < 5; i++) {
            if (item[`answer${i}`] !== undefined || item[`photo${i}`] !== undefined) {
                if (item[`check${i}`] === undefined) item[`check${i}`] = false
                task_answers.push({
                    answer: item[`answer${i}`],
                    is_right: item[`check${i}`],
                    photo: item[`photo${i}`]
                })
            }
            delete copyArray.create_task.tasks[key][`answer${i}`]
            delete copyArray.create_task.tasks[key][`check${i}`]
            delete copyArray.create_task.tasks[key][`photo${i}`]
        }

        copyArray.create_task.tasks[key]['task_answers'] = task_answers
    })
    return createSimpleGame(values, copyArray)
}

const createhardGame = (values, create_task) => {
    let copyArray = copy(create_task)
    copyArray.create_task.tasks.forEach((item, key) => {
        if (item.text === undefined || item.text === "") { copyArray.create_task.tasks[key].text = null }
        if (item.photo !== null) {
            if (item.photo === undefined) {
                copyArray.create_task.tasks[key].photo = null
            }
        }
        if ('answer_photo' in item) {
            if (item.photo === undefined) {
                copyArray.create_task.tasks[key].photo = null
            }
        }
    })
    return createSimpleGame(values, copyArray)
}

const createYesOrNotGame = (values, create_task) => {
    return {
        name: create_task.create_task.name,
        game: {
            notAnswers: create_task.create_task.notAnswers,
            yesAnswers: create_task.create_task.yesAnswers
        },
        game_type: create_task.task_type,
        background: create_task.background,
        is_published: false
    }
}

const createCardsGame = (values, create_task) => {
    return {
        name: create_task.create_task.name,
        game: { tasks: create_task.create_task.tasks },
        game_type: create_task.task_type,
        background: create_task.background,
        is_published: false
    }
}

const createCpaseMathemathicsGame = (values, create_task) => {
    let copyArray = copy(create_task)
    copyArray.create_task.tasks.forEach(async (item, key) => {
        let task_answers = []
        if (item.text === undefined || item.text === "") { copyArray.create_task.tasks[key].text = null }
        for (let i = 1; i < 7; i++) {
            if (item[`answer${i}`] !== undefined) {
                if (item[`check${i}`] === undefined) item[`check${i}`] = false
                task_answers.push({
                    answer: item[`answer${i}`],
                    is_right: item[`check${i}`]
                })
            }
            delete copyArray.create_task.tasks[key][`answer${i}`]
            delete copyArray.create_task.tasks[key][`check${i}`]
        }
        copyArray.create_task.tasks[key]['task_answers'] = task_answers
    })
    return createSimpleGame(values, copyArray)
}

const createInsertLetterSecondGame = (values, create_task) => {
    return {
        name: create_task.create_task.name,
        game: { tasks: create_task.create_task.tasks },
        game_type: create_task.task_type,
        background: create_task.background,
        is_published: false
    }
}

const createCrosswordPuzzleGame = (values, create_task) => {
    return {
        name: create_task.create_task.name,
        game: { tasks: create_task.create_task.tasks },
        game_type: create_task.task_type,
        background: create_task.background,
        is_published: false
    }
}