import './style.css'

const DrawerBlock = ({
  children,
  active,
  onClose
}) => {

  return (
    <>
      <div className={`drawerBlock ${active !== null && (active ? 'active' : 'notActive')}`}>
        {children}
      </div>
      {active && <div className='drawerBlock_bg' onClick={onClose} />}
    </>
  )
}

export default DrawerBlock