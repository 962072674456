/* eslint-disable react-hooks/exhaustive-deps */
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';

import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';


import { copy } from "functions/generalFunction";
import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

function OpenWindow(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState([[]]) /*список окон для каждой страницы */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState(0) /*Массив ответов - выбранных окон */

    const [activePage, setActivePage] = useState(0) /* отображаемая страница */
    const [windowsStatus, setWindowsStatus] = useState([]) /*Статус для каждого окна (открыто оно или нет) */
    const [modal, setModal] = useState(false) /*Показывать модальное окно с вопросом */

    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/

    const maxWindows = 8 //количество окон на странице

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        let subarray = [];
        const supStatusArray = []
        for (let i = 0; i < Math.ceil(props.one_exercises.game.tasks.length / maxWindows); i++) {
            subarray[i] = props.one_exercises.game.tasks.slice((i * maxWindows), (i * maxWindows) + maxWindows);
        }
        for (let i = 0; i < subarray.length; i++) {
            const array = []
            for (let j = 0; j < subarray[i].length; j++) {
                array.push(false)
            }
            supStatusArray.push(array)
        }
        setWindowsStatus(supStatusArray)
        setTasks(subarray);
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    useEffect(() => {
        if (tasks.length === 0) {
            audio.current.pause()
            onClickFullScreen(false)
        }
    }, [activeTask])

    const refreshPage = () => {
        const windowsStatusCopy = copy(windowsStatus) //клонирование массива статусов
        for (let i = 0; i < windowsStatusCopy.length; i++) {
            for (let j = 0; j < windowsStatusCopy[i].length; j++) {
                windowsStatusCopy[i][j] = false
            }
        }
        setAnswers(0)
        setWindowsStatus(windowsStatusCopy)
    }

    const openWindowClick = (key) => {
        const windowsStatusCopy = copy(windowsStatus) //клонирование массива статусов
        if (!windowsStatusCopy[activePage][key]) {
            setAnswers(answers + 1)
        }
        windowsStatusCopy[activePage][key] = true
        setActiveTask(key)
        setWindowsStatus(windowsStatusCopy)
        setModal(true)
    }

    const nextTask = () => {
        if (activePage + 1 < tasks.length) {
            setActivePage(activePage + 1)
        }
    }

    const backTask = () => {
        if (activePage !== 0) {
            setActivePage(activePage - 1)
        }
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        tasks.length > 0 &&
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                <div className="gameBlock_header" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
                    <p className="gameBlock_header__title" style={{margin: 0}}>{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                    <p className="gameBlock_header__dascription">Коснитесь одного окна, чтобы открыть</p>
                </div>
                <div className="gameBlock_content">
                    <div className="openWindowBlock">
                        {tasks[activePage].map((window, key) => (
                            <div className="window" onClick={() => openWindowClick(key)}>
                                <p className="window_number">{key + 1 + (activePage) * maxWindows}</p>
                                <div className="window_doors">
                                    <div className={`window_door_1 ${windowsStatus[activePage][key] ? 'active' : 'notactive'}`}></div>
                                    <div className={`window_door_2 ${windowsStatus[activePage][key] ? 'active' : 'notactive'}`}></div>
                                    {/* <img src={leftDoor} alt="leftDoor" className={`window_door_1 ${windowsStatus[activePage][key] ? 'active' : 'notactive'}`} />
                                    <img src={rightDoor} alt="rightDoor" className={`window_door_2 ${windowsStatus[activePage][key] ? 'active' : 'notactive'}`} /> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="gameBlock_footer">
                    <div className="gameBlock_footer__steps">
                        <img
                            src={activePage !== 0 ? circleLeftActive : circleLeft}
                            alt="circleLeft"
                            onClick={backTask}
                        />
                        <p>{activePage + 1} из {tasks.length}</p>
                        <img
                            src={activePage + 1 < tasks.length ? circleRightActive : circleRight}
                            alt="circleRight"
                            onClick={nextTask}
                        />
                    </div>
                    <div className="gameBlock_footer__options">
                        {soundPlay
                            ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                            : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                        }
                        {(isFullscreenEnabled || isFullscreenEnabledClass)
                            ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                            : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                        }
                    </div>
                </div>
                <div className={`openWindowAnswer ${answers > 0 ? (modal ? 'active' : 'notactive') : ''}`}>
                    <div className="openWindowAnswer_content">
                        {tasks[activePage].length > 0
                            ? tasks[activePage][activeTask].photo !== null
                                ? <div>
                                    <img src={tasks[activePage][activeTask].photo} alt="img" />
                                    <p>{tasks[activePage][activeTask].text}</p>
                                </div>
                                : <div><p>{tasks[activePage][activeTask].text}</p></div>
                            : null
                        }
                    </div>
                    {answers === props.one_exercises.game.tasks.length
                        ? <div className='openWindowAnswer_content_buttons'>
                            <button onClick={() => setModal(false)}>Закрыть</button>
                            <button onClick={refreshPage}>Начнём сначала?</button>
                        </div>
                        : <button onClick={() => setModal(false)}>Закрыть</button>
                    }
                </div>
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(OpenWindow);
