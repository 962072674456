import { combineReducers } from 'redux';

import achievements from './reducers/achievements';
import all_exercises from './reducers/allExercises';
import create_task from './reducers/createTask';
import delete_exercise from './reducers/deleteExercis';
import exercises from './reducers/exercises';
import faq from './reducers/faq';
import folders from './reducers/folders';
import game from './reducers/game';
import groups from './reducers/groups';
import joint_exercises from './reducers/jointExercises';
import leader_board from './reducers/leaderBoard';
import leader_board_results from './reducers/leaderboardResults';
import likes_users from './reducers/likesUsers';
import login from './reducers/login';
import modal from './reducers/modal';
import one_exercises from './reducers/oneExercise';
import one_folder from './reducers/oneFolder';
import user from './reducers/oneUser';
import participant_exercises from './reducers/participantExercises';
import pdf from './reducers/pdf';
import search from './reducers/search';
import search_exercises from './reducers/searchExercises';
import search_images from './reducers/searchImages';
import users from './reducers/searchUsers';
import search_hashtags from './reducers/serchHastags';
import step from './reducers/stepCount';
import subscriptions from './reducers/subscriptions';
import userMe from './reducers/userMe';

export default combineReducers({
    modal,
    login,
    search,
    userMe,
    folders,
    one_folder,
    exercises,
    create_task,
    all_exercises,
    game,
    one_exercises,
    step,
    delete_exercise,
    faq,
    users,
    user,
    search_exercises,
    search_hashtags,
    likes_users,
    leader_board,
    leader_board_results,
    achievements,
    participant_exercises,
    groups,
    search_images,
    joint_exercises,
    pdf,
    subscriptions,
})