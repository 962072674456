import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchSubscriptions } from 'store/actions/subscriptions';

import background from 'assets/backgrounds/main.png';
import SubscriptionPrice from 'components/components/subscriptionPrice/SubscriptionPrice';

import './style.css';

function SubscriptionStandartPlus({ subscriptions, fetchSubscriptions, subscriptionType }) {
  const { t } = useTranslation();

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <>
      <img src={background} alt='background' className='mainImg' />
      <div className='subscriptionBlock'>
        <p className='subscriptionBlock_title'>{t(`subscriptions.title`)}</p>
        <div className='pricesBlock'>
          {subscriptions.map((item) => {
            if (subscriptionType === item.name)
              return <SubscriptionPrice key={`subscription_${item.id}`} item={item} />;
          })}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    subscriptions: state.subscriptions.subscriptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSubscriptions: () => dispatch(fetchSubscriptions()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionStandartPlus);
