import { Button, Form, Input, message } from 'antd';
import { copy } from 'functions/generalFunction';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import delete_icon from 'assets/delete.png';
import ArrowsOutCardinal from 'assets/forms/ArrowsOutCardinal.svg';
import Copy from 'assets/forms/Copy.svg';
import Trash from 'assets/forms/Trash.svg';
import imageIcon from 'assets/imageIcon.svg';
import purpulePlus from 'assets/purpulePlus.svg';

import '../media.css';
import '../style.css';
import styles from './checkYourself.module.css';
import './media.css';

const DragHandle = sortableHandle(() => <span><img src={ArrowsOutCardinal} alt="ArrowsOutCardinal" /></span>);

const SortableItem = sortableElement(({ children }) => (
    children
));

const SortableContainer = sortableContainer(({ children }) => {
    return <ol style={{ padding: 0 }}>{children}</ol>;
});


function CheckYourself({
    create_task,
    image_url,
    saveTaskForm,
    deleteTaskForm,
    stepUp,
    stepDown,
    setVisible,
    SELECT_SEARCHIMAGE
}) {
    const [images, setImages] = useState([])
    const [answersImages, setAnswersImages] = useState([])
    const [position, setPosition] = useState(null)
    const [fields, setFields] = useState([])
    const [form] = Form.useForm();
    const tasks = Form.useWatch('tasks', form);
    const { t } = useTranslation();

    useEffect(() => {
        const arr = []
        const answersImagesArray = []
        if (create_task.create_task !== null) {
            create_task.create_task.tasks.forEach((item, key) => {
                if (item.photo === 'no_photo' || item.photo === null) {
                    arr.push(undefined)
                } else {
                    arr.push(item.photo)
                }
                answersImagesArray.push(item.answer_photo || null)
            })
            setFields([
                {
                    name: ['name'],
                    value: create_task.create_task.name,
                },
                {
                    name: ['tasks'],
                    value: create_task.create_task.tasks,
                },
                {
                    name: ['hashtags'],
                    value: (create_task.create_task.hashtags.length === 1 || create_task.create_task.hashtags.length === 0)
                        ? create_task.create_task.hashtags
                        : [{
                            first_hashtag: create_task.create_task.hashtags[0],
                            second_hashtag: create_task.create_task.hashtags[1],
                            third_hashtag: create_task.create_task.hashtags[2],
                            fourth_hashtag: create_task.create_task.hashtags[3]
                        }],
                },
            ])
        }
        setImages(arr)
        setAnswersImages(answersImagesArray)
    }, [])

    useEffect(() => {
        if (image_url) {
            saveImageOnForm(position.key, position.name, position.type, image_url)
            SELECT_SEARCHIMAGE('')
        }
    }, [image_url])

    useEffect(() => {
        return () => {
            SELECT_SEARCHIMAGE('')
        };
    }, []);

    const saveImageOnForm = (key, name, type, data) => {
        if (type !== undefined) {
            let imagesCopy = JSON.parse(JSON.stringify(answersImages))
            imagesCopy[key] = data
            if (data !== '') {
                const tasks_form = tasks
                tasks_form[name][type] = data
                form.setFieldsValue({
                    tasks: tasks_form
                });
                setAnswersImages(imagesCopy)
            }
        } else {
            const array = copy(images)
            array[key] = data
            if (data !== '') {
                const tasks_form = tasks
                tasks_form[name].photo = data
                form.setFieldsValue({
                    tasks: tasks_form
                });
                setImages(array)
            }
        }
    }

    const onFinish = (values) => {
        let count = 0
        if (values?.hashtags?.length) {
            values.hashtags = [
                values.hashtags[0].first_hashtag,
                values.hashtags[0].second_hashtag,
                values.hashtags[0].third_hashtag,
                values.hashtags[0].fourth_hashtag,
            ]
        }
        if (values.tasks !== undefined && values.tasks.length !== 0) {
            values.tasks.forEach((item, key) => {
                if ((item.text === undefined || item.text === '') && (item.photo === undefined)) {
                    message.error(`Заполните поле 'Вопрос/ответ' в элементе ${key + 1}`)
                    count++
                }
            })
            if (count === 0) {
                saveTaskForm(values)
                stepUp()
            }
        } else {
            message.error("Вопросы отсутствуют!")
        }
    };

    const goBack = () => {
        deleteTaskForm()
        stepDown()
    }

    const onSortEnd = (oldIndex, newIndex, move) => {
        move(oldIndex, newIndex)
    };

    const SelectPhoto = (key, name, type) => {
        setPosition({ key, name, type })
        setVisible('searchImages')
    }

    const deletePhoto = (key, name, type) => {
        if (type !== undefined) {
            saveImageOnForm(key, name, type, undefined)
        } else {
            saveImageOnForm(key, name, undefined)
        }
    }

    const onCopy = (key, name, add) => {
        const arr = copy(images)
        const imagesCopy = JSON.parse(JSON.stringify(answersImages))
        arr.push(arr[key])
        imagesCopy.push(imagesCopy[key])
        setImages(arr)
        setAnswersImages(imagesCopy)
        add(tasks[name], name + 1)
    }

    const onAdd = (add) => {
        const arr = copy(images)
        arr.push(undefined)
        setImages(arr)
        setAnswersImages([...answersImages, null])
        add({ photo: undefined })
    }

    const onDelete = (key, name, remove) => {
        const imagesCopy = JSON.parse(JSON.stringify(answersImages))
        imagesCopy[key] = null
        setAnswersImages(imagesCopy)
        remove(name)
    }

    return (
        <Form
            name="basic"
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            fields={fields}
            autoComplete="off"
            className="modalForm createTaskForm"
            form={form}
        >
            <h2>Хеш-теги</h2>
            <Form.List name="hashtags">
                {(fields, { add, remove, move }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <>
                                <p>Обязательно нужно указать возраст, класс и предмет</p>
                                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 24, marginTop: 12 }} key={`hashtag-${name}`}>
                                    <Form.Item
                                        {...restField}
                                        label={''}
                                        name={[name, 'first_hashtag']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Обязательное поле',
                                            },
                                        ]}
                                    >
                                        <Input className="formInput" prefix="#" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        label={''}
                                        name={[name, 'second_hashtag']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Обязательное поле',
                                            },
                                        ]}
                                    >
                                        <Input className="formInput" prefix="#" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        label={''}
                                        name={[name, 'third_hashtag']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Обязательное поле',
                                            },
                                        ]}
                                    >
                                        <Input className="formInput" prefix="#" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        label={''}
                                        name={[name, 'fourth_hashtag']}
                                        rules={[
                                            {
                                                required: false,
                                                message: t(`games.title_error`),
                                            },
                                        ]}
                                    >
                                        <Input className="formInput" prefix="#" />
                                    </Form.Item>
                                    <img src={Trash} alt="Trash" onClick={() => remove(name)} style={{ cursor: 'pointer' }} />
                                </div>
                            </>
                        ))}
                        {fields.length == 0 &&
                            <Form.Item>
                                <Button
                                    type="link"
                                    onClick={add}
                                >
                                    <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />Добавить хеш-теги</p>
                                </Button>
                            </Form.Item>
                        }
                    </>
                )}
            </Form.List>
            <h2>Задание</h2>
            <Form.Item
                label={t(`games.title`)}
                name="name"
                rules={[
                    {
                        required: true,
                        message: t(`games.title_error`),
                    },
                ]}
            >
                <Input className="formInput" placeholder='Введите название' />
            </Form.Item>
            <Form.List name="tasks">
                {(fields, { add, remove, move }) => (
                    <>
                        <SortableContainer onSortEnd={(e) => onSortEnd(e.oldIndex, e.newIndex, move)} useDragHandle>
                            {fields.map(({ key, name, ...restField }) => (
                                <SortableItem key={`item-${name}`} index={name} >
                                    <li index={key}>
                                        <div key={key}>
                                            <div>
                                                <div>
                                                    <div className='quizesQuestionBlock find_couple_questions'>
                                                        <div className={styles.form__columnInputs}>
                                                            <div className='form_matchElementquestionBlock__item'>
                                                                <p>Вопрос</p>
                                                                <div className='quizesQuestionBlock_uploadBlock'>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'text']}
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                // message: t(`games.title_error`),
                                                                            },
                                                                        ]}
                                                                        style={{ width: '100%' }}
                                                                        className={styles.form__columnInputs_item}
                                                                    >
                                                                        <Input className="formInput" placeholder={t(`games.quizes.question`)} bordered={false} />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'photo']}
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                            },
                                                                        ]}
                                                                        className={styles.form__columnInputs_item}
                                                                    >
                                                                        <Input
                                                                            id={`photoInput${key}`}
                                                                            className='quizesQuestionBlock_upload'
                                                                            bordered={false}
                                                                            suffix={
                                                                                <div>
                                                                                    <img className='customUploadIcon' src={images[key] === undefined ? imageIcon : images[key]} alt="imageIcon" onClick={() => SelectPhoto(key, name)} />
                                                                                    {images[key] !== undefined && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto(key, name)} />}
                                                                                </div>
                                                                            }
                                                                            style={{ width: '10%' }}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='form_matchElementquestionBlock__item'>
                                                                <p>Ответ</p>
                                                                <div className='quizesQuestionBlock_uploadBlock'>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'answer']}
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                // message: t(`games.title_error`),
                                                                            },
                                                                        ]}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <Input className="formInput" placeholder={t(`games.quizes.answer`)} bordered={false} />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'answer_photo']}
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id={`photoInput${key}`}
                                                                            className='quizesQuestionBlock_upload'
                                                                            bordered={false}
                                                                            suffix={
                                                                                <div>
                                                                                    <img className='customUploadIcon' src={!answersImages[key] ? imageIcon : answersImages[key]} alt="imageIcon" onClick={() => SelectPhoto(key, name, 'answer_photo')} />
                                                                                    {answersImages[key] && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto(key, name, 'answer_photo')} />}
                                                                                </div>
                                                                            }
                                                                            style={{ width: '10%' }}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='form_questionOption needMargin'>
                                                            <DragHandle />
                                                            <img src={Copy} alt="Copy" onClick={() => onCopy(key, name, add)} />
                                                            <img src={Trash} alt="Trash" onClick={() => remove(name)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </SortableItem>
                            ))}
                            <Form.Item>
                                <Button
                                    type="link"
                                    onClick={() => onAdd(add)}
                                >
                                    <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />{t(`games.addRow`)}</p>
                                </Button>
                            </Form.Item>
                        </SortableContainer>
                    </>
                )}
            </Form.List>
            <Form.Item className='createTaskForm_buttons'>
                <span className='editProfileButton' onClick={goBack}>{t(`games.backButton`)}</span>
                <button htmltype="submit" className='editProfileButton'>{t(`games.saveButton`)}</button>
            </Form.Item>
        </Form>
    );
};

function mapStateToProps(state) {
    return {
        create_task: state.create_task,
        image_url: state.search_images.image_url
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        deleteTaskForm: () => dispatch({ type: 'deleteTaskForm' }),
        stepUp: () => dispatch({ type: 'stepUp' }),
        stepDown: () => dispatch({ type: 'stepDown' }),
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        SELECT_SEARCHIMAGE: (image_url) => dispatch({ type: 'SELECT_SEARCHIMAGE', image_url }),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(CheckYourself);