import { countScore } from 'functions/countScore';
import { tableleaderboardResultContent, tableResultContent, tableResultHeader } from 'functions/tableResult';
import './style.css';

function TableResult({ tableData, game_type, page }) {
    return (
        <div className="customTableBlock">
            <div className={page === 'leader_board_results' ? 'customTableBlock_header customTableBlock_header_leader_board_results' : 'customTableBlock_header'} >
                <p>Всего баллов: {countScore(tableData.result, 'max')}</p>
                <p>Набранно баллов: {countScore(tableData.result, 'actual')}</p>
                <p>Время: {tableData.time}</p>
            </div>
            <div className='customTableBlock_table__mainBlock'>
            <div className='customTableBlock_table'>
                <div className='customTableBlock_table__header'></div>
                <div className='customTableBlock_table__row'></div>
                <table
                    bgcolor="white"
                    width={'100%'}
                    className="customTableBlock_table"
                >
                    {tableResultHeader(game_type)}
                    {tableData.result.map((item, key) => {
                        if(page === 'leader_board_results') {
                            return tableleaderboardResultContent(game_type, item, key)
                        } else {
                            return tableResultContent(game_type, item, key)
                        }
                    })}
                </table>
            </div>
            </div>
        </div>
    );
}

export default TableResult;