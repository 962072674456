
import './style.css';

function SpaceMathematics({ game }) {
    return (
        <div className='exercisesCardBg_content'>
            <div className='spaceMathematicsBg'>
                <div className="starsBlock leftStars">
                    <div className="leftStars_item"><p>{game[0].task_answers[0]?.answer}</p></div>
                    <div className="leftStars_item"><p>{game[0].task_answers[1]?.answer}</p></div>
                    <div className="leftStars_item"><p>{game[0].task_answers[2]?.answer}</p></div>
                </div>
                <div className="startShip">
                    <p>{game[0].text}</p>
                </div>
                <div className="starsBlock rightStars">
                    <div className="rightStars_item"><p>{game[0].task_answers[3]?.answer}</p></div>
                    <div className="rightStars_item"><p>{game[0].task_answers[4]?.answer}</p></div>
                    <div className="rightStars_item"><p>{game[0].task_answers[5]?.answer}</p></div>
                </div>
            </div>
        </div>
    );
}

export default SpaceMathematics;
