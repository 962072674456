import { api_exercises } from 'API/exercises'
import { getQueryStringFromObject } from 'functions/filter'
import { FETCH_EXERCISES_ERROR, FETCH_EXERCISES_START, FETCH_EXERCISES_SUCCESS } from '../actionTypes'

export function fetchExercises(type, text) {
    return async dispatch => {
        dispatch(fetchExercisesStart())
        try {
            let obj = {}
            obj[type] = text
            const params = getQueryStringFromObject(JSON.stringify(obj))
            const exercises = await api_exercises.ReadeExercises(params)
            dispatch(fetchExercisesSuccess(exercises.data))
        } catch (e) {
            dispatch(fetchExercisesError(e))
        }
    }
}

export function fetchToggleHashtag(id, hashtag_id) {
    return async dispatch => {
        try {
            await api_exercises.ToggleExerciseHashtags(id, hashtag_id)
        } catch (e) {
        }
    }
}

export function fetchExercisesStart() {
    return {
        type: FETCH_EXERCISES_START
    }
}

export function fetchExercisesSuccess(exercises) {
    return {
        type: FETCH_EXERCISES_SUCCESS,
        exercises,
    }
}

export function fetchExercisesError(e) {
    return {
        type: FETCH_EXERCISES_ERROR,
        error: e,
    }
}
