import { useEffect, useState } from "react"
import './style.css'

const YesOrNot = ({ game }) => {
    const [tasks, setTasks] = useState([])

    useEffect(() => {
        const propsTasks = [...JSON.parse(JSON.stringify(game.notAnswers)), ...JSON.parse(JSON.stringify(game.yesAnswers))]
        setTasks(propsTasks.sort(() => Math.random() - 0.5))
    }, [])
    
    return (
        <div className='Pdf_task yesOrNot_pdf'>
            {tasks.map((el, index) => (
                <div key={`pdf${index}`}>
                    <div className="yesOrNot_pdf_item">
                        <div className="yesOrNot_pdf_item__content">
                            {el.photo
                                ?
                                <div className="yesOrNot_pdf_item__content">
                                    <img src={el.photo} alt="img" />
                                    <p className="yesOrNot_questionBlock__textUnderImg">{el.text}</p>
                                </div>
                                : <p>{el.text}</p>
                            }
                        </div>
                        <div className="yesOrNot_pdf_item__circles">
                            <div className="yesOrNot_pdf_item__circles__item">
                                <div className="pdfCircle" />
                                <p>Да</p>
                            </div>
                            <div className="yesOrNot_pdf_item__circles__item">
                                <div className="pdfCircle" />
                                <p>Нет</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default YesOrNot