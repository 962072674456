import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


import { VideoBlock } from "components/components/videoBlock/VideoBlock";
import './media.css';
import './style.css';

function MainBlock() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const anchorLink = () => {
        setTimeout(() => {
            const element = document.getElementById('main_video_block');
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }, 0);
    }

    return (
        <div className="mainBlock">
            <div className="mainBlock_text">
                <p className="mainBlock_title">{t(`mainBlock.title`)}</p>
                <p className="mainBlock_description">{t(`mainBlock.description`)}</p>
                <button className="mainBlock_actions__button" onClick={() => navigate(`patterns/`)}>{t(`mainBlock.button`)}</button>
            </div>
            <div className="mainBlock_video">
                <div className="mainBlock_video__player">
                    <VideoBlock />
                </div>
            </div>
        </div>
    );
}

export default MainBlock;
