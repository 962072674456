import Instruction from './mainBlocks/Instruction';
import MainBlock from './mainBlocks/MainBlock';
import Services from './mainBlocks/Services';

import './style.css';

function Main() {
    return (
        <div className='mainPageBlock'>
            <MainBlock />
            <Instruction />
            <Services />
        </div>
    );
}

export default Main;
