import { api_exercises } from 'API/exercises'
import { getQueryStringFromObject } from 'functions/filter'
import { FETCH_SEARCHEXERCISES_BY_HASHTAGS_SUCCESS, FETCH_SEARCHEXERCISES_ERROR, FETCH_SEARCHEXERCISES_START, FETCH_SEARCHEXERCISES_SUCCESS } from '../actionTypes'

export function fetchSearchExercises(type, text) {
    return async dispatch => {
        dispatch(fetchStart())
        try {
            let obj = {}
            obj[type] = text
            const params = getQueryStringFromObject(JSON.stringify(obj))
            const exercises = await api_exercises.SearchExercises(params)
            dispatch(fetchSuccess(exercises.data, type === 'hashtag_ids' ? FETCH_SEARCHEXERCISES_BY_HASHTAGS_SUCCESS : FETCH_SEARCHEXERCISES_SUCCESS))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchStart() {
    return {
        type: FETCH_SEARCHEXERCISES_START
    }
}

export function fetchSuccess(exercises, type) {
    return {
        type,
        exercises,
    }
}

export function fetchError(e) {
    return {
        type: FETCH_SEARCHEXERCISES_ERROR,
        error: e,
    }
}
