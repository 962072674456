import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { getRandomInt } from 'functions/generalFunction';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import HeadShake from 'react-reveal/HeadShake';
import ReactStopwatch from 'react-stopwatch';

import { copy } from "../../../functions/generalFunction";

import error from 'assets/error.svg';
import greenCheck from 'assets/greenCheck.svg';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

const bgArray = ['#ff7675', '#fdcb6e', '#00b894', '#cf6a87']

function FindCouple(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks.slice(0)) /*Задания */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState([]) /*Массив ответов */
    const [answersBg, setAnswersBg] = useState(Array(tasks.length))
    const [errorAnswers, setErrorAnswers] = useState(Array(tasks.length).fill(0)) /*Анимация неправильных ответов */
    const [loading, setLoading] = useState(false)
    const [isCurrent, setIsCurrent] = useState(true) /*Можно ли проверять ответ */
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState(null) /*Таймер*/
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        question: {
            photo:, фото вопроса
            text: текст вопроса
        },
        answers: , правильный ответ
        enter_answers: , выбранный ответ
        enter_answer_photo: , изображение выбранного ответа
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true можно ли изменять этот объект
        final_answer: дан финальный ответ
    */

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        const answersArray = []
        const arrayAnswersBg = []
        const resultArray = []
        tasks.forEach(el => {
            answersArray.push({
                answer: el.answer,
                answer_photo: el.answer_photo
            })
            arrayAnswersBg.push(bgArray[getRandomInt(bgArray.length - 1)])
            resultArray.push({
                question: {
                    photo: el.photo,
                    text: el.text
                },
                answers: {
                    answer: el.answer,
                    answer_photo: el.answer_photo
                },
                enter_answers: {
                    answer: null,
                    answer_photo: null
                },
                max_point: 1,
                actual_point: 0,
                active: true,
                final_answer: false
            })
        })
        setResult(resultArray)
        setLoading(true)
        setAnswers(answersArray.sort(() => Math.random() - 0.5))
        setAnswersBg(arrayAnswersBg)
        setActiveTask(0)
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            const greenCheck = document.getElementsByClassName('gameBlock_content__answers__step__greenCheck__findCouple')
            const errorCheck = document.getElementsByClassName('gameBlock_content__answers__step__errorCheck__findCouple')
            for(let i=0; i < greenCheck.length -1; i++){
                greenCheck[i].classList.remove('active')
            }
            for(let i=0; i < errorCheck.length -1; i++){
                errorCheck[i].classList.remove('active')
            }
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.currentTime = 0
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        temp.current()
    }, [props.restart])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    useEffect(() => {
        if (result.filter(el => el.final_answer).length === tasks.length) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            props.endGameResults(saveTime, result)
        }
    }, [result])

    const checkAnswer = (item, key) => {
        const greenCheck = document.getElementsByClassName('gameBlock_content__answers__step__greenCheck__findCouple')[key]
        const errorCheck = document.getElementsByClassName('gameBlock_content__answers__step__errorCheck__findCouple')
        let nextQuestion = false
        const resultCopy = copy(result)
        setIsCurrent(false)
        if ((tasks[activeTask].answer !== item.answer || item.answer === undefined) && (tasks[activeTask].answer_photo !== item.answer_photo || item.answer_photo === undefined)) {
            let array = errorAnswers
            array[key] += 1
            errorCheck[key].classList.add('active')
            if (resultCopy[activeTask].active) {
                resultCopy[activeTask].active = false
                resultCopy[activeTask].enter_answers = {
                    answer: item.answer,
                    answer_photo: item.answer_photo
                }
            }
        } else {
            greenCheck.classList.add('active')
            if (resultCopy[activeTask].active) {
                resultCopy[activeTask].active = false
                resultCopy[activeTask].enter_answers = {
                    answer: item.answer,
                    answer_photo: item.answer_photo
                }
                resultCopy[activeTask].actual_point = 1
            }
            resultCopy[activeTask].final_answer = true
            nextQuestion = true
        }
        setTimeout(() => {
            greenCheck.classList.remove('active')
            if (activeTask + 1 < tasks.length && nextQuestion) {
                setActiveTask(activeTask + 1)
                for (let i = 0; i < errorCheck.length; i++) {
                    errorCheck[i].classList.remove('active')
                }
            }
            setResult(resultCopy)
            setIsCurrent(true)
        }, 2000)
    }

    const onClickDontKnown = () => {
        const greenCheck = document.getElementsByClassName('gameBlock_content__answers__step__greenCheck__findCouple')[answers.findIndex(el => (el.answer === tasks[activeTask].answer && el.answer_photo === tasks[activeTask].answer_photo))]
        const errorCheck = document.getElementsByClassName('gameBlock_content__answers__step__errorCheck__findCouple')
        greenCheck.classList.add('active')
        const resultCopy = copy(result)
        resultCopy[activeTask].active = false
        resultCopy[activeTask].final_answer = true
        resultCopy[activeTask].enter_answers = {
            answer: 'Не знаю',
            answer_photo: null
        }
        setTimeout(() => {
            greenCheck.classList.remove('active')
            if (activeTask + 1 < tasks.length) setActiveTask(activeTask + 1)
            for (let i = 0; i < errorCheck.length; i++) {
                errorCheck[i].classList.remove('active')
            }
            setResult(resultCopy)
        }, 2000)
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        loading &&
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                <div className="gameBlock_header" style={{ flexDirection: 'column' }}>
                    <>
                        <div style={{
                            width: '100%',
                            position: 'relative',
                            height: 30,
                            alignItems: 'center'
                        }}>
                            {timer}
                            <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                            <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                                Не знаю
                            </div>
                        </div>
                        <p className="gameBlock_header__dascription">Подбери пару для каждого вопроса</p>
                    </>
                </div>
                <div className="gameBlock_content">
                    <div className="findCoupleBlock">
                        <div className="quizesBlock_question_text">
                            {tasks[activeTask].photo !== null
                                ?
                                <>
                                    <img src={tasks[activeTask].photo} alt="img" className="quizesBlock_question_img" />
                                    {tasks[activeTask].text && <p>{tasks[activeTask].text}</p>}
                                </>
                                : <p>{tasks[activeTask].text}</p>
                            }
                        </div>
                        <div className="findCoupleBlock_answers">
                            {answers.map((item, key) => (
                                <HeadShake spy={errorAnswers[key]} key={`answer${key}`}>
                                    <div
                                        className="findCoupleBlock_answers__item"
                                        style={{ backgroundColor: `${answersBg[key]}` }}
                                        onClick={() => { if (item.is_right !== 'success' && isCurrent) checkAnswer(item, key) }}
                                    >
                                        {item.answer_photo && <img src={item.answer_photo} alt='answer_photo' className='findCouple_answerPhoto' />}
                                        {item.answer}
                                        <img className="gameBlock_content__answers__step__greenCheck__findCouple" src={greenCheck} alt="greenCheck" />
                                        <img className="gameBlock_content__answers__step__errorCheck__findCouple" src={error} alt="greenCheck" />
                                    </div>
                                </HeadShake>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="gameBlock_footer">
                    <div className="gameBlock_footer__options">
                        {soundPlay
                            ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                            : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                        }
                        {(isFullscreenEnabled || isFullscreenEnabledClass)
                            ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                            : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                        }
                    </div>
                </div>
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(FindCouple);