import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, message } from 'antd';
import { api_user } from 'API/users';

import '../style.css';

function ResetPasswordForm({
    changeType,
    setVisible
}) {
    const { token } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation();

    const onFinish = async (values) => {
        if (values.password === values.yet_password) {
            const object = {
                token,
                new_password: values.password
            }
            await api_user.ResetPassword(object)
            .then(() => {
                message.success('Пароль успешно обнавлен!')
                navigate('/')
            })
        } else {
            message.error(t(`modal.registration.password_match`))
        }
    };

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };
    return (
        <>
            <p className="modalTitle">Восстановление пароля</p>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="modalForm"
            >
                <Form.Item
                    label={t(`modal.registration.password`)}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t(`modal.registration.password_error`),
                        },
                    ]}
                >
                    <Input.Password className="formInput" />
                </Form.Item>
                <Form.Item
                    label={t(`modal.registration.yet_password`)}
                    name="yet_password"
                    rules={[
                        {
                            required: true,
                            message: t(`modal.registration.yet_password_error`),
                        },
                    ]}
                >
                    <Input.Password className="formInput" />
                </Form.Item>
                <Form.Item>
                    <Button className="loginButton" htmlType="submit">Восстановить пароль</Button>
                </Form.Item>
            </Form>
        </>
    );
};

function mapDispatvhToProps(dispatch) {
    return {
        changeType: (text) => dispatch({ type: 'changeType', payload: text }),
        setVisible: () => dispatch({ type: 'closeModal' }),
    }
}



export default connect(null, mapDispatvhToProps)(ResetPasswordForm);