import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';

import greyCursor from 'assets/greyCursor.svg';
import noPlayers from 'assets/noPlayers.svg';

import { fetchLeaderBoardResultsById } from 'store/actions/leaderboardResults';
import './style.css';

function LeaderBoard({ leader_board, setVisible, closeModal, fetchLeaderBoardResultsById }) {
    const [watchLeaders, setWatchLEaders] = useState(3)
    const { t } = useTranslation();

    const onClickViewResult = (leaderboard_id, time) => {
        fetchLeaderBoardResultsById(leaderboard_id, 0, 100, time)
            .then(res => {
                if (res.length > 0) {
                    setVisible('results')
                } else {
                    message.warning('К сожалению у нас нет результатов этого пользователя!')
                }
            })
    }

    useEffect(() => {
        return () => closeModal()
    }, [])

    return (
        <div className="leaderBoard">
            <p className="leaderBoard_title">{t(`leaderBoard.title`)}</p>
            {leader_board.length > 0
                ? <div>
                    <div className="leaderBoard_scroll">
                        <table className="leaderBoard_table" cellSpacing="5px">
                            <tr className="leaderBoard_table__header">
                                <th>{t(`leaderBoard.table.rank`)}</th>
                                <th>{t(`leaderBoard.table.name`)}</th>
                                <th>{t(`leaderBoard.table.score`)}</th>
                                <th>{t(`leaderBoard.table.time`)}</th>
                                <th>{t(`table.players.action`)}</th>
                            </tr>
                            {leader_board.map((item, key) => {
                                if (key < watchLeaders) {
                                    return (
                                        <tr className="leaderBoard_table__item">
                                            <td>{key + 1}st</td>
                                            <td>{item.name}</td>
                                            <td>{item.score}</td>
                                            <td>{item.time}</td>
                                            <td>
                                                {/* <img src={eye} alt="eye" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => onClickViewResult(item.id, item.time)} /> */}
                                                <button
                                                    style={{
                                                        color: '#7C3AED',
                                                        fontWeight: 600,
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        padding: '8px 12px',
                                                        background: '#EDE9FE',
                                                        borderRadius: '8px',
                                                        marginRight: 5
                                                    }}
                                                    onClick={() => onClickViewResult(item.id, item.time)}
                                                >
                                                    Подробнее
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </table>
                    </div>
                    {leader_board.length > 3 && <p className="leaderBoard_seeMore" onClick={() => setWatchLEaders(500)}>{t(`leaderBoard.seeMore`)}<img src={greyCursor} alt="greyCursor" /></p>}
                </div>
                : <div className='leaderBoard_noresults'>
                    <img src={noPlayers} />
                    <p>На этом задании еще нет результатов!</p>
                </div>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        leader_board: state.leader_board.leader_board,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        closeModal: () => dispatch({ type: 'closeModal' }),
        fetchLeaderBoardResultsById: (leaderboard_id, skip, limit, time) => dispatch(fetchLeaderBoardResultsById(leaderboard_id, skip, limit, time))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(LeaderBoard);
