import { useTranslation } from "react-i18next";
import { declinationFolder, declinationTask } from "functions/decoder";

import purpuleAdd from 'assets/purpuleAdd.svg';
import addFolder from 'assets/addFolder.png'
import folder from 'assets/folder.png'

import './style.css';
import { useParams } from "react-router-dom";

function Folder({ type, onClick, item }) {
    const { t } = useTranslation();
    const { id } = useParams()

    return (
        <div className="folder">
            {(type === 'create' || type === 'create_subfolder')
                ?
                <div style={{ position: 'relative' }} onClick={() => onClick('createFolder', {parent_id: id})}>
                    <div className="folder_block__add">
                        <img src={purpuleAdd} alt="purpuleAdd" />
                        <p className="addFolder_title">{t(`myTask.add_folder`)}</p>
                    </div>
                    <img src={addFolder} alt="addFolder" className="folderHederImg" />
                </div>
                :
                <div style={{ position: 'relative' }} onClick={onClick}>
                    <div className="folder_block">
                        <p className="folder_block__title">{item.exercises_count} {declinationTask(item.exercises_count)}</p>
                        <p className="folder_block__subfolders">{item.subfolders_count} {declinationFolder(item.subfolders_count)}</p>
                    </div>
                    <img src={folder} alt="folder" className="folderHederImg" />
                    <p className="folder_title">{item.name}</p>
                </div>
            }
        </div>
    );
}

export default Folder;
