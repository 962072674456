import { api_folders } from 'API/folders'
import { FETCH_FOLDERS_START, FETCH_FOLDERS_SUCCESS, FETCH_FOLDERS_ERROR } from '../actionTypes'

export function fetchFolders() {
    return async dispatch => {
        dispatch(fetchFoldersStart())
        try {
            const folders = await api_folders.ReadeFolders()
            dispatch(fetchFoldersSuccess(folders.data))
        } catch (e) {
            dispatch(fetchFoldersError(e))
        }
    }
}

export function fetchFoldersStart() {
    return {
        type: FETCH_FOLDERS_START
    }
}

export function fetchFoldersSuccess(folders) {
    return {
        type: FETCH_FOLDERS_SUCCESS,
        folders,
    }
}

export function fetchFoldersError(e) {
    return {
        type: FETCH_FOLDERS_ERROR,
        error: e,
    }
}
