import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button, Result } from 'antd';
import { api_folders } from 'API/folders';

import '../style.css';

function DeleteFolder({
    one_folder,
    closeModal
}) {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const onFinish = async () => {
        await api_folders.DeleteOneFolder(one_folder.id)
        closeModal()
        navigate("../myTask")
    };

    return (
        <>
            <p className="modalTitle">{t(`modal.delete_folder.title`)}</p>
            <Result
                status="success"
                title={t(`modal.delete_folder.description`)}
                extra={[]}
                className="modalDeleteText"
            />
            <Button className="loginButton" onClick={onFinish}>{t(`modal.delete_folder.button`)}</Button>
        </>
    );
};

function mapStateToProps(state) {
    return {
        one_folder: state.one_folder.one_folder
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        closeModal: () => dispatch({ type: 'closeModal' })
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(DeleteFolder);