/* eslint-disable default-case */
import brown from 'assets/backgrounds/games/answers/brown_dark.svg';
import green from 'assets/backgrounds/games/answers/dark_green.png';
import purpule from 'assets/backgrounds/games/answers/dark_purpule.png';
import red from 'assets/backgrounds/games/answers/dark_red.png';

export const selectBackground = (backgrounds) => {
    switch (backgrounds) {
        case 'CLASSIC':
            return 'classic_bg'
        case 'NEUTRAL':
            return 'neutral_bg'
        case 'WINTER':
            return 'winter_bg'
        case 'SUMMER':
            return 'summer_bg'
        case 'AUTUMN':
            return 'autumn_bg'
        case 'SPACE':
            return 'space_bg'
        case 'SPRING':
            return 'spring_bg'
        case 'CLOUD':
            return 'cloud_bg'
        case 'BOOK':
            return 'book_bg'
        case 'PUZZLE':
            return 'puzzle_bg'
        case 'TREES':
            return 'story_bg'
        case 'STORY':
            return 'story_bg'
        case 'SCHOOL_BLACKBOARD':
            return 'schoolBlackboard_bg'
        case 'CHILDHOOD':
            return 'childhood_bg'
        case 'CELL':
            return 'cell_bg'
        case 'MUSIC':
            return 'music_bg'
        case 'FANTASY':
            return 'fantasy_bg'
        case 'NOTEBOOK':
            return 'notebook_bg'
    }
}

export const matchElementsTrueAnswer = (backgrounds) => {
    switch (backgrounds) {
        case 'classic_bg':
            return brown
        case 'neutral_bg':
            return green
        case 'winter_bg':
            return green
        case 'summer_bg':
            return purpule
        case 'autumn_bg':
            return red
        case 'space_bg':
            return purpule
        case 'spring_bg':
            return green
        case 'cloud_bg':
            return purpule
    }
}