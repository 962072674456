const initialState = {
    exercise_id: 0,
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case 'selectDeleteExercis':
            return {
                exercise_id: action.payload
            }
        default: return state
    }
}