import { 
    FETCH_SUBSCRIPTIONS_START, 
    FETCH_SUBSCRIPTIONS_SUCCESS, 
    FETCH_SUBSCRIPTIONS_ERROR 
} from '../actionTypes'

const initialState = {
    subscriptions: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SUBSCRIPTIONS_START:
            return {
                ...state, loading: true
            }
        case FETCH_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state, loading: false,
                subscriptions: action.subscriptions,
            }
        case FETCH_SUBSCRIPTIONS_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}