import conePurpleGlossy from 'assets/backgrounds/Cone-Purple-Glossy.svg';
import roundCubeBlueGlossy from 'assets/backgrounds/RoundCube-Blue-Glossy.svg';
import cloudTop from 'assets/backgrounds/cloud-top.svg';
import InstructionCard from 'components/components/instructionCard/InstructionCard';
import { instructions } from 'lists/mainInstructions';
import './instruction.css';

const Instruction = () => {
    return (
        <div className='mainInstructionBlock'>
            <img src={conePurpleGlossy} alt='icon' className='mainInstructionBlock_conePurpleGlossy' />
            <img src={roundCubeBlueGlossy} alt='icon' className='mainInstructionBlock_roundCubeBlueGlossy' />

            <img src={cloudTop} alt='cloudTop' className='servicesBlock_clouds' />
            <div className='mainInstructionBlock_content'>
                <div className='mainInstructionBlock_content__items'>
                    {instructions.map((el, key) => (
                        <InstructionCard key={`InstructionCard_${key}`} {...el} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Instruction