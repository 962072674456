import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import { footer, footerPrivacy } from "lists/list";
import logo from 'assets/logo.svg';

import './style.css';

function Footer() {
    const { t } = useTranslation();

    return (
        <div className="footer">
            <Link to="/">
                <img src={logo} alt="logo" />
            </Link>
            <div>
                <div className="footer_links">
                    {
                        footer.map((item, key) => {
                            return <Link to={item.link} className="linkFooter" key={key}><p key={key}>{t(`footer.${item.title}`)}</p></Link>
                        })
                    }
                </div>
                <div className="footer_links" style={{ marginTop: '15px' }}>
                    {
                        footerPrivacy.map((item, key) => {
                            return <Link to={{
                                pathname: item.link,
                                state: 'hello'
                            }}
                                className="linkFooter" key={`footerPrivacy${key}`}><p>{item.title}</p></Link>
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Footer;
