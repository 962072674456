import { api_groups } from 'API/groups';
import { Button, Form, Input } from 'antd';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { fetchGroups } from "store/actions/groups";

import '../style.css';

function CreateGroup({
  exerciseId,
  fetchGroups,
  closeModal
}) {
  const { t } = useTranslation();
  const onFinish = async (values) => {
    values['exercise_id'] = exerciseId
    await api_groups.CreateGroupsByExercise(values)
    fetchGroups(exerciseId)
    closeModal()
  };

  return (
    <>
      <p className="modalTitle">{t(`modal.create_group.title`)}</p>
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        className="modalForm"
      >
        <Form.Item
          label={t(`modal.create_group.name`)}
          name="name"
          rules={[
            {
              required: true,
              message: t(`modal.create_group.name_error`),
            },
          ]}
        >
          <Input className="formInput" />
        </Form.Item>
        <Form.Item>
          <Button className="loginButton" htmlType="submit">{t(`modal.create_group.button`)}</Button>
        </Form.Item>
      </Form>
    </>
  );
};

function mapStateToProps(state) {
  return {
    exerciseId: state.groups.create_group_exerciseId
  }
}

function mapDispatvhToProps(dispatch) {
  return {
    fetchGroups: (id) => dispatch(fetchGroups(id)),
    closeModal: () => dispatch({ type: 'closeModal' }),
  }
}



export default connect(mapStateToProps, mapDispatvhToProps)(CreateGroup);