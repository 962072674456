import { memo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';
import { fetchUser } from "store/actions/oneUser";

import './style.css';

function UserCard({ user, fetchUser, onSearch }) {
    const navigate = useNavigate()

    const onClickUser = () => {
        fetchUser(user.id)
        onSearch('')
        navigate(`../profileUser/${user.id}`)
    }

    return (
        <div className="userBlock" onClick={onClickUser}>
            <div className="user_img">
                {user.photo !== null ? <img src={user.photo} alt="avatar" /> : <UserOutlined style={{ fontSize: '50px', marginLeft: '24px' }} />}
            </div>
            <div className="userBlock_description">
                <p className="userBlock_description__title">{user.first_name} {user.last_name}</p>
                <p className="userBlock_description__description">{user.is_teacher ? 'Учитель' : 'Ученик'}</p>
            </div>
        </div>

    );
}

function mapDispatvhToProps(dispatch) {
    return {
        onSearch: (text) => dispatch({ type: 'onSearch', payload: text }),
        fetchUser: (id) => dispatch(fetchUser(id)),
    }
}



export default connect(null, mapDispatvhToProps)(memo(UserCard));
