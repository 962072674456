import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';

import { copy } from "../../../functions/generalFunction";

import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

function Anagram(props) {

    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks.slice(0)) /*Задания */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState([]) /*Массив ответов */
    const [loading, setLoading] = useState(false)
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState(null) /*Таймер*/
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        question: {
            photo:, фото вопроса
            text: текст вопроса
        },
        answers: [], массив изначальных ответов 
        enter_answers: [], массив выбранных ответов
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true можно ли изменять этот объект
    */
    const [selectAnswers, setSelectrAnswers] = useState(null) //массив для ответов

    const temp = useRef()
    let audio = useRef()

    const onSort = (answer) => {
        let sortAnswer = answer.toUpperCase().split('').sort(() => Math.random() - 0.5)
        // if(sortAnswer.join('') === answer.toUpperCase()) {
        //     onSort(answer)
        // } else {
        return sortAnswer.filter(el => /\S/.test(el))
        // }
    }

    const startFunction = () => {
        const answerArray = []
        const resultArray = []
        let selectAnswersArray = []
        for (let i = 0; i < tasks.length; i++) {
            answerArray.push(onSort(tasks[i].answer))
            selectAnswersArray = [...selectAnswersArray, ...[[]]]
            resultArray.push({
                question: {
                    photo: tasks[i].photo,
                    text: tasks[i].text
                },
                answers: [tasks[i].answer.toUpperCase()],
                enter_answers: [],
                max_point: tasks[i].answer.toUpperCase().split('').filter(el => /\S/.test(el)).length,
                actual_point: 0,
                active: true
            })
        }
        setLoading(true)
        setAnswers(answerArray)
        setSelectrAnswers(selectAnswersArray)
        setResult(resultArray)
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.currentTime = 0
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        let count = 0
        if (answers.length > 0) {
            for (let i = 0; i < selectAnswers.length; i++) {
                for (let j = 0; j < selectAnswers[i].length; j++) {
                    if (typeof selectAnswers[i][j] === "undefined") {
                        selectAnswers[i][j] = ' ';
                    }
                }
                if (selectAnswers[i].join('') === tasks[i].answer.toUpperCase() && !result[i].active) {
                    count++
                }
            }
            if (answers[activeTask].filter(el => /\S/.test(el)).length === 0 && result[activeTask].enter_answers.length === 0) setTimeout(checkCorrectAnswers, 2000)
        }
        if (count === answers.length && answers.length > 0) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            setTimeout(() => props.endGameResults(saveTime, result), 2000)
        }
    }, [answers, result, selectAnswers])

    useEffect(() => {
        const answerArray = []
        const resultArray = []
        let selectAnswersArray = []
        for (let i = 0; i < tasks.length; i++) {
            answerArray.push(tasks[i].answer.toUpperCase().split('').sort(() => Math.random() - 0.5))
            selectAnswersArray = [...selectAnswersArray, ...[[]]]
            resultArray.push({
                question: {
                    photo: tasks[i].photo,
                    text: tasks[i].text
                },
                answers: [tasks[i].answer.toUpperCase()],
                enter_answers: [],
                max_point: tasks[i].answer.toUpperCase().split('').filter(el => /\S/.test(el)).length,
                actual_point: 0,
                active: true
            })
        }
        setAnswers(answerArray)
        setSelectrAnswers(selectAnswersArray)
        setResult(resultArray)
        setTasks(props.one_exercises.game.tasks.slice(0))
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.currentTime = 0
        }
    }, [props.restart])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
        // console.log(source); //source - передвигаемый элемент
        // console.log(destination); //destination - в какой блок переносим

        if (destination === null || destination.droppableId === 'answers') return; //если пользователь попытается зайти в неизвестном направлении

        //если пользователь перетаскивает и опускает обратно в то же положение
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (source.droppableId === 'answers') {
            const newArrayAnswer = copy(answers) //клонирование массива возможных ответов
            const newArrayColumn = copy(selectAnswers) //клонирование массива вставленных ответов
            const i = newArrayAnswer[activeTask][source.index] //получение значение из массива ответов
            const j = newArrayColumn[activeTask][destination.index]//получение значение из ячейки ответов
            if (j === undefined) { //проверка на то, чтобы в яцейке не было буквы
                newArrayAnswer[activeTask].splice(source.index, 1).filter(el => /\S/.test(el)) //удаляем значение из массива ответов
                newArrayColumn[activeTask][destination.index] = i
            } else {
                newArrayColumn[activeTask][destination.index] = i
                newArrayAnswer[activeTask][source.index] = j
            }
            setAnswers(newArrayAnswer)
            setSelectrAnswers(newArrayColumn)
        } else { //если пользователь перетаскивает из столбца
            const newArrayColumn = copy(selectAnswers) //клонирование массива вставленных ответов
            const i = newArrayColumn[activeTask][source.index] //получение значение из массива ответов
            const j = newArrayColumn[activeTask][destination.index]//получение значение из ячейки ответов
            if (destination.droppableId !== source.droppableId) {
                newArrayColumn[activeTask][source.index] = j
                newArrayColumn[activeTask][destination.index] = i
            }
            setSelectrAnswers(newArrayColumn)
        }
    }

    const nextTask = () => {
        if (activeTask + 1 < tasks.length) {
            setActiveTask(activeTask + 1)
        }
    }

    const backTask = () => {
        if (activeTask !== 0) {
            setActiveTask(activeTask - 1)
        }
    }

    const checkCorrectAnswers = () => {
        const newArrayAnswer = copy(answers)
        const newArrayColumn = copy(selectAnswers)
        const resultCopy = copy(result)
        // resultCopy[activeTask].enter_answers = selectAnswers[activeTask]
        tasks[activeTask].answer.toUpperCase().split('').forEach((el, index) => {
            if (el !== ' ') {
                if (el === selectAnswers[activeTask][index]) {
                    resultCopy[activeTask].actual_point += 1
                } else {
                    newArrayAnswer[activeTask].push(el)
                    delete (newArrayColumn[activeTask][index])
                }
                resultCopy[activeTask].active = false
                resultCopy[activeTask].enter_answers[index] = selectAnswers[activeTask][index]
            } else {
                resultCopy[activeTask].enter_answers[index] = ' '
            }
        })
        setAnswers(newArrayAnswer)
        setSelectrAnswers(newArrayColumn)
        setResult(resultCopy)
    }

    const getStyle = (style, snapshot) => {
        if (!snapshot.isDropAnimating) {
            return {
                ...style,
                width: 100,
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            };
        }

        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.5s`,
            width: 100,
            height: 100
        };
    }

    const onClickDontKnown = () => {
        const newArrayAnswer = copy(answers)
        const newArrayColumn = copy(selectAnswers)
        const resultCopy = copy(result)
        if (resultCopy[activeTask].enter_answers.length === 0) resultCopy[activeTask].enter_answers.push('Не знаю')
        resultCopy[activeTask].active = false
        newArrayAnswer[activeTask].length = 0
        newArrayColumn[activeTask] = tasks[activeTask].answer.toUpperCase().split('')
        setAnswers(newArrayAnswer)
        setSelectrAnswers(newArrayColumn)
        setResult(resultCopy)
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if(type){
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if(type){
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        loading &&
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                <div className="gameBlock_header">
                    {timer}
                    <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                    <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                        Не знаю
                    </div>
                </div>
                <div className="gameBlock_content">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="anagramBlock">
                            <div className="anagramBlock_question">
                                <div className="quizesBlock_question_text">
                                    {tasks[activeTask].photo !== null
                                        ? <>
                                            <img src={tasks[activeTask].photo} alt="img" className="quizesBlock_question_img" />
                                            <p>{tasks[activeTask].text}</p>
                                        </>
                                        : <div className="anagramBlock_question_text"><p>{tasks[activeTask].text}</p></div>
                                    }
                                    {/* {tasks[activeTask].photo !== null
                                        ? <img src={tasks[activeTask].photo} alt="img" />
                                        : <div className="anagramBlock_question_text"><p>{tasks[activeTask].text}</p></div>
                                    } */}
                                </div>
                                <div className="anagramBlock_question__dropDragBlock">
                                    {tasks[activeTask].answer.split('').map((item, key) => {
                                        if (item !== ' ') {
                                            return <Droppable droppableId={`question_${key}`} index={key} key={`droppable${key}`}>
                                                {(droppableProvided, droppableSnapshot) => (
                                                    <div
                                                        className="anagramBlock_question__dropDragBlock__item"
                                                        ref={droppableProvided.innerRef}
                                                        {...droppableProvided.droppableProps}
                                                    >
                                                        {result[activeTask].active
                                                            ? result[activeTask].enter_answers.length > 0
                                                                ? result[activeTask].enter_answers[key] === selectAnswers[activeTask][key]
                                                                    ? <p>{selectAnswers[activeTask][key]}</p>
                                                                    : <Draggable
                                                                        key={`task.${key}`}
                                                                        draggableId={`${key + key * 100}`}
                                                                        index={key}
                                                                        isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                                    >
                                                                        {(draggableProvided, draggableSnapshot) => (
                                                                            <div
                                                                                ref={draggableProvided.innerRef}
                                                                                {...draggableProvided.draggableProps}
                                                                                {...draggableProvided.dragHandleProps}
                                                                                style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                                            >
                                                                                {selectAnswers[activeTask][key]}
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                :
                                                                <Draggable
                                                                    key={`task.${key}`}
                                                                    draggableId={`${key + key * 100}`}
                                                                    index={key}
                                                                    isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                                >
                                                                    {(draggableProvided, draggableSnapshot) => (
                                                                        <div
                                                                            ref={draggableProvided.innerRef}
                                                                            {...draggableProvided.draggableProps}
                                                                            {...draggableProvided.dragHandleProps}
                                                                            style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                                        >
                                                                            {selectAnswers[activeTask][key]}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            : <p>{selectAnswers[activeTask][key]}</p>
                                                        }
                                                    </div>
                                                )}
                                            </Droppable>
                                        } else {
                                            return <div className="anagramBlock_question__dropDragBlock__item__whitespace" />
                                        }
                                    })}
                                </div>
                            </div>
                            <Droppable droppableId={`answers`}>
                                {(droppableProvided, droppableSnapshot) => (
                                    <div
                                        className="anagramBlock_answer"
                                        ref={droppableProvided.innerRef}
                                        {...droppableProvided.droppableProps}
                                    >
                                        {answers[activeTask].map((item, key) => {
                                            if (item !== ' ') {
                                                return <Draggable
                                                    key={`answer.${key}`}
                                                    draggableId={`answer.${key}`}
                                                    index={key}
                                                    isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                >
                                                    {(draggableProvided, draggableSnapshot) => (
                                                        <div
                                                            ref={draggableProvided.innerRef}
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}
                                                            style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                        >
                                                            <p>{item}</p>
                                                        </div>

                                                    )}
                                                </Draggable>
                                            }
                                        })}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </DragDropContext>
                </div>
                <div className="gameBlock_footer">
                    <div className="gameBlock_footer__steps">
                        <img src={activeTask !== 0 ? circleLeftActive : circleLeft} alt="circleLeft" onClick={backTask} />
                        <p>{activeTask + 1} из {tasks.length}</p>
                        <img src={activeTask + 1 < tasks.length ? circleRightActive : circleRight} alt="circleRight" onClick={nextTask} />
                    </div>
                    <div className="gameBlock_footer__options">
                        {soundPlay
                            ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                            : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                        }
                        {(isFullscreenEnabled || isFullscreenEnabledClass)
                            ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                            : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                        }
                    </div>
                </div>
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Anagram);
