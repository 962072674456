export const FETCH_LOGIN_START = 'FETCH_LOGIN_START'
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR'

export const FETCH_USERME_START = 'FETCH_USERME_START'
export const FETCH_USERME_SUCCESS = 'FETCH_USERME_SUCCESS'
export const FETCH_USERMESUBSCRIPTION_SUCCESS = 'FETCH_USERMESUBSCRIPTION_SUCCESS'
export const FETCH_USERME_EXERCISES_COUNT_SUCCESS = 'FETCH_USERME_EXERCISES_COUNT_SUCCESS'
export const FETCH_USERME_ERROR = 'FETCH_USERME_ERROR'
export const FETCH_USERME_EXIT = 'FETCH_USERME_EXIT'
export const FAST_REGISTATION_USER = 'FAST_REGISTATION_USER'

export const FETCH_FOLDERS_START = 'FETCH_FOLDERS_START'
export const FETCH_FOLDERS_SUCCESS = 'FETCH_FOLDERS_SUCCESS'
export const FETCH_FOLDERS_ERROR = 'FETCH_FOLDERS_ERROR'

export const FETCH_ONEFOLDER_START = 'FETCH_ONEFOLDER_START'
export const FETCH_ONEFOLDER_SUCCESS = 'FETCH_ONEFOLDER_SUCCESS'
export const FETCH_ONEFOLDER_ERROR = 'FETCH_ONEFOLDER_ERROR'

export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START'
export const FETCH_EXERCISES_SUCCESS = 'FETCH_EXERCISES_SUCCESS'
export const FETCH_EXERCISES_ERROR = 'FETCH_EXERCISES_ERROR'
export const SEARCH_EXERCISES = 'SEARCH_EXERCISES'
export const SAVE_ACTIVE_HASHTAGS_MY_TASKS_SUCCESS = "SAVE_ACTIVE_HASHTAGS_MY_TASKS_SUCCESS"

export const FETCH_JOINTEXERCISES_START = 'FETCH_JOINTEXERCISES_START'
export const FETCH_JOINTEXERCISES_SUCCESS = 'FETCH_JOINTEXERCISES_SUCCESS'
export const FETCH_JOINTEXERCISES_ERROR = 'FETCH_JOINTEXERCISES_ERROR'

export const FETCH_ONEEXERCISES_START = 'FETCH_ONEEXERCISES_START'
export const FETCH_ONEEXERCISES_SUCCESS = 'FETCH_ONEEXERCISES_SUCCESS'
export const FETCH_ONEEXERCISES_ERROR = 'FETCH_ONEEXERCISES_ERROR'
export const FETCH_ONEEXERCISES_CLEAR = 'FETCH_ONEEXERCISES_CLEAR'

export const FETCH_ALLEXERCISES_START = 'FETCH_ALLEXERCISES_START'
export const FETCH_ALLEXERCISES_SUCCESS = 'FETCH_ALLEXERCISES_SUCCESS'
export const FETCH_ALLEXERCISES_ERROR = 'FETCH_ALLEXERCISES_ERROR'
export const FETCH_ALLEXERCISES_CLEAR = "FETCH_ALLEXERCISES_CLEAR"
export const FETCH_ALLEXERCISES_SUCCESS_CONCAT = "FETCH_ALLEXERCISES_SUCCESS_CONCAT"

export const FETCH_PARTICIPANTEXERCISES_START = 'FETCH_PARTICIPANTEXERCISES_START'
export const FETCH_PARTICIPANTEXERCISES_SUCCESS = 'FETCH_PARTICIPANTEXERCISES_SUCCESS'
export const FETCH_PARTICIPANTEXERCISES_ERROR = 'FETCH_PARTICIPANTEXERCISES_ERROR'

export const FETCH_SEARCHEXERCISES_START = 'FETCH_SEARCHEXERCISES_START'
export const FETCH_SEARCHEXERCISES_SUCCESS = 'FETCH_SEARCHEXERCISES_SUCCESS'
export const FETCH_SEARCHEXERCISES_BY_HASHTAGS_SUCCESS = 'FETCH_SEARCHEXERCISES_BY_HASHTAGS_SUCCESS'
export const FETCH_SEARCHEXERCISES_ERROR = 'FETCH_SEARCHEXERCISES_ERROR'

export const FETCH_GAME_START = 'FETCH_GAME_START'
export const FETCH_GAME_SUCCESS = 'FETCH_GAME_SUCCESS'
export const FETCH_GAME_ERROR = 'FETCH_GAME_ERROR'

export const FETCH_FAQ_START = 'FETCH_FAQ_START'
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS'
export const FETCH_FAQ_ERROR = 'FETCH_FAQ_ERROR'

export const FETCH_USERS_START = 'FETCH_USERS_START'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'

export const FETCH_USER_START = 'FETCH_USER_START'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'

export const FETCH_LIKESUSERS_START = 'FETCH_LIKESUSERS_START'
export const FETCH_LIKESUSERS_SUCCESS = 'FETCH_LIKESUSERS_SUCCESS'
export const FETCH_LIKESUSERS_ERROR = 'FETCH_LIKESUSERS_ERROR'

export const FETCH_LEADERBOARD_START = 'FETCH_LEADERBOARD_START'
export const FETCH_LEADERBOARD_SUCCESS = 'FETCH_LEADERBOARD_SUCCESS'
export const FETCH_LEADERBOARD_ERROR = 'FETCH_LEADERBOARD_ERROR'

export const FETCH_LEADERBOARD_RESULTS_START = 'FETCH_LEADERBOARD_RESULTS_START'
export const FETCH_LEADERBOARD_RESULTS_SUCCESS = 'FETCH_LEADERBOARD_RESULTS_SUCCESS'
export const FETCH_LEADERBOARD_RESULTS_ERROR = 'FETCH_LEADERBOARD_RESULTS_ERROR'

export const FETCH_ACHIEVEMENTS_START = 'FETCH_ACHIEVEMENTS_START'
export const FETCH_ACHIEVEMENTS_SUCCESS = 'FETCH_ACHIEVEMENTS_SUCCESS'
export const FETCH_ACHIEVEMENTS_ERROR = 'FETCH_ACHIEVEMENTS_ERROR'

export const FETCH_GROUPS_START = 'FETCH_GROUPS_START'
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS'
export const FETCH_GROUPS_ERROR = 'FETCH_GROUPS_ERROR'

export const FETCH_SEARCHIMAGES_START = 'FETCH_SEARCHIMAGES_START'
export const FETCH_SEARCHIMAGES_SUCCESS = 'FETCH_SEARCHIMAGES_SUCCESS'
export const FETCH_SEARCHIMAGES_ERROR = 'FETCH_SEARCHIMAGES_ERROR'
export const SELECT_SEARCHIMAGE = 'SELECT_SEARCHIMAGE'

export const PDF_START = 'PDF_START'
export const PDF_SUCCESS = 'PDF_SUCCESS'

export const FETCH_SUBSCRIPTIONS_START = 'FETCH_SUBSCRIPTIONS_START'
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS'
export const FETCH_SUBSCRIPTIONS_ERROR = 'FETCH_SUBSCRIPTIONS_ERROR'

export const FETCH_HASHTAGS_START = 'FETCH_HASHTAGS_START'
export const FETCH_HASHTAGS_SUCCESS = 'FETCH_HASHTAGS_SUCCESS'
export const SAVE_ACTIVE_HASHTAGS_SUCCESS = 'SAVE_ACTIVE_HASHTAGS_SUCCESS'
export const FETCH_HASHTAGS_ERROR = 'FETCH_HASHTAGS_ERROR'