import { useEffect, useState } from 'react';
import '../style.css';
import './style.css';

export const FindCouple = ({ game }) => {
    const [firstHalf, setFirstHalf] = useState([])
    const [secondHalf, setSecondHalf] = useState([])
    useEffect(() => {
        const newFirstHalf = []
        const newSecondHalf = []
        game.forEach(el => {
            newFirstHalf.push({
                photo: el.photo,
                text: el.text
            })
            newSecondHalf.push({
                photo: el.answer_photo,
                text: el.answer
            })
        })
        setFirstHalf(newFirstHalf)
        setSecondHalf(newSecondHalf.sort(() => Math.random() - 0.5))
    }, [])
    
    return (
        <>
            {firstHalf.map((el, index) => (
                <div className='Pdf_task findCouplePdf_task' key={`pdf${index}`}>
                    <div className='findCouplePdf_task__item' style={{ justifyContent: 'flex-end' }}>
                        <div className='findCouplePdf_task__item__answer'>
                            {el.photo !== null && <img src={el.photo} alt="el.photo" />}
                            {el.text !== null && <p>{el.text}</p>}
                        </div>
                        <div className="pdfCircle" />
                    </div>
                    <div className='findCouplePdf_task__item' style={{ justifyContent: 'flex-start' }}>
                        <div className="pdfCircle" />
                        {/* <p>{secondHalf[index].text}</p> */}
                        <div className='findCouplePdf_task__item__answer'>
                            {secondHalf[index].photo && <img src={secondHalf[index].photo} alt="el.photo" />}
                            {secondHalf[index].text !== null && <p>{secondHalf[index].text}</p>}
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}