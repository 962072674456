import { ApiUrl, instance } from './api';

export const api_groups = {

    async ReadeGroupsByExercise(id) {
        return await instance.get(`${ApiUrl}/api/v1/groups/by_exercise?exercise_id=${id}&skip=0&limit=100`)
    },

    async CreateGroupsByExercise(data) {
        return await instance.post(`${ApiUrl}/api/v1/groups`, data)
    },

}