import ResetPasswordForm from "components/forms/resetPassword/ResetPassword";

import background from 'assets/backgrounds/main.png';

import './style.css'

function ResetPassword() {
    return (
        <>
            <img src={background} className="mainImg" />
            <div className="resetPassworbBlock">
                <ResetPasswordForm />
            </div>
        </>
    );
}

export default ResetPassword;