import { FETCH_USERS_START, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR } from '../actionTypes'

const initialState = {
    users: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USERS_START:
            return {
                ...state, loading: true
            }
        case FETCH_USERS_SUCCESS:
            return {
                ...state, loading: false,
                users: action.users,
            }
        case FETCH_USERS_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}