import { Table } from 'antd';

function onChange(pagination, filters, sorter, extra) {
}

function CustomTable({columns, data}) {
    return (
        <Table
            pagination={false}
            columns={columns}
            dataSource={data}
            onChange={onChange}
        />
    );
}

export default CustomTable;