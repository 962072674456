import { dateConvertOnlyNumber } from 'functions/dateConverterFunction';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchSubscribe } from 'store/actions/subscriptions';

import { message } from 'antd';
import { prices_desc } from "lists/list";

import arrow from 'assets/arrow.svg';
import greenOk from 'assets/greenOk.svg';
import purpuleArrow from 'assets/purpuleArrow.svg';
import redCross from 'assets/redCross.svg';

import './style.css';

function SubscriptionPrice({ item, page, userMeSubscription, token, setVisible, PageAfterLogin, fetchSubscribe }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const switchButton = ({ name, id }) => {
        let currentSubscriptionObject
        if (userMeSubscription.subscription) {
            currentSubscriptionObject = userMeSubscription.subscription
        } else {
            currentSubscriptionObject = userMeSubscription
        }
        if (page === 'profile') {
            return (
                <button
                    className={`SubscriptionPriceBlock_header__title__button ${item === 'Start' ? 'Start' : 'full'}`}
                    onClick={() => {
                        if (userMeSubscription.meta && !userMeSubscription.meta.is_extended) {
                            message.error(`Подписка уже отменена! Подписка ${currentSubscriptionObject.name} будет действовать до ${dateConvertOnlyNumber(userMeSubscription.meta.end_date)}`)
                        } else {
                            navigate(`../subscriptions`)
                        }
                    }}
                >
                    Оформить подписку
                    <img src={item === 'Start' ? purpuleArrow : arrow} alt="purpuleArrow" />
                </button>
            )
        }
        else {
            if (name !== currentSubscriptionObject.name) {
                return (
                    <button
                        className={`SubscriptionPriceBlock_header__title__button full`}
                        onClick={() => {
                            if (name !== 'Start') {
                                subscribe(id)
                            }
                        }}
                    >
                        Подписаться
                        <img src={arrow} alt="purpuleArrow" />
                    </button>
                )
            } else {
                return (
                    <button
                        className={`SubscriptionPriceBlock_header__title__button`}
                        onClick={() => {
                            if (currentSubscriptionObject.name !== 'Start') {
                                if (!userMeSubscription.meta.is_extended) {
                                    message.error(`Подписка уже отменена! Подписка ${userMeSubscription.subscription.name} будет действовать до ${dateConvertOnlyNumber(userMeSubscription.meta.end_date)}`)
                                } else {
                                    setVisible('subscribeCancel')
                                }
                            } else {
                                message.error('Вы не можете отменить эту подписку!')
                            }
                        }}
                    >
                        Отменить подписку
                    </button>
                )
            }
        }
    }

    const subscribe = (id) => {
        fetchSubscribe(id)
            .then(res => {
                if (res) {
                    window.open(res, '_blank');
                }
            })
    }

    return (
        <div className="SubscriptionPriceBlock">
            {userMeSubscription !== null
                ?
                <div className="SubscriptionPriceBlock_header">
                    <div className="SubscriptionPriceBlock_header__title">
                        <p className="SubscriptionPriceBlock_header__title__type">{t(`subscriptions.prices.${item.name}.title`)}</p>
                        <p className="SubscriptionPriceBlock_header__title__price">{t(`subscriptions.prices.${item.name}.cost`)}{item.name !== 'Start' && <span>/ {t(`subscriptions.prices.${item.name}.measure_of_calculation`)}</span>}</p>
                    </div>
                    {switchButton(item)}
                </div>
                :
                <div className="SubscriptionPriceBlock_header">
                    <div className="SubscriptionPriceBlock_header__title">
                        <p className="SubscriptionPriceBlock_header__title__type">{t(`subscriptions.prices.${item.name}.title`)}</p>
                        <p className="SubscriptionPriceBlock_header__title__price">{t(`subscriptions.prices.${item.name}.cost`)}{item.name !== 'Start' && <span>/ {t(`subscriptions.prices.${item.name}.measure_of_calculation`)}</span>}</p>
                    </div>
                    <button
                        className={`SubscriptionPriceBlock_header__title__button ${item.name === 'Start' ? 'Start' : 'full'}`}
                        onClick={() => {
                            setVisible('registration')
                            PageAfterLogin('../profile')
                        }}
                    >
                        {t(`subscriptions.prices.${item.name}.button`)}
                        <img src={item.name === 'Start' ? purpuleArrow : arrow} alt="purpuleArrow" />
                    </button>
                </div>
            }
            <div className="SubscriptionPriceBlock_description">
                {prices_desc.map((el, key) => {
                    return (
                        <div className="SubscriptionPriceBlock_description__item" key={`priceDesc${key}`}>
                            <p className="SubscriptionPriceBlock_description__item__title"><img src={item.name === 'Start' ? redCross : greenOk} alt="greenOk" />{t(`subscriptions.prices.${item.name}.params.${el}.title`)}</p>
                            <p className="SubscriptionPriceBlock_description__item__description">{t(`subscriptions.prices.${item.name}.params.${el}.description`)}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        userMeSubscription: state.userMe.userMeSubscription,
        token: state.login.token,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        PageAfterLogin: (text) => dispatch({ type: 'PageAfterLogin', payload: text }),
        fetchSubscribe: (id) => dispatch(fetchSubscribe(id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(SubscriptionPrice);
