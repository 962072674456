import { useState, useEffect, useRef } from 'react';

import './style.css';

const createKeyBoard = () => {
    const keyBoard = [[], []]
    const specialSymbol = ['-', '+', '=', '÷', '×']

    for (let i = 48; i < 58; i++) {
        keyBoard[0].push(String.fromCharCode(i))
    }
    for (let i = 0; i < specialSymbol.length; i++) {
        keyBoard[0].push(specialSymbol[i])
    }
    for (let i = 1040; i < 1046; i++) {
        keyBoard[1].push(String.fromCharCode(i))
    }
    keyBoard[1].push(String.fromCharCode(1025))
    for (let i = 1046; i < 1072; i++) {
        keyBoard[1].push(String.fromCharCode(i))
    }

    return keyBoard
}

function WriteAnswer({ game }) {
    const [keyBoard, setKeyBoard] = useState([])
    const temp = useRef()

    const startFunction = () => {
        setKeyBoard(createKeyBoard())
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    return (
        <div className='exercisesCardBg_content'>
            <div className='exercisesCardBg_content__card'>
                {game[0].photo !== null
                    ? <img src={game[0].photo} />
                    : <p>{game[0].text}</p>
                }
            </div>
            <div className='inserLetterAnswerBg'>
                {game[0].answer.split('').map((item, key) => {
                    if(item !== ' '){
                        return <div className="writeAnswerBlock_question__answerItem" key={key} />
                    } else {
                        return <div style={{width: 11}} />
                    }
                }
                    
                )}
            </div>
            <div>
                {keyBoard.map((item, key) => (
                    <div className="writeAnswerBlock_keyboard" key={key}>
                        {keyBoard[key].map((value, index) => (
                            <div className="keyboardItem writeAnswerBgCard" key={index}>{value}</div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WriteAnswer;
