import { FETCH_ACHIEVEMENTS_START, FETCH_ACHIEVEMENTS_SUCCESS, FETCH_ACHIEVEMENTS_ERROR } from '../actionTypes'

const initialState = {
    achievements: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ACHIEVEMENTS_START:
            return {
                ...state, loading: true
            }
        case FETCH_ACHIEVEMENTS_SUCCESS:
            return {
                ...state, loading: false,
                achievements: action.achievements,
            }
        case FETCH_ACHIEVEMENTS_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}