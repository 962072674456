import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import chain from 'assets/chain.png';
import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Fullscreen from 'react-fullscreen-crossbrowser';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';
import { Textfit } from 'react-textfit';
import { copy } from "../../../functions/generalFunction";
import './media.css';
import './style.css';

const countValuesObject = (tasks) => {
    let array = []
    const counts = {}
    const newSecondHalf = [] //массив объектов с повторениями второй части
    for (var x of tasks) array.push({
        value: x.second_half || null,
        img: x.answer_photo || null
    })
    for (let i = 0; i < array.length; i++) {
        array[i] = JSON.stringify(array[i])
    }
    array.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    for (var k of Object.keys(counts)) newSecondHalf.push({
        value: k,
        count: counts[k]
    })
    return newSecondHalf
}

const countValuesArray = (answerArray) => {
    const newSecondHalf = [] //массив объектов с повторениями второй части
    var counts = Object.create(null) //объект повторений
    answerArray.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    for (var k of Object.keys(counts)) newSecondHalf.push({
        value: k,
        count: counts[k]
    })
    return newSecondHalf
}

function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
        return style;
    }

    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.001s`,
    };
}

function MatchElement(props) {
    // console.log(props.one_exercises.game.tasks);
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks.slice(0)) /*Задания */
    const [answers, setAnswers] = useState(Array(props.one_exercises.game.tasks.length)) //массив правильных ответов
    const [loading, setLoading] = useState(false)
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState(null) /*Таймер*/

    const [firstHalf, setFirstHalf] = useState([]) //массив с первыми частями ответов
    const [secondHalf, setSecondHalf] = useState([]) //массив со вторыми частями ответов
    const [marginCount, setMarginCount] = useState([]) //массив отступов для элементов
    const [selectItem, setSelectItem] = useState(null) //выбранный элемент
    const [selectItemIndex, setSelectItemIndex] = useState(null) //индекс выбранного элемента
    const [changeItemIndex, setChangeItemIndex] = useState(null) //элемент, в который опустили
    const [questionItem, setQuestionItem] = useState(false) //выбранный элемент переносится из отвеченных или из вариантов ответа
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        answer: , правильный ответ
        enter_answers: , выбранный ответ
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true можно ли изменять этот объект
        final_answer: дан финальный ответ
    */
    const [seeButton, setSeeButton] = useState(true) /*Показывать ли кнопку проверить */

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        const newFirstHalf = []
        const newMarginCount = []
        const resultArray = []
        tasks.forEach((el, i) => {
            newFirstHalf.push(el.first_half.toUpperCase())
            newMarginCount.push(randomIntFromInterval(0, 60))
            answers[i] = null
            resultArray.push({
                // answer: el.first_half.toUpperCase() + (el.second_half ? el.second_half.toUpperCase() : ''),
                answers: {
                    second_half: el?.second_half?.toUpperCase() || null,
                    answer_photo: el?.answer_photo || null
                },
                question: {
                    text: el.first_half.toUpperCase()
                },
                enter_answers: null,
                max_point: 1,
                actual_point: 0,
                active: true,
                final_answer: false
            })
        })

        setLoading(true)
        setFirstHalf(newFirstHalf)
        setSecondHalf(countValuesObject(tasks).sort(() => Math.random() - 0.5))
        setMarginCount(newMarginCount)
        setResult(resultArray)
        setSeeButton(true)
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.currentTime = 0
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        temp.current()
    }, [props.restart])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    useEffect(() => {
        if (result.filter(el => el.final_answer).length === tasks.length) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            props.endGameResults(saveTime, result)
        }
    }, [result])

    const randomIntFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const checkCorrectAnswers = () => {
        const resultCopy = copy(result)
        const answersCopy = copy(answers)
        const array = []
        firstHalf.forEach((el, index) => {
            if (resultCopy[index].answers.second_half === (JSON.parse(answers[index])?.value?.toUpperCase() || null) && resultCopy[index].answers.answer_photo === JSON.parse(answers[index])?.img) {
                if (resultCopy[index].active) {
                    resultCopy[index].active = false
                    resultCopy[index].actual_point = 1
                    resultCopy[index].final_answer = true
                    resultCopy[index].enter_answers = answers[index]
                } else {
                    resultCopy[index].final_answer = true
                }
            } else {
                array.push(answersCopy[index])
                answersCopy[index] = null
                resultCopy[index].active = false
                resultCopy[index].enter_answers = answers[index]
            }
        })
        setResult(resultCopy)
        setAnswers(answersCopy);
        setSecondHalf(countValuesArray(array).sort(() => Math.random() - 0.5))
    }

    const onClickDontKnown = () => {
        const answersCopy = copy(answers)
        const resultCopy = copy(result)
        tasks.forEach((el, index) => {
            answersCopy[index] = JSON.stringify({
                value: el.second_half?.toUpperCase(),
                img: el.answer_photo
            })
            resultCopy[index].final_answer = true
            resultCopy[index].enter_answers = JSON.stringify({
                value: 'Не знаю',
                img: null
            })
        })
        setSecondHalf([]);
        setAnswers(answersCopy);
        setTimer(null)
        setTimeout(() => {
            setResult(resultCopy)
        }, 2000)
        setSeeButton(false)
    }

    const onDragEnd = (result) => {
        if (result.destination && (result.destination.droppableId !== result.source.droppableId)) {
            const firsHalfIndex_destination = parseInt(result.destination.droppableId.split('_')[1])
            let answersCopy = copy(answers)
            let secondHalfCopy = copy(secondHalf)
            if (result.source.droppableId === "answers") {
                if (answersCopy[firsHalfIndex_destination]) {
                    if (secondHalfCopy.findIndex(el => el.value === answersCopy[firsHalfIndex_destination]) === -1) {
                        const copy = answersCopy[firsHalfIndex_destination]
                        secondHalfCopy.push({
                            value: copy,
                            count: 1
                        })
                        answersCopy[firsHalfIndex_destination] = secondHalf[result.source.index].value
                        if (secondHalf[result.source.index].count === 1) {
                            secondHalfCopy.splice(result.source.index, 1)
                        } else {
                            secondHalfCopy[result.source.index].count--
                        }
                    } else {
                        const copy = answersCopy[firsHalfIndex_destination]
                        secondHalfCopy[secondHalfCopy.findIndex(el => el.value === answersCopy[firsHalfIndex_destination])].count += 1
                        if (secondHalf[result.source.index].count === 1) {
                            secondHalfCopy.splice(result.source.index, 1)
                        } else {
                            secondHalfCopy[result.source.index].count--
                        }
                        answersCopy[firsHalfIndex_destination] = secondHalf[result.source.index].value
                    }
                } else {
                    answersCopy[firsHalfIndex_destination] = secondHalf[result.source.index].value
                    if (secondHalf[result.source.index].count === 1) {
                        secondHalfCopy.splice(result.source.index, 1)
                    } else {
                        secondHalfCopy[result.source.index].count--
                    }
                }
            } else if (result.destination.droppableId !== "answers") {
                const firsHalfIndex_source = parseInt(result.source.droppableId.split('_')[1])
                const a = answersCopy[firsHalfIndex_destination]
                answersCopy[firsHalfIndex_destination] = answersCopy[firsHalfIndex_source]
                answersCopy[firsHalfIndex_source] = a
            }
            setAnswers(answersCopy);
            setSecondHalf(secondHalfCopy)
        }
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        loading &&
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                <div className="gameBlock_header">
                    {timer}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
                        <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                        {secondHalf.length === 0 && seeButton &&
                            <div className="gameBlock_header__checkAnswers" onClick={checkCorrectAnswers}>
                                Проверить
                            </div>
                        }
                    </div>
                    {timer && <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                        Не знаю
                    </div>
                    }
                </div>
                <div className="gameBlock_content" style={{ flexBasis: 'auto', height: '80%' }}>
                    <div className="matchElementBlcok">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div className="matchElementBlcok_firstHalf">
                                {firstHalf.map((item, key) => (
                                    <Droppable droppableId={`firstHalf_${key}`} className="insertLetterAnswers">
                                        {(droppableProvided, droppableSnapshot) => (
                                            <div
                                                ref={droppableProvided.innerRef}
                                                {...droppableProvided.droppableProps}
                                            >
                                                <div
                                                    className="matchElementBlcok_firstHalf__item"
                                                    style={{ marginTop: marginCount[key] + 'px' }}
                                                >
                                                    <div className="matchElementBlcok_firstHalf__item__half"><Textfit mode="multi" className='textfit_block'>{item}</Textfit></div>
                                                    {answers[key] !== null && <img src={chain} alt='chain' width={30} />}
                                                    {answers[key] !== null &&
                                                        <Draggable
                                                            key={`firstHalf_answer${key}`}
                                                            draggableId={`firstHalf_answer${key + 1}`}
                                                            index={12}
                                                            isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                        >
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <div
                                                                    className="matchElementBlcok_secondHalf__item__on__answer matchElementBlcok_firstHalf__item__half"
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.draggableProps}
                                                                    {...draggableProvided.dragHandleProps}
                                                                    style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                                >
                                                                    {JSON.parse(answers[key]).img && <img src={JSON.parse(answers[key]).img} alt='second_half_img' className='matchElementBlcok_secondHalf__item__img' />}
                                                                    <Textfit mode="multi" className='textfit_block'>{JSON.parse(answers[key])?.value?.toUpperCase()}</Textfit>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Droppable>
                                ))}
                            </div>
                            <div>
                                <Droppable droppableId={`answers`} className="insertLetterAnswers">
                                    {(droppableProvided, droppableSnapshot) => (
                                        <div
                                            ref={droppableProvided.innerRef}
                                            {...droppableProvided.droppableProps}
                                        >
                                            <div className="matchElementBlcok_secondHalf">
                                                {secondHalf.map((item, key) => (
                                                    <Draggable
                                                        key={`answer${key}`}
                                                        draggableId={`answer${key + 1}`}
                                                        index={key}
                                                        isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                    >
                                                        {(draggableProvided, draggableSnapshot) => (
                                                            <div
                                                                className="matchElementBlcok_secondHalf__item"
                                                                ref={draggableProvided.innerRef}
                                                                {...draggableProvided.draggableProps}
                                                                {...draggableProvided.dragHandleProps}
                                                                style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                            >
                                                                {JSON.parse(item.value).img && <img src={JSON.parse(item.value).img} alt='second_half_img' className='matchElementBlcok_secondHalf__item__img' />}
                                                                <Textfit mode="multi" className='textfit_block'>{JSON.parse(item.value)?.value?.toUpperCase()}</Textfit>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </DragDropContext>
                    </div>
                </div>
                <div className="gameBlock_footer">
                    <div className="gameBlock_footer__options">
                        {soundPlay
                            ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                            : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                        }
                        {(isFullscreenEnabled || isFullscreenEnabledClass)
                            ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                            : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                        }
                    </div>
                </div>

            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MatchElement);
