import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';

import { copy } from "../../../functions/generalFunction";

import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';
import globe from 'assets/globe.svg';

import { getBrowserFullscreenElementProp } from "functions/useFullscreenStatus";
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

const createKeyBoard = (lang) => {
    const keyBoard = [[], []]
    const specialSymbol = ['-', '+', '=', '÷', '×']

    for (let i = 48; i < 58; i++) {
        keyBoard[0].push(String.fromCharCode(i))
    }
    for (let i = 0; i < specialSymbol.length; i++) {
        keyBoard[0].push(specialSymbol[i])
    }
    if (lang === 'ru') {
        for (let i = 1040; i < 1046; i++) {
            keyBoard[1].push(String.fromCharCode(i))
        }
        keyBoard[1].push(String.fromCharCode(1025))
        for (let i = 1046; i < 1072; i++) {
            keyBoard[1].push(String.fromCharCode(i))
        }
    } else if (lang === 'en') {
        for (let i = 65; i < 91; i++) {
            keyBoard[1].push(String.fromCharCode(i))
        }
    }

    return keyBoard
}

function WriteAnswer(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks.slice(0)) /*Задания */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState(Array(tasks.length).fill([])) /*Массив ответов */
    const [loading, setLoading] = useState(false)
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState(null) /*Таймер*/

    const [selectAnswers, setSelectrAnswers] = useState(Array(tasks.length).fill([])) //массив для ответов
    const [lang, setLang] = useState('ru') //язык раскладки
    const [checkAnswer, setCheckAnswer] = useState(false) // индикатор того можно ли проверять слово (нужен чтобы useEffect не залез в бесконечный цикл)
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        question: {
            photo:, фото вопроса
            text: текст вопроса
        },
        answers: , правильный ответ 
        enter_answers: , первоначальный ответ пользователя
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true, можно ли изменять этот объект
        final_answer: false можно ли отправлять в таблицу результатов
        number_of_attempts: количество попыток
    */

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        const answerArray = []
        const resultArray = []
        let selectAnswersArray = []
        for (let i = 0; i < tasks.length; i++) {
            answerArray.push(tasks[i].answer.toUpperCase().split(''))
            selectAnswersArray = [...selectAnswersArray, ...[[]]]
            resultArray.push({
                question: {
                    photo: tasks[i].photo,
                    text: tasks[i].text
                },
                answers: tasks[i].answer.toUpperCase(),
                enter_answers: null,
                max_point: tasks[i].answer.length,
                actual_point: 0,
                active: true,
                final_answer: false,
                number_of_attempts: 0
            })
        }
        setLoading(true)
        setAnswers(answerArray)
        setResult(resultArray);
        setSelectrAnswers(selectAnswersArray)
        setActiveTask(0)
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            document.getElementsByClassName(`writeAnswer_input_${activeTask}_${0}`)[0].focus()
            document.getElementsByClassName(`writeAnswer_input_${activeTask}_${0}`)[0].select()
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.currentTime = 0
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        temp.current()
    }, [props.restart])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    useEffect(() => {
        if (checkAnswer) {
            const newSelectAnswers = copy(selectAnswers) //клонирование массива поставленных ответов
            const resultCopy = copy(result) //клонирование массива результатов
            if (selectAnswers[activeTask].filter(el => el !== undefined).length === answers[activeTask].length) {
                setTimeout(
                    () => {
                        let count = 0
                        selectAnswers[activeTask].forEach((item, index) => {
                            if (answers[activeTask].length > 0) {
                                if (resultCopy[activeTask].active) resultCopy[activeTask].enter_answers = selectAnswers[activeTask].join('')
                                if (item === answers[activeTask][index]) {
                                    count++
                                    if (resultCopy[activeTask].active) {
                                        resultCopy[activeTask].actual_point++
                                    }
                                } else {
                                    newSelectAnswers[activeTask][index] = undefined
                                }
                            }
                        })
                        if (count === selectAnswers[activeTask].length) {
                            resultCopy[activeTask].final_answer = true
                            if (activeTask + 1 < tasks.length) {
                                setActiveTask(activeTask + 1)
                            }
                        }
                        resultCopy[activeTask].active = false
                        resultCopy[activeTask].number_of_attempts++
                        setResult(resultCopy)
                        setSelectrAnswers(newSelectAnswers)
                    },
                    1000
                )
            }
            setCheckAnswer(false)
        }
    }, [selectAnswers])

    useEffect(() => {
        if (result.filter(el => el.final_answer).length === answers.length) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            setTimeout(() => props.endGameResults(saveTime, result), 2000)
        }
    }, [result])

    useEffect(() => {
        if (props.play) {
            for (let i = 0; i < answers[activeTask].length; i++) {
                if (selectAnswers[activeTask][i] === undefined) {
                    document.getElementsByClassName(`writeAnswer_input_${activeTask}_${i}`)[0].focus()
                    document.getElementsByClassName(`writeAnswer_input_${activeTask}_${i}`)[0].select()
                    break
                }
            }
        }
    }, [activeTask])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const nextTask = () => {
        if (activeTask + 1 < tasks.length) {
            setActiveTask(activeTask + 1)
        }
    }

    const backTask = () => {
        if (activeTask !== 0) {
            setActiveTask(activeTask - 1)
        }
    }

    const avtiveItem = (key) => {
        if (selectAnswers[activeTask][key] === undefined) {
            if (key === 0) {
                return 'activeItem'
            } else if (selectAnswers[activeTask][key - 1] !== undefined) {
                return 'activeItem'
            }
        }
        return ''
    }

    const statusAnswer = (key) => {
        if (!result[activeTask].active) {
            const answer = result[activeTask].answers.split('')
            const enterAnswer = result[activeTask].enter_answers.split('')
            if (answer[key] === enterAnswer[key]) {
                return 'greateItem'
            } else {
                return 'errorItem'
            }
        }
        return ''
    }

    const onClickKeyBoard = (value) => {
        const newSelectAnswers = copy(selectAnswers) //клонирование массива поставленных ответов
        for (let i = 0; i < answers[activeTask].length; i++) {
            if (selectAnswers[activeTask][i] === undefined) {
                newSelectAnswers[activeTask][i] = value
                if (answers[activeTask][i + 1] === ' ') {
                    newSelectAnswers[activeTask][i + 1] = ' '
                }
                break
            }
        }
        setSelectrAnswers(newSelectAnswers)
        setCheckAnswer(true)
    }

    const onClickDontKnown = () => {
        const selectAnswersCopy = copy(selectAnswers) //клонирование массива поставленных ответов
        const resultCopy = copy(result) //клонирование массива результатов
        const answersCopy = copy(answers) //клонирование массива результатов
        selectAnswersCopy[activeTask] = answersCopy[activeTask]
        resultCopy[activeTask].active = false
        resultCopy[activeTask].final_answer = true
        resultCopy[activeTask].enter_answers = 'Не знаю'
        setResult(resultCopy)
        setSelectrAnswers(selectAnswersCopy)
        setTimeout(() => {
            if (activeTask + 1 < tasks.length) {
                setActiveTask(activeTask + 1)
            }
        }, 2000)
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    const onChangeINputValue = (value, itemKey) => {
        const newSelectAnswers = copy(selectAnswers)
        if (value !== '') {
            newSelectAnswers[activeTask][itemKey] = value.toUpperCase()
            if (answers[activeTask].length - 1 > itemKey) {
                if (answers[activeTask][itemKey + 1] === ' ') {
                    newSelectAnswers[activeTask][itemKey + 1] = ' '
                    document.getElementsByClassName(`writeAnswer_input_${activeTask}_${itemKey + 2}`)[0].focus()
                    document.getElementsByClassName(`writeAnswer_input_${activeTask}_${itemKey + 2}`)[0].select()
                } else {
                    document.getElementsByClassName(`writeAnswer_input_${activeTask}_${itemKey + 1}`)[0].focus()
                    document.getElementsByClassName(`writeAnswer_input_${activeTask}_${itemKey + 1}`)[0].select()
                }
            }
        } else {
            newSelectAnswers[activeTask][itemKey] = undefined
        }
        setSelectrAnswers(newSelectAnswers)
        setCheckAnswer(true)
    }

    return (
        <>
            {loading &&
                <Fullscreen
                    enabled={isFullscreenEnabled}
                    onClose={() => {
                        setIsFullscreenEnabled(false)
                        setIsFullscreenEnabledClass(false)
                    }}
                >
                    {props.animation}
                    <div className={`gameBlock ${props.background}`} >
                        <div className="gameBlock_header">
                            {timer}
                            <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                            {props.play &&
                                <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                                    Не знаю
                                </div>
                            }
                        </div>
                        <div className="gameBlock_content writeAnswer_content">
                            <div className="writeAnswerBlock">
                                <div className="writeAnswerBlock_question">
                                    <div className="quizesBlock_question_text">
                                        {tasks[activeTask].photo !== null
                                            ?
                                            <>
                                                <img src={tasks[activeTask].photo} alt="img" className="quizesBlock_question_img" />
                                                {tasks[activeTask].text && <p>{tasks[activeTask].text}</p>}
                                            </>
                                            : <p>{tasks[activeTask].text}</p>
                                        }
                                    </div>
                                    <div className="writeAnswerBlock_question__answerBlock">
                                        {tasks[activeTask].answer.split('').map((item, key) => {
                                            if (item !== ' ') {
                                                return <input
                                                    className={`writeAnswerBlock_question__answerItem writeAnswer_input_${activeTask}_${key}`}
                                                    onChange={(e => onChangeINputValue(e.target.value, key))}
                                                    value={selectAnswers[activeTask][key] || ''}
                                                    style={{ textAlign: 'center' }}
                                                />
                                                // <div
                                                //     className={`writeAnswerBlock_question__answerItem ${avtiveItem(key)} ${statusAnswer(key)}`}
                                                // >
                                                //     {selectAnswers[activeTask][key]}

                                                //     <input className='writeAnswerBlock_question__answerItem' />
                                                // </div>
                                            } else {
                                                return <div className="writeAnswerBlock_question__answerItem__whitespace" />
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="writeAnswerBlock_keyboardBlock">
                                    {createKeyBoard(lang).map((item, key) => (
                                        <div className="writeAnswerBlock_keyboard">
                                            {createKeyBoard(lang)[key].map((value, index) => (
                                                <div className="keyboardItem" onClick={() => onClickKeyBoard(value)}>{value}</div>
                                            ))}
                                            {key === 1 && <div className="keyboardItem" onClick={() => setLang(lang === 'en' ? 'ru' : 'en')}><img src={globe} alt='globe' style={{ width: '1em' }} /></div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__steps">
                                <img src={activeTask !== 0 ? circleLeftActive : circleLeft} alt="circleLeft" onClick={backTask} />
                                <p>{activeTask + 1} из {tasks.length}</p>
                                <img src={activeTask + 1 < tasks.length ? circleRightActive : circleRight} alt="circleRight" onClick={nextTask} />
                            </div>
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </div>
                </Fullscreen>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(WriteAnswer);
