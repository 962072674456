import { useEffect, useState } from 'react';
import CWG from 'cwg'

import './style.css';

function CrosswordPuzzle({ game }) {
    const [crosswordTable, setCrosswordTable] = useState(null) /*Сетка кроссворда */
    useEffect(() => {
        const words = []
        const resultArray = []
        game.forEach(el => {
            words.push(el.answer)
            resultArray.push({
                question: {
                    photo: el.photo,
                    text: el.text
                },
                answers: el.answer,
                enter_answers: null,
                max_point: 1,
                actual_point: 0,
                active: true
            })
        })
        const cwg = CWG(words)
        setCrosswordTable(cwg)
    }, [])

    const genegateTableCol = (row, rowIndex) => {
        const cols = []
        for (let i = 0; i < row.length; i++) {
            if (row[i] !== undefined) {
                cols.push(
                    <td>
                        <div className="crosswordPuzzleBgTable_input" >
                            {crosswordTable.positionObjArr.map((el, key) => {
                                if (el.yNum === rowIndex) {
                                    if (el.xNum === i) {
                                        return <p className="crosswordBgAnswerNumber" style={el.isHorizon ? { right: 2 } : { left: 4 }}>{key + 1}.</p>
                                    }
                                }
                            })}
                        </div>
                    </td>
                )
            } else {
                cols.push(<td></td>)
            }
        }
        return cols
    }


    return (
        crosswordTable &&
        <div className='exercisesCardBg_content'>
            <table
                className="crosswordPuzzleBgTable"
            >
                {crosswordTable.ownerMap.map((row, key) => (
                    <tr key={`crosswordRow_${key}`}>
                        {genegateTableCol(row, key)}
                    </tr>
                ))}
            </table>
        </div>
    );
}

export default CrosswordPuzzle;
