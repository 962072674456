import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { likeAction } from "store/actions/likeAction";
import { fetchLikesUsers } from 'store/actions/likesUsers';

import CustomTable from "components/components/customTable/CustomTable";
import { favourites_data } from 'lists/table_data';

import noPlayers from 'assets/noPlayers.svg';

import './style.css';

function Favourites({
    likes_users,
    likeAction,
    fetchLikesUsers
}) {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const columns = [
        {
            title: t(`table.favourites.name`),
            dataIndex: 'name',
            width: '90%'
        },
        {
            title: t(`table.favourites.action`),
            dataIndex: 'action',
        },
    ];

    const remoteLike = (id) => {
        likeAction('remote', 'User', String(id))
        fetchLikesUsers()
    }

    const navFunc = (id) => {
        navigate(`../profileUser/${id}`)
    }

    return (
        <div className='resultsBlock'>
            <div className='resultsBlock_tableBlock favoriteBlock'>
                <h1>{t(`favourites.title`)}</h1>
                <div className='tableBlock'>
                    {likes_users.length === 0
                        ? <div className='noTasks'>
                            <img src={noPlayers} alt="noPlayers" />
                            <p>{t(`favourites.noFavourite`)}</p>
                        </div>
                        : <CustomTable columns={columns} data={favourites_data(likes_users, remoteLike, navFunc)} />}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        likes_users: state.likes_users.likes_users,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        likeAction: (mode, object_type, object_id) => dispatch(likeAction(mode, object_type, object_id)),
        fetchLikesUsers: () => dispatch(fetchLikesUsers()),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(Favourites);
