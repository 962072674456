import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';

import { copy } from "../../../functions/generalFunction";

import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';
import error from 'assets/games/spaceMathematics/errorCheck.svg';
import greenCheck from 'assets/games/spaceMathematics/greenCheckr.svg';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

function SpaceMathematics(props) {
    const degs = [215, 180, 145, -35, 0, 35]
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(JSON.parse(JSON.stringify(props.one_exercises.game.tasks))) /*tasks */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState([]) /*Массив ответов в случайном полядке*/
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [errorAnswer1, setErrorAnswer1] = useState(0) /*Проверка на ошибку первого ответа */
    const [errorAnswer2, setErrorAnswer2] = useState(0) /*Проверка на ошибку второго ответа */
    const [errorAnswer3, setErrorAnswer3] = useState(0) /*Проверка на ошибку третьего ответа */
    const [errorAnswer4, setErrorAnswer4] = useState(0) /*Проверка на ошибку четвертого ответа */
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        question: {
            photo:, фото вопроса
            text: текст вопроса
        },
        answers: [], массив изначальных ответов 
        enter_answers: [], массив выбранных ответов
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true можно ли изменять этот объект
    */
    const [nextAnswer, setNextAnswer] = useState(true)
    const errorAnswers = [errorAnswer1, errorAnswer2, errorAnswer3, errorAnswer4]
    const setErrorAnswers = [setErrorAnswer1, setErrorAnswer2, setErrorAnswer3, setErrorAnswer4]
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState() /*Таймер*/
    const temp = useRef()
    let audio = useRef()
    const findexTaskIndex = () => {
        return props.one_exercises.game.tasks.findIndex(t =>
            t.text === tasks[activeTask].text &&
            t.task_answers === tasks[activeTask].task_answers
        )
    }

    const startFunction = () => {
        let answersArray = []
        const resultArray = []
        for (let j = 0; j < tasks.length; j++) {
            answersArray.push(tasks[j].task_answers.sort(() => Math.random() - 0.5))
            resultArray.push({
                question: {
                    text: tasks[j].text
                },
                answers: tasks[j].task_answers.filter(el => el.is_right).map(el => el.answer),
                enter_answers: [],
                max_point: tasks[j].task_answers.map(el => el.is_right).filter(right => right).length,
                actual_point: 0,
                active: true
            })
        }
        setResult(resultArray);
        setAnswers(answersArray);
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer" style={{ position: 'relative' }}>
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        let answersArray = []
        const resultArray = []
        const taskCopy = props.one_exercises.game.tasks.slice(0)
        for (let j = 0; j < taskCopy.length; j++) {
            answersArray.push(taskCopy[j].task_answers.sort(() => Math.random() - 0.5))
            resultArray.push({
                question: {
                    photo: taskCopy[j].photo,
                    text: taskCopy[j].text
                },
                answers: taskCopy[j].task_answers.filter(el => el.is_right).map(el => el.answer),
                enter_answers: [],
                max_point: taskCopy[j].task_answers.map(el => el.is_right).filter(right => right).length,
                actual_point: 0,
                active: true
            })
        }
        setResult(resultArray);
        setAnswers(answersArray);
        setTasks(taskCopy)
        if (props.play) {
            audio.current.play()
            audio.current.currentTime = 0
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer" style={{ position: 'relative' }}>
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
        }
    }, [props.restart])

    useEffect(() => {
        if (tasks.length === 0) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            props.endGameResults(saveTime, result)
        }
    }, [activeTask, result])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const checkAnswer = (deg, item, key) => {
        if (nextAnswer) {
            const starShip = document.getElementById('spaceMathematicsGame_startShip')
            const starShipText = document.getElementById('spaceMathematicsGame_startShip_text')
            starShip.style.transform = `rotate(${deg}deg)`
            if (deg > 90) {
                starShipText.style.transform = `rotate(${180}deg)`
            } else {
                starShipText.style.transform = `rotate(${0}deg)`
            }
            const newArrayAnswer = copy(answers)
            const resultCopy = copy(result)
            setNextAnswer(false)
            setTimeout(() => {
                if (!item.is_right) {
                    newArrayAnswer[activeTask][key].is_right = 'error'
                    setTimeout(() => {
                        resultCopy[findexTaskIndex()].active = false
                        resultCopy[findexTaskIndex()].enter_answers.push(item.answer)
                        setNextAnswer(true)
                        setResult(resultCopy)
                    }
                        , 1000
                    )
                } else {
                    if (result[findexTaskIndex()].active) {
                        resultCopy[findexTaskIndex()].actual_point += 1
                        resultCopy[findexTaskIndex()].enter_answers.push(item.answer)
                    }
                    newArrayAnswer[activeTask][key].is_right = 'success'
                    setTimeout(() => {
                        if (answers[activeTask].map((el, i) => el.is_right === true ? i : '').filter(String).length === 1) {
                            if (activeTask + 1 === tasks.length && activeTask !== 0) {
                                setActiveTask(activeTask - 1)
                            }
                            tasks.splice(tasks.indexOf(tasks[activeTask], activeTask), 1)
                            newArrayAnswer.splice(activeTask, 1)
                        }
                        setNextAnswer(true)
                        setResult(resultCopy)
                    }
                        , 1000)
                }
                setAnswers(newArrayAnswer)
            }, 2000)
        }
    }

    const nextTask = () => {
        if (activeTask + 1 < tasks.length) {
            setActiveTask(activeTask + 1)
        }
    }

    const backTask = () => {
        if (activeTask !== 0) {
            setActiveTask(activeTask - 1)
        }
    }

    const onClickDontKnown = () => {
        const newArrayAnswer = copy(answers)
        const resultCopy = copy(result)
        newArrayAnswer[activeTask].forEach(el => {
            if (el.is_right) {
                el.is_right = 'success'
            }
        })
        setAnswers(newArrayAnswer)
        setTimeout(() => {
            // resultCopy[findexTaskIndex()].actual_point = 0
            resultCopy[findexTaskIndex()].enter_answers.length = 0
            resultCopy[findexTaskIndex()].enter_answers.push('Не знаю')
            setResult(resultCopy)
            if (activeTask + 1 === tasks.length && activeTask !== 0) {
                setActiveTask(activeTask - 1)
            }
            tasks.splice(tasks.indexOf(tasks[activeTask], activeTask), 1)
            newArrayAnswer.splice(activeTask, 1)
            setAnswers(newArrayAnswer)
        }
            , 5000
        )
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }
    
    return (
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`}>
                {answers.length > 0 &&
                    <>
                        {timer &&
                            <div>
                                <div className="gameBlock_header" style={{ gap: 10, alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    {timer}
                                    {tasks[activeTask].task && <div className="gameBlock_header__title spaceMathematics_task" style={{ display: 'block' }}>{tasks[activeTask].task}</div>}
                                    <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown} style={{ position: 'relative', whiteSpace: 'nowrap' }}>
                                        Не знаю
                                    </div>
                                </div>
                                {tasks[activeTask].task && <p className="gameBlock_header__title  spaceMathematics_task__mobile" style={{ display: 'none' }}>{tasks[activeTask].task}</p>}
                            </div>
                        }
                        <div className="gameBlock_content">
                            <div className="spaceMathematicsGame">
                                <div className="spaceMathematicsGame_starsBlock leftStars">
                                    {answers[activeTask].map((el, key) => {
                                        if (key >= 0 && key <= 2) {
                                            return <div className="spaceMathematicsGame_leftStars_item" onClick={(e) => checkAnswer(degs[key], el, key)} style={{ opacity: el.is_right === 'success' ? 0 : 1 }}>
                                                {el.is_right === 'success' ? <div><img src={greenCheck} alt="greenCheck" /></div> : (el.is_right === 'error' ? <div><img src={error} alt="greenCheck" /></div> : <p>{el.answer}</p>)}
                                            </div>
                                        }
                                    })}
                                </div>
                                <div className="spaceMathematicsGame_startShip" id="spaceMathematicsGame_startShip">
                                    <p className="spaceMathematicsGame_startShip_text" id="spaceMathematicsGame_startShip_text">{tasks[activeTask].text}</p>
                                </div>
                                <div className="spaceMathematicsGame_starsBlock rightStars">
                                    {answers[activeTask].map((el, key) => {
                                        if (key >= 3 && key <= 5) {
                                            return <div
                                                className="spaceMathematicsGame_rightStars_item"
                                                onClick={(e) => checkAnswer(degs[key], el, key)}
                                                style={{ opacity: el.is_right === 'success' ? 0 : 1 }}
                                            // style={setTimeout(function () {
                                            //     setStyle({ opacity: 1 });
                                            //   }, 3000)}
                                            >
                                                {el.is_right === 'success' ? <div><img src={greenCheck} alt="greenCheck" /></div> : (el.is_right === 'error' ? <div><img src={error} alt="greenCheck" /></div> : <p>{el.answer}</p>)}
                                            </div>
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__steps">
                                <img src={activeTask !== 0 ? circleLeftActive : circleLeft} alt="circleLeft" onClick={backTask} />
                                <p>{activeTask + 1} из {tasks.length}</p>
                                <img src={activeTask + 1 < tasks.length ? circleRightActive : circleRight} alt="circleRight" onClick={nextTask} />
                            </div>
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(SpaceMathematics);
