import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';

import error from 'assets/bigErrorCheck.svg';
import greenCheck from 'assets/bigGreateCheck.svg';
import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';
import plusCircle from 'assets/plus-circle.svg';

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

function YesOrNot(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState([]) /*tasks */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [result, setResult] = useState([]) /*
    State для сохранения результатов (правильные/неправильные ответы) 
        question: {
            photo:, фото вопроса
            text: текст вопроса
        },
        answers: [], массив изначальных ответов 
        enter_answers: [], массив выбранных ответов
        max_point: , максимальное количество баллов
        actual_point: , набранное количество баллов
        active: true можно ли изменять этот объект
    */
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState() /*Таймер*/
    const [selectAnswerCard, setSelectAnswerCard] = useState(null)
    const [checkAnswerIcon, setCheckAnswerICon] = useState(null)

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        const propsTasks = [...JSON.parse(JSON.stringify(props.one_exercises.game.notAnswers)), ...JSON.parse(JSON.stringify(props.one_exercises.game.yesAnswers))]
        setTasks(propsTasks.sort(() => Math.random() - 0.5))
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        const propsTasks = [...JSON.parse(JSON.stringify(props.one_exercises.game.notAnswers)), ...JSON.parse(JSON.stringify(props.one_exercises.game.yesAnswers))]
        setTasks(propsTasks.sort(() => Math.random() - 0.5))
        setResult([])
        setCheckAnswerICon(null)
        setSelectAnswerCard(null)
        setActiveTask(0)
        if (props.play) {
            audio.current.play()
            audio.current.currentTime = 0
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
        }
    }, [props.restart])

    useEffect(() => {
        if (result.length > 0 && tasks.length === 0) {
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
            props.endGameResults(saveTime, result)
        }
    }, [activeTask, result, tasks])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }


    const nextTask = () => {
        if (activeTask + 1 < tasks.length) {
            setActiveTask(activeTask + 1)
        }
    }

    const backTask = () => {
        if (activeTask !== 0) {
            setActiveTask(activeTask - 1)
        }
    }

    const onClickDontKnown = () => {
        const obj = {
            question: {
                photo: tasks[activeTask].photo,
                text: tasks[activeTask].text
            },
            answers: tasks[activeTask].status ? 'Да' : 'Нет',
            max_point: 1,
            actual_point: 0,
            enter_answers: 'Не знаю'
        }
        setResult([...result, obj])
        setCheckAnswerICon({
            block: tasks[activeTask].status,
            status: true
        })
        setTimeout(() => {
            setCheckAnswerICon(null)
            if (activeTask + 1 === tasks.length) {
                setActiveTask(activeTask - 1)
            }
            tasks.splice(tasks.indexOf(tasks[activeTask], activeTask), 1)
        }
            , 2000
        )
    }

    const dragOverHandler = (e, item, status) => {
        e.preventDefault();
        if (status !== null) {
            setSelectAnswerCard({
                question: item,
                status
            })
        } else {
            setSelectAnswerCard(null)
        }
    }

    const dragLeaveHandler = (e) => {
    }

    const dragStartHandler = (e, item) => {
    }

    const dragEndHandler = (e) => {
    }

    const dropHandler = (e, answerCard) => {
        e.preventDefault();
        let selectCard

        if (answerCard) {
            selectCard = answerCard
        } else {
            selectCard = selectAnswerCard
        }


        if (selectCard) {
            const obj = {
                question: {
                    photo: selectCard.question.photo,
                    text: selectCard.question.text
                },
                answers: selectCard.question.status ? 'Да' : 'Нет',
                max_point: 1,
            }
            if (selectCard.status === selectCard.question.status) {
                obj.actual_point = 1
                obj.enter_answers = selectCard.status ? 'Да' : 'Нет'
            } else {
                obj.actual_point = 0
                obj.enter_answers = selectCard.status ? 'Да' : 'Нет'
            }
            setResult([...result, obj])
            setCheckAnswerICon({
                block: selectCard.status,
                status: selectCard.status === selectCard.question.status ? true : false
            })
            setTimeout(() => {
                setCheckAnswerICon(null)
                if (activeTask + 1 === tasks.length) {
                    setActiveTask(activeTask - 1)
                }
                tasks.splice(tasks.indexOf(tasks[activeTask], activeTask), 1)
            }
                , 2000
            )
        }
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                {tasks.length > 0 &&
                    <>
                        <div className="gameBlock_header">
                            {timer}
                            <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                            <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                                Не знаю
                            </div>
                        </div>
                        <div className="yesOrNotBlock_content">
                            <div
                                className="yesOrNot_questionBlock__mobile"
                                draggable={!checkAnswerIcon ? true : false}
                                onDragOver={(e) => dragOverHandler(e, null, null)}
                                onDragLeave={(e) => dragLeaveHandler(e)}
                                onDragStart={(e) => dragStartHandler(e, tasks[activeTask])}
                                onDragEnd={(e) => dragEndHandler(e)}
                                onDrop={(e) => dropHandler(e)}
                            >
                                {tasks[activeTask].photo
                                    ?
                                    <div>
                                        <img src={tasks[activeTask].photo} alt="img" />
                                        <p className="yesOrNot_questionBlock__textUnderImg">{tasks[activeTask].text}</p>
                                    </div>
                                    : <p>{tasks[activeTask].text}</p>
                                }
                            </div>
                            <div className="yesOrNotBlock_content__row">
                                <div
                                    className="yesOrNot_answerBlock"
                                    onDragOver={(e) => dragOverHandler(e, tasks[activeTask], true)}
                                    onDragLeave={(e) => dragLeaveHandler(e)}
                                    onDragStart={(e) => dragStartHandler(e, tasks[activeTask])}
                                    onDragEnd={(e) => dragEndHandler(e)}
                                    onDrop={(e) => dropHandler(e)}
                                    draggable={false}
                                    onClick={(e) => dropHandler(e, {
                                        question: tasks[activeTask],
                                        status: true
                                    })}
                                >
                                    <p className="yesOrNot_answerBlock__title">Да</p>
                                    <div className="yesOrNot_answerBlock__plusBlock">
                                        <img src={checkAnswerIcon ? (checkAnswerIcon.block ? (checkAnswerIcon.status ? greenCheck : error) : plusCircle) : plusCircle} alt="plusCircle" />
                                    </div>
                                </div>
                                <div
                                    className="yesOrNot_questionBlock"
                                    draggable={!checkAnswerIcon ? true : false}
                                    onDragOver={(e) => dragOverHandler(e, null, null)}
                                    onDragLeave={(e) => dragLeaveHandler(e)}
                                    onDragStart={(e) => dragStartHandler(e, tasks[activeTask])}
                                    onDragEnd={(e) => dragEndHandler(e)}
                                    onDrop={(e) => dropHandler(e)}
                                >
                                    {tasks[activeTask].photo
                                        ?
                                        <div>
                                            <img src={tasks[activeTask].photo} alt="img" />
                                            <p className="yesOrNot_questionBlock__textUnderImg">{tasks[activeTask].text}</p>
                                        </div>
                                        : <p>{tasks[activeTask].text}</p>
                                    }
                                </div>
                                <div
                                    className="yesOrNot_answerBlock"
                                    onDragOver={(e) => dragOverHandler(e, tasks[activeTask], false)}
                                    onDragLeave={(e) => dragLeaveHandler(e)}
                                    onDragStart={(e) => dragStartHandler(e, tasks[activeTask])}
                                    onDragEnd={(e) => dragEndHandler(e)}
                                    onDrop={(e) => dropHandler(e)}
                                    draggable={false}
                                    onClick={(e) => dropHandler(e, {
                                        question: tasks[activeTask],
                                        status: false
                                    })}
                                >
                                    <p className="yesOrNot_answerBlock__title">Нет</p>
                                    <div className="yesOrNot_answerBlock__plusBlock">
                                        <img src={checkAnswerIcon ? (!checkAnswerIcon.block ? (checkAnswerIcon.status ? greenCheck : error) : plusCircle) : plusCircle} alt="plusCircle" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__steps">
                                <img src={activeTask !== 0 ? circleLeftActive : circleLeft} alt="circleLeft" onClick={backTask} />
                                <p>{activeTask + 1} из {tasks.length}</p>
                                <img src={activeTask + 1 < tasks.length ? circleRightActive : circleRight} alt="circleRight" onClick={nextTask} />
                            </div>
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(YesOrNot);
