import CWG from 'cwg';
import { useEffect, useState } from 'react';

import '../style.css';
import './style.css';

export const CrosswordPuzzle = ({ game }) => {
    const [crosswordTable, setCrosswordTable] = useState(null) /*Сетка кроссворда */
    
    useEffect(() => {
        const words = []
        const resultArray = []
        game.forEach(el => {
            words.push(el.answer)
            resultArray.push({
                question: {
                    photo: el.photo,
                    text: el.text
                },
                answers: el.answer,
                enter_answers: null,
                max_point: 1,
                actual_point: 0,
                active: true
            })
        })
        const cwg = CWG(words)
        setCrosswordTable(cwg)
    }, [])

    const genegateTableCol = (row, rowIndex) => {
        const cols = []
        for (let i = 0; i < row.length; i++) {
            if (row[i] !== undefined) {
                cols.push(
                    <td>
                        <div className="crosswordPuzzlePdfTable_input" >
                            {crosswordTable.positionObjArr.map((el, key) => {
                                if (el.yNum === rowIndex) {
                                    if (el.xNum === i) {
                                        return <p className="crosswordPuzzlePdfAnswerNumber" style={el.isHorizon ? { right: 0 } : { left: 0 }}>{key + 1}.</p>
                                    }
                                }
                            })}
                        </div>
                    </td>
                )
            } else {
                cols.push(<td></td>)
            }
        }
        return cols
    }

    return (
        crosswordTable &&
        <>
            <div
            //  className='Pdf_task'
             >
                <div className='crosswordPuzzlePdf_task'>
                    <table
                        className="crosswordPuzzlePdfTable"
                    >
                        {crosswordTable.ownerMap.map((row, key) => (
                            <tr key={`crosswordRow_${key}`}>
                                {genegateTableCol(row, key)}
                            </tr>
                        ))}
                    </table>
                </div>
                <div className='crosswordPuzzlePdf_questions'>
                    <p className='crosswordPuzzlePdf_questions__title'>По горизонтали:</p>
                    <div className='crosswordPuzzlePdf_questions__tasks'>
                        {crosswordTable.positionObjArr.map((el, key) => {
                            if (el.isHorizon) {
                                return (
                                    <div key={`horizonQuestion_${key}`} className="crosswordPuzzlePdf_questions__tasks__item">
                                        <div className="crosswordPuzzlePdf_questions__tasks__item__number">{key + 1}.</div>
                                        <div className="crosswordPuzzlePdf_questions__tasks__item__question">
                                            {game[key].photo
                                                ?
                                                <div className='crosswordPuzzlePdf_questions__tasks__item__question__withPhoto'>
                                                    <img src={game[key].photo} alt="img" />
                                                    <p className="yesOrNot_questionBlock__textUnderImg">{game[key].text}</p>
                                                </div>
                                                : <p>{game[key].text}</p>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <p className='crosswordPuzzlePdf_questions__title'>По вертикали:</p>
                    <div className='crosswordPuzzlePdf_questions__tasks'>
                        {crosswordTable.positionObjArr.map((el, key) => {
                            if (!el.isHorizon) {
                                return (
                                    <div key={`horizonQuestion_${key}`} className="crosswordPuzzlePdf_questions__tasks__item">
                                        <div className="crosswordPuzzlePdf_questions__tasks__item__number">{key + 1}.</div>
                                        <div className="crosswordPuzzlePdf_questions__tasks__item__question">
                                            {game[key].photo
                                                ?
                                                <div className='crosswordPuzzlePdf_questions__tasks__item__question__withPhoto'>
                                                    <img src={game[key].photo} alt="img" />
                                                    <p className="crosswordPuzzlePdf_questionBlock__textUnderImg">{game[key].text}</p>
                                                </div>
                                                : <p>{game[key].text}</p>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}