import { ApiUrl, instance } from './api';

export const api_likes = {

    async ReadeLikesUsers() {
        return await instance.get(`${ApiUrl}/api/v1/likes/get_liked_users`)
    },

    async SetLike(data) {
        return await instance.post(`${ApiUrl}/api/v1/likes/set_like`, data)
    },

    async RemoteLike(data) {
        return await instance.post(`${ApiUrl}/api/v1/likes/remove_like`, data)
    },
}