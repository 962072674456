import { useRef } from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import { fetchSearchImages } from 'store/actions/searchImages';

import imageIcon from 'assets/imageIcon.svg'

import '../style.css';
import { fetchImages } from 'store/actions/images';

const { Search } = Input;

function SearchImages({
    search_images,
    setVisible,
    fetchSearchImages,
    SELECT_SEARCHIMAGE,
    fetchImages
}) {
    const fileInputRef = useRef();

    const onSearch = (value) => {
        fetchSearchImages(value)
    }

    return (
        <div>
            <div className='searchImage_header'>
                <Search
                    placeholder='Введите название'
                    onSearch={onSearch}
                />
                <img className='customUploadIcon' src={imageIcon} alt="imageIcon" onClick={() => fileInputRef.current.click()} />
                <input
                    onChange={(e) => {
                        let data = new FormData();
                        let filedata = e.target.files[0]
                        data.append('file', filedata);

                        fetchImages(data)
                        .then(res => {
                            if(res){
                                SELECT_SEARCHIMAGE(res)
                            }
                        })

                        setVisible('searchImages')
                        fileInputRef.current.value = null
                    }}
                    multiple={false}
                    ref={fileInputRef}
                    type='file'
                    accept='.png, .jpg, .doc, .docx, .pdf, .word'
                    hidden
                />
            </div>
            <div className='searchImage_images'>
                {search_images.map((item, key) => (
                    <img
                        src={item.thumbnailUrl}
                        key={key}
                        onClick={() => {
                            SELECT_SEARCHIMAGE(item.thumbnailUrl)
                            setVisible('searchImages')
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        search_images: state.search_images.search_images
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        fetchSearchImages: (text) => dispatch(fetchSearchImages(text)),
        SELECT_SEARCHIMAGE: (image_url) => dispatch({ type: 'SELECT_SEARCHIMAGE', image_url }),
        fetchImages: (data) => dispatch(fetchImages(data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(SearchImages);