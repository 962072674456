import { ApiUrl, instance } from './api';

export const api_subscription = {

    async GetSubscriptions() {
        return await instance.get(`${ApiUrl}/api/v1/subscriptions?skip=0&limit=100`)
    },

    async FreeTrial() {
        return await instance.post(`${ApiUrl}/api/v1/subscriptions/free_trial`)
    },

    async CreateSubscription(id) {
        return await instance.post(`${ApiUrl}/api/v1/subscriptions/purchase?subscription_id=${id}`)
    },

    async CancelSubscription() {
        return await instance.post(`${ApiUrl}/api/v1/subscriptions/cancel`)
    },

}