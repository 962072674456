import { api_subscription } from 'API/subscriptions';
import {
  FETCH_SUBSCRIPTIONS_START,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_ERROR,
} from '../actionTypes';

export function fetchSubscriptions() {
  return async (dispatch) => {
    dispatch(fetchSubscriptionsStart());
    try {
      const subscriptions = await api_subscription.GetSubscriptions();
      dispatch(fetchSubscriptionsSuccess(subscriptions.data));
    } catch (e) {
      dispatch(fetchSubscriptionsError(e));
    }
  };
}

export function fetchSubscribe(id) {
  return async (dispatch) => {
    dispatch(fetchSubscriptionsStart());
    try {
      const payment_link = await api_subscription.CreateSubscription(id);
      return payment_link.data;
    } catch (e) {
      dispatch(fetchSubscriptionsError(e));
    }
  };
}

export function fetchSubscriptionsStart() {
  return {
    type: FETCH_SUBSCRIPTIONS_START,
  };
}

export function fetchSubscriptionsSuccess(subscriptions) {
  return {
    type: FETCH_SUBSCRIPTIONS_SUCCESS,
    subscriptions,
  };
}

export function fetchSubscriptionsError(e) {
  return {
    type: FETCH_SUBSCRIPTIONS_ERROR,
    error: e,
  };
}
