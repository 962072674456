import { Button, Form, Input, message } from 'antd';
import { copy } from 'functions/generalFunction';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import delete_icon from 'assets/delete.png';
import ArrowsOutCardinal from 'assets/forms/ArrowsOutCardinal.svg';
import Copy from 'assets/forms/Copy.svg';
import Trash from 'assets/forms/Trash.svg';
import imageIcon from 'assets/imageIcon.svg';
import purpulePlus from 'assets/purpulePlus.svg';

import TextArea from 'antd/lib/input/TextArea';
import '../media.css';
import '../style.css';
import './yesOrNot.css';

const DragHandle = sortableHandle(() => <span><img src={ArrowsOutCardinal} alt="ArrowsOutCardinal" /></span>);

const SortableItem = sortableElement(({ children }) => (
    children
));

const SortableContainer = sortableContainer(({ children }) => {
    return <ol style={{ padding: 0 }}>{children}</ol>;
});

function YesOrNot({
    create_task,
    image_url,
    saveTaskForm,
    deleteTaskForm,
    stepUp,
    stepDown,
    setVisible,
    SELECT_SEARCHIMAGE,
}) {
    const [yesImages, setYesImages] = useState([])
    const [noImages, setNoImages] = useState([])
    const [position, setPosition] = useState(null)
    const [fields, setFields] = useState([])

    const [form] = Form.useForm();
    const yesTasks = Form.useWatch('yesAnswers', form);
    const noTasks = Form.useWatch('notAnswers', form);
    const { t } = useTranslation();

    useEffect(() => {
        const yesImagesArr = []
        const noImages = []
        if (create_task.create_task !== null) {
            create_task.create_task.notAnswers.forEach((item, key) => {
                if (item.photo === 'no_photo' || item.photo === null) {
                    noImages.push(undefined)
                } else {
                    noImages.push(item.photo)
                }
            })
            create_task.create_task.yesAnswers.forEach((item, key) => {
                if (item.photo === 'no_photo' || item.photo === null) {
                    yesImagesArr.push(undefined)
                } else {
                    yesImagesArr.push(item.photo)
                }
            })
            setFields([
                {
                    name: ['name'],
                    value: create_task.create_task.name,
                },
                {
                    name: ['yesAnswers'],
                    value: create_task.create_task.yesAnswers,
                },
                {
                    name: ['notAnswers'],
                    value: create_task.create_task.notAnswers,
                },
                {
                    name: ['hashtags'],
                    value: (create_task.create_task.hashtags.length === 1 || create_task.create_task.hashtags.length === 0)
                        ? create_task.create_task.hashtags
                        : [{
                            first_hashtag: create_task.create_task.hashtags[0],
                            second_hashtag: create_task.create_task.hashtags[1],
                            third_hashtag: create_task.create_task.hashtags[2],
                            fourth_hashtag: create_task.create_task.hashtags[3]
                        }],
                },
            ])
        }
        setYesImages(yesImagesArr)
        setNoImages(noImages)
    }, [])

    useEffect(() => {
        if (image_url) {
            saveImageOnForm(position, image_url)
            SELECT_SEARCHIMAGE('')
        }
    }, [image_url])

    useEffect(() => {
        return () => {
            SELECT_SEARCHIMAGE('')
        };
    }, []);

    const saveImageOnForm = (position, data) => {
        if (position) {
            if (position.form === 'yesAnswers') {
                const array = copy(yesImages)
                array[position.index] = data
                if (data !== '') {
                    const tasks_form = yesTasks
                    tasks_form[position.name].photo = data
                    form.setFieldsValue({
                        yesAnswers: tasks_form
                    });
                    setYesImages(array)
                }
            } else {
                const array = copy(noImages)
                array[position.index] = data
                if (data !== '') {
                    const tasks_form = noTasks
                    tasks_form[position.name].photo = data
                    form.setFieldsValue({
                        noTasks: tasks_form
                    });
                    setNoImages(array)
                }
            }
        }
    }

    const onFinish = ({ name, notAnswers, yesAnswers, hashtags }) => {
        let count = 0
        if (hashtags?.length) {
            hashtags = [
                hashtags[0].first_hashtag,
                hashtags[0].second_hashtag,
                hashtags[0].third_hashtag,
                hashtags[0].fourth_hashtag,
            ]
        }
        if (yesAnswers && yesAnswers.length > 0) {
            yesAnswers.forEach((item, key) => {
                if ((item.text === undefined || item.text === '') && (item.photo === undefined)) {
                    message.error(`Заполните поле 'Фотография/текст' в группе "Да"`)
                    count++
                }
            })
        } else {
            message.error("В группе 'Да' отсутствуют вопросы!")
            count++
        }

        if (notAnswers && notAnswers.length > 0) {
            notAnswers.forEach((item, key) => {
                if ((item.text === undefined || item.text === '') && (item.photo === undefined)) {
                    message.error(`Заполните поле 'Фотография/текст' в группе "Нет"`)
                    count++
                }
            })
        } else {
            message.error("В группе 'Нет' отсутствуют вопросы!")
            count++
        }

        if (count === 0) {
            saveTaskForm({
                name,
                notAnswers,
                yesAnswers,
                hashtags
            })
            stepUp()
        }
    };

    const goBack = () => {
        deleteTaskForm()
        stepDown()
    }

    const onSortEnd = (oldIndex, newIndex, move) => {
        move(oldIndex, newIndex)
    };

    const SelectPhoto = (form, index, name) => {
        setPosition({
            form,
            index,
            name
        })
        setVisible('searchImages')
    }

    const deletePhoto = (form, index) => {
        saveImageOnForm({ form, index }, undefined)
    }

    const onCopy = (form, key, name, add) => {
        if (form === 'yesAnswers') {
            const arr = copy(yesImages)
            arr.push(arr[key])
            setYesImages(arr)
            add(yesTasks[name], name + 1)
        } else {
            const arr = copy(noImages)
            arr.push(arr[key])
            setNoImages(arr)
        }
    }

    const onAdd = (form, add) => {
        if (form === 'yesAnswers') {
            const arr = copy(yesImages)
            arr.push(undefined)
            setYesImages(arr)
            add({ photo: undefined, status: true })
        } else {
            const arr = copy(noImages)
            arr.push(undefined)
            setNoImages(arr)
            add({ photo: undefined, status: false })
        }
    }

    return (
        <>
            <Form
                name="basic"
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                fields={fields}
                autoComplete="off"
                className="modalForm createTaskForm"
                form={form}
            >
                <h2>Хеш-теги</h2>
                <Form.List name="hashtags">
                    {(fields, { add, remove, move }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <>
                                    <p>Обязательно нужно указать возраст, класс и предмет</p>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: 24, marginTop: 12 }} key={`hashtag-${name}`}>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'first_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'second_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'third_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'fourth_hashtag']}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t(`games.title_error`),
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <img src={Trash} alt="Trash" onClick={() => remove(name)} style={{ cursor: 'pointer' }} />
                                    </div>
                                </>
                            ))}
                            {fields.length == 0 &&
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={add}
                                    >
                                        <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />Добавить хеш-теги</p>
                                    </Button>
                                </Form.Item>
                            }
                        </>
                    )}
                </Form.List>
                <h2>Задание</h2>
                <Form.Item
                    label={t(`games.title`)}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(`games.title_error`),
                        },
                    ]}
                >
                    <Input className="formInput" />
                </Form.Item>
                <div className='yesOrNotTasksBlock'>
                    <div className='yesOrNotTasksBlock_item'>
                        <p className='yesOrNotTasksBlock_item__title'>Да</p>
                        <Form.List name="yesAnswers">
                            {(fields, { add, remove, move }) => (
                                <>
                                    <SortableContainer onSortEnd={(e) => onSortEnd(e.oldIndex, e.newIndex, move)} useDragHandle>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <SortableItem key={`item-${name}`} index={name} >
                                                <div className='quizesQuestionBlock' key={key}>
                                                    <div className='form_matchElementquestionBlock'>
                                                        <div className='form_matchElementquestionBlock__item'>
                                                            <p>Вопрос</p>
                                                            <div className='quizesQuestionBlock_uploadBlock'>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'text']}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                        },
                                                                    ]}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <TextArea className="formTextArea" placeholder={t(`games.quizes.question`)} bordered={false} rows={2} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'photo']}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                        },
                                                                    ]}
                                                                    className="formTextArea_upload"
                                                                >
                                                                    <Input
                                                                        id={`photoInput${key}`}
                                                                        className='quizesQuestionBlock_upload'
                                                                        bordered={false}
                                                                        suffix={
                                                                            <div>
                                                                                <img className='customUploadIcon' src={yesImages[key] === undefined ? imageIcon : yesImages[key]} alt="imageIcon" onClick={() => SelectPhoto('yesAnswers', key, name)} />
                                                                                {yesImages[key] !== undefined && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto('yesAnswers', key, name)} />}
                                                                            </div>
                                                                        }
                                                                        style={{ width: '10%' }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='form_questionOption needMargin'>
                                                        <img src={Copy} alt="Copy" onClick={() => onCopy('yesAnswers', key, name, add)} />
                                                        <img src={Trash} alt="Trash" onClick={() => remove(name)} />
                                                    </div>
                                                </div>
                                            </SortableItem>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="link"
                                                onClick={() => onAdd('yesAnswers', add)}
                                            >
                                                <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />{t(`games.addRow`)}</p>
                                            </Button>
                                        </Form.Item>
                                    </SortableContainer>
                                </>
                            )}
                        </Form.List>
                    </div>
                    <div className='yesOrNotTasksBlock_item'>
                        <p className='yesOrNotTasksBlock_item__title'>Нет</p>
                        <Form.List name="notAnswers">
                            {(fields, { add, remove, move }) => (
                                <>
                                    <SortableContainer onSortEnd={(e) => onSortEnd(e.oldIndex, e.newIndex, move)} useDragHandle>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <SortableItem key={`item-${name}`} index={name} >
                                                <div className='quizesQuestionBlock' key={key}>
                                                    <div className='form_matchElementquestionBlock'>
                                                        <div className='form_matchElementquestionBlock__item'>
                                                            <p>Вопрос</p>
                                                            <div className='quizesQuestionBlock_uploadBlock'>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'text']}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                        },
                                                                    ]}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <TextArea className="formTextArea" placeholder={t(`games.quizes.question`)} bordered={false} rows={2} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'photo']}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                        },
                                                                    ]}
                                                                    className="formTextArea_upload"
                                                                >
                                                                    <Input
                                                                        id={`photoInput${key}`}
                                                                        className='quizesQuestionBlock_upload'
                                                                        bordered={false}
                                                                        suffix={
                                                                            <div>
                                                                                <img className='customUploadIcon' src={noImages[key] === undefined ? imageIcon : noImages[key]} alt="imageIcon" onClick={() => SelectPhoto('notAnswers', key, name)} />
                                                                                {noImages[key] !== undefined && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto('notAnswers', key, name)} />}
                                                                            </div>
                                                                        }
                                                                        style={{ width: '10%' }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='form_questionOption needMargin'>
                                                        <img src={Copy} alt="Copy" onClick={() => onCopy('notAnswers', key, name, add)} />
                                                        <img src={Trash} alt="Trash" onClick={() => remove(name)} />
                                                    </div>
                                                </div>
                                            </SortableItem>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="link"
                                                onClick={() => onAdd('notAnswers', add)}
                                            >
                                                <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />{t(`games.addRow`)}</p>
                                            </Button>
                                        </Form.Item>
                                    </SortableContainer>
                                </>
                            )}
                        </Form.List>
                    </div>
                </div>
                <Form.Item className='createTaskForm_buttons'>
                    <span className='editProfileButton' onClick={goBack}>{t(`games.backButton`)}</span>
                    <button htmltype="submit" className='editProfileButton'>{t(`games.saveButton`)}</button>
                </Form.Item>
            </Form>
        </>
    );
};

function mapStateToProps(state) {
    return {
        create_task: state.create_task,
        image_url: state.search_images.image_url
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        deleteTaskForm: () => dispatch({ type: 'deleteTaskForm' }),
        stepUp: () => dispatch({ type: 'stepUp' }),
        stepDown: () => dispatch({ type: 'stepDown' }),
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        SELECT_SEARCHIMAGE: (image_url) => dispatch({ type: 'SELECT_SEARCHIMAGE', image_url }),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(YesOrNot);