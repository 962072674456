import React from 'react'
import { patternsGame } from 'lists/list';
import { instructions } from 'lists/instructions';

import background from 'assets/backgrounds/main.png';
import Pattern from 'components/components/pattern/Pattern';

const Instructions = () => {

    const onClickPattern = (type) => {
        window.open(instructions[type], "_blank")
    }

    return (
        <>
            <img src={background} className="mainMiniImg" />
            <div className="patternsBlock">
                <div className="patternsBlock_header">
                    {/* <p className="patternsBlock_header__title">{t(`patterns.title`)}</p> */}
                </div>
                <div className="patternsBlock_list">
                    {patternsGame.map((item, key) => (
                        <Pattern mode="instruction" item={item} key={key} onClick={() => onClickPattern(item.type)} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default Instructions