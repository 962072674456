import autumn from 'assets/backgrounds/games/autumn.png';
import bg1 from 'assets/backgrounds/games/bg1.png';
import book from 'assets/backgrounds/games/book.png';
import cell from 'assets/backgrounds/games/cell.png';
import childhood from 'assets/backgrounds/games/childhood.png';
import cloud from 'assets/backgrounds/games/cloud.png';
import fantasy from 'assets/backgrounds/games/fantasy.png';
import music from 'assets/backgrounds/games/music.png';
import neutral from 'assets/backgrounds/games/neutral.png';
import notebook from 'assets/backgrounds/games/notebook.png';
import puzzle from 'assets/backgrounds/games/puzzle.png';
import schoolBlackboard from 'assets/backgrounds/games/schoolBlackboard2.png';
import space from 'assets/backgrounds/games/space.png';
import spring from 'assets/backgrounds/games/spring.png';
import story from 'assets/backgrounds/games/story.png';
import summer from 'assets/backgrounds/games/summer.png';
import winter from 'assets/backgrounds/games/winter.png';

export const formthemes = [
    {
        img: bg1,
        title: "classic",
        type: "CLASSIC"
    },
    {
        img: neutral,
        title: "neutral",
        type: "NEUTRAL"
    },
    {
        img: winter,
        title: "winter",
        type: "WINTER"
    },
    {
        img: spring,
        title: "spring",
        type: "SPRING"
    },
    {
        img: summer,
        title: "summer",
        type: "SUMMER"
    },
    {
        img: autumn,
        title: "autumn",
        type: "AUTUMN"
    },
    {
        img: space,
        title: "space",
        type: "SPACE"
    },
    {
        img: cloud,
        title: "cloud",
        type: "CLOUD"
    },
    {
        img: book,
        title: "book",
        type: "BOOK"
    },
    {
        img: puzzle,
        title: "puzzle",
        type: "PUZZLE"
    },
    {
        img: story,
        title: "story",
        type: "STORY"
    },
    {
        img: schoolBlackboard,
        title: "schoolBlackboard",
        type: "SCHOOL_BLACKBOARD"
    },
    {
        img: childhood,
        title: "childhood",
        type: "CHILDHOOD"
    },
    {
        img: cell,
        title: "cell",
        type: "CELL"
    },
    {
        img: music,
        title: "music",
        type: "MUSIC"
    },
    {
        img: fantasy,
        title: "fantasy",
        type: "FANTASY"
    },
    {
        img: notebook,
        title: "notebook",
        type: "NOTEBOOK"
    },
]

export const spaseMathematics = [
    {
        img: space,
        title: "space",
        type: "SPACE"
    },
]