import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';

import { copy, spl } from "../../../functions/generalFunction";

import { getBrowserFullscreenElementProp } from 'functions/useFullscreenStatus';
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
        return style;
    }

    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.001s`,
    };
}

function GroupDivion(props) {

    const { t } = useTranslation();
    const [tasks, setTasks] = useState(spl(props.one_exercises.game.tasks, 6)) /*Задания */
    const [activeTask, setActiveTask] = useState(0) /*Позиция задания, которое показывать пользователю */
    const [answers, setAnswers] = useState(Array(tasks.length).fill([])) /*Массив ответов */
    const [loading, setLoading] = useState(false)
    const [soundPlay, setSoundPlay] = useState(true) /*Вкл/выкл музыки*/
    const [saveTime, setSaveTime] = useState(null) /*Сохранение времени для отправки результата */
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false) /*Полноэкранный режим*/
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false) /*Полноэкранный режим*/
    const [timer, setTimer] = useState(null) /*Таймер*/
    const [checkAnswers, setCheckAnswers] = useState(false) /*Готовность проверить задание */

    const [selectAnswers, setSelectrAnswers] = useState(null) //массив для ответов
    const [dontKnownStatus, setDontKnownStatus] = useState(false) //нажата ли кнопка "не знаю"

    const temp = useRef()
    let audio = useRef()

    const startFunction = () => {
        let answersArray = Array(tasks.length).fill([])
        let selectAnswersArray = Array(tasks.length).fill([])
        for (let j = 0; j < tasks.length; j++) {
            for (let i = 0; i < tasks[j].length; i++) {
                const arrayAnswers = []
                tasks[j][i].answers.forEach((item, key) => {
                    arrayAnswers.push({
                        answer: item.answer,
                        true_group: tasks[j][i].title,
                        current_group: null,
                        select_group: null,
                        max_point: 1,
                        actual_point: 0,
                        tested: false
                    })
                })
                answersArray[j] = [...answersArray[j], ...arrayAnswers]
                selectAnswersArray[j] = [...selectAnswersArray[j], ...[[]]]
            }
        }
        answersArray[0].sort(() => Math.random() - 0.5)
        setAnswers(answersArray)
        setSelectrAnswers(selectAnswersArray)
        setLoading(true)
        setTasks(spl(props.one_exercises.game.tasks, 6))
        setDontKnownStatus(false)
        audio.current = new Audio(props.sound)
        audio.current.loop = true
        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ hours, minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds)
                    }}
                    // onCallback={() => console.log('Finish')}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            )
            audio.current.play()
            audio.current.currentTime = 0
            audio.current.volume = 0.05
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [props.play])

    useEffect(() => {
        temp.current()
    }, [props.restart])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    useEffect(() => {
        setCheckAnswers(false)
        let count = 0
        for (let i = 0; i < answers.length; i++) {
            if (answers[i].length === 0) count++
        }
        if (count === answers.length) {
            setCheckAnswers(true)
            audio.current.pause()
            setIsFullscreenEnabled(false)
            setIsFullscreenEnabledClass(false)
        }
    }, [answers])

    useEffect(() => {
        return () => {
            audio.current.pause()
        }
    }, [])

    useEffect(() => {
        if (dontKnownStatus) {
            setTimeout(checkCorrectAnswers, 1000)
        }
    }, [dontKnownStatus])

    const clickSound = () => {
        if (soundPlay) {
            audio.current.pause()
            setSoundPlay(false)
        }
        else {
            audio.current.play()
            setSoundPlay(true)
        }
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
        //source - передвигаемый элемент
        //destination - в какой блок переносим

        if (destination === null || destination.droppableId === 'answers') return; //если пользователь попытается зайти в неизвестном направлении

        //если пользователь перетаскивает и опускает обратно в то же положение
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        //если пользователь перетаскивает не из столбца
        if (source.droppableId === 'answers') {
            const newArrayAnswer = copy(answers) //клонирование массива возможных ответов
            const newArrayColumn = copy(selectAnswers) //клонирование массива вставленных ответов
            const selectColumnIndexTo = parseInt(destination.droppableId.split('_')[1]) //получение индекса массива ответов, в который перетаскиваем
            newArrayAnswer[activeTask][source.index].current_group = tasks[activeTask][selectColumnIndexTo].title
            newArrayColumn[activeTask][selectColumnIndexTo].splice(destination.index, 0, newArrayAnswer[activeTask][source.index])
            newArrayAnswer[activeTask].splice(source.index, 1)
            setAnswers(newArrayAnswer)
            setSelectrAnswers(newArrayColumn)
        } else { //если пользователь перетаскивает из столбца
            const newArrayColumn = copy(selectAnswers) //клонирование массива вставленных ответов ответов
            const selectColumnIndexTo = parseInt(destination.droppableId.split('_')[1]) //получение индекса массива ответов, в который перетаскиваем
            const selectColumnIndexFrom = parseInt(source.droppableId.split('_')[1]) //получение индекса массива ответов, из которого перетаскиваем
            if (destination.droppableId !== source.droppableId) {
                newArrayColumn[activeTask][selectColumnIndexTo].splice(destination.index, 0, newArrayColumn[activeTask][selectColumnIndexFrom][source.index])
                newArrayColumn[activeTask][selectColumnIndexFrom].splice(source.index, 1)
                newArrayColumn[activeTask][selectColumnIndexTo][newArrayColumn[activeTask][selectColumnIndexTo].length - 1].current_group = tasks[activeTask][selectColumnIndexTo].title
            } else {
                newArrayColumn[activeTask][selectColumnIndexTo][source.index] = selectAnswers[activeTask][selectColumnIndexFrom][destination.index]
                newArrayColumn[activeTask][selectColumnIndexTo][destination.index] = selectAnswers[activeTask][selectColumnIndexFrom][source.index]
            }
            // console.log(newArrayColumn[activeTask]);
            setSelectrAnswers(newArrayColumn)
        }
    }

    const checkCorrectAnswers = () => {
        let count = 0 //счетчик ответов, которые не на своем месте
        let allAnswersArray = [] //группируем все ответы в один массив
        let answersArray = Array(tasks.length).fill([]) //массив, в который записываются неправильные ответы
        let trueAnswers = [[]] //массив, в который записываются правильные ответы
        selectAnswers[activeTask].forEach(array => {
            allAnswersArray = [...allAnswersArray, ...array]
        })
        selectAnswers[activeTask].forEach((array, key) => {
            const testArray = []
            array.forEach((obj, index) => {
                if (obj.current_group === obj.true_group) {
                    if (!obj.tested) {
                        obj.actual_point = 1
                    }
                    testArray.push(obj)
                } else {
                    count++
                    answersArray[activeTask].push(obj)
                }
                if (!obj.tested) {
                    obj.tested = true
                    obj.select_group = obj.current_group
                }
            })
            trueAnswers[activeTask].push(testArray)
        })
        if (count === 0) {
            let result = []
            allAnswersArray.forEach(item => {
                result.push({
                    question: {
                        text: item.answer
                    },
                    answers: item.current_group,
                    enter_answers: item.select_group,
                    max_point: item.max_point,
                    actual_point: item.actual_point,
                })
            })
            props.endGameResults(saveTime, result)
            audio.current.pause()
            onClickFullScreen(false)
            setTimer(null)
        } else {
            setAnswers(answersArray)
            setSelectrAnswers(trueAnswers)
            setDontKnownStatus(false)
        }
    }

    const onClickDontKnown = () => {
        const newArrayAnswer = copy(answers)
        const newArrayColumn = copy(selectAnswers)
        newArrayAnswer[activeTask].forEach(obj => {
            obj.current_group = obj.true_group
            obj.select_group = 'Не знаю'
            obj.tested = true
            newArrayColumn[activeTask][tasks[activeTask].map(e => e.title).indexOf(obj.true_group)].push(obj)
        })
        newArrayAnswer[activeTask].length = 0
        setAnswers(newArrayAnswer)
        setSelectrAnswers(newArrayColumn)
        setDontKnownStatus(true)
    }

    const onClickFullScreen = (type) => {
        try {
            getBrowserFullscreenElementProp()
            if (type) {
                setIsFullscreenEnabled(true)
            } else {
                setIsFullscreenEnabled(false)
            }
        } catch {
            if (type) {
                document.querySelector('.FullScreen').classList.add("fullScreenActive")
                document.querySelector('.header').style.display = 'none'
                setIsFullscreenEnabledClass(true)
            } else {
                document.querySelector('.FullScreen').classList.remove("fullScreenActive")
                document.querySelector('.header').style.display = 'flex'
                setIsFullscreenEnabledClass(false)
            }
        }
    }

    return (
        loading &&
        <Fullscreen
            enabled={isFullscreenEnabled}
            onClose={() => {
                setIsFullscreenEnabled(false)
                setIsFullscreenEnabledClass(false)
            }}
        >
            {props.animation}
            <div className={`gameBlock ${props.background}`} >
                <div className="gameBlock_header">
                    {timer}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
                        <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                        {checkAnswers && !dontKnownStatus &&
                            <div className="gameBlock_header__checkAnswers" onClick={checkCorrectAnswers} style={{ width: 'fit-content' }}>
                                Проверить
                            </div>
                        }
                    </div>
                    <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                        Не знаю
                    </div>
                </div>
                <div className="gameBlock_content">
                    <div className="groupDivionBlock">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div className="groupDivionBlock_questions">
                                {tasks[activeTask].map((item, key) => (
                                    <div className="groupDivionBlock_questions__column" >
                                        <p className="groupDivionBlock_questions__column__title">{item.title}</p>
                                        <Droppable droppableId={`column_${key}`} index={key}>
                                            {(droppableProvided, droppableSnapshot) => (

                                                <div
                                                    ref={droppableProvided.innerRef}
                                                    {...droppableProvided.droppableProps}
                                                    style={{ height: '80%' }}
                                                    key={key}
                                                    className="groupDivionBlock_questions__droppable__context"
                                                >
                                                    {selectAnswers[activeTask][key].map((value, index) => (
                                                        <Draggable
                                                            key={`task.${index}.${key}`}
                                                            draggableId={`${index + key * 100}`}
                                                            index={index}
                                                            isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                                        >
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <p
                                                                    className="groupDivionBlock_questions__column__item"
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.draggableProps}
                                                                    {...draggableProvided.dragHandleProps}
                                                                >
                                                                    {value.answer}
                                                                </p>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </div>

                                            )}
                                        </Droppable>
                                    </div>
                                ))}
                            </div>
                            <Droppable droppableId={`answers`}>
                                {(droppableProvided, droppableSnapshot) => (
                                    <div
                                        className="groupDivionBlock_answers"
                                        ref={droppableProvided.innerRef}
                                        {...droppableProvided.droppableProps}
                                    >
                                        {answers[activeTask].map((item, key) => (
                                            <Draggable
                                                key={`answer${key}`}
                                                draggableId={`answer${key}`}
                                                index={key}
                                                isDragging={droppableSnapshot.isDragging && !droppableSnapshot.isDropAnimating}
                                            >
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <p
                                                        className="groupDivionBlock_answers__item"
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                                                    >
                                                        {item.answer}
                                                    </p>

                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                <div className="gameBlock_footer">
                    <div className="gameBlock_footer__options">
                        {soundPlay
                            ? <img src={props.icons.sound} alt="sound" onClick={clickSound} />
                            : <img src={props.icons.soundoff} alt="soundoff" onClick={clickSound} style={{ width: '32px', height: '32px' }} />
                        }
                        {(isFullscreenEnabled || isFullscreenEnabledClass)
                            ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(false)} />
                            : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => onClickFullScreen(true)} />
                        }
                    </div>
                </div>
            </div>
        </Fullscreen>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(GroupDivion);
