import '../style.css';
import './style.css';

export const WriteAnswer = ({ game }) => {
    return (
        game.map((el, index) => (
            <div className='Pdf_task' style={{ marginTop: '3mm' }} key={`writeAnswerPdf_task${index}`}>
                <div className='writeAnswerPdf_taskHeader'>
                    <p className='pdfTaskNumber'>{index + 1}</p>
                    <p>{el.text}</p>
                </div>
                <div className='writeAnswerPdf_task' >
                    {el.photo !== null &&
                        <div className='writeAnswerPdf_task__item__question'>
                            <img src={el.photo} alt="el.photo" />
                        </div>
                    }
                    <div className='writeAnswerPdf_task__item__answer'>
                        <div className='writeAnswerPdf_task__item__answer__square'>
                            {el.answer.split('').map((val, key) => {
                                if (val === ' ') {
                                    return <div style={{width: '0.5cm'}} />
                                } else {
                                    return <div key={`writeAnswerPdf_task__item__answer__square${key}`} className="pdfSquare" />
                                }
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        ))
    )
}