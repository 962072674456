import { Button, Checkbox, Form, Input, message } from 'antd';
import { copy } from 'functions/generalFunction';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import delete_icon from 'assets/delete.png';
import ArrowsOutCardinal from 'assets/forms/ArrowsOutCardinal.svg';
import Copy from 'assets/forms/Copy.svg';
import Trash from 'assets/forms/Trash.svg';
import imageIcon from 'assets/imageIcon.svg';
import purpulePlus from 'assets/purpulePlus.svg';

import TextArea from 'antd/lib/input/TextArea';
import '../media.css';
import '../style.css';
import './media.css';

const DragHandle = sortableHandle(() => <span><img src={ArrowsOutCardinal} alt="ArrowsOutCardinal" /></span>);

const SortableItem = sortableElement(({ children }) => (
    children
));

const SortableContainer = sortableContainer(({ children }) => {
    return <ol style={{ padding: 0 }}>{children}</ol>;
});


function Quizes({
    create_task,
    image_url,
    saveTaskForm,
    deleteTaskForm,
    stepUp,
    stepDown,
    setVisible,
    SELECT_SEARCHIMAGE
}) {
    const [images, setImages] = useState([])
    const [answersImages, setAnswersImages] = useState([])
    const [position, setPosition] = useState(null)
    const [fields, setFields] = useState([])
    const [form] = Form.useForm();
    const tasks = Form.useWatch('tasks', form);

    const { t } = useTranslation();

    useEffect(() => {
        const arr = []
        const answersImagesArray = []
        if (create_task.create_task !== null) {
            create_task.create_task.tasks.forEach((item, key) => {
                const answerImages = []
                if (item.photo === 'no_photo' || item.photo === null) {
                    arr.push(undefined)
                } else {
                    arr.push(item.photo)
                }
                for (let i = 1; i < 5; i++) {
                    answerImages.push({ photo: item[`photo${i}`] })
                }
                answersImagesArray.push(answerImages)
            })
            setFields([
                {
                    name: ['name'],
                    value: create_task.create_task.name,
                },
                {
                    name: ['tasks'],
                    value: create_task.create_task.tasks,
                },
                {
                    name: ['hashtags'],
                    value: (create_task.create_task.hashtags.length === 1 || create_task.create_task.hashtags.length === 0)
                        ? create_task.create_task.hashtags
                        : [{
                            first_hashtag: create_task.create_task.hashtags[0],
                            second_hashtag: create_task.create_task.hashtags[1],
                            third_hashtag: create_task.create_task.hashtags[2],
                            fourth_hashtag: create_task.create_task.hashtags[3]
                        }],
                },

            ])
        }
        setImages(arr)
        setAnswersImages(answersImagesArray)
    }, [])

    useEffect(() => {
        if (image_url) {
            saveImageOnForm(position.key, position.name, position.type, position.index, image_url)
            SELECT_SEARCHIMAGE('')
        }
    }, [image_url])

    useEffect(() => {
        return () => {
            SELECT_SEARCHIMAGE('')
        };
    }, []);

    const saveImageOnForm = (key, name, type, index, data) => {
        if (type !== undefined) {
            let imagesCopy = JSON.parse(JSON.stringify(answersImages))
            imagesCopy[key][index].photo = data
            if (data !== '') {
                const tasks_form = tasks
                tasks_form[name][type] = data
                form.setFieldsValue({
                    tasks: tasks_form
                });
                setAnswersImages(imagesCopy)
            }
        } else {
            const array = copy(images)
            array[key] = data
            if (data !== '') {
                const tasks_form = tasks
                tasks_form[name].photo = data
                form.setFieldsValue({
                    tasks: tasks_form
                });
                setImages(array)
            }
        }
    }

    const onFinish = (values) => {
        let count = 0
        let checkCount = 0
        let checkAnswerCount = 0
        if (values?.hashtags?.length) {
            values.hashtags = [
                values.hashtags[0].first_hashtag,
                values.hashtags[0].second_hashtag,
                values.hashtags[0].third_hashtag,
                values.hashtags[0].fourth_hashtag,
            ]
        }
        if (values.tasks !== undefined && values.tasks.length !== 0) {
            values.tasks.forEach((item, key) => {
                checkAnswerCount = 0
                if ((item.text === undefined || item.text === null) && (item.photo === undefined)) {
                    message.error(`Заполните поле 'Фотография/текст' в элементе ${key + 1}`)
                    count++
                }

                if (
                    (item.check1 === undefined || item.check1 === false) &&
                    (item.check2 === undefined || item.check2 === false) &&
                    (item.check3 === undefined || item.check3 === false) &&
                    (item.check4 === undefined || item.check4 === false)
                ) {
                    checkCount = 1
                }

                for (let i = 1; i < 5; i++) {
                    if (item[`answer${i}`] !== undefined && item[`answer${i}`].length > 0) checkAnswerCount++
                    if (item[`photo${i}`] !== undefined && item[`photo${i}`].length > 0) checkAnswerCount++
                }

                if (checkCount === 1) message.error(`Нет правильного ответа в элементе ${key + 1}`)
                if (checkAnswerCount < 2) message.error(`В ${key + 1} элементе должно быть минимум два ответа`)
            })

            if (count === 0 && checkCount === 0 && checkAnswerCount >= 2) {
                saveTaskForm(values)
                stepUp()
            }
        } else {
            message.error("Вопросы отсутствуют!")
        }
    };

    const goBack = () => {
        deleteTaskForm()
        stepDown()
    }

    const onSortEnd = (oldIndex, newIndex, move) => {
        move(oldIndex, newIndex)
    };

    const SelectPhoto = (key, name, type, index) => {
        setPosition({ key, name, type, index })
        setVisible('searchImages')
    }

    const deletePhoto = (key, name, type, index) => {
        if (type !== undefined) {
            saveImageOnForm(key, name, type, index, undefined)
        } else {
            saveImageOnForm(key, name, undefined)
        }
    }

    const onCopy = (key, name, add) => {
        const arr = copy(images)
        const imagesCopy = JSON.parse(JSON.stringify(answersImages))
        arr.push(arr[key])
        imagesCopy.push(imagesCopy[key])
        setImages(arr)
        setAnswersImages(imagesCopy)
        add(tasks[name], name + 1)
    }

    const onAdd = (add) => {
        const arr = copy(images)
        arr.push(undefined)
        setImages(arr)
        setAnswersImages([...answersImages, [
            {
                photo: null
            },
            {
                photo: null
            },
            {
                photo: null
            },
            {
                photo: null
            },
        ]])
        add({ photo: undefined })
    }

    const onDelete = (key, name, remove) => {
        const imagesCopy = JSON.parse(JSON.stringify(answersImages))
        imagesCopy[key] = null
        setAnswersImages(imagesCopy)
        remove(name)
    }

    return (
        <>
            <Form
                name="basic"
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                fields={fields}
                autoComplete="off"
                className="modalForm createTaskForm"
                form={form}
            >
                <h2>Хеш-теги</h2>
                <Form.List name="hashtags">
                    {(fields, { add, remove, move }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <>
                                    <p>Обязательно нужно указать возраст, класс и предмет</p>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: 24, marginTop: 12 }} key={`hashtag-${name}`}>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'first_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'second_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'third_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'fourth_hashtag']}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t(`games.title_error`),
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <img src={Trash} alt="Trash" onClick={() => remove(name)} style={{ cursor: 'pointer' }} />
                                    </div>
                                </>
                            ))}
                            {fields.length == 0 &&
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={add}
                                    >
                                        <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />Добавить хеш-теги</p>
                                    </Button>
                                </Form.Item>
                            }
                        </>
                    )}
                </Form.List>
                <h2>Задание</h2>
                <Form.Item
                    label={t(`games.title`)}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(`games.title_error`),
                        },
                    ]}
                >
                    <Input className="formInput" />
                </Form.Item>
                <Form.List name="tasks">
                    {(fields, { add, remove, move }) => (
                        <>
                            <SortableContainer onSortEnd={(e) => onSortEnd(e.oldIndex, e.newIndex, move)} useDragHandle>
                                {fields.map(({ key, name, ...restField }) => (
                                    <SortableItem key={`item-${name}`} index={name} >
                                        <li index={key}>
                                            <div key={key}>
                                                <div>
                                                    <div>
                                                        {t(`games.quizes.question`)}
                                                        <div className='quizesQuestionBlock'>
                                                            <div className='quizesQuestionBlock_uploadBlock'>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'text']}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                        },
                                                                    ]}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <TextArea className="formTextArea" placeholder={t(`games.quizes.question`)} bordered={false} rows={2} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'photo']}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                        },
                                                                    ]}
                                                                    className="formTextArea_upload"
                                                                >
                                                                    <Input
                                                                        id={`photoInput${key}`}
                                                                        className='quizesQuestionBlock_upload'
                                                                        bordered={false}
                                                                        suffix={
                                                                            <div>
                                                                                <img className='customUploadIcon' src={images[key] === undefined ? imageIcon : images[key]} alt="imageIcon" onClick={() => SelectPhoto(key, name)} />
                                                                                {images[key] !== undefined && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto(key, name)} />}
                                                                            </div>
                                                                        }
                                                                        style={{ width: '10%' }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='form_questionOption'>
                                                                <DragHandle />
                                                                <img src={Copy} alt="Copy" onClick={() => onCopy(key, name, add)} />
                                                                <img src={Trash} alt="Trash" onClick={() => onDelete(key, name, remove)} />
                                                            </div>
                                                        </div>
                                                        {t(`games.quizes.answer`)}
                                                        <div className='quezeAnswersBlock'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer1']}
                                                                className="leftAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check1']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    suffix={
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'photo1']}
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                id={`photoInput${key}`}
                                                                                className='quizesAnswerBlock_upload'
                                                                                bordered={false}
                                                                                suffix={
                                                                                    <div>
                                                                                        <img className='customUploadIcon' src={!answersImages[key][0]?.photo ? imageIcon : answersImages[key][0]?.photo} alt="imageIcon" onClick={() => SelectPhoto(key, name, 'photo1', 0)} />
                                                                                        {answersImages[key][0].photo && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto(key, name, 'photo1', 0)} />}
                                                                                    </div>
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    }
                                                                    className="formInput"
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer2']}
                                                                className="rightAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check2']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    suffix={
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'photo2']}
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                id={`photoInput${key}`}
                                                                                className='quizesAnswerBlock_upload'
                                                                                bordered={false}
                                                                                suffix={
                                                                                    <div>
                                                                                        <img className='customUploadIcon' src={!answersImages[key][1].photo ? imageIcon : answersImages[key][1].photo} alt="imageIcon" onClick={() => SelectPhoto(key, name, 'photo2', 1)} />
                                                                                        {answersImages[key][1].photo && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto(key, name, 'photo2', 1)} />}
                                                                                    </div>
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    }
                                                                    className="formInput"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className='quezeAnswersBlock'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer3']}
                                                                className="leftAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check3']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    suffix={
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'photo3']}
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                id={`photoInput${key}`}
                                                                                className='quizesAnswerBlock_upload'
                                                                                bordered={false}
                                                                                suffix={
                                                                                    <div>
                                                                                        <img className='customUploadIcon' src={!answersImages[key][2].photo ? imageIcon : answersImages[key][2].photo} alt="imageIcon" onClick={() => SelectPhoto(key, name, 'photo3', 2)} />
                                                                                        {answersImages[key][2].photo && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto(key, name, 'photo3', 2)} />}
                                                                                    </div>
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    }
                                                                    className="formInput"
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer4']}
                                                                className="rightAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check4']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    suffix={
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'photo4']}
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                id={`photoInput${key}`}
                                                                                className='quizesAnswerBlock_upload'
                                                                                bordered={false}
                                                                                suffix={
                                                                                    <div>
                                                                                        <img className='customUploadIcon' src={!answersImages[key][3].photo ? imageIcon : answersImages[key][3].photo} alt="imageIcon" onClick={() => SelectPhoto(key, name, 'photo4', 3)} />
                                                                                        {answersImages[key][3].photo && <img className='customUploadIcon_deleteIcon' src={delete_icon} alt="delete_icon" onClick={() => deletePhoto(key, name, 'photo4', 3)} />}
                                                                                    </div>
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    }
                                                                    className="formInput"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </SortableItem>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={() => onAdd(add)}
                                    >
                                        <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />{t(`games.addRow`)}</p>
                                    </Button>
                                </Form.Item>
                            </SortableContainer>
                        </>
                    )}
                </Form.List>
                <Form.Item className='createTaskForm_buttons'>
                    <span className='editProfileButton' onClick={goBack}>{t(`games.backButton`)}</span>
                    <button htmltype="submit" className='editProfileButton'>{t(`games.saveButton`)}</button>
                </Form.Item>
            </Form>
        </>
    );
};

function mapStateToProps(state) {
    return {
        create_task: state.create_task,
        image_url: state.search_images.image_url
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        deleteTaskForm: () => dispatch({ type: 'deleteTaskForm' }),
        stepUp: () => dispatch({ type: 'stepUp' }),
        stepDown: () => dispatch({ type: 'stepDown' }),
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        SELECT_SEARCHIMAGE: (image_url) => dispatch({ type: 'SELECT_SEARCHIMAGE', image_url }),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(Quizes);