import { ApiUrl, instance } from './api';

export const api_leaderBoard_results = {

    async CreateLeaderBoardResult(data) {
        return await instance.post(`${ApiUrl}/api/v1/leaderboard_results`, data)
    },

    async ReadeLeadrBoardResultByLeaderboard(leaderboard_id, skip, limit) {
        return await instance.get(`${ApiUrl}/api/v1/leaderboard_results/by_leaderboard?leaderboard_id=${leaderboard_id}&skip=${skip}&limit=${limit}`)
    },

}