import { Button, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import ArrowsOutCardinal from 'assets/forms/ArrowsOutCardinal.svg';
import Copy from 'assets/forms/Copy.svg';
import Trash from 'assets/forms/Trash.svg';
import purpulePlus from 'assets/purpulePlus.svg';

import '../media.css';
import '../style.css';

const DragHandle = sortableHandle(() => <span><img src={ArrowsOutCardinal} alt="ArrowsOutCardinal" /></span>);

const SortableItem = sortableElement(({ children }) => (
    children
));

const SortableContainer = sortableContainer(({ children }) => {
    return <ol style={{ padding: 0 }}>{children}</ol>;
});

const createTasks = (item) => {
    const array = []
    item.forEach(el => {
        array.push({
            answer: el
        })
    })
    return array
}

function InsertLetter({
    create_task,
    changeType,
    saveTaskForm,
    deleteTaskForm,
    stepUp,
    stepDown
}) {
    const [text, setText] = useState('')
    const [fields, setFields] = useState([])
    const [mount, setMount] = useState(false)

    const [form] = Form.useForm();
    const tasks = Form.useWatch('tasks', form);

    const { t } = useTranslation();

    useEffect(() => {
        if (create_task.create_task !== null) {
            // console.log(create_task.create_task.tasks);
            setText(create_task.create_task.question)
            setFields([
                {
                    name: ['name'],
                    value: create_task.create_task.name,
                },
                {
                    name: ['question'],
                    value: create_task.create_task.question,
                },
                {
                    name: ['tasks'],
                    value: create_task.create_task.tasks.length > 0
                        ? (typeof create_task.create_task.tasks[0] === 'object'
                            ? create_task.create_task.tasks
                            : createTasks(create_task.create_task.tasks)
                        )
                        : create_task.create_task.tasks,
                },
                {
                    name: ['hashtags'],
                    value: (create_task.create_task.hashtags.length === 1 || create_task.create_task.hashtags.length === 0)
                        ? create_task.create_task.hashtags
                        : [{
                            first_hashtag: create_task.create_task.hashtags[0],
                            second_hashtag: create_task.create_task.hashtags[1],
                            third_hashtag: create_task.create_task.hashtags[2],
                            fourth_hashtag: create_task.create_task.hashtags[3]
                        }],
                },
            ])
        }
    }, [])

    useEffect(() => {
        if (document.getElementsByClassName('ant-input-suffix')[0] && !mount) {
            if (fields.length > 0) {
                setMount(true)
                fields[2].value.forEach((el, index) => {
                    findInclusion(el.answer, index)
                })
            }
        }
    })

    useEffect(() => {
        const inputs = document.querySelectorAll('.searchCountInput .ant-input')
        for (let i = 0; i < inputs.length; i++) {
            findInclusion(inputs[i].value, i)
        }
    }, [text])

    const onFinish = (values) => {
        let count = 0
        const tasks = values.tasks.map(el => el.answer)
        if (values?.hashtags?.length) {
            values.hashtags = [
                values.hashtags[0].first_hashtag,
                values.hashtags[0].second_hashtag,
                values.hashtags[0].third_hashtag,
                values.hashtags[0].fourth_hashtag,
            ]
        }
        for (let i = 0; i < tasks.length; i++) {
            if (values.question.split(' ').indexOf(tasks[i]) === -1) {
                count++
            }
        }
        if (values.tasks !== undefined && values.tasks.length !== 0) {
            if (count === tasks.length) {
                message.error("Нет правильных ответов!")
            } else {
                saveTaskForm(values)
                stepUp()
            }
        } else {
            message.error("Вопросы отсутствуют!")
        }
    };

    const goBack = () => {
        deleteTaskForm()
        stepDown()
    }

    const onSortEnd = (oldIndex, newIndex, move) => {
        move(oldIndex, newIndex)
    };

    const findInclusion = (e, key) => {
        let textArray = text.split(' ')
        textArray.forEach((el, index) => {
            textArray[index] = el.split('\n')[0].replace(/[,.;:?!"'|@#()_+=-]\s*$/, "")
        })
        const count = textArray.filter((value) => value === e).length
        if (count === 0) {
            document.getElementsByClassName('ant-input-suffix')[key].style.color = '#D91C10'
            document.getElementsByClassName('ant-input-suffix')[key].innerHTML = 'НЕТ в тексте'
        }
        if (count === 1) {
            document.getElementsByClassName('ant-input-suffix')[key].style.color = '#10D979'
            document.getElementsByClassName('ant-input-suffix')[key].innerHTML = 'Найдено'
        }
        if (count > 1) {
            document.getElementsByClassName('ant-input-suffix')[key].style.color = '#F6BB61'
            document.getElementsByClassName('ant-input-suffix')[key].innerHTML = 'Найдено ' + count
        }
    }

    const onCopy = (name, add) => {
        add(tasks[name], name + 1)
    }

    return (
        <Form
            name="basic"
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            fields={fields}
            autoComplete="off"
            className="modalForm createTaskForm"
            form={form}
        >
            <h2>Хеш-теги</h2>
            <Form.List name="hashtags">
                {(fields, { add, remove, move }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <>
                                <p>Обязательно нужно указать возраст, класс и предмет</p>
                                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 24, marginTop: 12 }} key={`hashtag-${name}`}>
                                    <Form.Item
                                        {...restField}
                                        label={''}
                                        name={[name, 'first_hashtag']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Обязательное поле',
                                            },
                                        ]}
                                    >
                                        <Input className="formInput" prefix="#" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        label={''}
                                        name={[name, 'second_hashtag']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Обязательное поле',
                                            },
                                        ]}
                                    >
                                        <Input className="formInput" prefix="#" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        label={''}
                                        name={[name, 'third_hashtag']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Обязательное поле',
                                            },
                                        ]}
                                    >
                                        <Input className="formInput" prefix="#" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        label={''}
                                        name={[name, 'fourth_hashtag']}
                                        rules={[
                                            {
                                                required: false,
                                                message: t(`games.title_error`),
                                            },
                                        ]}
                                    >
                                        <Input className="formInput" prefix="#" />
                                    </Form.Item>
                                    <img src={Trash} alt="Trash" onClick={() => remove(name)} style={{ cursor: 'pointer' }} />
                                </div>
                            </>
                        ))}
                        {fields.length == 0 &&
                            <Form.Item>
                                <Button
                                    type="link"
                                    onClick={add}
                                >
                                    <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />Добавить хеш-теги</p>
                                </Button>
                            </Form.Item>
                        }
                    </>
                )}
            </Form.List>
            <h2>Задание</h2>
            <Form.Item
                label={t(`games.title`)}
                name="name"
                rules={[
                    {
                        required: true,
                        message: t(`games.title_error`),
                    },
                ]}
            >
                <Input className="formInput" />
            </Form.Item>
            <Form.Item
                label={"Текст"}
                name="question"
                rules={[
                    {
                        required: true,
                        message: "Введите текст",
                    },
                ]}
            >
                <Input.TextArea onChange={(e) => { setText(e.target.value); }} autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.List name="tasks">
                {(fields, { add, remove, move }) => (
                    <>
                        <SortableContainer onSortEnd={(e) => onSortEnd(e.oldIndex, e.newIndex, move)} useDragHandle>
                            {fields.map(({ key, name, ...restField }) => {
                                return (
                                    <SortableItem key={`item-${name}`} index={name} >
                                        <li index={key}>
                                            <div key={key}>
                                                <div>
                                                    <div>
                                                        <div className='quizesQuestionBlock'>
                                                            <div className='form_insertLetterquestionBlock'>
                                                                <div className='form_insertLetterquestionBlock__item'>
                                                                    <p>Ключевое слово</p>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'answer']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Введите слово",
                                                                                // pattern: new RegExp("^[а-яА-ЯёЁa-zA-Z,]*$"),
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input className="formInput searchCountInput" onChange={(e) => findInclusion(e.target.value, key)} suffix=" " />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='form_questionOption needMargin'>
                                                                <DragHandle />
                                                                <img src={Copy} alt="Copy" onClick={() => onCopy(name, add)} />
                                                                <img src={Trash} alt="Trash" onClick={() => remove(name)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </SortableItem>
                                )
                            })}
                            <Form.Item>
                                <Button type="link" onClick={() => add()} >
                                    <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />{t(`games.addLetter`)}</p>
                                </Button>
                            </Form.Item>
                        </SortableContainer>
                    </>
                )}

            </Form.List>
            <Form.Item className='createTaskForm_buttons'>
                <span className='editProfileButton' onClick={goBack}>{t(`games.backButton`)}</span>
                <button htmltype="submit" className='editProfileButton'>{t(`games.saveButton`)}</button>
            </Form.Item>
        </Form>
    );
};

function mapStateToProps(state) {
    return {
        create_task: state.create_task
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        changeType: (text) => dispatch({ type: 'changeType', payload: text }),
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        deleteTaskForm: () => dispatch({ type: 'deleteTaskForm' }),
        stepUp: () => dispatch({ type: 'stepUp' }),
        stepDown: () => dispatch({ type: 'stepDown' }),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(InsertLetter);