import { PDF_START, PDF_SUCCESS } from '../actionTypes'

const initialState = {
    loading: false,
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case PDF_START:
            return {
                loading: true
            }
        case PDF_SUCCESS:
            return {
                loading: false,
            }
        default: return state
    }
}