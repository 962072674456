import anagram from 'assets/docs/instructions/anagram.pdf';
import cards from 'assets/docs/instructions/cards.pdf';
import check_yourself from 'assets/docs/instructions/checkYourself.pdf';
import crossword from 'assets/docs/instructions/crossword.pdf';
import cubes from 'assets/docs/instructions/cubes.pdf';
import findCouple from 'assets/docs/instructions/findCouple.pdf';
import groupDivision from 'assets/docs/instructions/groupDivision.pdf';
import insertLetter from 'assets/docs/instructions/insertLetter.pdf';
import insertLetterSecond from 'assets/docs/instructions/insertLetterSecond.pdf';
import matchElements from 'assets/docs/instructions/matchElements.pdf';
import openWindow from 'assets/docs/instructions/openWindow.pdf';
import quiz from 'assets/docs/instructions/quiz.pdf';
import spaceMathematics from 'assets/docs/instructions/spaceMathematics.pdf';
import writeAnswer from 'assets/docs/instructions/writeAnswer.pdf';
import yesOrNot from 'assets/docs/instructions/yesOrNot.pdf';

export const instructions = {
    TYPE_QUIZ: quiz, 
    TYPE_CARDS: cards, 
    TYPE_ANAGRAM: anagram, 
    TYPE_MATCH_ELEMENTS: matchElements, 
    TYPE_INSERT_LETTER: insertLetter, 
    TYPE_FIND_COUPLE: findCouple, 
    TYPE_OPEN_WINDOW: openWindow, 
    TYPE_WRITE_ANSWER: writeAnswer, 
    TYPE_GROUPS_DIVISION: groupDivision, 
    TYPE_CROSSWORD: crossword, 
    TYPE_YES_OR_NOT: yesOrNot, 
    TYPE_SPACE_MATHEMATICS: spaceMathematics, 
    TYPE_CUBES: cubes, 
    TYPE_INSERT_LETTER_SECOND: insertLetterSecond,
    TYPE_CHECK_YOURSELF: check_yourself,
}