import { FETCH_ONEFOLDER_START, FETCH_ONEFOLDER_SUCCESS, FETCH_ONEFOLDER_ERROR } from '../actionTypes'

const initialState = {
    one_folder: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ONEFOLDER_START:
            return {
                ...state, loading: true
            }
        case FETCH_ONEFOLDER_SUCCESS:
            return {
                ...state, loading: false,
                one_folder: action.one_folder,
            }
        case FETCH_ONEFOLDER_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}