import choose_template from 'assets/instructions/choose_template.svg'
import create_task from 'assets/instructions/create_task.svg'
import print_task from 'assets/instructions/print_task.svg'
import send_link from 'assets/instructions/send_link.svg'
import use_task from 'assets/instructions/use_task.svg'

export const instructions = [
    {
        img: choose_template,
        type: 'choose_template'
    },
    {
        img: create_task,
        type: 'create_task'
    },
    {
        img: use_task,
        type: 'use_task'
    },
    {
        img: send_link,
        type: 'send_link'
    },
    {
        img: print_task,
        type: 'print_task'
    },
]