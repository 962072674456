import {api_leaderBoard} from 'API/leaderBoard';
import { FETCH_LEADERBOARD_START, FETCH_LEADERBOARD_SUCCESS, FETCH_LEADERBOARD_ERROR } from '../actionTypes'
import { getQueryStringFromObject } from 'functions/filter'

export function fetchLeaderBoard(id, group_name, skip, limit) {
    return async dispatch => {
        dispatch(fetchStart())
        try {
            let leader_board
            if(group_name === null){
                leader_board = await api_leaderBoard.ReadeLeadrBoardByExercise(id, '', skip, limit)
            } else {
                let obj = {}
                obj['group_name'] = group_name
                const params = getQueryStringFromObject(JSON.stringify(obj))
                leader_board = await api_leaderBoard.ReadeLeadrBoardByExercise(id, params, skip, limit)
            }            
            dispatch(fetchSuccess(leader_board.data))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchStart() {
    return {
        type: FETCH_LEADERBOARD_START
    }
}

export function fetchSuccess(leader_board) {
    return {
        type: FETCH_LEADERBOARD_SUCCESS,
        leader_board,
    }
}

export function fetchError(e) {
    return {
        type: FETCH_LEADERBOARD_ERROR,
        error: e,
    }
}
