import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


import './style.css';

function ServiceCard({ item }) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    
    return (
        <div className="serviceCard" onClick={() => navigate(`../tasks/${item.game_type}`)}>
            <img src={item.preview} alt="preview" className="serviceCard_image" />
            <div className="serviceCard_text">
                <p className="serviceCard_title">{t(`service.${item.step}.title`)}</p>
                <p className="serviceCard_description">{t(`service.${item.step}.description`)}</p>
            </div>
        </div>
    );
}

export default ServiceCard;
