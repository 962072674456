import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Button, Result } from 'antd';
import { api_exercises } from 'API/exercises';

import { fetchExercises } from 'store/actions/exercises';

import '../style.css';

function DeleteExercises({
    exercise_id,
    closeModal,
    fetchExercises
}) {
    const { t } = useTranslation();

    const onFinish = async () => {
        await api_exercises.DeleteExercis(exercise_id)
        fetchExercises('search_name', '')
        closeModal()
    };

    return (
        <>
            <p className="modalTitle">Удалить задание</p>
            <Result
                status="success"
                title={"Подтвердить удаление задания"}
                extra={[]}
                className="modalDeleteText"
            />
            <Button className="loginButton" onClick={onFinish}>{t(`modal.delete_folder.button`)}</Button>
        </>
    );
};

function mapStateToProps(state) {
    return {
        exercise_id: state.delete_exercise.exercise_id
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        closeModal: () => dispatch({ type: 'closeModal' }),
        fetchExercises: (type, text) => dispatch(fetchExercises(type, text)),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(DeleteExercises);