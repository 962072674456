import { Button, Checkbox, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import ArrowsOutCardinal from 'assets/forms/ArrowsOutCardinal.svg';
import Copy from 'assets/forms/Copy.svg';
import Trash from 'assets/forms/Trash.svg';
import purpulePlus from 'assets/purpulePlus.svg';

import '../media.css';
import '../style.css';

const DragHandle = sortableHandle(() => <span><img src={ArrowsOutCardinal} alt="ArrowsOutCardinal" /></span>);

const SortableItem = sortableElement(({ children }) => (
    children
));

const SortableContainer = sortableContainer(({ children }) => {
    return <ol style={{ padding: 0 }}>{children}</ol>;
});


function SpaceMathematics({
    create_task,
    saveTaskForm,
    deleteTaskForm,
    stepUp,
    stepDown,
    SELECT_SEARCHIMAGE
}) {
    const [fields, setFields] = useState([])
    const [form] = Form.useForm();
    const tasks = Form.useWatch('tasks', form);

    const { t } = useTranslation();

    useEffect(() => {
        if (create_task.create_task !== null) {

            setFields([
                {
                    name: ['name'],
                    value: create_task.create_task.name,
                },
                {
                    name: ['task'],
                    value: create_task.create_task.task,
                },
                {
                    name: ['tasks'],
                    value: create_task.create_task.tasks,
                },
                {
                    name: ['hashtags'],
                    value: (create_task.create_task.hashtags.length === 1 || create_task.create_task.hashtags.length === 0)
                        ? create_task.create_task.hashtags
                        : [{
                            first_hashtag: create_task.create_task.hashtags[0],
                            second_hashtag: create_task.create_task.hashtags[1],
                            third_hashtag: create_task.create_task.hashtags[2],
                            fourth_hashtag: create_task.create_task.hashtags[3]
                        }],
                },
            ])
        }
    }, [])

    useEffect(() => {
        return () => {
            SELECT_SEARCHIMAGE('')
        };
    }, []);

    const onFinish = (values) => {
        let count = 0
        let checkCount = 0
        let checkAnswerCount = 0
        if (values?.hashtags?.length) {
            values.hashtags = [
                values.hashtags[0].first_hashtag,
                values.hashtags[0].second_hashtag,
                values.hashtags[0].third_hashtag,
                values.hashtags[0].fourth_hashtag,
            ]
        }
        if (values.tasks !== undefined && values.tasks.length !== 0) {
            values.tasks.forEach((item, key) => {
                checkAnswerCount = 0
                if ((item.text === undefined || item.text === null) && (item.photo === undefined)) {
                    message.error(`Заполните поле 'Фотография/текст' в элементе ${key + 1}`)
                    count++
                }

                if (
                    (item.check1 === undefined || item.check1 === false) &&
                    (item.check2 === undefined || item.check2 === false) &&
                    (item.check3 === undefined || item.check3 === false) &&
                    (item.check4 === undefined || item.check4 === false) &&
                    (item.check5 === undefined || item.check5 === false) &&
                    (item.check6 === undefined || item.check6 === false)
                ) {
                    checkCount = 1
                }

                for (let i = 1; i < 7; i++) {
                    if (item[`answer${i}`] !== undefined && item[`answer${i}`].length > 0) checkAnswerCount++
                }

                if (checkCount === 1) message.error(`Нет правильного ответа в элементе ${key + 1}`)
                if (checkAnswerCount < 6) message.error(`В ${key + 1} вопросе заполены не все ответы`)
            })
            if (count === 0 && checkCount === 0 && checkAnswerCount === 6) {
                saveTaskForm(values)
                stepUp()
            }
        } else {
            message.error("Вопросы отсутствуют!")
        }
    };

    const goBack = () => {
        deleteTaskForm()
        stepDown()
    }

    const onSortEnd = (oldIndex, newIndex, move) => {
        move(oldIndex, newIndex)
    };

    const onCopy = (key, name, add) => {
        add(tasks[name], name + 1)
    }

    return (
        <>
            <Form
                name="basic"
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                fields={fields}
                autoComplete="off"
                className="modalForm createTaskForm"
                form={form}
            >
                <h2>Хеш-теги</h2>
                <Form.List name="hashtags">
                    {(fields, { add, remove, move }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <>
                                    <p>Обязательно нужно указать возраст, класс и предмет</p>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: 24, marginTop: 12 }} key={`hashtag-${name}`}>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'first_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'second_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'third_hashtag']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Обязательное поле',
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label={''}
                                            name={[name, 'fourth_hashtag']}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t(`games.title_error`),
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" prefix="#" />
                                        </Form.Item>
                                        <img src={Trash} alt="Trash" onClick={() => remove(name)} style={{ cursor: 'pointer' }} />
                                    </div>
                                </>
                            ))}
                            {fields.length == 0 &&
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={add}
                                    >
                                        <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />Добавить хеш-теги</p>
                                    </Button>
                                </Form.Item>
                            }
                        </>
                    )}
                </Form.List>
                <h2>Задание</h2>
                <Form.Item
                    label={t(`games.title`)}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(`games.title_error`),
                        },
                    ]}
                >
                    <Input className="formInput" />
                </Form.Item>
                <Form.List name="tasks">
                    {(fields, { add, remove, move }) => (
                        <>
                            <SortableContainer onSortEnd={(e) => onSortEnd(e.oldIndex, e.newIndex, move)} useDragHandle>
                                {fields.map(({ key, name, ...restField }) => (
                                    <SortableItem key={`item-${name}`} index={name} >
                                        <li index={key}>
                                            <div key={key}>
                                                <div>
                                                    <div>
                                                        {t(`games.quizes.question`)}
                                                        <div className='quizesQuestionBlock'>
                                                            <div className='quizesQuestionBlock_uploadBlock'>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'text']}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            // message: t(`games.title_error`),
                                                                        },
                                                                    ]}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <Input className="formInput" placeholder={t(`games.quizes.question`)} bordered={false} maxLength={14} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'photo']}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        id={`photoInput${key}`}
                                                                        className='quizesQuestionBlock_upload'
                                                                        bordered={false}
                                                                        style={{ width: '10%' }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='form_questionOption'>
                                                                <DragHandle />
                                                                <img src={Copy} alt="Copy" onClick={() => onCopy(key, name, add)} />
                                                                <img src={Trash} alt="Trash" onClick={() => remove(name)} />
                                                            </div>
                                                        </div>
                                                        <Form.Item
                                                            label={t(`games.task`)}
                                                            {...restField}
                                                            name={[name, 'task']}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: t(`games.title_error`),
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder='Задание' className="formInput" />
                                                        </Form.Item>
                                                        {t(`games.quizes.answer`)}
                                                        <div className='quezeAnswersBlock'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer1']}
                                                                className="leftAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check1']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    className="formInput"
                                                                    maxLength={4}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer2']}
                                                                className="rightAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check2']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    className="formInput"
                                                                    maxLength={4}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className='quezeAnswersBlock'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer3']}
                                                                className="leftAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check3']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    className="formInput"
                                                                    maxLength={4}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer4']}
                                                                className="rightAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check4']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    className="formInput"
                                                                    maxLength={4}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className='quezeAnswersBlock'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer5']}
                                                                className="leftAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check5']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    className="formInput"
                                                                    maxLength={4}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'answer6']}
                                                                className="rightAnswer"
                                                            >
                                                                <Input
                                                                    prefix={
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'check6']}
                                                                                className="formAnswerCheck"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox />
                                                                            </Form.Item>
                                                                        </>
                                                                    }
                                                                    className="formInput"
                                                                    maxLength={4}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </SortableItem>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={() => add()}
                                    >
                                        <p className='createTaskForm_addRow'><img src={purpulePlus} alt="purpulePlus" />{t(`games.addRow`)}</p>
                                    </Button>
                                </Form.Item>
                            </SortableContainer>
                        </>
                    )}
                </Form.List>
                <Form.Item className='createTaskForm_buttons'>
                    <span className='editProfileButton' onClick={goBack}>{t(`games.backButton`)}</span>
                    <button htmltype="submit" className='editProfileButton'>{t(`games.saveButton`)}</button>
                </Form.Item>
            </Form>
        </>
    );
};

function mapStateToProps(state) {
    return {
        create_task: state.create_task,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        deleteTaskForm: () => dispatch({ type: 'deleteTaskForm' }),
        stepUp: () => dispatch({ type: 'stepUp' }),
        stepDown: () => dispatch({ type: 'stepDown' }),
        SELECT_SEARCHIMAGE: (image_url) => dispatch({ type: 'SELECT_SEARCHIMAGE', image_url }),
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(SpaceMathematics);