import { useEffect, useRef, useState } from 'react';

import '../style.css';
import './style.css';

const bgArray = ['#ff7675', '#fdcb6e', '#00b894', '#cf6a87']

function Quiz({ game }) {
    const [answers, setAnswers] = useState([])
    const temp = useRef()

    const startFunction = () => {
        const arr = []
        game[0].task_answers.forEach(item => {
            arr.push({
                answer: item.answer,
                photo: item.photo
            })
        })
        setAnswers(arr.sort(() => Math.random() - 0.5))
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    return (
        <div className='exercisesCardBg_content'>
            <div className='exercisesCardBg_content__card'>
                {game[0].photo !== null
                    ? <img src={game[0].photo} />
                    : <p>{game[0].text}</p>
                }
            </div>
            <div className='quizBgAnswers'>
                {answers.map((item, key) => (
                    <div style={{ border: `1px solid ${bgArray[key]}`, background: '#FFFFFF', height: '100%' }} key={key}>
                        {item.photo && <img src={item.photo} alt='answerImage' className='quizBgAnswersImage' />}
                        <p>{item.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Quiz;
