import { FETCH_FAQ_START, FETCH_FAQ_SUCCESS, FETCH_FAQ_ERROR } from '../actionTypes'

const initialState = {
    faq: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FAQ_START:
            return {
                ...state, loading: true
            }
        case FETCH_FAQ_SUCCESS:
            return {
                ...state, loading: false,
                faq: action.faq,
            }
        case FETCH_FAQ_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}