import { api_bing } from 'API/bing'
import { FETCH_SEARCHIMAGES_START, FETCH_SEARCHIMAGES_SUCCESS, FETCH_SEARCHIMAGES_ERROR } from '../actionTypes'

export function fetchSearchImages(search) {
    return async dispatch => {
        dispatch(fetchStart())
        try {
            const images = await api_bing.BingSearchImages(search)
            dispatch(fetchSuccess(images.data.value))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchStart() {
    return {
        type: FETCH_SEARCHIMAGES_START
    }
}

export function fetchSuccess(images) {
    return {
        type: FETCH_SEARCHIMAGES_SUCCESS,
        images,
    }
}

export function fetchError(e) {
    return {
        type: FETCH_SEARCHIMAGES_ERROR,
        error: e,
    }
}
