import './style.css';

const Starsflake = (props) => {
    return (
        <p className='Starsflake' id={`item${props.id}`} style={props.style} />
        
    )
}

function Star() {
    const star = () => {
        let animationDelay = '0s';
        let fontSize = '100px';
        let arr = Array.from('starstarstar')
        return arr.map((el, i) => {
            // animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
            let style = {
                // animationDelay,
            }
            return (<Starsflake key={i} id={i} style={style} />)
        })
    }

    return (
        <div className='starsAnimation space_bg'>
            {star()}
        </div>
    )
}

export default Star;