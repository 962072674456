import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { api_subscription } from 'API/subscriptions';
import { fetchUserMe, fetchUserMeSubscription } from 'store/actions/userMe';

import '../style.css';

function SubscribeCancel({ closeModal, fetchUserMe, fetchUserMeSubscription }) {

    const onFinish = async () => {
        await api_subscription.CancelSubscription()
            .then(() => {
                fetchUserMe()
                fetchUserMeSubscription()
                closeModal()
                message.success('Подписка успешно отменена!')
            })
    };

    return (
        <>
            <p className="modalTitle" style={{ textAlign: 'center' }}>Отменить подписку?</p>
            <p
                style={{
                    textAlign: 'center',
                    marginTop: 16,
                    fontSize: 16,
                    fontWeight: 500
                }}
            >
                После отмены подписки у Вас отключится регулярный платеж и по истечению нынешней подписки будет активирована подписка Start
            </p>
            <Button
                className="loginButton"
                onClick={onFinish}
                style={{ marginTop: 16 }}
            >
                Отменить подписку
            </Button>
        </>
    );
};

function mapDispatvhToProps(dispatch) {
    return {
        closeModal: () => dispatch({ type: 'closeModal' }),
        fetchUserMe: () => dispatch(fetchUserMe()),
        fetchUserMeSubscription: () => dispatch(fetchUserMeSubscription()),
    }
}

export default connect(null, mapDispatvhToProps)(SubscribeCancel);