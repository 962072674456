import star from 'assets/backgrounds/games/animation/star.png';

import './style.css';

const Cloudflake = (props) => {
    return (
        <p className='Cloudflake' id={`item${props.id}`} style={props.style}>
            <img src={star} alt="star" />
        </p>
    )
}

function Cloud() {
    const cloud = () => {
        let animationDelay = '0s';
        let top = '100px';
        let left = 0
        let animation = ''
        let arr = Array.from('Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!!')
        return arr.map((el, i) => {
            animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
            top = `${(Math.random() * 2000)}px`;
            left = `${(Math.random() * 2000)}px`;
            animation = `blinker 1.5s linear infinite`
            let style = {
                animationDelay,
                top,
                left,
                animation
            }
            return (<Cloudflake key={i} id={i} style={style} />)
        })
    }

    return (
        <div className='cloudAnimation cloud_bg'>
            {cloud()}
        </div>
    )
}

export default Cloud;