import { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { message, Select } from 'antd';
import { fetchOneExercises } from "store/actions/oneExercises";
import { fetchLeaderBoard } from "store/actions/leaderBoard";
import { likeAction } from "store/actions/likeAction";
import { fetchLikesUsers } from 'store/actions/likesUsers';
import { editGame } from 'functions/editGames';
import { fetchGroups } from "store/actions/groups";

import CustomTable from "components/components/customTable/CustomTable";
import { players_data } from 'lists/table_data';

import noPlayers from 'assets/noPlayers.svg'
import link from 'assets/link.svg'
import plus from 'assets/plus.svg'

import './style.css'
import { fetchLeaderBoardResultsById } from "store/actions/leaderboardResults";

const { Option } = Select;

function Results({
    one_exercises,
    leader_board,
    likes_users,
    groups,
    setVisible,
    fetchOneExercises,
    fetchGroups,
    fetchLeaderBoard,
    likeAction,
    fetchLikesUsers,
    stepUp,
    selectTaskPattern,
    saveTaskForm,
    selectTheme,
    selectPhoto,
    editGameProps,
    SAVE_EXERCISESID_CREATEGROUP,
    fetchLeaderBoardResultsById,
}) {
    const { t } = useTranslation();
    const [groupFilter, setGroupFilter] = useState(null)
    const [domen, setDomen] = useState(window.location.hostname)
    const [puth, setPuth] = useState('https')
    const [groupId, setGropuId] = useState('all')
    const navigate = useNavigate()
    const { id } = useParams()
    const temp = useRef()

    const startFunction = () => {
        fetchOneExercises(id)
        fetchLeaderBoard(id, null, 0, 1000)
        fetchGroups(id)
        if (domen === 'localhost') {
            setDomen('localhost:3000')
            setPuth('http')
        }
    }

    temp.current = startFunction

    useEffect(() => {
        temp.current()
    }, [])

    const shareInvite = () => {
        navigator.clipboard.writeText(`${puth}://${domen}/task/${id}/${groupId}`)
        message.success(t(`tasks.share_message`))
    }

    const handleChange = (value) => {
        setGropuId(value)
    }

    const columns = [
        {
            title: t(`table.players.place`),
            dataIndex: 'place',
            sorter: {
                compare: (a, b) => a.place - b.place,
                multiple: 1,
            },
        },
        {
            title: t(`table.players.name`),
            dataIndex: 'name',
        },
        {
            title: t(`table.players.score`),
            dataIndex: 'score',
            sorter: {
                compare: (a, b) => a.score - b.score,
                multiple: 2,
            },
        },
        {
            title: t(`table.players.time`),
            dataIndex: 'time',
            sorter: {
                compare: (a, b) => a.time - b.time,
                multiple: 3,
            },
        },
        // {
        //     title: t(`table.players.group`),
        //     dataIndex: 'group',
        // },
        {
            title: t(`table.players.action`),
            dataIndex: 'action',
        },
    ];

    const remoteLike = (owner_id, mode) => {
        likeAction(mode, 'User', String(owner_id))
        fetchLikesUsers()
        fetchLeaderBoard(id, groupFilter, 0, 1000)
    }

    const navFunc = (id) => {
        navigate(`../profileUser/${id}`)
    }

    const onClickAckionButton = () => {
        stepUp()
        selectTaskPattern(one_exercises.game_type)
        saveTaskForm(editGame(one_exercises))
        selectPhoto(editGame(one_exercises).photo)
        selectTheme(one_exercises.background)
        editGameProps()
        navigate(`../create_task`)
    }

    const onClickFilterGroup = (group_name) => {
        setGroupFilter(group_name)
        fetchLeaderBoard(id, group_name, 0, 1000)
    }

    const onClickViewResult = (leaderboard_id, time) => {
        fetchLeaderBoardResultsById(leaderboard_id, 0, 100, time)
        .then(res => {
            if(res.length > 0) {
                setVisible('results')
            } else {
                message.warning('К сожалению у нас нет результатов этого пользователя!')
            }
        })
    }
    
    return (
        <>
            {one_exercises !== null &&
                <div className='resultsBlock'>
                    <div className='resultsBlock_taskTitle'>
                        {one_exercises.name}
                    </div>
                    <div className='resultsBlock_actions'>
                        <div className='selectInviteLink'>
                            {/* <div className='selectInviteLink_link' onClick={shareInvite}>
                                <img src={link} alt="link" />
                                {puth}://{domen}/task/{id}/{groupId}
                            </div>
                            <Select
                                defaultValue="all"
                                style={{ width: 230 }}
                                onChange={handleChange}
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <p className='createGroup' onClick={() => { setVisible('createGroup'); SAVE_EXERCISESID_CREATEGROUP(id) }}><img src={plus} alt="plus" />Создать новую группу</p>
                                    </>
                                )}
                            >
                                <Option value="all">{t(`myTask.all_groups`)}</Option>
                                {groups.map((item, key) => (
                                    <Option value={item.id} key={`group${key}`}>{item.name}</Option>
                                ))}
                            </Select> */}
                        </div>
                        <button onClick={onClickAckionButton}>{t(`myTask.edit_task`)}</button>
                    </div>
                    <div className='resultsBlock_tableBlock'>
                        <h1>{t(`myTask.players`)}</h1>
                        {/* <div className='filterTablePlayers'>
                            <div className={`filterTablePlayers_filter ${groupFilter === null && 'active'}`} onClick={() => onClickFilterGroup(null)}>{t(`myTask.select_all`)}</div>
                            {groups.map((item, key) => (
                                <div key={`tableGroup${key}`} className={`filterTablePlayers_filter ${groupFilter === item.name && 'active'}`} onClick={() => onClickFilterGroup(item.name)}>{item.name}</div>
                            ))}
                        </div> */}
                        <div className='tableBlock'>
                            <CustomTable columns={columns} data={players_data(leader_board, remoteLike, navFunc, likes_users, (leaderboard_id, time) => onClickViewResult(leaderboard_id, time))} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
        leader_board: state.leader_board.leader_board,
        likes_users: state.likes_users.likes_users,
        groups: state.groups.groups
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
        fetchOneExercises: (id) => dispatch(fetchOneExercises(id)),
        fetchGroups: (id) => dispatch(fetchGroups(id)),
        fetchLeaderBoard: (id, group_name, skip, limit) => dispatch(fetchLeaderBoard(id, group_name, skip, limit)),
        likeAction: (mode, object_type, object_id) => dispatch(likeAction(mode, object_type, object_id)),
        fetchLikesUsers: () => dispatch(fetchLikesUsers()),
        stepUp: () => dispatch({ type: 'stepUp' }),
        selectTaskPattern: (text) => dispatch({ type: 'selectTaskPattern', payload: text }),
        saveTaskForm: (text) => dispatch({ type: 'saveTaskForm', payload: text }),
        selectTheme: (text) => dispatch({ type: 'selectTheme', payload: text }),
        selectPhoto: (text) => dispatch({ type: 'selectPhoto', payload: text }),
        editGameProps: () => dispatch({ type: 'editGame' }),
        SAVE_EXERCISESID_CREATEGROUP: (id) => dispatch({ type: 'SAVE_EXERCISESID_CREATEGROUP', id }),
        fetchLeaderBoardResultsById: (leaderboard_id, skip, limit, time) => dispatch(fetchLeaderBoardResultsById(leaderboard_id, skip, limit, time))
    }
}



export default connect(mapStateToProps, mapDispatvhToProps)(Results);
