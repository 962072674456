import { FETCH_EXERCISES_ERROR, FETCH_EXERCISES_START, FETCH_EXERCISES_SUCCESS, SAVE_ACTIVE_HASHTAGS_MY_TASKS_SUCCESS, SEARCH_EXERCISES } from '../actionTypes'

const initialState = {
    exercises: [],
    active_hashtags: [],
    search: '',
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_EXERCISES_START:
            return {
                ...state, loading: true
            }
        case FETCH_EXERCISES_SUCCESS:
            return {
                ...state, loading: false,
                exercises: action.exercises,
            }
        case SAVE_ACTIVE_HASHTAGS_MY_TASKS_SUCCESS:
            return {
                ...state, loading: false,
                active_hashtags: action.hashtags,
            }
        case FETCH_EXERCISES_ERROR:
            return {
                ...state, loading: false
            }
        case SEARCH_EXERCISES:
            return {
                ...state,
                search: action.payload
            }
        default: return state
    }
}