import './style.css';
import '../style.css';

export const Anagram = ({ game }) => {
    return (
        game.map((el, index) => (
            <div className='Pdf_task' style={{ marginTop: '3mm' }}>
                <div className='anagramPdf_taskHeader'>
                    <p className='pdfTaskNumber'>{index + 1}</p>
                    <p>{el.text}</p>
                </div>
                <div className='anagramPdf_task' key={`anagramPdf_task${index}`}>
                    {el.photo !== null &&
                        <div className='anagramPdf_task__item__question'>
                            <img src={el.photo} alt="el.photo" />
                        </div>
                    }
                    <div className='anagramPdf_task__item__answer'>
                        <div className='anagramPdf_task__item__answer__text'>
                            {el.answer.split('').sort(() => Math.random() - 0.5).map((val, key) => (
                                <p key={`anagramPdf_task__item__answer__text${key}`}>{val.toUpperCase()}</p>
                            ))
                            }
                        </div>
                        <div className='anagramPdf_task__item__answer__square'>
                            {el.answer.split('').sort(() => Math.random() - 0.5).map((val, key) => (
                                <div key={`anagramPdf_task__item__answer__square${key}`} className="pdfSquare" />
                            ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        ))
    )
}