import { FETCH_USER_START, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from '../actionTypes'

const initialState = {
    user: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_START:
            return {
                ...state, loading: true
            }
        case FETCH_USER_SUCCESS:
            return {
                ...state, loading: false,
                user: action.userMe,
            }
        case FETCH_USER_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}