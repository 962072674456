import { FETCH_JOINTEXERCISES_START, FETCH_JOINTEXERCISES_SUCCESS, FETCH_JOINTEXERCISES_ERROR } from '../actionTypes'

const initialState = {
    joint_exercises: [],
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_JOINTEXERCISES_START:
            return {
                ...state, loading: true
            }
        case FETCH_JOINTEXERCISES_SUCCESS:
            return {
                ...state, loading: false,
                joint_exercises: action.joint_exercises,
            }
        case FETCH_JOINTEXERCISES_ERROR:
            return {
                ...state, loading: false
            }
        default: return state
    }
}